<template>
  <Schema>
    <div class="w-full">
      <!-- Breadcrumb -->
      <div class="flex w-full mb-4 text-sm">
        <div class="mb-4 text-gray-400">
          <router-link to="/app" class="hover:text-blue-500 transition-colors">
            Home
          </router-link>
          <span class="text-blue-500"> • Usuários</span>
        </div>
      </div>

      <div class="flex flex-col">
        <h1 class="text-3xl text-gray-600">Gerenciamento de usuários</h1>
        <p class="text-gray-400 max-w-xs mt-1">
          Gerencie os usuários do Albert Einstein
        </p>
      </div>

      <div class="mt-10">
        <DataTable
          :value="usersData"
          paginatorTemplate="FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink RowsPerPageDropdown"
          paginator
          :rows="15"
          :rowsPerPageOptions="[15, 20, 30, 50]"
          currentPageReportTemplate="{first} de {last} de {totalRecords}"
          :globalFilterFields="['name', 'email']"
          v-model:filters="filters"
          class="table-fixed"
        >
          <template #header>
            <span class="p-input-icon-left">
              <i class="pi pi-search pt-1" />
              <input
                v-model="filters['global'].value"
                placeholder="Pesquisa global"
                class="border-2 border-gray-300 p-3 pl-10 rounded-lg w-full text-sm mt-2"
              />
            </span>
          </template>
          <Column field="name" header="Nome"> </Column>
          <Column field="email" header="Email"> </Column>
          <Column header="Ativo">
            <template #body="{ data }">
              <i
                v-if="data.active"
                class="ph ph-check-circle text-green-500 text-2xl"
              ></i>
              <i v-else class="ph ph-x-circle text-red-500 text-2xl"></i>
            </template>
          </Column>
          <Column header="Permissão" class="w-[250px]">
            <template #body="{ data }">
              <Dropdown
                v-if="isProfile"
                v-model="data.profileId"
                :options="profilesData"
                optionLabel="name"
                optionValue="id"
                placeholder="Select a City"
                class="w-full md:w-14rem"
                @change="handleUpdateUser($event, data.id, data.profileId)"
              />
            </template>
          </Column>
          <Column header="Ações" class="w-[200px]">
            <template #body="{ data }">
              <button
                :disabled="!isProfile"
                title="Clique para selecionar as unidades"
                type="button"
                class="bg-cyan-500 text-white px-4 py-3 rounded-full text-sm hover:brightness-90 transition-all disabled:opacity-60 disabled:cursor-not-allowed"
                @click="onToggleModal(data)"
              >
                Unidades
              </button>
              <button
                :disabled="!isProfile"
                title="Clique para selecionar as unidades"
                type="button"
                class="bg-cyan-700 text-white px-4 py-3 ml-4 rounded-full text-sm hover:brightness-90 transition-all disabled:opacity-60 disabled:cursor-not-allowed"
                @click="onToggleModalLocals(data)"
              >
                Locais
              </button>
            </template>
          </Column>
        </DataTable>
      </div>

      <ModalSelectUnits
        v-if="selectedUser"
        :onToggleModal="onToggleModal"
        :isActiveModal="isActiveModal"
        :currentId="currentId"
        :userUnits="selectedUser"
        :userService="userService"
        :profile="isProfile"
        @handleGetUsers="handleGetUsers"
      />

      <ModalSelectLocals
        v-if="selectedUser"
        :onToggleModal="onToggleModalLocals"
        :isActiveModalLocals="isActiveModalLocals"
        :currentIdLocals="currentIdLocals"
        :userUnits="selectedUser"
        :userService="userService"
        :profile="isProfile"
        @handleGetUsers="handleGetUsers"
      />
      <ConfirmDialog />
    </div>
  </Schema>
</template>

<script>
import { useToast } from 'vue-toastification';

import Schema from '../../components/Schema/index.vue';
import ModalSelectUnits from '../../components/Modal/ModalSelectUnits.vue';
import ModalSelectLocals from '../../components/Modal/ModalSelectLocals.vue';
import { FilterMatchMode } from 'primevue/api';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Dropdown from 'primevue/dropdown';
import ConfirmDialog from 'primevue/confirmdialog';
import { useConfirm } from 'primevue/useconfirm';
import Cookies from 'vue-cookies';
import { UserService } from '../../services';

export default {
  name: 'ManagementUsersView',
  components: {
    Schema,
    ModalSelectUnits,
    ModalSelectLocals,
    Dropdown,
    DataTable,
    Column,
    ConfirmDialog,
  },

  setup() {
    const toast = useToast();
    return { toast };
  },

  data() {
    return {
      usersData: [],
      profilesData: [],

      currentId: 0,
      currentIdLocals: 0,

      isActiveModalLocals: false,
      isActiveModal: false,
      userService: null,
      isProfile: false,
      token: null,
      filters: {},
      selectedUser: null,
      confirm: useConfirm(),
    };
  },

  mounted() {
    this.token = Cookies.get('token');
    this.userService = new UserService(this.token);

    this.handleGetUsers();
    this.handleGetProfiles();

    this.isProfile = this.$store.state.Default.profile;
  },

  updated() {
    this.isProfile = this.$store.state.Default.profile;
  },

  created() {
    this.initFilters();
  },

  computed: {
    isLoading: {
      set(value) {
        this.$store.commit('Default/CHANGE_ISLOADING', value);
      },
    },
  },

  methods: {
    initFilters() {
      this.filters = {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      };
    },

    async handleGetUsers() {
      try {
        this.isLoading = true;
        const data = await this.userService.getUsers();

        data.sort((a, b) =>
          a.name.localeCompare(b.name, undefined, { sensitivity: 'base' })
        );

        if (this.selectedUser) {
          this.selectedUser = data.find(
            (user) => user.id === this.selectedUser.id
          );
        }

        this.usersData = data;
      } catch (error) {
        console.log(error);
      } finally {
        this.isLoading = false;
      }
    },

    async handleGetProfiles() {
      try {
        this.isLoading = true;
        const data = await this.userService.getProfiles();
        this.profilesData = data;
      } catch (error) {
        console.log(error);
      } finally {
        this.isLoading = false;
      }
    },

    // Função para abrir o modal
    onToggleModal(user) {
      this.isActiveModal = !this.isActiveModal;
      this.currentId = user.id;
      this.selectedUser = user;
    },

    // Função para abrir o modal de locais
    onToggleModalLocals(user) {
      this.isActiveModalLocals = !this.isActiveModal;
      this.currentIdLocals = user.id;
      this.selectedUser = user;
    },

    async handleUpdateUser(event, userId, profileId) {
      this.confirm.require({
        target: event.currentTarget,
        message: 'Deseja realmente alterar o perfil do usuário?',
        icon: 'pi pi-exclamation-triangle',
        acceptLabel: 'Sim',
        rejectLabel: 'Não',
        accept: async () => {
          const userIdCookie = Number(Cookies.get('user_id'));

          const profile = this.profilesData.find(
            (profile) => profile.id === profileId
          );

          const dataToUpdate = {
            id: userId,
            profileId: Number(profileId),
            role: profile.name,
          };

          try {
            this.isLoading = true;
            const data = await this.userService.updateUser(dataToUpdate);

            this.toast.success('Perfil do usuário modificado com sucesso!');
            this.$store.commit('Default/CHANGE_PROFILE', false);

            const dataProfile = await this.userService.getProfileById(
              data.profileId
            );

            if (userId === userIdCookie) {
              this.$store.commit('Auth/CHANGE_ACCESS', dataProfile.Access);
              this.$store.commit('Auth/CHANGE_PROFILENAME', dataProfile.name);
            }
          } catch (error) {
            console.log(error);
          } finally {
            this.isLoading = false;
            this.handleGetUsers();
          }
        },
        reject: () => {
          this.handleGetUsers();
        },
      });
    },
  },
};
</script>
