<template>
  <Schema>
    <form class="w-full max-w-3xl">
      <!-- Breadcrumb -->
      <div class="flex w-full mb-4 text-sm">
        <div class="mb-4 text-gray-400">
          <router-link to="/app" class="hover:text-blue-500 transition-colors">
            Home
          </router-link>
          <span class="text-blue-500"> • Registro de ocorrência</span>
        </div>
      </div>

      <div class="flex flex-col">
        <h1 class="text-3xl text-gray-600">Registro de ocorrência</h1>
        <p class="text-gray-400 max-w-xs mt-1">
          Registre uma ocorrência conforme o agendamento
        </p>
      </div>

      <div v-show="step === 0">
        <div class="flex items-center gap-5 mt-14">
          <button
            type="button"
            class="flex-1 border-2 border-dashed text-gray-500 border-gray-400 h-[250px] rounded-lg text-2xl hover:brightness-90 transition-all"
            @click="handleChangeStep(2.1)"
          >
            <i class="ph ph-calendar-blank"></i> <br />
            Registro com <br />
            agendamento
          </button>

          <button
            type="button"
            class="flex-1 border-2 border-dashed border-gray-400 h-[250px] text-white text-2xl bg-blue-500 rounded-lg hover:brightness-90 transition-all"
            @click="handleChangeStep(2.2)"
          >
            <i class="ph ph-arrow-up-right"></i> <br />
            Registro sem <br />
            agendamento
          </button>
        </div>
      </div>

      <!-- Com agendamento -->
      <div v-show="step === 2.1">
        <div class="flex justify-end mt-14 relative">
          <div class="flex items-center gap-2">
            <button
              type="button"
              @click="isActiveFilter = !isActiveFilter"
              class="bg-blue-500 text-white px-5 py-2 rounded-full flex items-center gap-2 hover:brightness-90 transition-all"
            >
              <i class="ph ph-funnel"></i>
              Abrir filtro
            </button>

            <button
              type="button"
              v-if="isActiveFilter"
              @click="handleReset"
              class="bg-blue-500 text-white px-5 py-2 rounded-full flex items-center gap-2 hover:brightness-90 transition-all"
            >
              <i class="ph ph-funnel"></i>
              Resetar filtro
            </button>
          </div>

          <div
            :class="`mt-4 flex items-end gap-4 transition-all origin-top-right absolute right-0 bottom-12 z-40 bg-blue-500 p-4 rounded-lg w-full shadow-xl ${
              isActiveFilter ? 'scale-100' : 'scale-0'
            }`"
          >
            <div class="flex flex-col flex-1">
              <label class="text-sm text-white">Filtrar por data</label>
              <input
                v-model="filterDate"
                type="date"
                class="border-2 border-gray-300 h-10 rounded-lg w-full text-md mt-2 px-4 text-sm text-gray-500"
              />
            </div>

            <div class="flex flex-col flex-1">
              <label class="text-sm text-white">Filtrar por sala</label>

              <select
                v-model="filterRoom"
                class="border-2 border-gray-300 h-10 rounded-lg w-full text-md mt-2 px-4 text-sm text-gray-500"
              >
                <option value="">Selecione uma sala</option>
                <option v-for="room in rooms" :key="room.id" :value="room.id">
                  {{ room.name }}
                </option>
              </select>
            </div>

            <div>
              <button
                type="button"
                class="h-10 w-10 bg-white rounded-lg flex items-center justify-center hover:brightness-90 transition-all"
                @click="handleSearch"
              >
                <i class="ph ph-magnifying-glass"></i>
              </button>
            </div>
          </div>
        </div>

        <table class="w-full mt-10">
          <thead class="text-white text-sm">
            <tr>
              <td class="px-4 py-2 rounded-l-lg bg-blue-500">
                Número do agendamento
              </td>
              <td class="px-4 py-2 bg-blue-500">Data do agendamento</td>
              <td class="px-4 py-2 bg-blue-500">Sala</td>
              <td class="px-4 py-2 rounded-r-lg bg-blue-500"></td>
            </tr>
          </thead>

          <tbody class="text-gray-500 text-sm">
            <tr
              class="border-b border-gray-300"
              v-for="item in appointments"
              :key="item.id"
            >
              <td class="px-4 py-2 opacity-90">#{{ item.id }}</td>
              <td class="px-4 py-2 opacity-90">{{ item.date }}</td>
              <td class="px-4 py-2 opacity-90">{{ item.room }}</td>
              <td class="px-4 py-2 opacity-90">
                <button
                  type="button"
                  @click="handleGetAppointment(item.id)"
                  class="bg-blue-500 text-white flex items-center p-2 rounded-lg hover:brightness-90 transition-all"
                >
                  <i class="ph ph-link-simple"></i>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <!-- Sem agendamento -->
      <div v-show="step === 2.2">
        <section class="mt-14">
          <div class="flex-1">
            <label class="text-sm text-gray-500">
              Sala/Auditórios

              <select
                v-model="roomId"
                @change="handleGetLocalAndUnit($event)"
                class="border-2 border-gray-300 h-12 rounded-lg w-full text-md mt-2 pl-4"
              >
                <option value="" selected disabled>Selecione uma sala</option>
                <option v-for="room in rooms" :key="room.id" :value="room.id">
                  {{ room.name }}
                </option>
              </select>
            </label>
          </div>
        </section>

        <section class="mt-4">
          <div class="flex-1">
            <label class="text-sm text-gray-500">
              Local

              <input
                disabled
                :value="
                  localName === '' ? 'Selecione uma sala/auditórios' : localName
                "
                class="border-2 border-gray-300 h-12 rounded-lg w-full text-md mt-2 pl-4"
              />
            </label>
          </div>
        </section>

        <section class="mt-4">
          <div class="flex-1">
            <label class="text-sm text-gray-500">
              Unidade

              <input
                disabled
                :value="
                  unitName === '' ? 'Selecione uma sala/auditórios' : unitName
                "
                class="border-2 border-gray-300 h-12 rounded-lg w-full text-md mt-2 pl-4"
              />
            </label>
          </div>
        </section>

        <section class="mt-4">
          <div class="flex-1">
            <label class="text-sm text-gray-500">
              Registrar evento

              <input
                v-model="event"
                class="border-2 border-gray-300 h-12 rounded-lg w-full text-md mt-2 pl-4"
              />
            </label>
          </div>
        </section>

        <section class="mt-6 w-full">
          <label
            class="border-2 border-dashed border-gray-300 flex w-full rounded-lg flex justify-center py-14 hover:border-blue-500 hover:text-blue-500 transition-colors text-gray-400 cursor-pointer"
          >
            Anexar arquivo

            <input @change="newFileSelected" type="file" class="hidden" />
          </label>
        </section>

        <span
          v-show="selectedFileName !== ''"
          class="text-gray-500 text-sm block mt-2"
          >Arquivo selecionado: {{ selectedFileName }}</span
        >

        <section class="mt-6 flex justify-between items-center">
          <button
            @click="handleChangeStep(0)"
            type="button"
            class="bg-blue-500 py-2 text-white text-sm px-8 rounded-full flex items-center justify-center disabled:opacity-70 disabled:brightness-100 disabled:cursor-not-allowed hover:brightness-90 transition-all"
          >
            <span>Voltar</span>
          </button>

          <button
            type="button"
            :disable="event === ''"
            v-show="profile"
            @click="handleCreateNewOccurrence"
            class="bg-blue-500 py-2 text-white text-sm px-8 rounded-full flex items-center justify-center disabled:opacity-70 disabled:brightness-100 disabled:cursor-not-allowed hover:brightness-90 transition-all"
          >
            <span>Salvar registro de ocorrência</span>
          </button>
        </section>
      </div>
    </form>
  </Schema>
</template>

<script>
import { useToast } from 'vue-toastification';
import Schema from '../../components/Schema/index.vue';
import api from '../../lib/axios';

import Cookies from 'vue-cookies';

export default {
  name: 'OccurrenceView',

  setup() {
    const toast = useToast();
    return { toast };
  },

  components: {
    Schema,
  },

  data() {
    return {
      step: 0,
      isActiveFilter: false,

      localName: '',

      roomName: '',
      unitName: '',
      event: '',
      roomId: '',

      filterDate: '',
      filterRoom: '',

      rooms: [],

      appointments: [],
      appointmentsBackup: [],

      selectedFile: null,
      selectedFileName: '',
    };
  },

  mounted() {
    this.$store.dispatch('Rooms/getRoomsByData', '/rooms');

    this.handleGetAllAppointments();
    this.handleGetAllRooms();
  },

  computed: {
    roomsdata() {
      return this.$store.state.Rooms.roomsdata;
    },

    profile() {
      return this.$store.state.Default.profile;
    },

    profileName() {
      return this.$store.state.Auth.profileName;
    },
  },

  methods: {
    handleChangeStep(step) {
      this.step = step;
    },

    sortFunction(a, b) {
      a = a.id;
      b = b.id;

      return b - a;
    },

    handleGetAllAppointments() {
      const token = Cookies.get('token');

      api
        .get('/appointments', {
          headers: {
            token: token,
          },
        })
        .then((res) => {
          const userId = this.$cookies.get('user_id');

          const data = res.data.appointments.map((item) => {
            const currentDate = item.date;
            const dateRef = currentDate.split('-');
            const dateFormatted =
              dateRef[2] + '/' + dateRef[1] + '/' + dateRef[0];

            const res = {
              id: item.id,
              date: dateFormatted,
              room: item.room,
              getSolicitationId: item.getSolicitationId,
            };

            return res;
          });

          if (this.profileName === 'Básico') {
            const userId = Cookies.get('user_id');
            const filterReservation = data.filter(
              (appointment) =>
                appointment.getSolicitationId.createdBy === Number(userId)
            );
            this.appointments = filterReservation.sort(this.sortFunction);
            this.appointmentsBackup = filterReservation.sort(this.sortFunction);
          } else if (this.profileName === 'Técnico') {
            const token = Cookies.get('token');

            api
              .get(`/users/${Number(userId)}`, {
                headers: {
                  token: token,
                },
              })
              .then((response) => {
                if (response.data.Units.length > 0) {
                  let unitsArrayId = [];

                  for (let i = 0; i < response.data.Units.length; i++) {
                    unitsArrayId.push(response.data.Units[i].id);
                  }

                  if (unitsArrayId.length > 0) {
                    const resultArray = data.filter((item) =>
                      unitsArrayId.includes(item.getSolicitationId.unitId)
                    );

                    let localsArrayId = [];

                    for (let i = 0; i < response.data.Locals.length; i++) {
                      localsArrayId.push(response.data.Locals[i].id);
                    }

                    if (localsArrayId.length > 0) {
                      const resultArrayUnitsAndLocals = resultArray.filter(
                        (item) =>
                          localsArrayId.includes(item.getSolicitationId.localId)
                      );

                      this.appointments = resultArrayUnitsAndLocals.sort(
                        this.sortFunction
                      );
                      this.appointmentsBackup = resultArrayUnitsAndLocals.sort(
                        this.sortFunction
                      );
                    } else {
                      this.appointments = resultArray.sort(this.sortFunction);
                      this.appointmentsBackup = resultArray.sort(
                        this.sortFunction
                      );
                    }
                  } else {
                    this.appointments = data.sort(this.sortFunction);
                    this.appointmentsBackup = data.sort(this.sortFunction);
                  }
                } else {
                  this.appointments = data.sort(this.sortFunction);
                  this.appointmentsBackup = data.sort(this.sortFunction);
                }
              })
              .catch((err) => {
                console.log(err);
              });
          } else {
            this.appointments = data.sort(this.sortFunction);
            this.appointmentsBackup = data.sort(this.sortFunction);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    handleGetAppointment(id) {
      const token = Cookies.get('token');

      api
        .get(`/appointments/${Number(id)}`, {
          headers: {
            token: token,
          },
        })
        .then((res) => {
          this.handleGetLocalAndUnitAppointments(res.data.appointment.room);
          this.step = 2.2;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    handleGetLocalAndUnitAppointments(id) {
      this.roomId = id;
      const token = Cookies.get('token');

      api
        .get(`/rooms/${id}`, {
          headers: {
            token: token,
          },
        })
        .then((res) => {
          this.localName = res.data.getLocalId.name;
          this.roomName = res.data.name;
          api
            .get(`/units/${res.data.getLocalId.unitId}`, {
              headers: {
                token: token,
              },
            })
            .then((res) => {
              this.unitName = res.data.name;
            })
            .catch((err) => {
              console.log(err);
            });
        })
        .catch((err) => {
          console.log(err);
        });
    },

    handleGetLocalAndUnit(event) {
      this.roomId = event.target.value;
      const token = Cookies.get('token');

      api
        .get(`/rooms/${event.target.value}`, {
          headers: {
            token: token,
          },
        })
        .then((res) => {
          this.localName = res.data.getLocalId.name;
          this.roomName = res.data.name;
          api
            .get(`/units/${res.data.getLocalId.unitId}`, {
              headers: {
                token: token,
              },
            })
            .then((res) => {
              this.unitName = res.data.name;
            })
            .catch((err) => {
              console.log(err);
            });
        })
        .catch((err) => {
          console.log(err);
        });
    },

    newFileSelected(event) {
      this.selectedFile = event.target.files[0];
      this.selectedFileName = event.target.files[0].name;
    },

    handleCreateNewOccurrence() {
      const formData = new FormData();

      formData.append('active', true);
      formData.append('deleted', false);
      formData.append('roomId', Number(this.roomId));
      formData.append('event', this.event);
      formData.append('status', 'Pendente');
      formData.append('roomName', this.roomName);
      formData.append('file', this.selectedFile);

      const token = this.$cookies.get('token');

      api
        .post('/ocurrence', formData, {
          headers: {
            token: token,
          },
        })
        .then(() => {
          this.toast.success(
            'Registro de ocorrência sem agendamento criado com sucesso'
          );

          this.roomName = '';
          this.roomId = '';
          this.event = '';

          (this.localName = ''),
            (this.unitName = ''),
            this.$router.push('/gerenciamento/ocorrencias');
        })
        .catch((err) => {
          console.log(err);
        });
    },

    functionFilterDate(appointments) {
      if (this.filterDate !== '') {
        const date = this.filterDate.split('-');
        const dateF = date[2] + '/' + date[1] + '/' + date[0];
        return appointments.filter((appoint) => appoint.date === dateF);
      } else {
        return appointments;
      }
    },

    functionFilterRoom(appointments) {
      if (this.filterRoom !== '') {
        return appointments.filter(
          (appoint) => appoint.room === Number(this.filterRoom)
        );
      } else {
        return appointments;
      }
    },

    handleSearch() {
      const filter = this.functionFilterDate(
        this.functionFilterRoom(this.appointments)
      );
      this.appointments = filter;
    },

    handleReset() {
      this.appointments = this.appointmentsBackup;
      this.filterRoom = '';
      this.filterDate = '';
    },

    handleGetAllRooms() {
      const token = Cookies.get('token');

      api
        .get('/rooms', {
          headers: {
            token: token,
          },
        })
        .then((res) => {
          const filter = res.data.filter(
            (room) => room.maintenance === false && room.occult === false
          );
          this.rooms = filter;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>
