import { useToast } from 'vue-toastification';

const toast = useToast();

export function messageSuccess(msg) {
  return toast.success(msg);
}

export function messageError(msg) {
  return toast.error(msg);
}

export function messageWarning(msg) {
  return toast.warning(msg);
}
