<template>
  <div v-if="isLoading" class="loading-overlay">
    <!-- https://i.gifer.com/Vp3R.gif -->
    <!-- https://i.gifer.com/origin/34/34338d26023e5515f6cc8969aa027bca.gif -->
    <img
      src="../../assets/puff.svg"
      alt=""
      width="70"
    />
  </div>
</template>

<script>
export default {
  name: 'Loading',

  computed: {
    isLoading() {
      return this.$store.state.Default.isLoading;
    },
  },
};
</script>

<style scoped>
.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  /* background: rgba(10, 88, 155, 0.99); cor usada em outros sistema do einstein*/
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}
</style>
