const ReserveFilter = {
  namespaced: true,
  state: {
    filterId: '',
    filterDate: '',
    filterLocal: '',
    filterRequester: '',
    filterUnit: '',
    filterStatus: '',
    filterEventType: '',
    filterBranch: '',
    filterEventName: '',
    filterStartTime: '',
    filterEndTime: '',
    filterRoom: '',
  },

  mutations: {
    CHANGE_FILTER_ID(state, payload) {
      state.filterId = payload;
    },

    CHANGE_FILTER_DATE(state, payload) {
      state.filterDate = payload;
    },

    CHANGE_FILTER_LOCAL(state, payload) {
      state.filterLocal = payload;
    },

    CHANGE_FILTER_SOLICITANTE(state, payload) {
      state.filterRequester = payload;
    },

    CHANGE_FILTER_UNIT(state, payload) {
      state.filterUnit = payload;
    },

    CHANGE_FILTER_STATUS(state, payload) {
      state.filterStatus = payload;
    },

    CHANGE_FILTER_EVENT_TYPE(state, payload) {
      state.filterEventType = payload;
    },

    CHANGE_FILTER_BRANCH(state, payload) {
      state.filterBranch = payload;
    },

    CHANGE_FILTER_EVENT_NAME(state, payload) {
      state.filterEventName = payload;
    },

    CHANGE_FILTER_START_TIME(state, payload) {
      state.filterStartTime = payload;
    },

    CHANGE_FILTER_END_TIME(state, payload) {
      state.filterEndTime = payload;
    },

    CHANGE_FILTER_ROOM(state, payload) {
      state.filterRoom = payload;
    },
  },

  getters: {},

  actions: {},
};

export default ReserveFilter;
