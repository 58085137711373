<template>
  <div
    class="w-full h-screen absolute p-12 left-0 top-0 bg-modal-600 z-50 flex items-center justify-center backdrop-blur-sm"
  >
    <div
      class="bg-white w-full h-auto max-h-full overflow-y-auto p-6 rounded-xl shadow-lg relative"
    >
      <button
        type="button"
        @click="onCloseModal"
        class="absolute right-2 top-2 text-white bg-blue-500 w-8 h-8 rounded-full flex items-center justify-center"
      >
        <i class="ph ph-x"></i>
      </button>

      <div class="text-2xl pb-5 text-blue-500 border-b border-gray-300">
        Editar sala
      </div>

      <form
        method="post"
        @submit.prevent="handleUpdatedRoom"
        class="w-full mt-10"
      >
        <div class="flex items-center gap-8">
          <label class="text-sm text-gray-500 flex-1">
            Nome da sala

            <input
              type="text"
              v-model="data.name"
              class="border-2 border-gray-300 h-12 rounded-lg w-full text-md mt-2 pl-4"
            />
          </label>

          <label class="text-sm text-gray-500 flex-1">
            Descrição da sala

            <input
              type="text"
              v-model="data.description"
              class="border-2 border-gray-300 h-12 rounded-lg w-full text-md mt-2 pl-4"
            />
          </label>
        </div>

        <label class="text-sm text-gray-500 flex-1 mt-4 block">
          Unidade

          <input
            type="text"
            v-model="unitName"
            disabled
            class="border-2 border-gray-300 h-12 rounded-lg w-full text-md mt-2 pl-4"
          />
        </label>

        <div class="flex items-center gap-8 mt-4">
          <label class="text-sm text-gray-500 flex-1">
            Número da sala
            <input
              type="text"
              v-model="data.roomNumber"
              class="border-2 border-gray-300 h-12 rounded-lg w-full text-md mt-2 pl-4"
            />
          </label>

          <label class="text-sm text-gray-500 flex-1">
            Link virtual da sala

            <input
              type="text"
              v-model="data.virtualRoomLink"
              class="border-2 border-gray-300 h-12 rounded-lg w-full text-md mt-2 pl-4"
            />
          </label>
        </div>

        <div class="flex items-center gap-8 mt-4">
          <label class="text-sm text-gray-500 flex-1">
            Local

            <select
              v-model="data.localId"
              class="border-2 border-gray-300 h-12 rounded-lg w-full text-md mt-2 pl-4"
            >
              <option value="" selected disabled>Local</option>
              <option
                v-for="item in locationsdata"
                :key="item.id"
                :value="item.id"
              >
                {{ item.name }}
              </option>
            </select>
          </label>

          <label class="text-sm text-gray-500 flex-1">
            Tipo de sala

            <select
              v-model="data.roomTypeId"
              class="border-2 border-gray-300 h-12 rounded-lg w-full text-md mt-2 pl-4"
            >
              <option value="" selected disabled>Tipo de sala</option>
              <option
                v-for="item in roomtypesdata"
                :key="item.id"
                :value="item.id"
              >
                {{ item.name }}
              </option>
            </select>
          </label>
        </div>

        <div class="flex items-start gap-8 mt-4">
          <label class="text-sm text-gray-500 flex-1">
            Equipamentos atuais

            <div
              class="bg-gray-100 p-4 h-[250px] overflow-y-auto rounded-lg mt-4 flex flex-col gap-2"
            >
              <div
                class="bg-blue-500 flex items-center justify-between px-4 py-2 rounded-lg text-white"
                v-for="item in data.getEquipments"
                :key="item.id"
              >
                <div>
                  <h2 class="text-lg">{{ item.name }}</h2>
                  <p>
                    {{
                      item.type === 0 ? 'Equipamento móvel' : 'Equipamento fixo'
                    }}
                  </p>
                  <span class="text-cyan-500 block mt-4"
                    >Patrimonio: {{ item.patrimony }}</span
                  >
                </div>

                <button
                  type="button"
                  @click="handleRemoveEquipment(item.id)"
                  class="bg-red-500 text-lg text-white w-10 h-10 flex items-center justify-center rounded-lg"
                >
                  <i class="ph ph-trash text-white"></i>
                </button>
              </div>
            </div>
          </label>

          <!-- ==================== -->
          <label class="text-sm text-gray-500 flex-1">
            Equipamentos não selecionados

            <div
              class="bg-gray-100 p-4 h-[250px] overflow-y-auto rounded-lg mt-4 flex flex-col gap-2"
            >
              <div
                class="bg-blue-500 flex items-center justify-between px-4 py-2 rounded-lg text-white"
                v-for="item in equipmentsByLocal"
                :key="item.id"
              >
                <div class="equipamentInfo">
                  <h2 class="text-lg">{{ item.name }}</h2>
                  <p>
                    {{
                      item.type === 0 ? 'Equipamento fixo' : 'Equipamento móvel'
                    }}
                  </p>
                  <span class="text-cyan-500 block mt-4"
                    >Patrimonio: {{ item.patrimony }}</span
                  >
                </div>

                <button
                  type="button"
                  @click="handleAddEquipment(item.id)"
                  class="bg-green-500 text-lg text-white w-10 h-10 flex items-center justify-center rounded-lg"
                >
                  <i class="ph ph-plus text-white"></i>
                </button>
              </div>
            </div>
          </label>
        </div>

        <div class="flex items-start gap-8 mt-4">
          <label class="text-sm text-gray-500 flex-1">
            Layouts atuais

            <div
              class="bg-gray-100 p-4 h-[250px] overflow-y-auto rounded-lg mt-4 flex flex-col gap-2"
            >
              <div
                v-for="item in data.getLayouts"
                :key="item.id"
                class="bg-blue-500 px-4 py-2 rounded-lg text-white flex flex-col"
              >
                <div class="flex items-center justify-between w-full">
                  <div class="flex items-center gap-4">
                    <img
                      :src="item.imageUrl"
                      :alt="item.name"
                      class="w-24 h-20 object-cover rounded-lg"
                    />
                    <div>
                      <h1>{{ item.name }}</h1>
                      <p>
                        {{
                          item.description === ''
                            ? 'Sem descrição'
                            : item.description
                        }}
                      </p>
                      <span class="block mt-2 text-cyan-500"
                        >Capacidade: {{ item.Capacity[0].capacity }}</span
                      >
                    </div>
                  </div>
                  <button
                    type="button"
                    @click="handleRemoveLayout(item.id)"
                    class="bg-red-500 text-lg text-white w-10 h-10 flex items-center justify-center rounded-lg hover:brightness-90 transition-all"
                  >
                    <i class="ph ph-trash text-white"></i>
                  </button>
                </div>
              </div>
            </div>
          </label>

          <label class="text-sm text-gray-500 flex-1">
            Layouts não selecionados

            <div
              class="bg-gray-100 p-4 h-[250px] overflow-y-auto rounded-lg mt-4 flex flex-col gap-2"
            >
              <div
                v-for="item in roomlayoutsdata"
                :key="item.id"
                class="bg-blue-500 px-4 py-2 rounded-lg text-white flex flex-col justify-between text-gray-400"
              >
                <div class="flex items-center justify-between w-full">
                  <div class="flex items-center gap-4">
                    <img
                      :src="item.imageUrl"
                      :alt="item.name"
                      class="w-24 h-20 object-cover rounded-lg"
                    />
                    <div>
                      <h1>{{ item.name }}</h1>
                      <p>
                        {{
                          item.description === ''
                            ? 'Sem descrição'
                            : item.description
                        }}
                      </p>
                    </div>
                  </div>
                  <button
                    type="button"
                    @click="handleAddLayout(item.id)"
                    class="bg-green-500 text-lg text-white w-10 h-10 flex items-center justify-center rounded-lg disabled:opacity-75 disabled:cursor-not-allowed"
                    v-show="activeInputCapacity === false"
                  >
                    <i class="ph ph-plus text-white"></i>
                  </button>
                </div>

                <div class="flex items-end gap-2">
                  <input
                    type="number"
                    class="w-1/2 h-8 rounded-lg mt-3 pl-4 text-gray-500"
                    placeholder="Capacidade"
                    v-model="capacityAdd"
                    v-show="
                      activeInputCapacity === true &&
                      layoutIdIsActiveInput === item.id
                    "
                  />
                  <button
                    type="button"
                    class="bg-green-500 text-white w-8 h-8 rounded-full flex items-center justify-center hover:brightness-90 transition-all"
                    @click="handleCreateNewLayoutWithCapacity(item.id)"
                    v-show="
                      activeInputCapacity === true &&
                      layoutIdIsActiveInput === item.id
                    "
                  >
                    <i class="ph ph-plus text-white"></i>
                  </button>
                </div>
              </div>
            </div>
          </label>
        </div>
        <select
          v-if="data.composable"
          v-model="composablePairFormatted"
          class="border-2 mt-5 border-gray-300 h-12 rounded-lg w-full text-md pl-4 text-gray-400"
        >
          <option value="" selected disabled>
            Selecione a sala componível
          </option>
          <option v-for="room in roomsdata" :key="room.id" :value="room.id">
            {{ room.name }}
          </option>
        </select>

        <div className="flex items-center gap-2 mt-10">
          <div class="switch">
            <label>
              <input
                v-model="data.composable"
                type="checkbox"
                class="toggle-input"
              />
              <div class="slider">
                <button class="button"></button>
              </div>
            </label>
          </div>
          <label
            >Componível
            {{
              data.composablePairName ? `(${data.composablePairName})` : ''
            }}</label
          >
        </div>

        <div className="flex items-center gap-2 mt-2">
          <div class="switch">
            <label>
              <input
                v-model="data.selfReserve"
                type="checkbox"
                class="toggle-input"
              />
              <div class="slider">
                <button class="button"></button>
              </div>
            </label>
          </div>
          <label>Auto reserva</label>
        </div>

        <div className="flex items-center gap-2 mt-2">
          <div class="switch">
            <label>
              <input
                v-model="data.maintenance"
                type="checkbox"
                class="toggle-input"
              />
              <div class="slider">
                <button class="button"></button>
              </div>
            </label>
          </div>
          <label>Manutenção</label>
        </div>

        <div className="flex items-center gap-2 mt-2">
          <div class="switch">
            <label>
              <input
                v-model="data.occult"
                type="checkbox"
                class="toggle-input"
              />
              <div class="slider">
                <button class="button"></button>
              </div>
            </label>
          </div>
          <label>Ocultação</label>
        </div>

        <div className="flex items-center gap-2 mt-2">
          <div class="switch">
            <label>
              <input
                v-model="data.active"
                type="checkbox"
                class="toggle-input"
              />
              <div class="slider">
                <button class="button"></button>
              </div>
            </label>
          </div>
          <label>Ativo</label>
        </div>

        <button
          v-show="profile"
          type="submit"
          :disabled="data.composable && !composablePairFormatted"
          class="bg-blue-500 w-full text-white py-3 rounded-lg mt-10 disabled:hover:brightness-100 disabled:cursor-not-allowed disabled:opacity-70"
        >
          Salvar
        </button>
      </form>
    </div>
  </div>
</template>

<script>
import { useToast } from 'vue-toastification';
import api from '../../lib/axios';
import Cookies from 'vue-cookies';
import { useRoomsService } from '../../services';

export default {
  name: 'ModalRoomsItem',
  props: {
    onCloseModal: Function,
    id: Number,
    data: Object,
    equipmentsByLocal: Array,
    unitName: String,
    composablePairFormatted: String,
    getAllRooms: Function,
  },

  data() {
    return {
      equipmentsEmpty: [],
      capacityAdd: 0,
      capacityAll: [],
      activeInputCapacity: false,
      layoutIdIsActiveInput: '',
      composablePair: '',
      roomsService: null,
    };
  },

  setup() {
    const toast = useToast();
    return { toast };
  },

  computed: {
    // Api call locals
    locationsdata() {
      return this.$store.state.Default.datastore;
    },

    roomsdata() {
      return this.$store.state.Rooms.roomsdata;
    },

    // Api call room types
    roomtypesdata() {
      return this.$store.state.RoomTypes.roomtypesdata;
    },

    // Api call room layouts
    roomlayoutsdata() {
      return this.$store.state.RoomLayouts.roomlayoutsdata;
    },

    // Api call room layouts
    equipmentsdata() {
      return this.$store.state.Equipments.equipmentsdata;
    },

    profile() {
      return this.$store.state.Default.profile;
    },

    isLoading: {
      set(value) {
        this.$store.commit('Default/CHANGE_ISLOADING', value);
      },
    },
  },

  mounted() {
    this.$store.dispatch('Default/getDataToPages', '/locals');
    this.$store.dispatch('Rooms/getRoomsByData');

    this.handleGetEquipmentsEmpty();
    this.roomsService = useRoomsService();
  },

  methods: {
    handleUpdatedRoom() {
      const dataRoom = {
        name: this.data.name,
        description: this.data.description,
        roomNumber: this.data.roomNumber,
        virtualRoomLink: this.data.virtualRoomLink,
        composable: this.data.composable,
        selfReserve: this.data.selfReserve,
        active: this.data.active,
        localId: this.data.localId,

        composablePair: this.data.composable
          ? Number(this.composablePairFormatted)
          : 0,

        maintenance: this.data.maintenance,
        occult: this.data.occult,
      };

      if (dataRoom.composablePair === this.id) {
        this.toast.warning(
          'Não é possível selecionar a mesma sala como componível'
        );
        return;
      }

      if (
        this.roomsdata.some(
          (room) =>
            room.composablePair !== 0 &&
            room.composablePair === dataRoom.composablePair
        )
      ) {
        this.toast.warning(
          'Não é possível selecionar uma sala que já está componível com outra sala'
        );
        return;
      }

      this.isLoading = true;

      this.roomsService
        .updateRoom(this.id, dataRoom)
        .then(() => {
          this.onCloseModal();
          this.getAllRooms();
          this.$store.dispatch('Rooms/getRoomsByData');
        })
        .finally(() => {
          this.isLoading = false;
        });
    },

    handleRemoveLayout(id) {
      const token = Cookies.get('token');
      api
        .patch(
          `/rooms/${this.data.id}/layouts`,
          {
            removedIds: [{ id: id }],
            layoutId: id,
          },
          {
            headers: {
              token: token,
            },
          }
        )
        .then((res) => {
          this.$emit('getRooms', this.data.id);
          this.toast.success('Layout removido com sucesso');
          this.handleRemoveCapacityWithLayout(res.data[0].id);
        })
        .catch((err) => {
          console.log(err);
        });
    },

    handleRemoveCapacityWithLayout(id) {
      const token = Cookies.get('token');

      api
        .delete(`/capacity/${id}`, {
          headers: {
            token: token,
          },
        })
        .then(() => {
          //console.log('Capacidade removida com sucesso!');
        })
        .catch((err) => {
          console.log(err);
        });
    },

    handleAddLayout(id) {
      this.layoutIdIsActiveInput = id;
      this.activeInputCapacity = true;
    },

    handleCreateNewLayoutWithCapacity(id) {
      const token = Cookies.get('token');

      api
        .patch(
          `/rooms/${this.data.id}/layouts`,
          {
            newIds: [{ id: id }],
            layoutId: id,
          },
          {
            headers: {
              token: token,
            },
          }
        )
        .then(() => {
          this.$emit('getRooms', this.data.id);
          this.toast.success('Layout adicionado com sucesso');
        })
        .catch((err) => {
          console.log(err);
        });

      api
        .post(
          '/capacity',
          {
            deleted: false,
            active: true,
            roomId: this.data.id,
            layoutId: id,
            capacity: this.capacityAdd,
          },
          {
            headers: {
              token: token,
            },
          }
        )
        .then(() => {
          this.capacityAdd = 0;
        })
        .catch((err) => {
          console.log(err);
        });

      this.layoutIdIsActiveInput = '';
      this.activeInputCapacity = false;
    },

    handleRemoveEquipment(id) {
      const token = Cookies.get('token');

      api
        .patch(
          `/rooms/${this.data.id}/equipments`,
          {
            removedIds: [{ id: id }],
          },
          {
            headers: {
              token: token,
            },
          }
        )
        .then(() => {
          this.$emit('getRooms', this.data.id);
          this.handleGetEquipmentsEmpty();
          this.toast.success('Equipamento removido com sucesso');
        })
        .catch((err) => {
          console.log(err);
        });
    },

    handleAddEquipment(id) {
      const token = Cookies.get('token');

      api
        .patch(
          `/rooms/${this.data.id}/equipments`,
          {
            newIds: [{ id: id }],
          },
          {
            headers: {
              token: token,
            },
          }
        )
        .then(() => {
          this.$emit('getRooms', this.data.id);
          this.handleGetEquipmentsEmpty();
          this.toast.success('Equipamento adicionado com sucesso');
        })
        .catch((err) => {
          console.log(err);
        });
    },

    handleGetEquipmentsEmpty() {
      const token = Cookies.get('token');

      api
        .get('/equipments/empty', {
          headers: {
            token: token,
          },
        })
        .then((res) => {
          this.equipmentsEmpty = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>
