import api from '../lib/axios';
import Cookies from 'vue-cookies';

export class SolicitationService {
  constructor() {
    this.token = Cookies.get('token');
  }

  async getSolicitationId(id) {
    try {
      const response = await api.get(`/solicitations/${id}`, {
        headers: {
          token: this.token,
        },
      });
      return response.data;
    } catch (error) {
      console.error('Error fetching solicitation id:', error);
    }
  }

  async updateStatusSolicitation(data) {
    try {
      const response = await api.post(`/solicitation/status`, data, {
        headers: {
          token: this.token,
        },
      });
      return response.data;
    } catch (error) {
      console.error('Error posting solicitation status:', error);
    }
  }

  async createSolicitation(data) {
    try {
      const response = await api.post(`/solicitation`, data, {
        headers: {
          token: this.token,
        },
      });
      return response.data;
    } catch (error) {
      console.error('Error posting solicitation:', error);
    }
  }

  async recurrentSolicitation(data) {
    try {
      const response = await api.post(`/recurrent`, data, {
        headers: {
          token: this.token,
        },
      });
      return response.data;
    } catch (error) {
      console.error('Error posting solicitation:', error);
    }
  }

  async createDirectSolicitation(data) {
    try {
      const response = await api.post(`/solicitation/create-direct`, data, {
        headers: {
          token: this.token,
        },
      });
      return response.data;
    } catch (error) {
      console.error('Error posting solicitation:', error);
    }
  }

  async updateSolicitation(solicitationId, data) {
    try {
      const response = await api.patch(
        `/solicitation/${solicitationId}`,
        data,
        {
          headers: {
            token: this.token,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error('Error updating solicitation:', error);
    }
  }

  verifyStatusSolicitation(appointments) {
    const appointmentsCanceled = appointments.filter(
      (appointment) => appointment.status === 'Cancelada'
    );

    const appointmentsReproved = appointments.filter(
      (appointment) => appointment.status === 'Reprovada'
    );

    const appointmentsApproved = appointments.filter(
      (appointment) => appointment.status === 'Aprovada'
    );

    const appointmentsPending = appointments.filter(
      (appointment) => appointment.status === 'Pendente'
    );

    if (appointmentsApproved.length === appointments.length) {
      return 'Aprovada';
    }

    if (appointmentsPending.length === appointments.length) {
      return 'Pendente';
    }

    if (appointmentsReproved.length === appointments.length) {
      return 'Reprovada';
    }

    if (appointmentsCanceled.length === appointments.length) {
      return 'Cancelada';
    }

    if (appointmentsApproved.length) {
      return 'Parcialmente aprovada';
    }

    if (appointmentsPending.length && !appointmentsApproved.length) {
      return 'Pendente';
    }

    return 'Reprovada';
  }

  async uploadFile(formData) {
    try {
      const response = await api.post('/solicitation/upload', formData, {
        headers: {
          token: this.token,
        },
      });
      return response.data;
    } catch (error) {
      console.error('Error uploading file:', error);
    }
  }

  async updateSolicitationAndAppointments(payload) {
    try {
      const response = await api.post(
        '/solicitation/update/solicitation-appointments',
        payload,
        {
          headers: {
            token: this.token,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error('Error updating solicitation:', error);
    }
  }

  async createDirectSolicitationCustomRecurrent(payload){
    try {
      const response = await api.post('/solicitation/create-direct-custom-recurrent', payload, {
        headers: {
          token: this.token,
        },
      });
      return response.data;
    } catch (error) {
      console.error('Error posting solicitation:', error);
    }
  }
}

export const useSolicitationService = () => new SolicitationService();
