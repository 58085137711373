<template>
  <Schema>
    <div class="flex w-full h-full flex-col">
      <!-- Breadcrumb -->
      <div class="flex w-full text-sm">
        <div class="mb-4 text-gray-400">
          <router-link to="/app" class="hover:text-blue-500 transition-colors">
            Home
          </router-link>
          <span> • Ensalamento</span>
          <span class="text-blue-500"> • Vincular cursos</span>
        </div>
      </div>

      <!-- Title da página -->
      <div class="mt-[11px] border-b-2 border-gray-400 flex items-center gap-5">
        <button
          :class="`${
            pageCurrentSelect === 1
              ? 'text-blue-500 border-blue-500'
              : 'text-gray-500 border-gray-400'
          } py-4 px-5  font-medium text-sm uppercase border-b-2  relative top-[2px]`"
          type="button"
          @click="handleCleanData(1)"
        >
          Cursos vinculados
        </button>

        <button
          :class="`${
            pageCurrentSelect === 2
              ? 'text-blue-500 border-blue-500'
              : 'text-gray-500 border-gray-400'
          } py-4 px-5  font-medium text-sm uppercase border-b-2  relative top-[2px]`"
          type="button"
          @click="handleCleanData(2)"
        >
          Vincular cursos a calendários
        </button>
      </div>

      <section class="mt-[30px]" v-show="pageCurrentSelect === 1">
        <div class="flex flex-col mt-[24px]">
          <h1 class="text-2xl text-gray-600">
            Lista de cursos vinculados a calendários
          </h1>
          <p class="text-gray-400 mt-1">
            Confira abaixo a lista de Cursos vinculados aos Calendários
            cadastrados
          </p>
        </div>

        <div
          v-if="isLoadingCalendarsData"
          class="flex items-center justify-center mt-[35px]"
        >
          <div
            class="w-16 h-16 rounded-full flex border-2 border-t-blue-500 animate-spin"
          ></div>
        </div>

        <div v-else class="mt-10">
          <DataTable
            :value="calendarCourses"
            paginatorTemplate="FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink RowsPerPageDropdown"
            paginator
            :rows="15"
            :rowsPerPageOptions="[15, 20, 30, 50]"
            currentPageReportTemplate="{first} de {last} de {totalRecords}"
            :globalFilterFields="[
              'courseName',
              'classScheduleCalendar.version',
              'class',
              'unit.name',
              'totalMeetings',
            ]"
            v-model:filters="filters"
          >
            <template #header>
              <span class="p-input-icon-left">
                <i class="pi pi-search pt-1" />
                <input
                  v-model="filters['global'].value"
                  placeholder="Pesquisa global"
                  class="border-2 border-gray-300 p-3 pl-10 rounded-lg w-full text-sm mt-2"
                />
              </span>
            </template>
            <Column field="courseName" header="Curso"></Column>
            <Column
              field="classScheduleCalendar.version"
              header="Calendário Vinculado"
            >
            </Column>
            <Column field="class" header="Turma"></Column>
            <Column field="unit.name" header="Unidade"> </Column>
            <Column field="totalMeetings" header="Encontros"> </Column>
            <Column header="Ações">
              <template #body="{ data }">
                <ConfirmPopup />
                <div class="flex items-center gap-[11px]">
                  <button
                    class="bg-gray-500 w-[25px] h-[25px] flex items-center justify-center text-white rounded-full hover:brightness-90 transition-all"
                    title="Clique para visualizar o calendário"
                    type="button"
                  >
                    <i class="ph ph-eye"></i>
                  </button>

                  <button
                    class="bg-blue-500 w-[25px] h-[25px] flex items-center justify-center text-white rounded-full hover:brightness-90 transition-all"
                    title="Clique para editar o calendário"
                    type="button"
                    @click="handleEditCalendarCourse(data)"
                  >
                    <i class="ph ph-pencil-simple"></i>
                  </button>

                  <button
                    class="bg-red-500 w-[25px] h-[25px] flex items-center justify-center text-white rounded-full hover:brightness-90 transition-all"
                    title="Clique para excluir o calendário"
                    type="button"
                    @click="handleDeleteCourse($event, data.id)"
                  >
                    <i class="ph ph-trash"></i>
                  </button>
                </div>
              </template>
            </Column>
          </DataTable>
        </div>
      </section>

      <section class="mt-[30px]" v-show="pageCurrentSelect === 2">
        <div class="flex flex-col">
          <h1 class="text-2xl text-gray-600">Associar curso a calendário</h1>
          <p class="text-gray-400 mt-1">
            Configure as informações abaixo para vincular um curso a um ou mais
            calendários
          </p>
        </div>
        <div class="mt-10">
          <div class="flex items-end gap-5">
            <div class="flex-1 text-sm text-gray-500">
              Unidade

              <Dropdown
                v-model="formObjects.unitId"
                :options="units"
                optionLabel="name"
                placeholder="Selecione a unidade"
                class="w-full h-12 mt-2"
                optionValue="id"
              />
            </div>
            <div class="flex-1 text-sm text-gray-500">
              Ano

              <Dropdown
                v-model="formObjects.year"
                :options="years"
                optionLabel="year"
                placeholder="Selecione o ano"
                class="w-full h-12 mt-2"
                optionValue="year"
                @change="handleGetPeriods"
              />
            </div>

            <div class="flex-1 text-sm text-gray-500">
              <div class="flex items-center gap-2 mb-2">
                <h1 class="">Período</h1>

                <div
                  class="group cursor-help text-blue-500 relative flex items-center justify-center text-lg"
                >
                  <i class="ph ph-warning-circle"></i>

                  <div
                    class="group-hover:scale-100 scale-0 transition-all origin-bottom-left absolute -left-1 bottom-7 bg-gray-700 w-[250px] px-4 py-2 text-white rounded-md text-xs font-light"
                  >
                    Apenas administradores podem editar essa informação

                    <div
                      class="w-3 h-3 bg-gray-700 rotate-45 absolute left-2 -bottom-1"
                    ></div>
                  </div>
                </div>
              </div>

              <Dropdown
                v-model="formObjects.period"
                :options="periods"
                optionLabel="period"
                placeholder="Selecione o período"
                class="w-full h-12 mt-2"
                optionValue="period"
                @change="handleGetCoursesLyceum"
                :disabled="!periods.length"
              />
            </div>
          </div>

          <div class="flex items-center gap-5 mt-5">
            <div class="flex-2 text-sm text-gray-500">
              Área de negócio

              <Dropdown
                v-model="formObjects.businessAreaId"
                :options="businessAreas"
                optionLabel="name"
                placeholder="Selecione uma área de negócio"
                class="w-full h-12 mt-2"
                optionValue="id"
              />
            </div>

            <div class="flex-1 text-sm text-gray-500">
              Calendários Cadastrados

              <Dropdown
                v-model="formObjects.classScheduleCalendarId"
                :options="classScheduleCalendars"
                optionLabel="version"
                placeholder="Selecione um calendário"
                class="w-full h-12 mt-2"
                optionValue="id"
                @change="handleClassScheduleCalendar"
                :disabled="!formObjects.year || !formObjects.period"
              />
            </div>
            <div class="flex-2 text-sm text-gray-500">
              <h1 class="mb-2">Total de encontros</h1>

              <InputText
                placeholder="Digite o total de encontros"
                class="w-full h-12 mt-2"
                type="number"
                :disabled="true"
                v-model="formObjects.totalMeetings"
              />
            </div>
          </div>
        </div>

        <div class="mt-10">
          <h1 class="text-blue-500 text-lg">Selecione um ou mais cursos</h1>

          <div class="flex-1 text-sm text-gray-500 mt-3">
            <div class="flex items-center gap-2">
              Curso

              <div
                class="group cursor-help text-blue-500 relative flex items-center justify-center text-lg"
              >
                <i class="ph ph-warning-circle"></i>

                <div
                  class="group-hover:scale-100 scale-0 transition-all origin-bottom-left absolute -left-1 bottom-7 bg-gray-700 w-[250px] px-4 py-2 text-white rounded-md text-xs font-light"
                >
                  Cursos relacionados com o Ano e Período digitados acima

                  <div
                    class="w-3 h-3 bg-gray-700 rotate-45 absolute left-2 -bottom-1"
                  ></div>
                </div>
              </div>
            </div>

            <Dropdown
              v-model="formObjects.courseCode"
              :options="courses"
              optionLabel="NM_CURSO"
              placeholder="Selecione o curso"
              class="w-full h-12 mt-2"
              optionValue="COD_CURSO"
              @change="handleGetClassLyceum"
            />
          </div>

          <div class="flex">
            <div class="flex-1 text-sm text-gray-500 mt-6">
              <div class="flex items-center gap-2">Turmas</div>

              <div class="flex items-center gap-4">
                <Dropdown
                  v-model="formObjects.class"
                  :options="classes"
                  optionLabel="TURMA"
                  placeholder="Selecione a turma"
                  class="w-full h-12 mt-2"
                  optionValue="TURMA"
                  :disabled="classes.length === 0"
                  @change="handleGetStatusMatrTurma"
                />

                <button
                  type="button"
                  :disabled="
                    formObjects.class === '' || formObjects.courseCode === ''
                  "
                  @click="handleCreateCourseAndClasse"
                  class="p-2 mt-2 bg-blue-500 text-white rounded-full hover:brightness-90 transition-all flex items-center justify-center text-lg disabled:cursor-not-allowed disabled:opacity-60"
                >
                  <i class="ph ph-plus"></i>
                </button>
              </div>
            </div>
            <div class="flex-none w-60 text-sm text-gray-500 mt-6 ml-5">
              <div class="flex items-center gap-2">Status da turma</div>

              <div class="flex items-center gap-4">
                <Dropdown
                  v-model="classStatus"
                  :options="classesStatus"
                  optionLabel="label"
                  class="w-full h-12 mt-2"
                  optionValue="label"
                  :disabled="true"
                />
              </div>
            </div>
          </div>
        </div>

        <div class="mt-8">
          <h1 class="text-blue-500">Curso selecionado</h1>
          <div class="bg-gray-200 p-5 rounded-lg mt-2 min-h-[300px]">
            <div
              class="pb-5 px-4 border-b border-gray-400 flex items-center justify-between text-sm text-gray-500"
            >
              <div class="flex-1">
                <h1>Curso</h1>
              </div>
              <div class="w-[300px]">
                <h1>Turma</h1>
              </div>
            </div>

            <div class="mt-4 flex flex-col gap-4">
              <div
                v-for="item in courseAndClassSelected"
                :key="item.id"
                class="bg-blue-500 text-white py-2 px-4 rounded-md flex items-center text-sm"
              >
                <div class="flex-1">
                  <h1>
                    {{ item.courseName }}
                  </h1>
                </div>

                <div class="w-[calc(300px-28px)]">
                  <h1>{{ item.class }}</h1>
                </div>

                <div>
                  <button
                    type="button"
                    @click="handleRemoveCourseAndClasse(item.class)"
                    class="bg-red-500 w-7 h-7 text-white rounded-full flex items-center justify-center"
                  >
                    <i class="ph ph-trash"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="flex items-center justify-between mt-10">
          <button
            to="/app"
            title="Clique para cancelar a criação"
            class="border border-blue-500 px-4 py-2 rounded-full text-blue-500 hover:bg-blue-500 hover:text-white transition-all"
            @click="handleCleanData(1)"
          >
            Cancelar
          </button>

          <button
            title="Clique para salvar o calendário"
            class="bg-blue-500 text-white px-4 py-2 rounded-full hover:brightness-90 transition-all disabled:cursor-not-allowed disabled:opacity-80 disabled:hover:brightness-100"
            type="button"
            :disabled="courseAndClassSelected.length === 0"
            @click="handleSaveOrUpdate"
          >
            Salvar
          </button>
        </div>
      </section>
    </div>
  </Schema>
</template>

<script>
import Schema from '../../components/Schema/index.vue';
import InputText from 'primevue/inputtext';
import Dropdown from 'primevue/dropdown';
import { ClassCalendarApiService } from './service/class-calendar.service';
import {
  ClassScheduleCalendarService,
  DynamicLyceumService,
} from '../../services';
import Cookies from 'vue-cookies';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import { FilterMatchMode } from 'primevue/api';
import { messageSuccess, messageWarning } from '../../lib/toast';
import ConfirmPopup from 'primevue/confirmpopup';
import { useConfirm } from 'primevue/useconfirm';

export default {
  name: 'SimulatedViewRehearsal',
  components: {
    Schema,
    DataTable,
    Column,
    InputText,
    Dropdown,
    ConfirmPopup,
  },

  data() {
    return {
      formObjects: {
        unitId: null,
        businessAreaId: null,
        classScheduleCalendarId: null,
        year: null,
        period: null,
        courseCode: '',
        courseName: '',
        class: '',
        totalMeetings: null,
      },

      units: [],
      businessAreas: [],
      classScheduleCalendars: [],
      classScheduleCalendarsBackup: [],
      courses: [],
      classes: [],

      pageCurrentSelect: 1,

      calendarsData: [],
      countTotalRegisters: [],

      isLoadingCalendarsData: false,

      courseAndClassSelected: [],
      classCalendarApiService: null,
      classScheduleCalendarService: null,
      dynamicLyceumService: null,
      userInfo: Cookies.get('user_info'),
      filters: null,
      calendarCourses: [],
      calendarCourseItem: null,
      isUpdate: false,
      token: null,
      years: [],
      periods: [],
      confirm: useConfirm(),
      classesStatus: [],
      classStatus: null,
    };
  },

  created() {
    this.initFilters();
  },

  mounted() {
    this.token = Cookies.get('token');
    this.classCalendarApiService = new ClassCalendarApiService(this.token);
    this.classScheduleCalendarService = new ClassScheduleCalendarService(
      this.token
    );
    this.dynamicLyceumService = new DynamicLyceumService(this.token);
    this.getInitialData();
  },

  methods: {
    initFilters() {
      this.filters = {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      };
    },

    async getInitialData() {
      try {
        this.isLoadingCalendarsData = true;

        const {
          units,
          businessAreas,
          classScheduleCalendars,
          calendarCourses,
          years,
        } = await this.classCalendarApiService.getInitialDataCourseCalendar();

        this.units = units;
        this.businessAreas = businessAreas;
        this.classScheduleCalendars = classScheduleCalendars;
        this.classScheduleCalendarsBackup = classScheduleCalendars;
        this.calendarCourses = calendarCourses;
        this.years = years;
      } catch (error) {
        console.error('Error fetching initial datas:', error);
      } finally {
        this.isLoadingCalendarsData = false;
      }
    },

    async getCoursesLyceum(data) {
      try {
        const response = await this.classCalendarApiService.getCoursesLyceum(
          data
        );
        this.courses = response;
      } catch (error) {
        console.error('Error fetching initial datas:', error);
      } finally {
        this.isLoadingCalendarsData = false;
      }
    },

    async getClassesLyceum(data) {
      try {
        const response = await this.classCalendarApiService.getClassesLyceum(
          data
        );
        this.classes = response;
      } catch (error) {
        console.error('Error fetching initial datas:', error);
      } finally {
        this.isLoadingCalendarsData = false;
      }
    },

    async handleGetCoursesLyceum() {
      if (this.formObjects.year && this.formObjects.period) {
        const data = {
          ano: this.formObjects.year,
          periodo: this.formObjects.period,
        };

        this.getCoursesLyceum(data);

        this.classScheduleCalendars = this.classScheduleCalendarsBackup.filter(
          (item) =>
            item.year === this.formObjects.year &&
            item.period === this.formObjects.period
        );
      }
    },

    async handleGetClassLyceum() {
      if (
        this.formObjects.year &&
        this.formObjects.period &&
        this.formObjects.courseCode
      ) {
        const data = {
          ano: this.formObjects.year,
          periodo: this.formObjects.period,
          cod_curso: this.formObjects.courseCode,
        };
        this.getClassesLyceum(data);
      }
    },

    // FUNC: Função para selecionar a página de listagem ou de criar calendário
    handleChangePageCurrentSelect(page) {
      this.pageCurrentSelect = page;
    },

    handleEditCalendarCourse(item) {
      this.isUpdate = true;

      const dataToUpdate = {
        id: item.id,
        unitId: item.unitId,
        businessAreaId: item.businessAreaId,
        classScheduleCalendarId: item.classScheduleCalendarId,
        year: item.year,
        period: item.period,
        totalMeetings: item.totalMeetings,
        courseCode: item.courseCode,
        courseName: item.courseName,
        class: item.class,
      };

      this.formObjects = dataToUpdate;
      this.calendarCourseItem = dataToUpdate;

      this.handleGetCoursesLyceum();
      this.handleGetClassLyceum();
      this.courseAndClassSelected.push(dataToUpdate);

      this.handleChangePageCurrentSelect(2);
    },

    // FUNC: Função para adicionar
    handleCreateCourseAndClasse() {
      if (this.isUpdate && this.courseAndClassSelected.length > 0) {
        messageWarning(
          'Você já adicionou um curso para este calendário, para adicionar outro, exclua o atual e adicione outro'
        );
        return;
      }

      const form = JSON.parse(JSON.stringify(this.formObjects));
      const findCourse = this.courses.find(
        (c) => c.COD_CURSO === form.courseCode
      );

      form.courseName = findCourse.NM_CURSO;
      form.createdBy = this.userInfo.id;

      if (this.isUpdate) {
        form.id = this.calendarCourseItem.id;
      }

      if (
        this.courseAndClassSelected.some(
          (c) => c.class === form.class && c.courseCode === form.courseCode
        )
      ) {
        messageWarning(
          'Você já adicionou este curso e essa turma para este calendário'
        );
        return;
      }

      this.courseAndClassSelected.push(form);

      this.classes = [];
      this.classStatus = null;
      this.classesStatus = [];
      this.formObjects.class = '';
      this.formObjects.courseCode = '';
    },

    async handleSaveOrUpdate() {
      try {
        if (this.isUpdate) {
          await this.classCalendarApiService.updateCourseCalendar(
            this.courseAndClassSelected[0]
          );
        } else {
          await this.classCalendarApiService.createCourseCalendar(
            this.courseAndClassSelected
          );
        }
      } catch (error) {
        console.log(error);
      } finally {
        this.handleCleanData(1);
      }
    },

    handleRemoveCourseAndClasse(classCode) {
      this.courseAndClassSelected = this.courseAndClassSelected.filter(
        (c) => c.class !== classCode
      );
    },

    // FUNC: Função para deletar um vinculo de calendário
    async handleDeleteCourse(event, itemId) {
      this.confirm.require({
        target: event.currentTarget,
        message: 'Deseja remover esse vínculo?',
        icon: 'pi pi-exclamation-triangle',
        acceptLabel: 'Sim',
        rejectLabel: 'Não',
        accept: async () => {
          await this.classCalendarApiService.deactivateCourseCalendar(itemId);
          messageSuccess('Deletado com sucesso');
          this.handleCleanData(1);
        },
        reject: () => {},
      });
    },

    handleCleanData(page) {
      this.formObjects = {
        unitId: null,
        businessAreaId: null,
        classScheduleCalendarId: null,
        year: null,
        period: null,
        courseCode: '',
        courseName: '',
        class: '',
        totalMeetings: null,
      };
      this.courses = [];
      this.classes = [];
      this.courseAndClassSelected = [];
      this.pageCurrentSelect = page;
      this.isUpdate = false;
      this.calendarCourseItem = null;
      this.getInitialData();
    },

    handleGetPeriods() {
      const { year } = this.formObjects;

      if (!year) {
        return;
      }

      this.classScheduleCalendarService
        .getPeriodsByYear(year)
        .then((response) => {
          this.periods = response;
        });
    },

    handleClassScheduleCalendar(event) {
      const classScheduleCalendar = this.classScheduleCalendars.find(
        (c) => c.id === event.value
      );
      this.formObjects.totalMeetings =
        classScheduleCalendar.countScheduleSelect.toString();
    },

    async handleGetStatusMatrTurma() {
      const { year, period, class: className } = this.formObjects;
      const result = await this.dynamicLyceumService.getStatusMatrTurma({
        year,
        period,
        className,
      });

      this.classesStatus = result.map((item) => ({
        label: item.FL_FIELD_01,
      }));
      this.classStatus = this.classesStatus[0].label;
    },
  },
};
</script>
