<template>
  <Schema>
    <form class="w-full max-w-3xl" @submit.prevent="handleCreateNewProfile">
      <div class="flex w-full mb-4 text-sm">
        <div class="mb-4 text-gray-400">
          <router-link to="/app" class="hover:text-blue-500 transition-colors">
            Home
          </router-link>
          <span> • Cadastrar</span>
          <span class="text-blue-500"> • Perfis</span>
        </div>
      </div>

      <div class="flex flex-col">
        <h1 class="text-3xl text-gray-600">Cadastro de perfis</h1>
        <p class="text-gray-400 max-w-xs mt-1">
          Cadastre os perfis do Albert Einstein
        </p>
      </div>

      <section class="mt-14">
        <label class="text-sm text-gray-500">
          Nome do perfil

          <input
            type="text"
            v-model="name"
            class="border-2 border-gray-300 h-12 rounded-lg w-full text-md mt-2 pl-4"
          />
        </label>
      </section>

      <section class="mt-4 flex items-center gap-8">
        <div class="flex-1">
          <label class="text-sm text-gray-500">
            Descrição

            <input
              type="text"
              v-model="description"
              class="border-2 border-gray-300 h-12 rounded-lg w-full text-md mt-2 pl-4"
            />
          </label>
        </div>
      </section>

      <section class="mt-4 flex items-start gap-8">
        <div class="flex-1">
          <label class="text-sm text-gray-500">
            Acessos

            <div
              class="bg-gray-100 p-3 rounded-lg flex flex-col gap-3 h-[300px] overflow-y-auto"
            >
              <button
                v-for="item in screensData"
                :key="item.id"
                type="button"
                v-on:click="handleAddItem(item.id, item.name)"
                class="bg-blue-500 flex items-center justify-between text-white px-4 py-2 rounded-lg hover:brightness-90 transition-all cursor-pointer"
              >
                <span>{{ item.name }}</span>
                <div
                  class="w-6 ml-auto h-6 bg-green-500 flex items-center justify-center text-white rounded-lg"
                >
                  <i class="ph ph-plus"></i>
                </div>
              </button>
            </div>
          </label>
        </div>
        <div class="flex-1">
          <label class="text-sm text-gray-500">
            Acessos selecionados

            <div
              class="bg-gray-200 p-4 rounded-lg flex flex-col gap-2 h-[300px] overflow-y-auto"
            >
              <button
                type="button"
                v-for="item in Access_Data"
                @click="handleRemoveItem(item.id, item.name)"
                :key="item.name"
                class="bg-blue-500 flex items-center justify-between text-white px-4 py-2 rounded-lg hover:brightness-90 transition-all cursor-pointer"
              >
                <span>{{ item.name }}</span>
                <div
                  class="w-6 ml-auto h-6 bg-red-500 flex items-center justify-center text-white rounded-lg"
                >
                  <i class="ph ph-trash"></i>
                </div>
              </button>
            </div>
          </label>
        </div>
      </section>

      <section class="mt-6" v-show="profile">
        <button
          type="submit"
          :disabled="name === '' || Access_Data.length === 0"
          class="bg-blue-500 py-4 text-white px-10 rounded-full flex items-center justify-center disabled:opacity-70 disabled:brightness-100 disabled:cursor-not-allowed disabled"
        >
          <SmallLoader v-if="isLoadingButton === true" />

          <span v-else>Salvar perfil</span>
        </button>
      </section>
    </form>

    <div
      v-if="isLoading === true"
      class="w-[50px] h-[50px] border-[6px] border-gray-200 border-t-blue-500 animate-spin rounded-full"
    ></div>

    <div v-else class="w-full mt-20" v-show="profiledata.length > 0">
      <DataTable
        :value="profiledata"
        :rowClass="rowClass"
        paginatorTemplate="FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink RowsPerPageDropdown"
        paginator
        :rows="15"
        :rowsPerPageOptions="[15, 20, 30, 50]"
        currentPageReportTemplate="{first} de {last} de {totalRecords}"
        :globalFilterFields="['name', 'description']"
        v-model:filters="filters"
      >
        <template #header>
          <span class="p-input-icon-left">
            <i class="pi pi-search pt-1" />
            <input
              v-model="filters['global'].value"
              placeholder="Pesquisa global"
              class="border-2 border-gray-300 p-3 pl-10 rounded-lg w-full text-sm mt-2"
            />
          </span>
        </template>
        <Column field="name" header="Nome"></Column>
        <Column field="description" header="Descrição"></Column>
        <Column field="id" header="Editar" class="w-20">
          <template #body="{ data }">
            <button
              type="button"
              class="bg-blue-500 text-white px-4 py-1 rounded-full hover:brightness-90 transition-all"
              @click="handleOpenModal(data.id)"
            >
              Editar
            </button>
          </template>
        </Column>
      </DataTable>

      <ModalEditProfile
        :onCloseModal="handleCloseModal"
        :id="currentIdModal"
        :data="profileDataByID"
        v-show="isOpenModal"
        @getProfileById="getProfileById"
      />
    </div>
  </Schema>
</template>

<script>
import Schema from '../../components/Schema/index.vue';
import TableProfile from '../../components/Tables/TableProfile.vue';
import SmallLoader from '../../components/Loaders/SmallLoader.vue';
import ModalEditProfile from '../../components/Modal/ModalEditProfile.vue';

import api from '../../lib/axios';

import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import { FilterMatchMode } from 'primevue/api';

import { messageSuccess, messageError } from '../../lib/toast';

export default {
  name: 'ProfileView',
  components: {
    Schema,
    TableProfile,
    SmallLoader,
    DataTable,
    Column,
    ModalEditProfile,
  },
  data() {
    return {
      profileDataTable: [],
      access: [],
      description: '',
      name: '',
      unitId: '',
      screensData: [],
      isLoadingButton: false,

      Access_Data: [],
      Access_DataId: [],
      Access_DataIds: [],

      filters: null,
      isOpenModal: false,
      currentIdModal: 0,
      profileDataByID: {},
    };
  },
  computed: {
    unitsData() {
      return this.$store.state.Units.unitsdata;
    },
    profiledata() {
      return this.$store.state.Profile.profiledata;
    },
    isLoading() {
      return this.$store.state.Profile.isLoading;
    },

    profile() {
      return this.$store.state.Default.profile;
    },
  },

  mounted() {
    this.$store.dispatch('Units/getUnitsByData', '/units');

    this.$store.dispatch('Profile/getProfileByData', '/profiles');
    this.getAccessScreens();
  },

  created() {
    this.initFilters();
  },

  methods: {
    initFilters() {
      this.filters = {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      };
    },

    rowClass(data) {
      return [{ 'disabled-row': data.active === false }];
    },

    handleCreateNewProfile() {
      const data = {
        active: true,
        createdBy: 1,
        updatedBy: 1,
        deleted: false,
        name: this.name,
        description: this.description,
        Access: {
          connect: this.Access_DataId,
        },
      };

      this.isLoadingButton = true;
      const token = this.$cookies.get('token');

      api
        .post('/profiles', data, {
          headers: {
            token: token,
          },
        })
        .then(() => {
          messageSuccess(
            `Cadastro do perfil: ${this.name}, foi realizado com sucesso!`
          );

          this.description = '';
          this.name = '';
          this.access = [];
          this.isLoadingButton = false;
          this.Access_Data = [];
          this.Access_DataId = [];

          this.$store.dispatch('Profile/getProfileByData', '/users');
        })
        .catch((err) => {
          this.isLoadingButton = false;
          messageError(err.response.data.err);
          console.log(err);
        });
    },

    handleAddItem(id, name) {
      this.Access_Data = [
        ...this.Access_Data,
        {
          id: id,
          name: name,
        },
      ];

      this.Access_DataId = [
        ...this.Access_DataId,
        {
          id: Number(id),
        },
      ];

      this.Access_DataIds = [...this.Access_DataIds, id];

      setTimeout(() => {
        const resultArray = this.screensData.filter(
          (item) => !this.Access_DataIds.includes(item.id)
        );
        this.screensData = resultArray;
        this.Access_DataIds = [];
      }, 300);
    },

    async handleRemoveItem(id, name) {
      this.Access_DataIds = [...this.Access_DataIds, id];

      setTimeout(() => {
        const resultArray = this.Access_Data.filter(
          (item) => !this.Access_DataIds.includes(item.id)
        );
        const resultArrayIds = this.Access_DataId.filter(
          (item) => !this.Access_DataIds.includes(item.id)
        );
        this.Access_Data = resultArray;
        this.Access_DataId = resultArrayIds;

        this.screensData = [
          ...this.screensData,
          {
            id,
            name,
          },
        ];
      }, 500);
    },

    getAccessScreens() {
      const token = this.$cookies.get('token');

      api
        .get('/screens', {
          headers: {
            token: token,
          },
        })
        .then((res) => {
          this.screensData = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    handleOpenModal(id) {
      this.isOpenModal = true;
      this.currentIdModal = id;
      this.getProfileById(id);
    },

    handleCloseModal() {
      this.isOpenModal = false;
    },

    getProfileById(id) {
      const token = this.$cookies.get('token');

      api
        .get(`/profiles/${id}`, {
          headers: {
            token: token,
          },
        })
        .then((res) => {
          this.profileDataByID = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style lang="scss">
.access {
  background: #ddd;
  padding: 10px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  gap: 6px;
  height: 300px;
  overflow-y: hidden;
}
</style>
