<template>
  <Schema>
    <div class="flex w-full h-full flex-col">
      <!-- Breadcrumb -->
      <div class="flex w-full mb-4 text-sm">
        <div class="mb-4 text-gray-400">
          <router-link to="/app" class="hover:text-blue-500 transition-colors">
            Home
          </router-link>
          <span class="text-blue-500"> • Configuração</span>
        </div>
      </div>
      <div class="min-h-full main">
        <TabView>
          <TabPanel header="PARAMETRIZAR CALENDÁRIO" class="relative">
            <div
              class="w-[calc(100vw-210px-200px)] flex gap-5 overflow-x-auto flex-nowrap whitespace-nowrap pb-10"
              ref="scrollDiv"
            >
              <div>
                <ConfigurationItem
                  :description="'Modalidade'"
                  :createOrUpdate="handleCreateOrUpdateModality"
                  :listData="modalities"
                  :isShowLoading="isShowLoading"
                />
              </div>
              <div>
                <ConfigurationItem
                  :description="'Área de Negócio'"
                  :createOrUpdate="handleCreateOrUpdateBusinessArea"
                  :listData="businessAreas"
                  :isShowLoading="isShowLoading"
                />
              </div>
              <div>
                <ConfigurationItem
                  :description="'Tipo de Calendário'"
                  :createOrUpdate="handleCreateOrUpdateCalendarType"
                  :listData="calendarTypes"
                  :isShowLoading="isShowLoading"
                />
              </div>
              <div>
                <ConfigurationItem
                  :description="'Relação Período x Semestre'"
                  :createOrUpdate="handleCreateRelationshipPeriodSemester"
                  :listData="relationshipsPeriodsSemesters"
                  :isShowLoading="isShowLoading"
                  :isSemesterPeriod="true"
                />
              </div>
            </div>

            <button
              @click="scrollToLeft"
              class="absolute top-[40%] -translate-y-1/2 -left-8 text-4xl"
            >
              <i class="ph ph-caret-left text-blue-500"></i>
            </button>
            <button
              @click="scrollToRight"
              class="absolute top-[40%] -translate-y-1/2 -right-8 text-4xl"
            >
              <i class="ph ph-caret-right text-blue-500"></i>
            </button>
          </TabPanel>
          <TabPanel header="OUTRAS ABAS"> OUTRAS ABAS </TabPanel>
        </TabView>
      </div>
    </div>
  </Schema>
</template>
<script>
import Schema from '../../components/Schema';
import api from '../../lib/axios';
import ConfigurationItem from './components/configuration-Item.vue';
import Cookies from 'vue-cookies';
import { useToast } from 'vue-toastification';
import { ConfigurationService } from '../../services';

export default {
  name: 'ConfigurationView',
  components: {
    Schema,
    ConfigurationItem,
  },

  data() {
    return {
      modalities: [],
      businessAreas: [],
      calendarTypes: [],
      relationshipsPeriodsSemesters: [],
      isShowLoading: true,
      token: Cookies.get('token'),
      isShowBtnScroll: false,
      configurationService: null,
    };
  },

  mounted() {
    this.configurationService = new ConfigurationService(this.token);
    this.handleGetAllModalities();
    this.handleGetAllBusinessAreas();
    this.handleGetAllCalendarTypes();
    this.handleGetAllRelationshipsPeriodsSemesters();
  },

  setup() {
    const toast = useToast();
    return { toast };
  },

  methods: {
    async handleGetAllModalities() {
      try {
        this.isShowLoading = true;
        const result = await this.configurationService.getModalities();
        this.modalities = this.sortList(result);
      } catch (error) {
        console.error('Error fetching modalities:', error);
      } finally {
        this.isShowLoading = false;
      }
    },

    async handleGetAllBusinessAreas() {
      try {
        this.isShowLoading = true;
        const result = await this.configurationService.getBusinessAreas();
        this.businessAreas = this.sortList(result);
      } catch (error) {
        console.error('Error fetching business areas:', error);
      } finally {
        this.isShowLoading = false;
      }
    },

    async handleGetAllCalendarTypes() {
      try {
        this.isShowLoading = true;
        const result = await this.configurationService.getCalendarTypes();
        this.calendarTypes = this.sortList(result);
      } catch (error) {
        console.error('Error fetching calendar types:', error);
      } finally {
        this.isShowLoading = false;
      }
    },

    async handleGetAllRelationshipsPeriodsSemesters() {
      try {
        this.isShowLoading = true;
        const result =
          await this.configurationService.getRelationshipPeriodSemester();
        this.relationshipsPeriodsSemesters = this.sortList(result);
      } catch (error) {
        console.error('Error fetching relationships periods semesters:', error);
      } finally {
        this.isShowLoading = false;
      }
    },

    async handleCreateOrUpdateModality(data) {
      try {
        const dataToCreateOrCreate = {
          name: data.name,
          active: data.active,
        };

        if (data.isUpdate) {
          await api.patch(`/modality/${data.id}`, dataToCreateOrCreate, {
            headers: {
              token: this.token,
            },
          });

          this.toast.success(
            `${dataToCreateOrCreate.name} atualizado com sucesso!`
          );
        } else {
          dataToCreateOrCreate.active = true;
          await api.post('/modality', dataToCreateOrCreate, {
            headers: {
              token: this.token,
            },
          });
          this.toast.success(`${dataToCreateOrCreate.name} salvo com sucesso!`);
        }
      } catch (error) {
        const { data } = error.response;
        if (data && data.message) {
          this.toast.error(data.message);
        }
      } finally {
        await this.handleGetAllModalities();
      }
    },

    async handleCreateOrUpdateBusinessArea(data) {
      try {
        const dataToCreateOrUpdate = {
          name: data.name,
          active: data.active,
        };

        if (data.isUpdate) {
          await api.patch(`/business-area/${data.id}`, dataToCreateOrUpdate, {
            headers: {
              token: this.token,
            },
          });

          this.toast.success(
            `${dataToCreateOrUpdate.name} atualizado com sucesso!`
          );
        } else {
          dataToCreateOrUpdate.active = true;
          await api.post('/business-area', dataToCreateOrUpdate, {
            headers: {
              token: this.token,
            },
          });

          this.toast.success(`${dataToCreateOrUpdate.name} salvo com sucesso!`);
        }
      } catch (error) {
        const { data } = error.response;
        if (data && data.message) {
          this.toast.error(data.message);
        }
      } finally {
        await this.handleGetAllBusinessAreas();
      }
    },

    async handleCreateOrUpdateCalendarType(data) {
      try {
        const dataToCreateOrUpdate = {
          name: data.name,
          active: data.active,
        };

        if (data.isUpdate) {
          await api.patch(`/calendar-type/${data.id}`, dataToCreateOrUpdate, {
            headers: {
              token: this.token,
            },
          });

          this.toast.success(
            `${dataToCreateOrUpdate.name} atualizado com sucesso!`
          );
        } else {
          dataToCreateOrUpdate.active = true;
          await api.post('/calendar-type', dataToCreateOrUpdate, {
            headers: {
              token: this.token,
            },
          });

          this.toast.success(`${dataToCreateOrUpdate.name} salvo com sucesso!`);
        }
      } catch (error) {
        const { data } = error.response;
        if (data && data.message) {
          this.toast.error(data.message);
        }
      } finally {
        await this.handleGetAllCalendarTypes();
      }
    },

    async handleCreateRelationshipPeriodSemester(data) {
      try {
        const dataToCreateOrUpdate = {
          period: data.period,
          semester: data.semester,
          description: `${data.period} ${data.semester}`,
          active: data.active,
        };

        if (data.isUpdate) {
          await api.patch(
            `/relationship-period-semester/${data.id}`,
            dataToCreateOrUpdate,
            {
              headers: {
                token: this.token,
              },
            }
          );

          this.toast.success(
            `${dataToCreateOrUpdate.description} atualizado com sucesso!`
          );
        } else {
          dataToCreateOrUpdate.active = true;
          await api.post(
            '/relationship-period-semester',
            dataToCreateOrUpdate,
            {
              headers: {
                token: this.token,
              },
            }
          );

          this.toast.success(
            `${dataToCreateOrUpdate.description} salvo com sucesso!`
          );
        }
      } catch (error) {
        const { data } = error.response;
        if (data && data.message) {
          this.toast.error(data.message);
        }
      } finally {
        await this.handleGetAllRelationshipsPeriodsSemesters();
      }
    },

    sortList(list) {
      return list.sort(this.compare);
    },

    compare(a, b) {
      if (a.name < b.name) {
        return -1;
      } else if (a.name > b.name) {
        return 1;
      } else {
        return 0;
      }
    },

    scrollToRight() {
      const div = this.$refs.scrollDiv;
      div.scrollLeft = div.scrollWidth;
    },

    scrollToLeft() {
      const div = this.$refs.scrollDiv;
      div.scrollLeft = 0;
    },
  },
};
</script>
<style scoped lang="scss">
.main {
  position: relative;
}

.scroll-container {
  display: flex;
  overflow-x: scroll;
  scroll-behavior: smooth;
  padding-bottom: 10px;
}

.scroll-content {
  margin-right: 15px;
  flex: 1 0 auto;
  width: 22%;
}

.scroll-button-left {
  position: absolute;
  top: 35%;
  left: -25px;
  transform: translateY(-50%);
  border: none;
  background-color: transparent;
  font-size: 30px;
  color: #666;
  cursor: pointer;
  z-index: 1;
}
.scroll-button-right {
  position: absolute;
  top: 35%;
  right: -25px;
  transform: translateY(-50%);
  border: none;
  background-color: transparent;
  font-size: 30px;
  color: #666;
  cursor: pointer;
  z-index: 1;
}

.scroll-button:first-of-type {
  left: 0;
}

.scroll-button:last-of-type {
  right: 0;
}
</style>
