<template>
  <Schema>
    <div class="w-full flex-1">
      <h1 class="text-3xl text-gray-600">Administração</h1>
      <h2 class="text-xl text-gray-600">
        Gerenciar espaços nas unidades do Ensino Albert Einstein
      </h2>
      <div
        class="bg-zinc-50 mt-10 mb-10 rounded-xl p-7 gap-4 flex justify-between"
      >
        <div class="flex text-center items-center">
          <div class="justify-start">
            <h1 class="text-xl/8 text-blue-500">
              Bem-vindo(a) ao Gestão de Salas e Ensalamento
            </h1>
            <h2 class="text-base text-blue-500">
              Controle reservas e otimize espaços de forma eficiente
            </h2>
          </div>
        </div>
        <div class="flex text-center items-center">
          <Calendar inline v-on:date-select="handleFilterDateCalendar" />
        </div>
        <div class="flex text-center items-center">
          <img src="../../assets/img-admin.png" width="500" />
        </div>
      </div>

      <div class="flex border-b-2 text-white">
        <h1 class="bg-blue-500 px-5 py-1 m-0 rounded-t-lg text-lg">
          SALAS OCUPADAS
        </h1>
      </div>

      <SelectableUnits
        @handleChangeUnit="handleChangeUnit"
        :selectableUnits="selectableUnits"
        :unitSelected="unitSelected"
      />

      <div class="flex mt-5 mb-6 gap-3">
        <div class="flex-1 text-blue-500">
          <span class="text-sm block">Data</span>
          <Calendar
            v-model="filterDates"
            selectionMode="range"
            :manualInput="false"
            showIcon
            dateFormat="dd/mm/yy"
            v-on:date-select="getUnits()"
            class="border-2 border-gray-300 h-12 rounded-lg w-72 text-md pl-4 bg-white"
          />
        </div>
        <div class="flex-1 justify-items-center"></div>
        <div class="flex-1 text-right">
          <button
            @click="handleExport"
            v-if="appointments.length"
            class="p-2 px-5 mt-6 text-white bg-blue-500 border-1 border-blue-500 rounded-full hover:brightness-90 transition-all text-lg disabled:cursor-not-allowed"
          >
            <i class="ph ph-export"></i> Exportar
          </button>
        </div>
      </div>

      <DataTableAppointments
        v-if="isShowComponent"
        ref="dataTableAppointments"
        :appointments="appointments"
        @handleFilter="handleFilter"
      />

      <DataTableUnits
        :units="units"
        :unitsBackup="unitsBackup"
        v-show="!appointments.length && !isFilterAppointments"
        @handleExport="handleExport"
        @handleUnitsFilter="handleUnitsFilter"
      />
    </div>
  </Schema>
</template>
<script>
import Schema from '../../components/Schema/index.vue';
import Calendar from 'primevue/calendar';
import { useUnitService, useAppointmentService } from '../../services';
import DataTableAppointments from './components/DataTableAppointments.vue';
import DataTableUnits from './components/DataTableUnits.vue';
import SelectableUnits from './components/SelectableUnits.vue';
import { format } from 'date-fns';
import Cookies from 'vue-cookies';

export default {
  name: 'AdministrationScreen',

  components: {
    Schema,
    Calendar,
    DataTableAppointments,
    DataTableUnits,
    SelectableUnits,
  },

  data() {
    return {
      unitService: useUnitService(),
      appointmentService: useAppointmentService(),
      units: [],
      unitsBackup: [],
      unitSelected: [],
      selectableUnits: [],
      filterDates: [],
      userInfo: Cookies.get('user_info'),
      appointments: [],
      appointmentsBackup: [],
      isShowComponent: false,
      isFilterAppointments: false,
    };
  },

  mounted() {
    this.filterDates = [new Date(), new Date()];
    this.getUnits();
  },

  methods: {
    async getUnits() {
      this.isShowComponent = false;
      this.appointments = [];
      this.unitsSelected = [];
      this.units = [];

      const startDate = this.filterDates[0]
        ? format(this.filterDates[0], 'yyyy-MM-dd')
        : null;
      let finalDate = this.filterDates[1]
        ? format(this.filterDates[1], 'yyyy-MM-dd')
        : null;

      if (this.filterDates[0] && !this.filterDates[1]) {
        finalDate = startDate;
      }

      const data = {
        startDate,
        finalDate,
        userRole: this.userInfo.role,
        userId: this.userInfo.id,
      };

      this.isLoading = true;
      this.units = await this.appointmentService
        .getAppointmentsAdminScreen(data)
        .finally(() => {
          this.isLoading = false;
        });

      this.unitsBackup = this.units;

      this.selectableUnits = [{ name: 'Todas as unidades', value: 0 }];
      this.unitSelected = [{ name: 'Todas as unidades', value: 0 }];
      this.isFilterAppointments = false;

      for (const unit of this.units) {
        this.selectableUnits.push({ name: unit.name, value: unit.id });
      }
    },

    async handleChangeUnit(event) {
      this.appointments = [];
      this.isShowComponent = false;
      this.unitSelected = [];

      const value = event.value || [];
      const lastItem = value[value.length - 1];

      if (value.length && lastItem.value !== 0) {
        const findUnit = this.unitsBackup.find(
          (unit) => unit.id === lastItem.value
        );
        this.isLoading = true;
        setTimeout(() => {
          this.appointments =
            findUnit && findUnit.appointments ? findUnit.appointments : [];

          this.appointments = this.appointments.map((appointment) => {
            const roomName = appointment.getRoom
              ? appointment.getRoom.name
              : appointment.getSolicitationId.roomType === 'On-line'
              ? 'On-line'
              : '-';
            return {
              roomName: roomName,
              eventName: appointment.getSolicitationId.eventName,
              localName: appointment.getSolicitationId.getLocalId
                ? appointment.getSolicitationId.getLocalId.name
                : '-',
              dateFormatted: appointment.dateFormatted,
              time: `${appointment.startTime} - ${appointment.finalTime}`,
              solicitationId: appointment.getSolicitationId.id,
              recurrent: appointment.getSolicitationId.recurrent,
            };
          });

          this.appointmentsBackup = this.appointments;

          this.unitSelected = [{ value: findUnit.id, name: findUnit.name }];
          this.isShowComponent = true;
          this.isLoading = false;
        }, 1000);
      }

      if (lastItem && lastItem.value === 0) {
        this.appointments = [];

        this.unitSelected = [{ name: 'Todas as unidades', value: 0 }];
        this.isFilterAppointments = false;
      }
    },

    handleFilterDateCalendar(date) {
      this.filterDates = [date, date];
      this.getUnits();
    },

    handleExport() {
      this.$refs.dataTableAppointments.$refs.dataTableRef.exportCSV();
    },

    handleUnitsFilter(objectFilter) {
      if (objectFilter.unitsSelected.length) {
        this.units = objectFilter.unitsSelected;
      } else if (objectFilter.filterLocalName.length) {
        this.units = this.unitsBackup.filter((unit) => {
          return unit.localNames
            .toLowerCase()
            .includes(objectFilter.filterLocalName.toLowerCase());
        });
      } else {
        this.units = this.unitsBackup;
      }
    },

    handleFilter() {
      this.isFilterAppointments = true;
      this.isLoading = true;
      this.isShowComponent = false;

      setTimeout(() => {
        if (this.filterRoomName.length) {
          this.appointments = this.appointments.filter((appoint) => {
            return appoint.roomName
              .toLowerCase()
              .includes(this.filterRoomName.toLowerCase());
          });
        } else if (this.filterEventName.length) {
          this.appointments = this.appointments.filter((appoint) => {
            return appoint.eventName
              .toLowerCase()
              .includes(this.filterEventName.toLowerCase());
          });
        } else if (this.filterLocalName.length) {
          this.appointments = this.appointments.filter((appoint) => {
            return appoint.localName
              .toLowerCase()
              .includes(this.filterLocalName.toLowerCase());
          });
        } else if (this.filterDateFormatted.length) {
          this.appointments = this.appointments.filter((appoint) => {
            return appoint.dateFormatted
              .toLowerCase()
              .includes(this.filterDateFormatted.toLowerCase());
          });
        } else if (this.filterTime.length) {
          this.appointments = this.appointments.filter((appoint) => {
            return appoint.time
              .toLowerCase()
              .includes(this.filterTime.toLowerCase());
          });
        } else if (this.filterSolicitationId !== '') {
          this.appointments = this.appointments.filter((appoint) => {
            return appoint.solicitationId === this.filterSolicitationId;
          });
        } else {
          this.appointments = this.appointmentsBackup;
        }
        this.isLoading = false;
        this.isShowComponent = true;
      }, 1000);
    },
  },

  computed: {
    isLoading: {
      set(value) {
        this.$store.commit('Default/CHANGE_ISLOADING', value);
      },
    },

    filterRoomName: {
      get() {
        return this.$store.state.AdminScreen.filterRoomName;
      },
    },

    filterEventName: {
      get() {
        return this.$store.state.AdminScreen.filterEventName;
      },
    },

    filterLocalName: {
      get() {
        return this.$store.state.AdminScreen.filterLocalName;
      },
    },

    filterDateFormatted: {
      get() {
        return this.$store.state.AdminScreen.filterDateFormatted;
      },
    },

    filterTime: {
      get() {
        return this.$store.state.AdminScreen.filterTime;
      },
    },

    filterSolicitationId: {
      get() {
        return this.$store.state.AdminScreen.filterSolicitationId;
      },
    },
    
    unitsSelected: {
      set(value) {
        this.$store.commit('AdminScreen/SET_UNITS_SELECTED', value);
      },
    },
  },
};
</script>

<style>
.p-datepicker {
  padding: 0px !important;
}

.p-datepicker-calendar {
  padding: 0px !important;
  margin: 0 !important;
}
.p-datepicker-other-month {
  padding: 0px !important;
  margin: 0 !important;
}

.p-datepicker-touch-ui {
  border: solid 1px red !important ;
}
</style>
