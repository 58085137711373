import api from '../lib/axios';
import Cookies from 'vue-cookies';

export class ReservationService {
  constructor() {
    this.token = Cookies.get('token');
  }

  async getAllByUserId(
    userId,
    page,
    perPage,
    filterId,
    filterDate,
    filterLocal,
    filterRequester,
    filterUnit,
    filterStatus,
    filterEventType,
    filterBranch,
    filterEventName,
    filterStartTime,
    filterEndTime,
    filterRoom,
    isFilterMostRecent
  ) {
    try {
      const response = await api.get(`/reservation/user/${userId}`, {
        headers: {
          token: this.token,
        },
        params: {
          perPage: Number(perPage),
          page: Number(page),
          id: filterId !== '' ? filterId : undefined,
          date: filterDate !== '' ? filterDate : undefined,
          local: filterLocal !== '' ? filterLocal : undefined,
          requester: filterRequester !== '' ? filterRequester : undefined,
          unit: filterUnit !== '' ? filterUnit : undefined,
          status: filterStatus !== '' ? filterStatus : undefined,
          eventType: filterEventType !== '' ? filterEventType : undefined,
          phone: filterBranch !== '' ? filterBranch : undefined,
          eventName: filterEventName !== '' ? filterEventName : undefined,
          startTime: filterStartTime !== '' ? filterStartTime : undefined,
          endTime: filterEndTime !== '' ? filterEndTime : undefined,
          room: filterRoom !== '' ? filterRoom : undefined,
          isFilterMostRecent: isFilterMostRecent,
        },
      });
      return response.data;
    } catch (error) {
      console.error('Error fetching solicitations:', error);
    }
  }
}

export const useReservationService = () => new ReservationService();
