<template>
  <div class="flex w-full h-full flex-col mt-20">
    <h1 class="text-gray-600 mb-3 mr-3 text-2xl" v-if="hasRooms">
      Resultados encontrados
    </h1>
    <div class="flex" v-if="!hasRooms && availableRooms.length > 0">
      <InlineMessage severity="warn" class="font-bold h-12 w-full">
        Não foram encontrados resultados com base nos filtros de sua pesquisa.
        Abaixo segue sugestão do sistema.</InlineMessage
      >
    </div>
    <DataTable
      v-if="hasRooms"
      v-model:expandedRows="expandedRows"
      :value="availableRooms"
      dataKey="id"
      v-model:filters="filters"
      paginatorTemplate="FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink RowsPerPageDropdown"
      paginator
      :rows="15"
      :rowsPerPageOptions="[15, 20, 30, 50]"
      currentPageReportTemplate="{first} de {last} de {totalRecords}"
      :globalFilterFields="[
        'courseName',
        'class',
        'classScheduleCalendar.unit.name',
      ]"
    >
      <template #header>
        <span class="p-input-icon-left">
          <i class="pi pi-search pt-1" />
          <input
            v-model="filters['global'].value"
            placeholder="Pesquisa global"
            class="border-2 border-gray-300 p-3 pl-10 rounded-lg w-full text-sm mt-2"
          />
        </span>
      </template>
      <Column expander style="width: 5rem" />
      <Column field="courseName" header="Curso"></Column>
      <Column field="class" header="Turma"></Column>
      <Column field="classScheduleCalendar.unit.name" header="Unidade">
      </Column>
      <Column field="classScheduleCalendar.version" header="Calendário">
      </Column>
      <Column field="rooms.length" header="Qtd. Salas"> </Column>
      <template #expansion="slotProps">
        <div class="p-3">
          <h5 class="font-bold mb-5">
            Salas disponíveis para o curso e turma acima
          </h5>
          <DataTable :value="slotProps.data.rooms">
            <Column field="roomName" header="Sala"></Column>
            <Column header="Layout">
              <template #body="slotProps">
                <span
                  v-if="
                    slotProps.data.capacities &&
                    slotProps.data.capacities.length === 1
                  "
                  class="w-72 h-12"
                >
                  {{ slotProps.data.capacities[0].layoutName }}
                </span>
                <Dropdown
                  v-else
                  v-model="layout"
                  :options="slotProps.data.capacities"
                  optionLabel="layoutName"
                  placeholder="Selecione o layout"
                  class="w-72 h-12"
                  @change="handleChangeLayout"
                />
              </template>
            </Column>
            <Column header="Cap. sala">
              <template #body="slotProps">
                <span
                  v-if="
                    slotProps.data.capacities &&
                    slotProps.data.capacities.length === 1
                  "
                  class="w-72 h-12"
                >
                  {{ slotProps.data.capacities[0].capacity }}
                </span>
                <span v-else>
                  {{ getCapacityRoom(slotProps.data.capacities) }}
                </span>
              </template>
            </Column>
            <Column field="enrollment" header="Matricula"></Column>
            <Column field="unitName" header="Unidade"></Column>
            <Column headerStyle="width:4rem">
              <template #body="{ data }">
                <button
                  type="button"
                  @click="handleOpenModal(data)"
                  class="p-1 pr-2 pl-2 text-blue-500 border-2 border-blue-500 rounded-full hover:brightness-90 transition-all text-sm font-bold disabled:cursor-not-allowed"
                >
                  Agendar
                </button>
              </template>
            </Column>
          </DataTable>
        </div>
      </template>
    </DataTable>
    <div ref="gridResults"></div>
    <Dialog
      v-model:visible="displayModal"
      :style="{ width: '50vw' }"
      :modal="true"
      :closable="false"
      :showHeader="false"
    >
      <button
        @click="handleCloseModal()"
        class="float-right bg-blue-500 text-white rounded-full py-2 w-9 h-9 mt-3 justify-self-end"
      >
        <i class="ph ph-x"></i>
      </button>
      <h1 class="border-b-2 mt-5 mb-3 text-blue-500 pb-5 text-xl">
        Detalhes do agendamento
      </h1>
      <h1 class="mt-5 text-gray-500 pb-5 text-lg">
        Você está realizando um agendamento para a
        <b>{{ classSelected.roomName }}</b
        >, confirme informações abaixo:
      </h1>
      <div class="flex">
        <h1 class="text-gray-500 mb-5">
          <b>Turma: </b>{{ classSelected.class }}
        </h1>
      </div>
      <div class="flex justify-between">
        <h1 class="text-gray-500 mb-5">
          <b>Sala: </b>{{ classSelected.roomName }}
        </h1>
        <h1 class="text-gray-500 mb-5">
          <b>Unidade: </b>{{ classSelected.unitName }}
        </h1>
        <h1></h1>
      </div>
      <div class="flex">
        <h1 class="text-gray-500 mb-5">
          <b>Curso: </b>{{ classSelected.courseName }}
        </h1>
      </div>
      <div class="flex justify-between">
        <h1 class="text-gray-500 mb-5">
          <b>Calendário: </b>{{ classSelected.calendarName }}
        </h1>
        <h1 class="text-gray-500 mb-5">
          <b>Layout: </b>{{ classSelected.layoutName }}
        </h1>
        <h1></h1>
      </div>
      <div class="flex">
        <h1 class="text-gray-500">
          <b>Total de encontros: </b>{{ classSelected.totalMeetings }}
        </h1>
      </div>
      <div class="flex border-t-2 mt-5">
        <h1 class="text-gray-500 mt-3">
          <b>Sala online:</b>
        </h1>
      </div>
      <div class="flex">
        <p class="text-blue-500">Link da sala online</p>
      </div>
      <div class="flex items-end justify-end mb-5">
        <input
          class="w-full text-sm border-2 border-gray-300 h-12 pl-4 rounded mt-2 focus:ring-2 focus:ring-blue-500 outline-none transition-all"
          type="text"
          placeholder="Link da sala online"
        />
      </div>
      <div class="flex" v-if="isRehearse">
        <InlineMessage severity="warn" class="font-bold"
          >Tem certeza que deseja reensalar esta turma? Todos os agendamentos
          realizados no ensalamento anterior serão excluídos</InlineMessage
        >
      </div>
      <div class="border-t-2 flex items-end justify-end mt-5">
        <button
          type="button"
          @click="handleCloseModal()"
          v-if="!loading"
          class="mr-5 w-[150px] h-12 text-blue-500 border border-blue-500 rounded-full hover:brightness-90 transition-all text-lg disabled:cursor-not-allowed"
        >
          Cancelar
        </button>
        <button
          @click="handleConfirm()"
          v-if="!loading"
          class="w-[150px] bg-blue-500 text-white rounded-full pt-2 pb-3 text-xl mt-5 float-right"
        >
          Confirmar
        </button>
        <div v-if="loading" class="flex items-center justify-center mt-[35px]">
          <p class="mr-5">{{ loadingTitle }}</p>
          <div
            class="w-11 h-11 rounded-full flex border-2 border-t-blue-500 animate-spin"
          ></div>
        </div>
      </div>
    </Dialog>
  </div>
</template>
<script>
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Dropdown from 'primevue/dropdown';
import InlineMessage from 'primevue/inlinemessage';
import { FilterMatchMode } from 'primevue/api';
import { messageWarning, messageSuccess } from '../../../lib/toast';
import { ref } from 'vue';
import Cookies from 'vue-cookies';

export default {
  name: 'SchedulingSimulationResults',
  components: {
    DataTable,
    Column,
    Dropdown,
    InlineMessage,
  },

  props: {
    scheduledClassesService: Object,
    handleSearchAgain: Function,
  },

  data() {
    return {
      filters: null,
      expandedRows: ref([]),
      layout: [],
      displayModal: false,
      classSelected: null,
      userInfo: Cookies.get('user_info'),
      loading: false,
      loadingTitle: '',
    };
  },

  created() {
    this.initFilters();
  },

  computed: {
    isRehearse() {
      return this.$store.state.SchedulingSimulation.isRehearse;
    },

    scheduledClassesItem() {
      return this.$store.state.SchedulingSimulation.scheduledClassesItem;
    },

    hasRooms() {
      return this.$store.state.SchedulingSimulation.hasRooms;
    },

    availableRooms() {
      return this.$store.state.SchedulingSimulation.availableRooms;
    },
  },

  updated() {
    this.$refs.gridResults.scrollIntoView();
  },

  methods: {
    initFilters() {
      this.filters = {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      };
    },

    handleChangeLayout(event) {
      this.layout = event.value;
    },

    getCapacityRoom(capacities) {
      if (this.layout !== null && capacities.length) {
        const capacity = capacities.find(
          (capacity) => capacity.id === this.layout.id
        );
        return capacity ? capacity.capacity : '';
      }
    },

    handleOpenModal(data) {
      if (data.capacities.length === 1) {
        data['layoutName'] = data.capacities[0].layoutName;
        data['roomCapacity'] = data.capacities[0].capacity;
      }

      if (data.capacities.length > 1 && this.layout.length === 0) {
        messageWarning('Você deve selecionar um layout para a sala');
        return;
      }

      if (data.capacities.length > 1 && this.layout) {
        data['layoutName'] = this.layout.layoutName;
        data['roomCapacity'] = this.layout.capacity;
      }

      this.displayModal = true;
      this.classSelected = data;
    },

    handleCloseModal() {
      this.displayModal = false;
      this.classSelected = null;
      this.layout = [];
    },

    handleConfirm() {
      this.loading = true;
      
      const data = {
        createdBy: this.userInfo.id,
        classScheduleCalendarId: this.classSelected.classScheduleCalendarId,
        roomId: this.classSelected.id,
        layoutName: this.classSelected.layoutName,
        unitId: this.classSelected.unitId,
        roomCapacity: this.classSelected.roomCapacity,
        enrollment: this.classSelected.enrollment,
        unitName: this.classSelected.unitName,
        roomName: this.classSelected.roomName,
        classCode: this.classSelected.class,
        courseName: this.classSelected.courseName,
      };

      if (this.isRehearse) {
        this.loadingTitle = 'Reensalando turma, por favor aguarde...';
        this.scheduledClassesService
          .deleteScheduledClasses(this.scheduledClassesItem.id)
          .then(() => {
            this.createScheduledClasses(data);
          });
      } else {
        this.loadingTitle = 'Agendando turma, por favor aguarde...';
        this.createScheduledClasses(data);
      }
    },

    createScheduledClasses(data) {
      this.scheduledClassesService.createScheduledClasses(data).then(() => {
        this.handleSearchAgain(data.classCode);
        this.handleCloseModal();
        this.loading = false;
        if (this.isRehearse) {
          messageSuccess('Reensalamento realizado com sucesso!');
        } else {
          messageSuccess('Agendamento realizado com sucesso!');
        }
      });
    },
  },
};
</script>
