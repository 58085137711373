<template>
  <h1 class="mt-14 bg-blue-500 text-white px-5 py-2 rounded-lg text-sm">
    • {{ roomType }} por {{ localType }}
  </h1>

  <section class="mt-4">
    <div class="flex-1">
      <label class="text-sm text-gray-500">
        Nome do evento/curso

        <input
          type="text"
          v-model="eventName"
          class="border-2 border-gray-300 h-12 rounded-lg w-full text-md mt-2 pl-4"
        />
      </label>
    </div>
  </section>

  <section class="mt-4">
    <div class="flex-1">
      <label class="text-sm text-gray-500">
        Descrição do evento/curso

        <input
          type="text"
          v-model="eventDescription"
          class="border-2 border-gray-300 h-12 rounded-lg w-full text-md mt-2 pl-4"
        />
      </label>
    </div>
  </section>

  <section v-show="localType === 'Auditórios'">
    <AuditoryForm />
  </section>

  <section class="mt-4" v-show="roomType !== 'On-line'">
    <div class="flex-1">
      <label class="text-sm text-gray-500">
        Número de participantes

        <input
          type="number"
          v-model="numberOfParticipants"
          class="border-2 border-gray-300 h-12 rounded-lg w-full text-md mt-2 pl-4"
        />
      </label>
    </div>
  </section>

  <div v-show="roomType !== 'On-line'">
    <EquipmentSelection
      :equipments="equipments"
      :equipmentsSelect="equipmentsSelect"
      :handleAddEquipment="handleAddEquipment"
      :handleRemoveEquipment="handleRemoveEquipment"
    />
  </div>

  <section class="mt-4" v-show="roomType !== 'On-line'">
    <label class="text-sm text-gray-500">
      Observação equipamentos

      <textarea
        v-model="equipmentsDescription"
        class="border-2 border-gray-300 min-h-[120px] resize-y rounded-lg w-full text-md mt-2 pl-4"
      />
    </label>
  </section>

  <section class="mt-4" v-show="!recurrent && roomType !== 'On-line'">
    <div class="flex-1">
      <label class="text-sm text-gray-500">
        Exige preparação prévia

        <select
          v-model="previousPreparation"
          class="border-2 border-gray-300 h-12 rounded-lg w-full text-md mt-2 pl-4"
        >
          <option value="" selected disabled>Selecione uma opção</option>
          <option :value="true">Sim</option>
          <option :value="false">Não</option>
        </select>
      </label>
    </div>
  </section>

  <div v-show="previousPreparation">
    <div class="flex items-center gap-4">
      <section class="mt-4 flex-1">
        <div class="flex-1">
          <label class="text-sm text-gray-500">
            Data de início montagem

            <Calendar
              v-model="preparationStartDay"
              showIcon
              class="border-2 border-gray-300 h-12 rounded-lg w-full text-md mt-2 pl-4"
              dateFormat="dd/mm/yy"
              :manualInput="false"
            />
          </label>
        </div>
      </section>
      <section class="mt-4 flex-1">
        <div class="flex-1">
          <label class="text-sm text-gray-500">
            Horário início montagem

            <input
              v-model="preparationStartInitial"
              type="time"
              class="border-2 flex-1 border-gray-300 h-12 rounded-lg w-full text-md mt-2 pl-4"
            />
          </label>
        </div>
      </section>

      <section class="mt-4 flex-1">
        <div class="flex-1">
          <label class="text-sm text-gray-500">
            Horário final montagem

            <input
              v-model="preparationStartFinal"
              type="time"
              class="border-2 flex-1 border-gray-300 h-12 rounded-lg w-full text-md mt-2 pl-4"
            />
          </label>
        </div>
      </section>
    </div>

    <div class="flex items-center gap-4">
      <section class="mt-4 flex-1">
        <div class="flex-1">
          <label class="text-sm text-gray-500">
            Data desmontagem
            <Calendar
              v-model="preparationEndDay"
              showIcon
              class="border-2 border-gray-300 h-12 rounded-lg w-full text-md mt-2 pl-4"
              dateFormat="dd/mm/yy"
              :manualInput="false"
            />
          </label>
        </div>
      </section>
      <section class="mt-4 flex-1">
        <div class="flex-1">
          <label class="text-sm text-gray-500">
            Horário início desmontagem

            <input
              v-model="preparationEndInitial"
              type="time"
              class="border-2 flex-1 border-gray-300 h-12 rounded-lg w-full text-md mt-2 pl-4"
            />
          </label>
        </div>
      </section>

      <section class="mt-4 flex-1">
        <div class="flex-1">
          <label class="text-sm text-gray-500">
            Horário final desmontagem

            <input
              v-model="preparationEndFinal"
              type="time"
              class="border-2 flex-1 border-gray-300 h-12 rounded-lg w-full text-md mt-2 pl-4"
            />
          </label>
        </div>
      </section>
    </div>
  </div>

  <section class="mt-6 flex justify-between items-center">
    <button
      @click="handleStep(2)"
      type="button"
      class="bg-blue-500 py-2 text-white text-sm px-10 rounded-full flex items-center justify-center disabled:opacity-70 disabled:brightness-100 disabled:cursor-not-allowed hover:brightness-90 transition-all"
    >
      <span>Voltar</span>
    </button>

    <div class="flex">
      <button
        type="button"
        @click="cleanData"
        class="bg-blue-500 py-2 text-white text-sm px-10 rounded-full flex items-center justify-center disabled:opacity-70 disabled:brightness-100 disabled:cursor-not-allowed hover:brightness-90 transition-all"
      >
        Cancelar
      </button>
      <button
        type="button"
        :disabled="handleBottomDisabled()"
        @click="handleCreateSolicitation"
        class="bg-blue-500 ml-5 py-2 text-white text-sm px-10 rounded-full flex items-center justify-center disabled:opacity-70 disabled:brightness-100 disabled:cursor-not-allowed hover:brightness-90 transition-all"
      >
        Salvar
      </button>
    </div>
  </section>
</template>
<script>
import { useRoomLayoutsService } from '../../../services';
import EquipmentSelection from '../../../components/EquipmentSelection/EquipmentSelection.vue';
import AuditoryForm from './auditory-form.vue';
import computed from './step-three-computed';
import Calendar from 'primevue/calendar';

export default {
  name: 'StepThree',

  components: {
    EquipmentSelection,
    AuditoryForm,
    Calendar,
  },

  data() {
    return {
      equipmentsSelect: [],
      equipmentsSelectId: [],
      roomLayoutsService: useRoomLayoutsService(),
    };
  },

  methods: {
    cleanData() {
      this.$store.dispatch('SolicitationStore/cleanData');
    },

    handleCreateSolicitation() {
      this.router = this.$router;
      this.$store.commit(
        'SolicitationStore/SET_MOBILE_EQUIPMENTS',
        this.equipmentsSelectId
      );
      this.$store.dispatch('SolicitationStore/handleCreateSolicitation');
    },

    handleAddEquipment(id, name) {
      this.equipmentsSelect = [
        ...this.equipmentsSelect,
        {
          id: id,
          name: name,
        },
      ];

      this.equipmentsSelectId = [
        ...this.equipmentsSelectId,
        {
          id: id,
        },
      ];

      const filterEquipments = this.equipments.filter((item) => item.id !== id);
      this.equipments = filterEquipments;
    },

    handleRemoveEquipment(id, name) {
      const updateEquipment = this.equipmentsSelect.filter(
        (item) => item.id != id
      );
      this.equipmentsSelect = updateEquipment;

      const updateEquipmentId = this.equipmentsSelectId.filter(
        (item) => item.id != id
      );
      this.equipmentsSelectId = updateEquipmentId;

      this.equipments = [
        ...this.equipments,
        {
          id: id,
          name: name,
        },
      ];
    },

    handleStep(step) {
      this.$store.commit('SolicitationStore/SET_STEP', step);
    },

    handleBottomDisabled() {
      if (this.eventName === '' || this.eventDescription === '') {
        return true;
      }

      if (this.previousPreparation) {
        if (
          this.preparationStartDay === '' ||
          this.preparationStartInitial === '' ||
          this.preparationStartFinal === '' ||
          this.preparationEndDay === '' ||
          this.preparationEndInitial === '' ||
          this.preparationEndFinal === ''
        ) {
          return true;
        }
      }

      if (
        this.localType === 'Auditórios' &&
        this.roomType !== 'Presencial' &&
        (this.typeOfLink === '' ||
          this.zoomResources === '' ||
          this.accessesPrevision === '')
      ) {
        return true;
      }

      return false;
    },
  },

  computed,
};
</script>
