<template>
  <Schema>
    <div class="w-full">
      <div class="flex w-full mb-4 text-sm">
        <div class="mb-4 text-gray-400">
          <router-link to="/app" class="hover:text-blue-500 transition-colors">
            Home
          </router-link>
          <span class="text-blue-500"> • Configuração de e-mail</span>
        </div>
      </div>

      <div class="flex items-center justify-between">
        <div>
          <h1 class="text-3xl text-gray-600">Configuração de e-mail</h1>
          <p class="text-gray-400 max-w-xs mt-1">
            Faça a configuração dos e-mail que são enviados
          </p>
        </div>

        <div class="flex flex-col items-end gap-4">
          <div class="flex flex-col">
            <label class="text-sm text-gray-500">Unidade</label>

            <select
              v-model="unitId"
              @change="handleGetEmailByUnit"
              class="h-10 border border-gray-300 pl-4 rounded-lg mt-2 text-sm text-gray-600 min-w-[250px]"
            >
              <option value="">Selecione uma unidade</option>
              <option v-for="unit in units" :key="unit.id" :value="unit.id">
                {{ unit.name }}
              </option>
            </select>
          </div>

          <button
            type="button"
            @click="handleSendEmail"
            :disabled="currentId === '' || isLoadingButtonTest"
            class="bg-blue-500 text-white px-4 py-2 rounded-lg flex items-center gap-2 hover:brightness-90 transition-all disabled:opacity-60 disabled:cursor-not-allowed disabled:brightness-100"
          >
            <div
              v-if="isLoadingButtonTest"
              class="block w-5 h-5 rounded-full border-t-white border-t-2 animate-spin"
            ></div>
            <div v-else>
              <i class="ph ph-paper-plane-tilt"></i>
              Testar envio de e-mail
            </div>
          </button>
        </div>
      </div>

      <div class="grid grid-cols-6 gap-2 mt-10">
        <button
          type="button"
          v-for="item in emails"
          :key="item.id"
          @click="handleGetEmail(item.id)"
          class="bg-cyan-500 text-white rounded-lg text-sm px-3 py-2 hover:brightness-90 transition-all"
        >
          {{ item.name }}
        </button>
      </div>

      <div class="mt-10" v-show="emailBody !== '' || name !== ''">
        <div>
          <label class="text-sm text-gray-400 mt-4">Assunto do e-mail</label>
          <input
            type="text"
            v-model="name"
            placeholder="Escreva o assunto do e-mail"
            class="bg-gray-100 h-12 mt-2 text-sm px-4 py-3 rounded-lg outline-none w-full border-2 border-gray-100 focus:border-blue-500 transition-all"
          />
        </div>

        <h1 class="text-sm text-gray-400 mt-4">Corpo do e-mail</h1>
        <textarea
          v-model="emailBody"
          class="bg-gray-100 min-h-[400px] text-sm mt-2 px-4 py-3 rounded-lg outline-none w-full border-2 border-gray-100 focus:border-blue-500 transition-all"
          placeholder="Corpo do e-mail"
        />

        <button
          type="button"
          @click="handleUpdateEmail"
          class="bg-blue-500 text-white w-full py-2 rounded-lg hover:brightness-90 transition-all mt-10"
        >
          Salvar configuração de e-mail
        </button>
      </div>
    </div>
  </Schema>
</template>

<script>
import { useToast } from 'vue-toastification';
import Schema from '../../components/Schema/index.vue';
import api from '../../lib/axios';

import Cookies from 'vue-cookies';

export default {
  name: 'ConfigEmail',
  components: {
    Schema,
  },

  setup() {
    const toast = useToast();
    return { toast };
  },

  data() {
    return {
      emails: [],
      name: '',
      emailBody: '',
      currentId: '',

      units: [],
      unitId: '',

      isLoadingButtonTest: false,
    };
  },

  mounted() {
    this.handleGetEmailByUnit();
    this.gelAllUnits();
  },

  methods: {
    handleUpdateEmail() {
      const token = Cookies.get('token');

      api
        .patch(
          `/email/${this.currentId}`,
          {
            subject: this.name,
            body: this.emailBody,
          },
          {
            headers: {
              token: token,
            },
          }
        )
        .then((res) => {
          this.toast.success('E-mail atualizado com sucesso');
        })
        .catch((err) => {
          console.log(err);
        });
    },

    handleSendEmail() {
      this.isLoadingButtonTest = true;
      const userId = Cookies.get('user_id');
      const token = Cookies.get('token');

      api
        .post(
          `/email/test`,
          {
            userId: Number(userId),
            emailId: this.currentId,
          },
          {
            headers: {
              token: token,
            },
          }
        )
        .then((res) => {
          this.toast.success('E-mail enviado com sucesso');
          this.isLoadingButtonTest = false;
        })
        .catch((err) => {
          console.log(err);
          this.isLoadingButtonTest = false;
        });
    },

    handleGetEmail(id) {
      const token = Cookies.get('token');

      api
        .get(`/email/${id}`, {
          headers: {
            token: token,
          },
        })
        .then((res) => {
          this.name = res.data.subject;
          this.emailBody = res.data.body;
          this.currentId = res.data.id;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    gelAllUnits() {
      api
        .get('/units', {
          headers: {
            token: this.$cookies.get('token'),
          },
        })
        .then((res) => {
          this.units = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    handleGetEmailByUnit() {
      api
        .get(`/email/unit/${Number(this.unitId)}`, {
          headers: {
            token: this.$cookies.get('token'),
          },
        })
        .then((res) => {
          this.emails = res.data;

          this.name = '';
          this.emailBody = '';
          this.currentId = '';
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>
