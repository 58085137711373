<template>
  <Schema>
    <form class="w-full max-w-3xl" @submit.prevent="handleCreateNewCalendar">
      <!-- Breadcrumb -->
      <div class="flex w-full mb-4 text-sm">
        <div class="mb-4 text-gray-400">
          <router-link to="/app" class="hover:text-blue-500 transition-colors">
            Home
          </router-link>
          <span> • Cadastrar</span>
          <span class="text-blue-500"> • Calendários</span>
        </div>
      </div>

      <div class="flex flex-col">
        <h1 class="text-3xl text-gray-600">Cadastro de calendários</h1>
        <p class="text-gray-400 max-w-xs mt-1">
          Cadastre os feriados do Albert Einstein
        </p>
      </div>

      <section class="mt-14 flex gap-8 items-center">
        <div class="flex-1 flex flex-col">
          <label class="text-sm text-gray-500">
            Unidade

            <select
              v-model="unitId"
              class="border-2 border-gray-300 h-12 rounded-lg w-full text-md mt-2 pl-4"
            >
              <option value="" selected disabled>Selecione uma unidade</option>
              <option v-for="item in unitsData" :key="item.id" :value="item.id">
                {{ item.name }}
              </option>
            </select>
          </label>

          <v-date-picker is-expanded class="mt-6" v-model="date" />
          <label class="text-sm text-gray-500 mt-6">
            Nome

            <input
              type="text"
              v-model="description"
              class="border-2 border-gray-300 h-12 rounded-lg w-full text-md mt-2 pl-4"
            />
          </label>

          <button
            v-show="profile"
            type="submit"
            :disabled="unitId === '' || date === '' || isLoadingButton === true"
            class="mt-6 bg-blue-500 py-4 text-white px-10 rounded-full flex items-center justify-center disabled:opacity-70 disabled:brightness-100 disabled:cursor-not-allowed disabled"
          >
            <SmallLoader v-if="isLoadingButton === true" />

            <span v-else>Salvar calendário</span>
          </button>
        </div>

        <div class="inputRight flex-1">
          <div class="flex items-center g-2">
            <textarea
              class="bg-gray-100 w-full resize-none p-4 rounded-lg"
              disabled
              :value="date"
            >
            </textarea>
          </div>

          <div
            class="flex flex-col items-center gap-4 h-[300px] overflow-y-auto mt-8"
          >
            <div
              v-if="isLoading === true"
              class="w-[50px] h-[50px] border-[6px] border-gray-200 border-t-blue-500 animate-spin rounded-full"
            ></div>

            <div
              class="w-full border border-gray-300 bg-white shadow-md rounded-lg py-4 px-5 flex items-center justify-between"
              v-for="item in blockedDays"
              :key="item.id"
              v-else
            >
              <div class="">
                <h3 class="text-blue-500 text-md">
                  {{ item.customData.description }}
                </h3>
                <p class="text-gray-500 text-sm">
                  {{ item.customData.unitId }}
                </p>
              </div>
              <div>
                <span class="text-blue-500 text-sm">{{
                  item.customData.date
                }}</span>
                <div class="flex flex-col items-end">
                  <button
                    @click="handleDeleteBlockedDay(item.id)"
                    type="button"
                    title="Clique para excluir o feriado"
                    class="text-red-500 text-xl mt-2"
                  >
                    <i class="ph ph-trash"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </form>
  </Schema>
</template>

<script>
import Schema from '../../components/Schema/index.vue';
import SmallLoader from '../../components/Loaders/SmallLoader.vue';

import api from '../../lib/axios';
import ptBR from 'date-fns/locale/pt-BR';
import { format } from 'date-fns';
import { useToast } from 'vue-toastification';
import Cookies from 'vue-cookies';

export default {
  name: 'CalendarView',
  components: {
    Schema,
    SmallLoader,
  },
  setup() {
    const toast = useToast();
    return { toast };
  },
  data() {
    return {
      calendarData: [],
      date: '',
      unitId: '',
      description: '',
      blockedDays: [],
      isLoadingButton: false,
    };
  },
  computed: {
    // Api call units
    unitsData() {
      return this.$store.state.Units.unitsdata;
    },

    // Api call calendar
    calendardata() {
      return this.$store.state.Calendar.calendardata;
    },

    // Api call loading
    isLoading() {
      return this.$store.state.Calendar.isLoading;
    },

    profile() {
      return this.$store.state.Default.profile;
    },
  },
  mounted() {
    // Api call units
    this.$store.dispatch('Units/getUnitsByData', '/units');

    // Api call calendar
    this.$store.dispatch('Calendar/getCalendarByData', '/blocked');
    this.getBlockedDays();
  },
  methods: {
    // Function create new calendar
    handleCreateNewCalendar() {
      const currentDate = new Date(this.date);
      var dateBase = currentDate.toISOString().replace(/\.\d{3}Z$/, '');

      const data = {
        createdBy: 1,
        updatedBy: 1,
        deleted: false,
        unitId: Number(this.unitId),
        date: dateBase.toString(),
        description: this.description,
        blocked: true,
      };

      const token = this.$cookies.get('token');
      this.isLoadingButton = true;
      api
        .post('/blocked', data, {
          headers: {
            token: token,
          },
        })
        .then(() => {
          // Reset form
          this.unitId = '';
          this.description = '';
          this.data = '';

          // Api call calendar
          this.$store.dispatch('Calendar/getCalendarByData', '/blocked');

          // Call function blocked days
          this.getBlockedDays();
          this.isLoadingButton = false;

          this.toast.success('Cadastro de calendário realizado com sucesso');
        })
        .catch((err) => {
          console.log(err);
          this.isLoadingButton = false;
        });
    },

    // Function get blocked days
    getBlockedDays() {
      const token = Cookies.get('token');

      api
        .get('/blocked', {
          headers: {
            token: token,
          },
        })
        .then((res) => {
          const data = res.data;

          const newFormatted = data.map((item) => {
            const dateFormatted = format(
              new Date(item.date),
              "dd 'de' LLLL Y",
              {
                locale: ptBR,
              }
            );

            const result = {
              id: item.id,
              customData: {
                description: item.description,
                date: dateFormatted,
                unitId: item.getUnitId.name,
              },
            };
            return result;
          });

          this.blockedDays = newFormatted;
        });
    },

    // Function to create new delete blocked days
    handleDeleteBlockedDay(id) {
      const token = Cookies.get('token');

      const data = {
        deleted: true,
      };

      api
        .patch(`/blocked/${id}`, data, {
          headers: {
            token: token,
          },
        })
        .then(() => {
          this.toast.success('Bloqueio de dia excluído com sucesso');
          this.getBlockedDays();
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style scoped lang="scss">
.buttonsAdd {
  display: flex;
  flex-direction: column;
  align-items: center;

  gap: 10px;

  .add,
  .subtract {
    width: 25px;
    height: 25px;

    border-radius: 50%;
    color: var(--white);
  }

  .add {
    background-color: var(--primary-color);
  }

  .subtract {
    background-color: var(--subtract-color);
  }
}

.holidayCardContainer {
  margin-top: 3rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.5rem;
  height: 300px;
  overflow-y: auto;

  .holidayCard {
    background: var(--white);
    padding: 1rem;
    border-radius: 8px;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;

    border: 1px solid var(--gray-200);
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);

    h3 {
      color: var(--primary-color);
      font-size: 0.9rem;
      margin-right: 2rem;
    }

    p {
      margin-top: 0.5rem;
      font-size: 0.9rem;
      opacity: 0.8;
    }

    span {
      color: var(--primary-color);
      font-size: 0.9rem;
    }

    .holidayCardButtons {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      margin-top: 10px;
      gap: 10px;

      img {
        width: 20px;
      }
    }
  }
}

.smallCalendar {
  padding: 1rem 0;
}

.text-area-selected {
  height: 100px;
  resize: vertical;
  width: 100%;
  max-height: 300px;
  border-radius: 8px;
  border: 1px solid var(--primary-color);
  padding: 1rem;
  resize: none;
}
</style>
