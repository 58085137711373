import api from '../../../lib/axios';

export class AdminFilterService {
  constructor(token) {
    this.token = token;
  }

  async getAllUnits() {
    try {
      const response = await api.get('/units', {
        headers: {
          token: this.token,
        },
      });
      return response.data;
    } catch (error) {
      console.error('Error fetching units:', error);
    }
  }

  async getLocalsByUnitId(unitId) {
    try {
      const response = await api.get(`/units/${unitId}`, {
        headers: {
          token: this.token,
        },
      });
      return response.data.Locals;
    } catch (error) {
      console.error('Error fetching locals:', error);
    }
  }

  async getRoomsByLocalId(localId) {
    try {
      const response = await api.get(`/locals/${localId}`, {
        headers: {
          token: this.token,
        },
      });
      return response.data.Rooms;
    } catch (error) {
      console.error('Error fetching rooms:', error);
    }
  }

  async filter(params) {
    try {
      const response = await api.get('/administration/appointments', {
        headers: {
          token: this.token,
        },
        params,
      });
      return response.data;
    } catch (error) {
      console.error('Error filtering:', error);
    }
  }
}
