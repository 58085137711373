<template>
  <div
    class="w-full h-screen absolute py-12 left-0 top-0 bg-modal-600 z-50 flex items-center justify-center backdrop-blur-sm"
  >
    <div
      class="bg-white w-[600px] h-auto max-h-full overflow-y-auto p-6 rounded-xl shadow-lg relative"
    >
      <button
        type="button"
        @click="onCloseModal"
        class="absolute right-2 top-2 text-white bg-blue-500 w-8 h-8 rounded-full flex items-center justify-center"
      >
        <i class="ph ph-x"></i>
      </button>

      <div class="text-2xl pb-5 text-blue-500 border-b border-gray-300">
        Selecionar layouts de sala
      </div>

      <div
        class="h-[400px] overflow-y-auto flex flex-col gap-4 mt-10"
        v-if="isActiveCapacity === false"
      >
        <div
          class="flex items-center justify-between border-2 border-gray-200 rounded-lg p-2"
          v-for="item in data"
          :key="item.id"
        >
          <div class="flex items-center">
            <figure>
              <img class="w-16" :src="item.imageUrl" alt="item.name" />
            </figure>
            <div class="ml-3 leading-5">
              <h3 class="text-blue-500">{{ item.name }}</h3>
              <span class="text-sm text-gray-500">{{
                item.description === '' ? 'Sem descrição' : item.description
              }}</span>
            </div>
          </div>
          <button
            type="button"
            @click="catchInfoLayout(item.id, item.name, item.imageUrl)"
            class="bg-green-500 w-10 h-10 rounded-lg text-white flex items-center justify-center hover:brightness-90 transition-all"
          >
            <i class="ph ph-plus"></i>
          </button>
        </div>
      </div>

      <div class="w-full text-center mt-10" v-else>
        <h3 class="text-xl text-gray-500">Qual a capacidade?</h3>
        <input
          type="number"
          v-model="capacity"
          class="border-2 border-gray-300 h-12 rounded-lg w-full text-md mt-4 pl-4"
        />
        <button
          type="button"
          v-show="profile"
          :disabled="capacity === 0"
          @click="handleCatchCapacityRoom"
          class="bg-blue-500 w-full text-white mt-2 h-10 rounded-lg mt-4"
        >
          Confirmar
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import api from '../../lib/axios';
import Cookies from 'vue-cookies';

export default {
  name: 'ModalSelectLayoutItem',
  props: {
    onCloseModal: Function,
    data: Array,
  },
  data() {
    return {
      capacity: 0,
      isActiveCapacity: false,
      name: '',
      imageUrl: '',
      id: '',

      capacityId: 0,
    };
  },

  computed: {
    profile() {
      return this.$store.state.Default.profile;
    },
  },
  methods: {
    catchInfoLayout(id, name, imageUrl) {
      this.isActiveCapacity = true;
      this.name = name;
      this.imageUrl = imageUrl;
      this.id = id;
    },
    handleCatchCapacityRoom() {
      const token = this.$cookies.get('token');
      api
        .post(
          '/capacity',
          {
            deleted: false,
            active: false,
            layoutId: Number(this.id),
            capacity: this.capacity,
          },
          {
            headers: {
              token: token,
            },
          }
        )
        .then((res) => {
          this.capacityId = res.data.id;

          const data = {
            name: this.name,
            imageUrl: this.imageUrl,
            capacity: this.capacity,
            id: Number(this.id),
            capacityId: this.capacityId,
          };

          this.$emit('catchInfoLayout', data);
          this.name = '';
          this.imageUrl = '';
          this.capacity = 0;
          this.capacityId = 0;
          this.isActiveCapacity = false;
          this.onCloseModal();
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style scoped lang="scss">
.cardContainer {
  height: 300px;
  overflow-y: auto;
  padding: 0 1rem;

  display: flex;
  flex-direction: column;
  gap: 1rem;

  .cards {
    display: flex;
    align-items: center;
    justify-content: space-between;

    border: 2px dashed var(--gray-200);
    padding: 0.8rem 1rem;
    border-radius: 8px;

    img {
      width: 80px;
      height: 80px;
      object-fit: contain;
      margin-right: 0.5rem;
    }

    button {
      padding: 0 1rem;

      &:disabled {
        opacity: 0.8;
        cursor: not-allowed;

        &:hover {
          filter: brightness(1);
        }
      }
    }

    h3 {
      color: var(--blue-500);
      font-weight: 500;
    }

    p {
      color: var(--blue-500);
    }

    .cardInfo {
      display: flex;
      align-items: center;
    }

    input {
      height: auto;
      width: auto;
      margin-top: 1rem;
    }
  }
}

.capacityContainer {
  text-align: center;
  h3 {
    font-size: 1rem;
    color: var(--blue-500);
  }

  input {
    margin-top: 1rem;
  }

  button {
    margin-top: 1rem;
    width: 100%;
  }
}
</style>
