class HelperService {
  checkAvailableRooms(payload, appointments) {
    const foundAppointmentsFiltered = appointments.filter(
      (app) =>
        app.date === payload.date &&
        app.roomId === payload.roomId &&
        app.status === 'Aprovada' &&
        app.deleted === false
    );

    if (foundAppointmentsFiltered.length) {
      const isAlreadyBooked = foundAppointmentsFiltered.some((appointment) => {
        const appointmentStart = new Date(
          `${appointment.date}T${appointment.startTime}:00`
        );

        const appointmentEnd = new Date(
          `${appointment.date}T${appointment.finalTime}:00`
        );

        const payloadStart = new Date(
          `${payload.date}T${payload.startTime}:00`
        );

        const payloadEnd = new Date(`${payload.date}T${payload.endTime}:00`);

        return (
          (payloadStart >= appointmentStart && payloadStart < appointmentEnd) ||
          (payloadEnd > appointmentStart && payloadEnd <= appointmentEnd) ||
          (payloadStart <= appointmentStart && payloadEnd >= appointmentEnd)
        );
      });

      return isAlreadyBooked;
    }

    return false;
  }
}

export const useHelperService = () => new HelperService();
