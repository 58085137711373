<template>
  <tr>
    <td class="py-2 pr-5">
      <div
        class="w-full h-10 border-2 border-gray-500 text-gray-500 text-sm pl-4 rounded-md flex items-center"
      >
        {{
          work.dayOfWeek === ''
            ? 'Você precisa adicionar esse dia'
            : work.dayOfWeek
        }}
      </div>
    </td>
    <td class="pr-5">
      <div class="flex items-center justify-between">
        <input
          class="border-2 rounded-md px-3 text-sm py-2 flex-1"
          type="time"
          v-model="this.$props.work.scheduleStart"
        />
        <span class="text-sm px-2">às</span>
        <input
          class="border-2 rounded-md px-3 text-sm py-2 flex-1"
          type="time"
          v-model="this.$props.work.scheduleEnd"
        />

        <button
          class="bg-gray-400 ml-4 w-7 h-7 rounded-full hover:bg-red-500 transition-all flex items-center justify-center text-gray-200"
          type="button"
          title="Clique para excluir o intervalo"
          @click="handleRemoveWorkingTime(work)"
        >
          <i class="ph ph-trash"></i>
        </button>
      </div>
    </td>
    <td class="pr-5">
      <div class="flex items-center justify-between">
        <input
          class="border-2 rounded-md px-3 text-sm py-2 flex-1"
          type="time"
          v-model="this.$props.work.intervalStart1"
        />
        <span class="text-sm px-2">às</span>
        <input
          class="border-2 rounded-md px-3 text-sm py-2 flex-1"
          type="time"
          v-model="this.$props.work.intervalEnd1"
        />

        <button
          class="bg-gray-400 ml-4 w-7 h-7 rounded-full hover:bg-red-500 transition-all flex items-center justify-center text-gray-200"
          type="button"
          title="Clique para excluir o intervalo"
          @click="
            onRemoveValuesStartAndEnd(
              $event,
              1,
              this.$props.work,
              this.$props.work.intervalStart1,
              this.$props.work.intervalEnd1
            )
          "
        >
          <i class="ph ph-trash"></i>
        </button>
      </div>
    </td>
    <td class="pr-5">
      <div class="flex items-center justify-between">
        <input
          class="border-2 rounded-md px-3 text-sm py-2 flex-1"
          type="time"
          v-model="this.$props.work.intervalStart2"
        />
        <span class="text-sm px-2">às</span>
        <input
          class="border-2 rounded-md px-3 text-sm py-2 flex-1"
          type="time"
          v-model="this.$props.work.intervalEnd2"
        />

        <button
          class="bg-gray-400 ml-4 w-7 h-7 rounded-full hover:bg-red-500 transition-all flex items-center justify-center text-gray-200"
          type="button"
          title="Clique para excluir o intervalo"
          @click="
            onRemoveValuesStartAndEnd(
              $event,
              2,
              this.$props.work,
              this.$props.work.intervalStart2,
              this.$props.work.intervalEnd2
            )
          "
        >
          <i class="ph ph-trash"></i>
        </button>
      </div>
    </td>
  </tr>
</template>

<script>
export default {
  name: 'IdentifyWeekDayItem',

  props: {
    onChangeValuesStartAndEnd: Function,
    onRemoveValuesStartAndEnd: Function,
    handleRemoveWorkingTime: Function,
    work: Object,
  },
};
</script>
