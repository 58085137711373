import mutations from './mutations';
import actions from './actions';

const SolicitationStore = {
  namespaced: true,
  state: {
    step: 1,
    router: '',
    // step one
    createdBy: '',
    name: '',
    branch: '',
    email: '',
    roomType: '',
    localType: '',

    // step two
    unitId: '',
    localId: '',
    date: '',
    startTime: '',
    finalTime: '',
    recurrent: false,
    costCenter: '',
    manager: '',
    board: '',
    equipments: [],
    recurrentSolicitation: {
      defaultRecurrent: 'Diário',
      toggleMonday: true,
      toggleTuesday: true,
      toggleWednesday: true,
      toggleThursday: true,
      toggleFriday: true,
      toggleSaturday: true,
      toggleSunday: true,
      valueMonday: 'segunda-feira',
      valueTuesday: 'terça-feira',
      valueWednesday: 'quarta-feira',
      valueThursday: 'quinta-feira',
      valueFriday: 'sexta-feira',
      valueSaturday: 'sábado',
      valueSunday: 'domingo',
      activeMonday: false,
      activeTuesday: false,
      activeWednesday: false,
      activeThursday: false,
      activeFriday: false,
      activeSaturday: false,
      activeSunday: false,
      endDate: '',
      repeatWeek: '1',
    },

    // step three
    layoutId: '',
    roomLayout: '',
    roomId: '',
    eventName: '',
    eventDescription: '',
    numberOfParticipants: 0,
    equipmentsDescription: '',
    previousPreparation: false,
    preparationStartDay: '',
    preparationStartInitial: '',
    preparationStartFinal: '',
    preparationEndDay: '',
    preparationEndInitial: '',
    preparationEndFinal: '',
    layoutInfoImage: {
      name: '',
      imageUrl: '',
    },
    mobileEquipments: {
      connect: [],
    },
    typeOfTransmission: '',
    socialNetworkText: '',
    typeOfLink: '',
    zoomResources: '',
    accessesPrevision: 0,
    transmissionAudioFile: '',
    transmissionDescription: '',
    thumbNail: '',
    programmingFile: '',

    locals: [],
    auditories: [],
    isChooseAuditory: false,
    chosenAuditoriums: [],
  },

  mutations,
  actions,
};

export default SolicitationStore;
