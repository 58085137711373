<template>
  <Schema>
    <form
      method="post"
      class="w-full max-w-3xl"
      @submit.prevent="handleCreatedNewLocal"
    >
      <!-- Breadcrumb -->
      <div class="flex w-full mb-4 text-sm">
        <div class="mb-4 text-gray-400">
          <router-link to="/app" class="hover:text-blue-500 transition-colors">
            Home
          </router-link>
          <span> • Cadastrar</span>
          <span class="text-blue-500"> • Locais</span>
        </div>
      </div>

      <div class="flex flex-col">
        <h1 class="text-3xl text-gray-600">Cadastro de locais</h1>
        <p class="text-gray-400 max-w-xs mt-1">
          Cadastre os locais do Albert Einstein
        </p>
      </div>

      <!-- Select unit -->
      <section class="flex mt-14">
        <div class="flex-1">
          <label class="text-sm text-gray-500">
            Unidade

            <select
              v-model="unitId"
              class="border-2 border-gray-300 h-12 rounded-lg w-full text-md mt-2 pl-4"
            >
              <option value="" selected disabled>Selecione uma unidade</option>
              <option v-for="item in unitsdata" :key="item.id" :value="item.id">
                {{ item.name }}
              </option>
            </select>
          </label>
        </div>
      </section>

      <!-- Local name and activex -->
      <section class="flex items-center gap-8 mt-4">
        <div class="flex-1">
          <label class="text-sm text-gray-500">
            Local

            <input
              v-model="name"
              type="text"
              class="border-2 border-gray-300 h-12 rounded-lg w-full text-md mt-2 pl-4"
            />
          </label>
        </div>
      </section>

      <!-- WorkingTime Selected -->
      <section class="mt-6" v-show="workingTime.length !== 0">
        <div class="w-full">
          <div class="bg-gray-100 p-4 rounded-lg flex flex-col gap-4">
            <span
              v-for="item in workingTime"
              :key="item.Day"
              class="bg-blue-500 w-full flex items-center justify-between text-white py-2 px-5 rounded-lg"
            >
              {{ item.Day }} - {{ item.startTime }} : {{ item.endTime }}

              <button
                @click="handleRemoveItem(item.Day)"
                class="bg-red-500 w-6 h-6 flex items-center justify-center rounded-full hover:brightness-90 transition-all"
              >
                <i class="ph ph-trash"></i>
              </button>
            </span>
          </div>
        </div>
      </section>

      <!-- WorkingTime select days -->
      <section class="mt-8">
        <div class="flex flex-col">
          <div class="bg-gray-100 px-4 py-7 rounded-lg">
            <label class="text-sm text-gray-500"
              >Horários de funcionamento</label
            >

            <div class="flex flex-col mt-10 gap-4">
              <!-- Monday -->
              <div class="flex items-center gap-4">
                <span class="text-sm text-gray-500 w-28">Segunda-feira</span>
                <div class="timeInput" v-show="valueActiveMonday === true">
                  <input
                    type="time"
                    v-model="valueStartMonday"
                    class="h-10 px-4 rounded-md border-2 border-gray-200 focus:border-blue-500 transition-colors outline-none"
                  />

                  <span class="text-sm text-gray-500 inline-block mx-4"
                    >Até</span
                  >

                  <input
                    type="time"
                    v-model="valueEndMonday"
                    class="h-10 px-4 rounded-md border-2 border-gray-200 focus:border-blue-500 transition-colors outline-none"
                  />
                </div>
                <div class="switch">
                  <label>
                    <input
                      v-model="valueActiveMonday"
                      @change="test('Segunda-feira', valueActiveMonday)"
                      type="checkbox"
                      class="toggle-input"
                    />
                    <div class="slider">
                      <button class="button"></button>
                    </div>
                  </label>
                </div>

                <button
                  :disabled="valueStartMonday === '' || valueEndMonday === ''"
                  type="button"
                  v-on:click="
                    handleAddItem(
                      'Segunda-feira',
                      this.valueStartMonday,
                      this.valueEndMonday,
                      this.valueActiveMonday,
                      this.activeButtonSunday
                    )
                  "
                  class="flex items-center justify-center bg-blue-500 text-white w-6 h-6 rounded-full hover:brightness-90 transition-all disabled:opacity-75 disabled:cursor-not-allowed"
                  v-show="valueActiveMonday === true"
                >
                  <i class="ph ph-plus"></i>
                </button>
              </div>

              <!-- Tuesday -->
              <div class="flex items-center gap-4">
                <span class="text-sm text-gray-500 w-28">Terça-feira</span>
                <div class="timeInput" v-show="valueActiveTuesday === true">
                  <input
                    type="time"
                    v-model="valueStartTuesday"
                    class="h-10 px-4 rounded-md border-2 border-gray-200 focus:border-blue-500 transition-colors outline-none"
                  />

                  <span class="text-sm text-gray-500 inline-block mx-4"
                    >Até</span
                  >

                  <input
                    type="time"
                    v-model="valueEndTuesday"
                    class="h-10 px-4 rounded-md border-2 border-gray-200 focus:border-blue-500 transition-colors outline-none"
                  />
                </div>
                <div class="switch">
                  <label>
                    <input
                      v-model="valueActiveTuesday"
                      @change="test('Terça-feira', valueActiveTuesday)"
                      type="checkbox"
                      class="toggle-input"
                    />
                    <div class="slider">
                      <button class="button"></button>
                    </div>
                  </label>
                </div>

                <button
                  :disabled="valueStartTuesday === '' || valueEndTuesday === ''"
                  type="button"
                  v-on:click="
                    handleAddItem(
                      'Terça-feira',
                      this.valueStartTuesday,
                      this.valueEndTuesday,
                      this.valueActiveTuesday,
                      this.activeButtonSunday
                    )
                  "
                  class="flex items-center justify-center bg-blue-500 text-white w-6 h-6 rounded-full hover:brightness-90 transition-all disabled:opacity-75 disabled:cursor-not-allowed"
                  v-show="valueActiveTuesday === true"
                >
                  <i class="ph ph-plus"></i>
                </button>
              </div>

              <!-- Wednesday -->
              <div class="flex items-center gap-4">
                <span class="text-sm text-gray-500 w-28">Quarta-feira</span>
                <div class="timeInput" v-show="valueActiveWednesday === true">
                  <input
                    type="time"
                    v-model="valueStartWednesday"
                    class="h-10 px-4 rounded-md border-2 border-gray-200 focus:border-blue-500 transition-colors outline-none"
                  />

                  <span class="text-sm text-gray-500 inline-block mx-4"
                    >Até</span
                  >

                  <input
                    type="time"
                    v-model="valueEndWednesday"
                    class="h-10 px-4 rounded-md border-2 border-gray-200 focus:border-blue-500 transition-colors outline-none"
                  />
                </div>
                <div class="switch">
                  <label>
                    <input
                      v-model="valueActiveWednesday"
                      @change="test('Quarta-feira', valueActiveWednesday)"
                      type="checkbox"
                      class="toggle-input"
                    />
                    <div class="slider">
                      <button class="button"></button>
                    </div>
                  </label>
                </div>

                <button
                  :disabled="
                    valueStartWednesday === '' || valueEndWednesday === ''
                  "
                  type="button"
                  v-on:click="
                    handleAddItem(
                      'Quarta-feira',
                      this.valueStartWednesday,
                      this.valueEndWednesday,
                      this.valueActiveWednesday,
                      this.activeButtonWednesday
                    )
                  "
                  class="flex items-center justify-center bg-blue-500 text-white w-6 h-6 rounded-full hover:brightness-90 transition-all disabled:opacity-75 disabled:cursor-not-allowed"
                  v-show="valueActiveWednesday === true"
                >
                  <i class="ph ph-plus"></i>
                </button>
              </div>

              <!-- Thursday -->
              <div class="flex items-center gap-4">
                <span class="text-sm text-gray-500 w-28">Quinta-feira</span>
                <div class="timeInput" v-show="valueActiveThursday === true">
                  <input
                    type="time"
                    v-model="valueStartThursday"
                    class="h-10 px-4 rounded-md border-2 border-gray-200 focus:border-blue-500 transition-colors outline-none"
                  />

                  <span class="text-sm text-gray-500 inline-block mx-4"
                    >Até</span
                  >

                  <input
                    type="time"
                    v-model="valueEndThursday"
                    class="h-10 px-4 rounded-md border-2 border-gray-200 focus:border-blue-500 transition-colors outline-none"
                  />
                </div>
                <div class="switch">
                  <label>
                    <input
                      v-model="valueActiveThursday"
                      @change="test('Quinta-feira', valueActiveThursday)"
                      type="checkbox"
                      class="toggle-input"
                    />
                    <div class="slider">
                      <button class="button"></button>
                    </div>
                  </label>
                </div>

                <button
                  :disabled="
                    valueStartThursday === '' || valueEndThursday === ''
                  "
                  type="button"
                  v-on:click="
                    handleAddItem(
                      'Quinta-feira',
                      this.valueStartThursday,
                      this.valueEndThursday,
                      this.valueActiveThursday,
                      this.activeButtonThurday
                    )
                  "
                  class="flex items-center justify-center bg-blue-500 text-white w-6 h-6 rounded-full hover:brightness-90 transition-all disabled:opacity-75 disabled:cursor-not-allowed"
                  v-show="valueActiveThursday === true"
                >
                  <i class="ph ph-plus"></i>
                </button>
              </div>

              <!-- Friday -->
              <div class="flex items-center gap-4">
                <span class="text-sm text-gray-500 w-28">Sexta-feira</span>
                <div class="timeInput" v-show="valueActiveFriday === true">
                  <input
                    type="time"
                    v-model="valueStartFriday"
                    class="h-10 px-4 rounded-md border-2 border-gray-200 focus:border-blue-500 transition-colors outline-none"
                  />

                  <span class="text-sm text-gray-500 inline-block mx-4"
                    >Até</span
                  >

                  <input
                    type="time"
                    v-model="valueEndFriday"
                    class="h-10 px-4 rounded-md border-2 border-gray-200 focus:border-blue-500 transition-colors outline-none"
                  />
                </div>
                <div class="switch">
                  <label>
                    <input
                      v-model="valueActiveFriday"
                      @change="test('Sexta-feira', valueActiveFriday)"
                      type="checkbox"
                      class="toggle-input"
                    />
                    <div class="slider">
                      <button class="button"></button>
                    </div>
                  </label>
                </div>

                <button
                  :disabled="valueStartFriday === '' || valueEndFriday === ''"
                  type="button"
                  v-on:click="
                    handleAddItem(
                      'Sexta-feira',
                      this.valueStartFriday,
                      this.valueEndFriday,
                      this.valueActiveFriday,
                      this.activeButtonFriday
                    )
                  "
                  class="flex items-center justify-center bg-blue-500 text-white w-6 h-6 rounded-full hover:brightness-90 transition-all disabled:opacity-75 disabled:cursor-not-allowed"
                  v-show="valueActiveFriday === true"
                >
                  <i class="ph ph-plus"></i>
                </button>
              </div>

              <!-- Saturday -->
              <div class="flex items-center gap-4">
                <span class="text-sm text-gray-500 w-28">Sábado</span>
                <div class="timeInput" v-show="valueActiveSaturday === true">
                  <input
                    type="time"
                    v-model="valueStartSaturday"
                    class="h-10 px-4 rounded-md border-2 border-gray-200 focus:border-blue-500 transition-colors outline-none"
                  />

                  <span class="text-sm text-gray-500 inline-block mx-4"
                    >Até</span
                  >

                  <input
                    type="time"
                    v-model="valueEndSaturday"
                    class="h-10 px-4 rounded-md border-2 border-gray-200 focus:border-blue-500 transition-colors outline-none"
                  />
                </div>
                <div class="switch">
                  <label>
                    <input
                      v-model="valueActiveSaturday"
                      @change="test('Sábado', valueActiveSaturday)"
                      type="checkbox"
                      class="toggle-input"
                    />
                    <div class="slider">
                      <button class="button"></button>
                    </div>
                  </label>
                </div>

                <button
                  :disabled="
                    valueStartSaturday === '' || valueEndSaturday === ''
                  "
                  type="button"
                  v-on:click="
                    handleAddItem(
                      'Sábado',
                      this.valueStartSaturday,
                      this.valueEndSaturday,
                      this.valueActiveSaturday,
                      this.activeButtonSaturday
                    )
                  "
                  class="flex items-center justify-center bg-blue-500 text-white w-6 h-6 rounded-full hover:brightness-90 transition-all disabled:opacity-75 disabled:cursor-not-allowed"
                  v-show="valueActiveSaturday === true"
                >
                  <i class="ph ph-plus"></i>
                </button>
              </div>

              <!-- Sunday -->
              <div class="flex items-center gap-4">
                <span class="text-sm text-gray-500 w-28">Domingo</span>
                <div class="timeInput" v-show="valueActiveSunday === true">
                  <input
                    type="time"
                    v-model="valueStartSunday"
                    class="h-10 px-4 rounded-md border-2 border-gray-200 focus:border-blue-500 transition-colors outline-none"
                  />

                  <span class="text-sm text-gray-500 inline-block mx-4"
                    >Até</span
                  >

                  <input
                    type="time"
                    v-model="valueEndSunday"
                    class="h-10 px-4 rounded-md border-2 border-gray-200 focus:border-blue-500 transition-colors outline-none"
                  />
                </div>
                <div class="switch">
                  <label>
                    <input
                      v-model="valueActiveSunday"
                      @change="test('Domingo', valueActiveSunday)"
                      type="checkbox"
                      class="toggle-input"
                    />
                    <div class="slider">
                      <button class="button"></button>
                    </div>
                  </label>
                </div>

                <button
                  :disabled="valueStartSunday === '' || valueEndSunday === ''"
                  type="button"
                  v-on:click="
                    handleAddItem(
                      'Domingo',
                      this.valueStartSunday,
                      this.valueEndSunday,
                      this.valueActiveSunday,
                      this.activeButtonSunday
                    )
                  "
                  class="flex items-center justify-center bg-blue-500 text-white w-6 h-6 rounded-full hover:brightness-90 transition-all disabled:opacity-75 disabled:cursor-not-allowed"
                  v-show="valueActiveSunday === true"
                >
                  <i class="ph ph-plus"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="inputRight"></div>
      </section>

      <!-- Button save -->
      <section class="mt-6" v-show="profile">
        <button
          type="submit"
          :disabled="name === '' || isLoadingButton === true"
          class="bg-blue-500 py-4 text-white px-10 rounded-full flex items-center justify-center disabled:opacity-70 disabled:brightness-100 disabled:cursor-not-allowed disabled"
        >
          <SmallLoader v-if="isLoadingButton === true" />

          <span v-else>Salvar local</span>
        </button>
      </section>
    </form>

    <div
      v-if="isLoading === true"
      class="w-[50px] h-[50px] border-[6px] border-gray-200 border-t-blue-500 animate-spin rounded-full"
    ></div>

    <!-- Table -->
    <div v-else class="w-full mt-20" v-show="locationsdata.length > 0">
      <DataTable
        :value="locationsdata"
        :rowClass="rowClass"
        paginatorTemplate="FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink RowsPerPageDropdown"
        paginator
        :rows="15"
        :rowsPerPageOptions="[15, 20, 30, 50]"
        currentPageReportTemplate="{first} de {last} de {totalRecords}"
        :globalFilterFields="['name', 'getUnitId.name']"
        v-model:filters="filters"
      >
        <template #header>
          <span class="p-input-icon-left">
            <i class="pi pi-search pt-1" />
            <input
              v-model="filters['global'].value"
              placeholder="Pesquisa global"
              class="border-2 border-gray-300 p-3 pl-10 rounded-lg w-full text-sm mt-2"
            />
          </span>
        </template>
        <Column field="getUnitId.name" header="Unidade"></Column>
        <Column field="name" header="Local"></Column>
        <Column field="Working_Time" header="Funcionamento">
          <template #body="{ data }">
            <div class="grid grid-cols-2">
              <div v-for="item in data.Working_Time" :key="item">
                <span :class="!item.active ? 'text-red-500' : 'text-gray-700'"
                  >{{ item.workingTimeDay }} - {{ item.initialTime }} :
                  {{ item.endTime }}</span
                >
              </div>
            </div>
          </template>
        </Column>
        <Column field="id" header="Editar" class="w-20">
          <template #body="{ data }">
            <button
              type="button"
              class="bg-blue-500 text-white px-4 py-1 rounded-full hover:brightness-90 transition-all"
              @click="handleOpenModal(data.id)"
            >
              Editar
            </button>
          </template>
        </Column>
      </DataTable>

      <ModalEditLocals
        :onCloseModal="handleCloseModal"
        :id="currentIdModal"
        :data="localsDataByID"
        v-show="isOpenModal"
        @getLocalsById="getLocalsById"
        :hours="hours"
      />
    </div>
  </Schema>
</template>

<script>
import Schema from '../../components/Schema';
import SmallLoader from '../../components/Loaders/SmallLoader.vue';

import api from '../../lib/axios';
import { useToast } from 'vue-toastification';

import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import { FilterMatchMode } from 'primevue/api';

import ModalEditLocals from '../../components/Modal/ModalEditLocals.vue';

export default {
  name: 'LocalsView',
  components: {
    Schema,
    SmallLoader,
    DataTable,
    Column,
    ModalEditLocals,
  },

  setup() {
    const toast = useToast();
    return { toast };
  },

  data() {
    return {
      active: true,
      name: '',
      startTime: '',
      finalTime: '',
      unitId: '',
      description: '',
      isLoadingButton: false,
      unitName: '',
      workingTime: [],

      valueStartSunday: '',
      valueEndSunday: '',
      valueActiveSunday: true,

      valueStartSaturday: '',
      valueEndSaturday: '',
      valueActiveSaturday: true,

      valueStartFriday: '',
      valueEndFriday: '',
      valueActiveFriday: true,

      valueStartThursday: '',
      valueEndThursday: '',
      valueActiveThursday: true,

      valueStartWednesday: '',
      valueEndWednesday: '',
      valueActiveWednesday: true,

      valueStartTuesday: '',
      valueEndTuesday: '',
      valueActiveTuesday: true,

      valueStartMonday: '',
      valueEndMonday: '',
      valueActiveMonday: true,

      isOpenModal: false,
      currentIdModal: 0,
      localsDataByID: {},
      hours: [],

      filters: null,
    };
  },

  computed: {
    // Api call units
    unitsdata() {
      return this.$store.state.Units.unitsdata;
    },

    // Api call locals
    locationsdata() {
      return this.$store.state.Default.datastore;
    },

    // Api call loading
    isLoading() {
      return this.$store.state.Units.isLoading;
    },

    profile() {
      return this.$store.state.Default.profile;
    },
  },

  mounted() {
    // Api call units
    this.$store.dispatch('Units/getUnitsByData', '/units');

    // Api call locals
    this.$store.dispatch('Default/getDataToPages', '/locals');
  },

  created() {
    this.initFilters();
  },

  methods: {
    initFilters() {
      this.filters = {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      };
    },

    rowClass(data) {
      return [{ 'disabled-row': data.active === false }];
    },

    handleOpenModal(id) {
      this.isOpenModal = true;
      this.currentIdModal = id;
      this.getLocalsById(id);
    },

    handleCloseModal() {
      this.isOpenModal = false;
    },

    getLocalsById(id) {
      const token = this.$cookies.get('token');

      api
        .get(`/locals/${id}`, {
          headers: {
            token: token,
          },
        })
        .then((res) => {
          this.localsDataByID = res.data;
          this.hours = this.localsDataByID.Working_Time;
        });
    },

    handleCreatedNewLocal() {
      const data = {
        local: {
          active: this.active,
          createdBy: 1,
          updatedBy: 1,
          deleted: false,
          name: this.name,
          description: this.description,
          getUnitId: {
            connect: {
              id: Number(this.unitId),
            },
          },
        },
        GetWorkingTime: {
          workingTime: this.workingTime,
        },
      };

      if (this.workingTime.length < 7) {
        this.toast.error('Adicione ou desative os dias');
      } else {
        this.isLoadingButton = true;
        const token = this.$cookies.get('token');
        api
          .post('/locals', data, {
            headers: {
              token: token,
            },
          })
          .then(() => {
            // Reset form
            this.active = true;
            this.name = '';
            this.description = '';
            this.startTime = '';
            this.finalTime = '';
            this.unitId = '';

            this.workingTime = [];
            this.valueStartSunday = '';
            this.valueEndSunday = '';
            this.valueActiveSunday = true;

            this.valueStartSaturday = '';
            this.valueEndSaturday = '';
            this.valueActiveSaturday = true;

            this.valueStartFriday = '';
            this.valueEndFriday = '';
            this.valueActiveFriday = true;

            this.valueStartThursday = '';
            this.valueEndThursday = '';
            this.valueActiveThursday = true;

            this.valueStartWednesday = '';
            this.valueEndWednesday = '';
            this.valueActiveWednesday = true;

            this.valueStartTuesday = '';
            this.valueEndTuesday = '';
            this.valueActiveTuesday = true;

            this.valueStartMonday = '';
            this.valueEndMonday = '';
            this.valueActiveMonday = true;

            // Api call locals
            this.$store.dispatch('Default/getDataToPages', '/locals');
            this.isLoadingButton = false;

            // Notification success
            this.toast.success('Cadastro de local realizado com sucesso');
          })
          .catch((err) => {
            // Notification error
            if (err.response.status === 500) {
              this.toast.error(
                'Não é permitido criar dois locais com o mesmo nome'
              );
            }
            this.isLoadingButton = false;
          });
      }
    },

    handleAddItem(day, valueOne, valueTwo, valueThree) {
      const filterDay = this.workingTime.filter((work) => work.Day === day);

      if (filterDay.length <= 0) {
        this.workingTime = [
          ...this.workingTime,
          {
            Day: day,
            startTime: valueOne,
            endTime: valueTwo,
            active: valueThree,
          },
        ];
        valueOne = '';
        valueTwo = '';
      }
    },

    handleRemoveItem(day) {
      const updatedWorkingTime = this.workingTime.filter(
        (item) => item.Day !== day
      );

      this.workingTime = [...updatedWorkingTime];
    },

    test(day, active) {
      if (active === false) {
        this.workingTime = [
          ...this.workingTime,
          {
            Day: day,
            startTime: '00:00',
            endTime: '00:00',
            active: false,
          },
        ];
      } else {
        const updatedWorkingTime = this.workingTime.filter(
          (item) => item.Day !== day
        );
        this.workingTime = [...updatedWorkingTime];
      }
    },
  },
};
</script>
