<template>
  <button @click="onPageChange(number)" type="button">
    <slot />
  </button>
</template>

<script>
export default {
  name: 'PaginationItem',
  props: {
    isCurrent: Boolean,
    onPageChange: Function,
    number: Number,
  },
};
</script>

<style></style>
