<template>
  <Schema>
    <!-- Breadcrumb -->

    <div class="w-full">
      <div class="flex w-full mb-4 text-sm">
        <div class="mb-4 text-gray-400">
          <router-link to="/app" class="hover:text-blue-500 transition-colors">
            Home
          </router-link>
          <span class="text-blue-500"> • Gerenciamento</span>
          <span class="text-blue-500"> • Gerenciamento de ocorrências</span>
        </div>
      </div>

      <div class="flex items-center justify-between">
        <div class="flex flex-col">
          <h1 class="text-3xl text-gray-600">Gerenciamento de ocorrências</h1>
          <p class="text-gray-400 max-w-xs mt-1">
            Gerencie os chamados de ocorrências
          </p>
        </div>
      </div>
    </div>

    <div class="w-full">
      <DataTable
        :value="ocurrence"
        :rowClass="rowClass"
        paginatorTemplate="FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink RowsPerPageDropdown"
        paginator
        :rows="15"
        :rowsPerPageOptions="[15, 20, 30, 50]"
        currentPageReportTemplate="{first} de {last} de {totalRecords}"
        :globalFilterFields="['id', 'event', 'roomName', 'status']"
        v-model:filters="filters"
      >
        <template #header>
          <span class="p-input-icon-left">
            <i class="pi pi-search pt-1" />
            <input
              v-model="filters['global'].value"
              placeholder="Pesquisa global"
              class="border-2 border-gray-300 p-3 pl-10 rounded-lg w-full text-sm mt-2"
            />
          </span>
        </template>
        <Column field="id" header="N da ocorrência">
          <template #body="{ data }">
            <button
              @click="handleGetOccurenceById(data.id)"
              class="text-blue-500 flex items-center gap-2"
            >
              <i class="ph ph-arrow-up-right"></i
              ><span class="underline underline-offset-2">#{{ data.id }}</span>
            </button>
          </template>
        </Column>
        <Column field="event" header="Evento"></Column>
        <Column field="roomName" header="Sala"></Column>
        <Column field="roomName" header="Sala">
          <template #body="{ data }">
            <span
              :class="
                data.status === 'Finalizada'
                  ? 'text-green-500'
                  : 'text-yellow-500'
              "
            >
              {{ data.status }}
            </span>
          </template>
        </Column>
      </DataTable>
    </div>

    <!-- Modal -->
    <div
      v-show="isActiveModal"
      class="w-full h-screen absolute py-12 left-0 top-0 bg-modal-600 z-50 flex items-center justify-center backdrop-blur-sm"
    >
      <div
        class="bg-white w-[600px] h-auto max-h-full overflow-y-auto p-6 rounded-xl shadow-lg relative"
      >
        <button
          type="button"
          @click="isActiveModal = !isActiveModal"
          class="absolute right-2 top-2 text-white bg-blue-500 w-8 h-8 rounded-full flex items-center justify-center"
        >
          <i class="ph ph-x"></i>
        </button>

        <div class="text-2xl pb-5 text-blue-500 border-b border-gray-300">
          Editar registro de ocorrência
        </div>

        <form
          method="post"
          @submit.prevent="handleUpdateOcurrence"
          class="w-full mt-10"
        >
          <label class="text-sm text-gray-500">
            Evento

            <input
              v-model="occurrenceIdData.event"
              type="text"
              class="border-2 border-gray-300 h-12 rounded-lg w-full text-md mt-2 pl-4"
            />
          </label>

          <label class="flex flex-col text-sm text-gray-500 mt-4">
            Sala

            <select
              v-model="occurrenceIdData.roomId"
              class="border-2 border-gray-300 h-12 rounded-lg w-full text-md mt-2 pl-4"
            >
              <option v-for="room in roomsdata" :key="room.id" :value="room.id">
                {{ room.name }}
              </option>
            </select>
          </label>

          <label class="flex flex-col text-sm text-gray-500 mt-4">
            Status

            <select
              v-model="occurrenceIdData.status"
              class="border-2 border-gray-300 h-12 rounded-lg w-full text-md mt-2 pl-4"
            >
              <option value="Pendente">Pendente</option>
              <option value="Finalizada">Finalizada</option>
            </select>
          </label>

          <div
            class="flex justify-center mt-10 border border-dashed rounded-lg py-5"
          >
            <img
              v-show="
                occurrenceIdData.evidence !==
                'https://ens-files-application.ensinoeinstein.com/'
              "
              class="w-44"
              :src="occurrenceIdData.evidence"
              :alt="occurrenceIdData.event"
            />
          </div>

          <button
            type="submit"
            v-show="profile"
            class="bg-blue-500 w-full text-white py-3 rounded-lg mt-10 hover:brightness-90 transition-all"
          >
            Salvar
          </button>
        </form>
      </div>
    </div>
  </Schema>
</template>

<script>
import { useToast } from 'vue-toastification';
import Schema from '../../components/Schema/index.vue';
import api from '../../lib/axios';
import Cookies from 'vue-cookies';

import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import { FilterMatchMode } from 'primevue/api';

export default {
  name: 'ManagementOccurrenceView',
  components: {
    Schema,
    DataTable,
    Column,
  },

  setup() {
    const toast = useToast();
    return { toast };
  },

  data() {
    return {
      status: 'warn',
      isActiveModal: false,

      ocurrence: [],
      occurrenceIdData: {},

      filters: null,
    };
  },

  computed: {
    profile() {
      return this.$store.state.Default.profile;
    },

    roomsdata() {
      return this.$store.state.Rooms.roomsdata;
    },
  },

  mounted() {
    this.handleGetAllOccurrences();

    this.$store.dispatch('Rooms/getRoomsByData');
  },

  created() {
    this.initFilters();
  },

  methods: {
    initFilters() {
      this.filters = {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      };
    },

    rowClass(data) {
      return [{ 'recurrent-row': data.recurrent === true }];
    },
    handleGetAllOccurrences() {
      const token = Cookies.get('token');

      api
        .get('/ocurrence', {
          headers: {
            token: token,
          },
        })
        .then((res) => {
          this.ocurrence = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    handleGetOccurenceById(occurrenceId) {
      this.isActiveModal = !this.isActiveModal;
      const token = Cookies.get('token');

      api
        .get(`/ocurrence/${occurrenceId}`, {
          headers: {
            token: token,
          },
        })
        .then((res) => {
          this.occurrenceIdData = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    handleUpdateOcurrence() {
      const token = this.$cookies.get('token');
      api
        .patch(
          `/ocurrence/${this.occurrenceIdData.id}`,
          {
            event: this.occurrenceIdData.event,
            status: this.occurrenceIdData.status,
          },
          {
            headers: {
              token: token,
            },
          }
        )
        .then((res) => {
          this.toast.success('Ocorrência atualizada com sucesso!');
          this.handleGetAllOccurrences();
          this.isActiveModal = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>
