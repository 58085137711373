import api from '../lib/axios';

export class UserService {
  constructor(token) {
    this.token = token;
  }

  async getUsers() {
    try {
      const response = await api.get('/users', {
        headers: {
          token: this.token,
        },
      });
      return response.data;
    } catch (error) {
      console.error('Error fetching users:', error);
    }
  }

  async getUserById(userId) {
    try {
      const response = await api.get(`/users/${Number(userId)}`, {
        headers: {
          token: this.token,
        },
      });
      return response.data;
    } catch (error) {
      console.error('Error fetching user:', error);
    }
  }

  async getProfiles() {
    try {
      const response = await api.get('/profiles', {
        headers: {
          token: this.token,
        },
      });
      return response.data;
    } catch (error) {
      console.error('Error fetching profiles:', error);
    }
  }

  async updateUser(data) {
    try {
      const response = await api.patch(`/users/${data.id}`, data, {
        headers: {
          token: this.token,
        },
      });
      return response.data;
    } catch (error) {
      console.error('Error updating user:', error);
    }
  }

  async getProfileById(id) {
    try {
      const response = await api.get(`/profiles/${id}`, {
        headers: {
          token: this.token,
        },
      });
      return response.data;
    } catch (error) {
      console.error('Error fetching profile:', error);
    }
  }

  async addOrRemoveUnit(unitId, useId, remove = false) {
    try {
      const response = await api.patch(
        `/users/${useId}`,
        {
          Units: remove
            ? { disconnect: { id: unitId } }
            : { connect: { id: unitId } },
        },
        {
          headers: {
            token: this.token,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error('Error adding or removing unit:', error);
    }
  }

  async addOrRemoveLocals(localId, useId, remove = false) {
    try {
      const response = await api.patch(
        `/users/${useId}`,
        {
          Locals: remove
            ? { disconnect: { id: localId } }
            : { connect: { id: localId } },
        },
        {
          headers: {
            token: this.token,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error('Error adding or removing unit:', error);
    }
  }
}
