import api from '../../lib/axios';
import Cookies from 'vue-cookies';

const Calendar = {
  namespaced: true,
  state: {
    calendardata: [],
    isLoading: true,
  },
  mutations: {
    GET_DATA_CALENDAR(state, payload) {
      state.calendardata = payload;
    },
  },
  actions: {
    getCalendarByData({ commit }) {
      const token = Cookies.get('token');

      api
        .get('/blocked', {
          headers: {
            token: token,
          },
        })
        .then((res) => {
          commit('GET_DATA_CALENDAR', res.data);
          this.state.Calendar.isLoading = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};

export default Calendar;
