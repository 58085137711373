<template>
  <section class="mt-4" v-show="roomType !== 'Presencial'">
    <div class="flex-1">
      <label class="text-sm text-gray-500">
        <div class="flex items-center justify-between">
          Tipo de transmissão

          <div
            v-show="typeOfTransmission !== ''"
            class="group relative cursor-help"
          >
            <i class="ph ph-info text-xl"></i>

            <div
              class="absolute right-2 bottom-6 bg-gray-500 w-[200px] shadow-lg z-40 rounded-lg text-white p-2 text-center transition-all scale-0 origin-bottom-right duration-300 group-hover:scale-100"
            >
              {{
                typeOfTransmission === 'Zoom'
                  ? 'Plataforma de transmissão- Até 3.000 participantes'
                  : typeOfTransmission === 'Workplace'
                  ? 'Transmissão na plataforma de comunicação interna. Informar o grupo que será utilizado. Sem limite de participantes.'
                  : 'Transmissão na plataforma. Informar a conta que será utilizada. Sem limite de participantes.'
              }}
            </div>
          </div>
        </div>

        <select
          v-model="typeOfTransmission"
          class="border-2 border-gray-300 h-12 rounded-lg w-full text-md mt-2 pl-4"
        >
          <option value="" selected disabled>Selecione uma opção</option>
          <option value="Zoom">Zoom</option>
          <option value="Workplace">Workplace</option>
          <option value="Facebook">Facebook</option>
          <option value="Youtube">Youtube</option>
        </select>
      </label>
    </div>
  </section>

  <section class="mt-4" v-show="roomType !== 'Presencial'">
    <div class="flex-1">
      <label class="text-sm text-gray-500">
        Workplace, Facebook e Youtube

        <input
          type="text"
          v-model="transmissionDescription"
          class="border-2 border-gray-300 h-12 rounded-lg w-full text-md mt-2 pl-4"
        />
      </label>
    </div>
  </section>

  <section class="mt-4" v-show="roomType !== 'Presencial'">
    <div class="flex-1">
      <label class="text-sm text-gray-500">
        Trilha sonora para o início da live

        <div
          class="w-full border-dashed border border-gray-300 mt-2 py-4 flex items-center justify-center rounded-lg hover:border-blue-500 cursor-pointer"
        >
          <i class="ph ph-file-audio"></i>
        </div>

        <input type="file" @change="handleUploadFile($event, 'audio')" hidden />
      </label>

      <span class="block mt-2 text-sm text-gray-500">{{
        selectedFileAudioName
      }}</span>
    </div>
  </section>

  <section class="mt-4" v-show="roomType !== 'Presencial'">
    <div class="flex-1">
      <label class="text-sm text-gray-500">
        Texto para rede social

        <input
          v-model="socialNetworkText"
          type="text"
          class="border-2 border-gray-300 h-12 rounded-lg w-full text-md mt-2 pl-4"
        />
      </label>
    </div>
  </section>

  <section class="mt-4" v-show="roomType !== 'Presencial'">
    <div class="flex-1">
      <label class="text-sm text-gray-500">
        <div class="flex items-center justify-between">
          Tipo de link

          <div v-show="typeOfLink !== ''" class="group relative cursor-help">
            <i class="ph ph-info text-xl"></i>

            <div
              class="absolute right-2 bottom-6 bg-gray-500 w-[200px] shadow-lg z-40 rounded-lg text-white p-2 text-center transition-all scale-0 origin-bottom-right duration-300 group-hover:scale-100"
            >
              {{
                typeOfLink === 'Reunião'
                  ? 'Todos os participantes podem ter acesso à câmera e microfone. Limite- 300 participantes'
                  : 'Apenas palestrantes com acesso à câmera. Demais participantes interagem pelo chat. Limite- 3.000 participantes'
              }}
            </div>
          </div>
        </div>

        <select
          v-model="typeOfLink"
          class="border-2 border-gray-300 h-12 rounded-lg w-full text-md mt-2 pl-4"
        >
          <option value="" selected disabled>Selecione uma opção</option>
          <option value="Webinar">
            Webinar (até 1.000 ou 3.000 participantes, apenas palestrante tem
            acesso à câmera)
          </option>
          <option value="Reunião">
            Reunião (até 300 participantes, todos acessam possuem acesso à
            câmera)
          </option>
        </select>
      </label>
    </div>
  </section>

  <section class="mt-4" v-show="roomType !== 'Presencial'">
    <div class="flex-1">
      <label class="text-sm text-gray-500">
        Recursos do zoom

        <select
          v-model="zoomResources"
          multiple
          class="border-2 border-gray-300 h-26 rounded-lg w-full text-md mt-2 p-4"
        >
          <option value="Ficha de inscrição">Ficha de inscrição</option>
          <option value="Enquete">Enquete</option>
          <option value="Q&A">Q&A</option>
        </select>
      </label>
    </div>
  </section>

  <section class="mt-4" v-show="roomType !== 'Presencial'">
    <div class="flex-1">
      <label class="text-sm text-gray-500">
        Previsão de acessos

        <input
          type="number"
          v-model="accessesPrevision"
          class="border-2 border-gray-300 h-12 rounded-lg w-full text-md mt-2 pl-4"
        />
      </label>
    </div>
  </section>

  <section class="mt-4" v-show="roomType !== 'Presencial'">
    <div class="flex-1">
      <label class="text-sm text-gray-500">
        Anexar thumbnail

        <div
          class="w-full border-dashed border border-gray-300 mt-2 py-4 flex items-center justify-center rounded-lg hover:border-blue-500 cursor-pointer"
        >
          <i class="ph ph-plus"></i>
        </div>

        <input
          type="file"
          @change="handleUploadFile($event, 'thumbnail')"
          hidden
        />
      </label>

      <span class="block mt-2 text-sm text-gray-500">{{
        selectedFileThumbnailName
      }}</span>
    </div>
  </section>

  <section class="mt-4">
    <div class="flex-1">
      <label class="text-sm text-gray-500">
        Programação

        <div class="flex items-end gap-2">
          <input
            type="text"
            class="border-2 flex-1 border-gray-300 h-12 rounded-lg w-full text-md mt-2 pl-4"
          />

          <label>
            <div
              class="w-12 h-12 border-dashed border border-gray-300 rounded-lg flex items-center text-gray-400 justify-center hover:border-blue-500 hover:text-blue-500 transition-all cursor-pointer"
            >
              <i class="ph ph-plus"></i>
            </div>

            <input
              type="file"
              @change="handleUploadFile($event, 'programming')"
              hidden
            />
          </label>
        </div>

        <span class="block mt-2 text-sm text-gray-500">{{
          selectedFileProgrammingName
        }}</span>
      </label>
    </div>
  </section>
</template>
<script>
import { useSolicitationService } from '../../../services';
export default {
  name: 'AuditoryForm',

  data() {
    return {
      selectedFileAudioName: '',
      selectedFileThumbnailName: '',
      selectedFileProgrammingName: '',
      solicitationService: useSolicitationService(),
    };
  },

  computed: {
    roomType: {
      get() {
        return this.$store.state.SolicitationStore.roomType;
      },
    },

    typeOfTransmission: {
      get() {
        return this.$store.state.SolicitationStore.typeOfTransmission;
      },
      set(value) {
        this.$store.commit('SolicitationStore/SET_TYPE_OF_TRANSMISSION', value);
      },
    },

    socialNetworkText: {
      get() {
        return this.$store.state.SolicitationStore.socialNetworkText;
      },
      set(value) {
        this.$store.commit('SolicitationStore/SET_SOCIAL_NETWORK_TEXT', value);
      },
    },

    typeOfLink: {
      get() {
        return this.$store.state.SolicitationStore.typeOfLink;
      },
      set(value) {
        this.$store.commit('SolicitationStore/SET_TYPE_OF_LINK', value);
      },
    },

    zoomResources: {
      get() {
        return this.$store.state.SolicitationStore.zoomResources;
      },
      set(value) {
        this.$store.commit('SolicitationStore/SET_ZOOM_RESOURCES', value);
      },
    },

    accessesPrevision: {
      get() {
        return this.$store.state.SolicitationStore.accessesPrevision;
      },
      set(value) {
        this.$store.commit('SolicitationStore/SET_ACCESSES_PREVISION', value);
      },
    },

    transmissionAudioFile: {
      set(value) {
        this.$store.commit(
          'SolicitationStore/SET_TRANSMISSION_AUDIO_FILE',
          value
        );
      },
    },

    transmissionDescription: {
      get() {
        return this.$store.state.SolicitationStore.transmissionDescription;
      },
      set(value) {
        this.$store.commit(
          'SolicitationStore/SET_TRANSMISSION_DESCRIPTION',
          value
        );
      },
    },

    thumbNail: {
      set(value) {
        this.$store.commit('SolicitationStore/SET_THUMBNAIL', value);
      },
    },

    programmingFile: {
      set(value) {
        this.$store.commit('SolicitationStore/SET_PROGRAMMING_FILE', value);
      },
    },

    isLoading: {
      set(value) {
        this.$store.commit('Default/CHANGE_ISLOADING', value);
      },
    },
  },

  methods: {
    async handleUploadFile(event, type) {
      let selectedFile = event.target.files[0];

      if (type === 'audio') {
        this.selectedFileAudioName = event.target.files[0].name;
      } else if (type === 'thumbnail') {
        this.selectedFileThumbnailName = event.target.files[0].name;
      } else if (type === 'programming') {
        this.selectedFileProgrammingName = event.target.files[0].name;
      }

      const formData = new FormData();
      formData.append('file', selectedFile);

      this.isLoading = true;

      await this.solicitationService
        .uploadFile(formData)
        .then((res) => {
          if (type === 'audio') {
            this.transmissionAudioFile = res.url;
          } else if (type === 'thumbnail') {
            this.thumbNail = res.url;
          } else if (type === 'programming') {
            this.programmingFile = res.url;
          }
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
};
</script>
