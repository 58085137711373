<template>
  <section
    class="w-full h-screen flex items-center justify-center bg-auth bg-cover bg-no-repeat bg-center"
  >
    <div
      class="bg-white text-center shadow-lg rounded-3xl min-w-[456px] min-h-[529px] py-8 flex items-center flex-col justify-center border-2 border-gray-200"
    >
      <div>
        <div>
          <figure
            title="Logo do Albert Einstein Sociedade Beneficiente Israelita Brasileira"
          >
            <img
              class="w-full py-4 px-12"
              src="/assets/logo.svg"
              alt="Logo do Albert Einstein"
            />
          </figure>
        </div>

        <div class="mt-6 mb-12">
          <h2 class="text-blue-500 text-2xl font-medium">Acesso</h2>
          <p class="mt-1 text-gray-500">
            Preencha o formulário para <br />
            ter o acesso à plataforma
          </p>
        </div>

        <form
          method="post"
          class="max-w-sm mx-auto"
          @submit.prevent="handleFormSubmitEmailAndPassword"
        >
          <div class="text-left">
            <label class="text-sm text-gray-400">Usuário</label>
            <input
              v-model="email"
              type="text"
              placeholder="Usuário credenciado"
              class="w-full border-2 mt-2 text-sm border-gray-300 rounded-lg h-12 pl-5 focus:border-blue-500 transition-colors outline-none"
            />
          </div>
          <div class="text-left mt-4">
            <label class="text-sm text-gray-400">Senha</label>

            <div class="relative mt-2">
              <input
                v-model="password"
                :type="typeInput"
                placeholder="•••••••••"
                class="w-full border-2 text-sm border-gray-300 rounded-lg h-12 pl-5 focus:border-blue-500 transition-colors outline-none"
              />

              <div class="absolute right-4 top-1/2 -translate-y-1/2">
                <button
                  type="button"
                  class="leading-3 mt-1 text-gray-500 text-xl hover:brightness-90 transition-all"
                  @click="handleChangeTypeInput"
                >
                  <i
                    :class="
                      typeInput === 'password' ? 'ph ph-eye-slash' : 'ph ph-eye'
                    "
                  ></i>
                </button>
              </div>
            </div>
          </div>

          <button
            title="Clique para entrar na plataforma"
            :disabled="email === '' || password === '' || isLoadingAuthMethod"
            type="submit"
            class="bg-blue-500 w-full flex items-center justify-center mt-7 h-12 text-white hover:brightness-90 transition-all cursor-pointer disabled:hover:brightness-100 disabled:cursor-not-allowed disabled:opacity-70 rounded-full"
          >
            <div
              v-if="isLoadingAuthMethod"
              class="w-[20px] h-[20px] border-[2px] border-blue-500 border-t-white animate-spin rounded-full"
            ></div>
            <span v-else>Entrar</span>
          </button>
        </form>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'AuthView',

  data() {
    return {
      email: '',
      password: '',
      userExists: null,
      typeInput: 'password',
    };
  },

  computed: {
    isLoadingAuthMethod() {
      return this.$store.state.Auth.isLoadingAuthMethod;
    },
  },

  methods: {
    // Função para a autenticação no RHSSO
    handleFormSubmitEmailAndPassword() {
      const data = {
        email: this.email.toLowerCase().trim(),
        password: this.password,
      };
      this.$store.dispatch('Auth/handleFormSubmitEmailAndPassword', data);
    },

    // Muda o tipo do input para password ou text
    handleChangeTypeInput() {
      if (this.typeInput === 'password') this.typeInput = 'text';
      else this.typeInput = 'password';
    },
  },
};
</script>
