export default {
  filterId: {
    get() {
      return this.$store.state.ReserveFilter.filterId;
    },

    set(value) {
      this.$store.commit('ReserveFilter/CHANGE_FILTER_ID', value);
    },
  },

  filterDate: {
    get() {
      return this.$store.state.ReserveFilter.filterDate;
    },

    set(value) {
      this.$store.commit('ReserveFilter/CHANGE_FILTER_DATE', value);
    },
  },

  filterLocal: {
    get() {
      return this.$store.state.ReserveFilter.filterLocal;
    },

    set(value) {
      this.$store.commit('ReserveFilter/CHANGE_FILTER_LOCAL', value);
    },
  },

  filterRequester: {
    get() {
      return this.$store.state.ReserveFilter.filterRequester;
    },

    set(value) {
      this.$store.commit('ReserveFilter/CHANGE_FILTER_SOLICITANTE', value);
    },
  },

  filterUnit: {
    get() {
      return this.$store.state.ReserveFilter.filterUnit;
    },

    set(value) {
      this.$store.commit('ReserveFilter/CHANGE_FILTER_UNIT', value);
    },
  },

  filterStatus: {
    get() {
      return this.$store.state.ReserveFilter.filterStatus;
    },

    set(value) {
      this.$store.commit('ReserveFilter/CHANGE_FILTER_STATUS', value);
    },
  },

  filterEventType: {
    get() {
      return this.$store.state.ReserveFilter.filterEventType;
    },

    set(value) {
      this.$store.commit('ReserveFilter/CHANGE_FILTER_EVENT_TYPE', value);
    },
  },

  filterBranch: {
    get() {
      return this.$store.state.ReserveFilter.filterBranch;
    },

    set(value) {
      this.$store.commit('ReserveFilter/CHANGE_FILTER_BRANCH', value);
    },
  },

  filterEventName: {
    get() {
      return this.$store.state.ReserveFilter.filterEventName;
    },

    set(value) {
      this.$store.commit('ReserveFilter/CHANGE_FILTER_EVENT_NAME', value);
    },
  },

  filterStartTime: {
    get() {
      return this.$store.state.ReserveFilter.filterStartTime;
    },

    set(value) {
      this.$store.commit('ReserveFilter/CHANGE_FILTER_START_TIME', value);
    },
  },

  filterEndTime: {
    get() {
      return this.$store.state.ReserveFilter.filterEndTime;
    },

    set(value) {
      this.$store.commit('ReserveFilter/CHANGE_FILTER_END_TIME', value);
    },
  },

  filterRoom: {
    get() {
      return this.$store.state.ReserveFilter.filterRoom;
    },

    set(value) {
      this.$store.commit('ReserveFilter/CHANGE_FILTER_ROOM', value);
    },
  },

  isLoading: {
    set(value) {
      this.$store.commit('Default/CHANGE_ISLOADING', value);
    },
  },
};
