<template>
  <Schema>
    <div v-if="!isActiveEditDashboard" class="w-full">
      <div class="flex w-full mb-4 text-sm">
        <div class="mb-4 text-gray-400">
          <router-link to="/app" class="hover:text-blue-500 transition-colors">
            Home
          </router-link>
          <span class="text-blue-500"> • Dashboards</span>
        </div>
      </div>

      <div class="flex items-center justify-between">
        <div>
          <h1 class="text-3xl text-gray-600">Dashboards</h1>
          <p class="text-gray-400 max-w-xs mt-1">
            Analise as dashboards geradas do sistema pelo Power BI
          </p>
        </div>

        <button
          type="button"
          @click="onToggleCreateDashboard"
          class="bg-blue-500 text-white px-4 py-2 rounded-lg flex items-center gap-2 hover:brightness-90 transition-all"
        >
          <i class="ph ph-plus"></i>
          Adicionar um dashboard
        </button>
      </div>

      <table class="w-full table-fixed mt-10">
        <thead>
          <tr>
            <td class="bg-blue-500 text-white py-2 text-center rounded-l-lg">
              ID
            </td>
            <td class="bg-blue-500 text-white py-2 text-center">Nome</td>
            <td class="bg-blue-500 text-white py-2 text-center rounded-r-lg">
              Link
            </td>
          </tr>
        </thead>

        <tbody>
          <tr v-for="item in dashboards" :key="item.id">
            <td class="bg-gray-100 text-gray-500 py-2 text-center">
              {{ item.id }}
            </td>
            <td class="bg-gray-100 text-gray-500 py-2 text-center">
              {{ item.name }}
            </td>
            <td class="bg-gray-100 text-gray-500 py-2 text-center">
              <button
                type="button"
                @click="handleGetDashboard(item.id)"
                class="text-xl w-10 h-10 rounded-lg hover:bg-gray-500 hover:text-white transition-all inline-flex items-center justify-center"
              >
                <i class="ph ph-pencil-simple"></i>
              </button>
              <a
                target="_blank"
                :href="item.link"
                type="button"
                class="text-xl w-10 h-10 rounded-lg hover:bg-gray-500 hover:text-white transition-all inline-flex items-center justify-center"
              >
                <i class="ph ph-play-circle"></i>
              </a>

              <button
                type="button"
                @click="handleDeleteDashboad(item.id)"
                class="text-xl w-10 h-10 rounded-lg hover:bg-gray-500 hover:text-white transition-all inline-flex items-center justify-center"
              >
                <i class="ph ph-trash-simple"></i>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div v-else class="w-full">
      <div class="flex w-full mb-4 text-sm">
        <div class="mb-4 text-gray-400">
          <button
            type="button"
            @click="isActiveEditDashboard = !isActiveEditDashboard"
            class="hover:text-blue-500 transition-colors"
          >
            Dashboards
          </button>
          <span class="text-blue-500">
            • {{ createdButton ? 'Criar' : 'Editar' }} dashboard</span
          >
        </div>
      </div>

      <div class="flex items-center justify-between">
        <div>
          <h1 class="text-3xl text-gray-600">
            {{ createdButton ? 'Criar' : 'Editar' }} dashboard
          </h1>
          <p class="text-gray-400 max-w-xs mt-1">
            {{ createdButton ? 'Crie' : 'Edite' }} o dashboard atual
          </p>
        </div>
      </div>

      <form class="w-full mt-14">
        <div class="flex items-center gap-2">
          <label class="text-sm text-gray-500 w-full">
            Nome do dashboard

            <input
              type="text"
              v-model="name"
              class="border-2 border-gray-300 h-12 rounded-lg w-full text-md mt-2 pl-4"
            />
          </label>
        </div>
        <div class="flex items-center gap-2 mt-2">
          <label class="text-sm text-gray-500 w-full">
            Link do dashboard

            <input
              type="text"
              v-model="link"
              class="border-2 border-gray-300 h-12 rounded-lg w-full text-md mt-2 pl-4"
            />
          </label>
        </div>

        <button
          v-if="!createdButton"
          type="button"
          class="bg-blue-500 w-full text-white rounded-lg py-2 mt-4 hover:brightness-90 transition-all"
          @click="handleUpdateReport"
        >
          Atualizar dashboard
        </button>

        <button
          v-else
          type="button"
          class="bg-blue-500 w-full text-white rounded-lg py-2 mt-4 hover:brightness-90 transition-all"
          @click="handleCreateReport"
        >
          Criar dashboard
        </button>
      </form>
    </div>
  </Schema>
</template>

<script>
import { useToast } from 'vue-toastification';
import Schema from '../../components/Schema/index.vue';
import api from '../../lib/axios';
import Cookies from 'vue-cookies';

export default {
  name: 'Dashboard',
  components: {
    Schema,
  },

  setup() {
    const toast = useToast();
    return { toast };
  },

  data() {
    return {
      dashboards: [],

      name: '',
      link: '',

      isActiveEditDashboard: false,
      createdButton: false,
      currentId: '',
    };
  },

  mounted() {
    this.handleGetAllDashboards();
  },

  methods: {
    handleGetAllDashboards() {
      const token = Cookies.get('token');

      api
        .get('/dashboard', {
          headers: {
            token: token,
          },
        })
        .then((res) => {
          this.dashboards = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    handleDeleteDashboad(id) {
      if (
        window.confirm(
          'Você realmente quer excluir esse relatório de dashboard?'
        )
      ) {
        const token = Cookies.get('token');

        api
          .delete(`/dashboard/${id}`, {
            headers: {
              token: token,
            },
          })
          .then((res) => {
            this.toast.success('Dashboard excluído com sucesso');
            this.handleGetAllDashboards();
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },

    handleGetDashboard(id) {
      const token = Cookies.get('token');

      api
        .get(`/dashboard/${id}`, {
          headers: {
            token: token,
          },
        })
        .then((res) => {
          this.name = res.data.name;
          this.link = res.data.link;
          this.isActiveEditDashboard = true;
          this.createdButton = false;

          this.currentId = res.data.id;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    handleUpdateReport() {
      const token = Cookies.get('token');

      api
        .patch(
          `/dashboard/${this.currentId}`,
          {
            name: this.name,
            link: this.link,
          },
          {
            headers: {
              token: token,
            },
          }
        )
        .then((res) => {
          this.toast.success('Dashboard atualizado com sucesso');

          this.name = '';
          this.link = '';
          this.isActiveEditDashboard = false;
          this.createdButton = false;

          this.handleGetAllDashboards();
        })
        .catch((err) => {
          console.log(err);
        });
    },

    handleCreateReport() {
      const token = Cookies.get('token');

      api
        .post(
          `/dashboard`,
          {
            deleted: false,
            name: this.name,
            link: this.link,
          },
          {
            headers: {
              token: token,
            },
          }
        )
        .then((res) => {
          this.toast.success('Dashboard criado com sucesso');

          this.name = '';
          this.link = '';
          this.isActiveEditDashboard = false;
          this.createdButton = false;

          this.handleGetAllDashboards();
        })
        .catch((err) => {
          console.log(err);
        });
    },

    onToggleCreateDashboard() {
      this.name = '';
      this.link = '';
      this.createdButton = true;
      this.isActiveEditDashboard = true;
    },
  },
};
</script>
