<template>
  <button
    class="text-gray-400 py-4 px-2 hover:text-blue-500 transition-colors flex items-center gap-2"
    v-if="isNotAllowed === true"
    @click="handleNotAllowed"
    :title="name"
  >
    <slot />
  </button>
  <router-link
    v-else
    type="button"
    :class="
      url === path
        ? 'text-white  w-full bg-gray-400 py-2 px-2 rounded-lg hover: transition-colors active flex items-center gap-2'
        : 'text-gray-400  py-4 px-2 hover:text-blue-500 transition-colors flex items-center gap-2'
    "
    :to="url"
    :title="name"
  >
    <slot />
  </router-link>
</template>

<script>
import { useToast } from 'vue-toastification';

export default {
  name: 'NavLinkItem',

  props: {
    name: String,
    url: String,
    isNotAllowed: Boolean,
  },

  setup() {
    const toast = useToast();
    return { toast };
  },

  data() {
    return {
      path: '',
    };
  },

  mounted() {
    const params = this.$route.path;
    this.path = params;
  },

  methods: {
    handleNotAllowed() {
      this.toast.warning('Rota em desenvolvimento');
    },
  },
};
</script>
