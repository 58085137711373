import { format } from 'date-fns';
import Cookies from 'vue-cookies';
import { messageSuccess, messageWarning } from '../../lib/toast';
import {
  useSolicitationService,
  useUnitService,
  useRoomsService,
} from '../../services';

export default {
  setUserData({ state, rootState }) {
    const user = rootState.Auth.user;
    state.name = user.name;
    state.email = user.email;
    state.createdBy = Cookies.get('user_id');
  },

  async handleCreateSolicitation({ state, rootState, dispatch }) {
    const solicitationService = useSolicitationService();
    let recurrentPattern = [];
    let finalDate = state.date;

    if (state.recurrent) {
      rootState.Default.isLoading = true;
      const data = {
        name: state.eventName,
        currentDate: format(state.date, 'yyyy-MM-dd'),
        lastDate: format(state.recurrentSolicitation.endDate, 'yyyy-MM-dd'),
        days: [
          {
            name: 'Segunda-feira',
            active: state.recurrentSolicitation.toggleMonday,
          },
          {
            name: 'Terça-feira',
            active: state.recurrentSolicitation.toggleTuesday,
          },
          {
            name: 'Quarta-feira',
            active: state.recurrentSolicitation.toggleWednesday,
          },
          {
            name: 'Quinta-feira',
            active: state.recurrentSolicitation.toggleThursday,
          },
          {
            name: 'Sexta-feira',
            active: state.recurrentSolicitation.toggleFriday,
          },
          {
            name: 'Sábado',
            active: state.recurrentSolicitation.toggleSaturday,
          },
          {
            name: 'Domingo',
            active: state.recurrentSolicitation.toggleSunday,
          },
        ],
        defaultRecurrent: state.recurrentSolicitation.defaultRecurrent,
        repeatWeek: Number(state.recurrentSolicitation.repeatWeek),
      };
      const result = await solicitationService
        .recurrentSolicitation(data)
        .finally(() => {
          rootState.Default.isLoading = false;
        });

      if (!result.recurrent.length) {
        messageWarning('Erro ao criar solicitação');
        return;
      }

      recurrentPattern = JSON.stringify(result);
      const decoded = JSON.parse(recurrentPattern);

      finalDate = new Date(
        decoded.recurrent[decoded.recurrent.length - 1].currentDate +
          ' ' +
          '00:00:00'
      );
    }

    if (!state.previousPreparation) {
      state.preparationStartDay = '';
      state.preparationStartInitial = '';
      state.preparationStartFinal = '';
      state.preparationEndDay = '';
      state.preparationEndInitial = '';
      state.preparationEndFinal = '';
    }

    let roomLayout = '';

    if (state.chosenAuditoriums.length) {
      roomLayout = state.chosenAuditoriums[0].roomLayout;
    }

    const dataToCreateSolicitation = {
      active: true,
      deleted: false,
      status: 'Pendente',
      createdBy: Number(state.createdBy),
      name: state.name,
      branch: state.branch,
      email: state.email,
      roomType: state.roomType,
      unitId: state.unitId,
      localId: state.localId,
      date: format(state.date, 'yyyy-MM-dd'),
      startTime: state.startTime,
      finalTime: state.finalTime,
      recurrent: state.recurrent,
      costCenter: state.costCenter,
      manager: state.manager,
      board: state.board,
      roomLayout: roomLayout,
      roomId:
        state.chosenAuditoriums.length &&
        state.chosenAuditoriums[0].roomId !== undefined
          ? Number(state.chosenAuditoriums[0].roomId)
          : null,
      eventName: state.eventName,
      eventDescription: state.eventDescription,
      numberOfParticipants: state.numberOfParticipants,
      equipmentsDescription: state.equipmentsDescription,
      previousPreparation: state.previousPreparation,
      preparationStartInitial: state.preparationStartInitial,
      preparationStartFinal: state.preparationStartFinal,
      preparationStartDay: state.preparationStartDay
        ? format(state.preparationStartDay, 'yyyy-MM-dd')
        : '',
      preparationEndDay: state.preparationEndDay
        ? format(state.preparationEndDay, 'yyyy-MM-dd')
        : '',
      preparationEndInitial: state.preparationEndInitial,
      preparationEndFinal: state.preparationEndFinal,
      recurrentPattern: recurrentPattern.length ? recurrentPattern : '',
      finalDate: finalDate,
      mobileEquipments: state.mobileEquipments,
      typeOfTransmission: state.typeOfTransmission,
      socialNetworkText: state.socialNetworkText,
      typeOfLink: state.typeOfLink,
      zoomResources: String(state.zoomResources),
      accessesPrevision: Number(state.accessesPrevision),
      transmissionAudioFile: state.transmissionAudioFile,
      transmissionDescription: state.transmissionDescription,
      thumbNail: state.thumbNail,
      programmingFile: state.programmingFile,
      roomIds: state.chosenAuditoriums.map((room) => room.roomId),
      requesterEmail: state.email,
    };

    rootState.Default.isLoading = true;

    await solicitationService
      .createSolicitation(dataToCreateSolicitation)
      .then(() => {
        messageSuccess('Solicitação criada com sucesso');
        state.router.push('/gerenciamento/reservas');
        dispatch('cleanData');
      })
      .finally(() => {
        rootState.Default.isLoading = false;
      });
  },

  handleGetLocalsWithUnit({ state, rootState }) {
    const unitService = useUnitService();

    rootState.Default.isLoading = true;

    unitService
      .getLocalsWithUnit(state.unitId)
      .then((response) => {
        state.locals = response.Locals;
      })
      .finally(() => {
        rootState.Default.isLoading = false;
      });
  },

  handleGetRoomsByLocalId({ state, rootState }) {
    const roomsService = useRoomsService();

    rootState.Default.isLoading = true;

    roomsService
      .getRoomsByLocalId(state.localId)
      .then((response) => {
        state.auditories = response;
      })
      .finally(() => {
        rootState.Default.isLoading = false;
      });
  },

  cleanData({ state }) {
    state.step = 1;
    state.branch = '';
    state.roomType = '';
    state.unitId = '';
    state.localId = '';
    state.date = '';
    state.startTime = '';
    state.finalTime = '';
    state.recurrent = false;
    state.costCenter = '';
    state.manager = '';
    state.board = '';
    state.eventName = '';
    state.eventDescription = '';
    state.numberOfParticipants = 0;
    state.equipmentsDescription = '';
    state.previousPreparation = false;
    state.preparationStartDay = '';
    state.preparationStartInitial = '';
    state.preparationStartFinal = '';
    state.preparationEndDay = '';
    state.preparationEndInitial = '';
    state.preparationEndFinal = '';
    state.recurrentSolicitation = {
      defaultRecurrent: 'Diário',
      toggleMonday: true,
      toggleTuesday: true,
      toggleWednesday: true,
      toggleThursday: true,
      toggleFriday: true,
      toggleSaturday: true,
      toggleSunday: true,
      valueMonday: 'segunda-feira',
      valueTuesday: 'terça-feira',
      valueWednesday: 'quarta-feira',
      valueThursday: 'quinta-feira',
      valueFriday: 'sexta-feira',
      valueSaturday: 'sábado',
      valueSunday: 'domingo',
      activeMonday: false,
      activeTuesday: false,
      activeWednesday: false,
      activeThursday: false,
      activeFriday: false,
      activeSaturday: false,
      activeSunday: false,
      endDate: '',
      repeatWeek: '1',
    };
    state.layoutId = '';
    state.roomLayout = '';
    state.roomId = '';
    state.mobileEquipments = {
      connect: [],
    };
    state.typeOfTransmission = '';
    state.socialNetworkText = '';
    state.typeOfLink = '';
    state.zoomResources = '';
    state.accessesPrevision = '';
    state.locals = [];
    state.auditories = [];
    state.chosenAuditoriums = [];
    state.programmingFile = '';
    state.thumbNail = '';
  },
};
