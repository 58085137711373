import Cookies from 'vue-cookies';
import { SchedulingSimulationService } from '../../services';

const SchedulingSimulation = {
  namespaced: true,
  state: {
    years: [],
    periods: [],
    units: [],
    selectedYears: [],
    selectedPeriods: [],
    selectedModalities: [],
    selectedStatusClass: [],
    selectedCourse: null,
    selectedClass: null,
    tabActive: 0,
    isRehearse: false,
    className: null,
    selectedCourses: [],
    statusClasses: [],
    unitsSelected: [],
    unitSelected: [],
    roomsSelected: [],
    scheduledClassesItem: null,
    availableRooms: [],
    hasRooms: false,
    modalities: [],
  },

  mutations: {
    GET_INITIAL_DATA(state, payload) {
      const { years, periods, unitList, modalities } = payload;
      state.years = years;
      state.periods = periods;
      state.units = unitList;
      state.modalities = modalities;
    },

    SET_SELECTED_YEARS(state, payload) {
      state.selectedYears = payload;
    },

    SET_SELECTED_PERIODS(state, payload) {
      state.selectedPeriods = payload;
    },

    SET_SELECTED_MODALITIES(state, payload) {
      state.selectedModalities = payload;
    },

    SET_SELECTED_STATUS_CLASS(state, payload) {
      state.selectedStatusClass = payload;
    },

    SET_SELECTED_COURSE(state, payload) {
      state.selectedCourse = payload;
    },

    SET_SELECTED_CLASS(state, payload) {
      state.selectedClass = payload;
    },

    SET_TAB_ACTIVE(state, payload) {
      state.tabActive = payload;
    },

    SET_IS_REHEARSE(state, payload) {
      state.isRehearse = payload;
    },

    SET_DATA_TO_REHEARSE(state, payload) {
      state.selectedYears = [{ year: payload.year }];
      state.selectedPeriods = [{ period: payload.period }];
      state.selectedModalities = [payload.modality];
      state.className = payload.className;
    },

    SET_SELECTED_COURSES(state, payload) {
      state.selectedCourses.push(payload);
    },

    REMOVE_SELECTED_COURSES_BY_CLASSCODE(state, payload) {
      state.selectedCourses = state.selectedCourses.filter(
        (course) => course.class !== payload
      );
    },

    REMOVE_SELECTED_COURSES(state, payload) {
      state.selectedCourses = state.selectedCourses.filter(
        (course) => course.class !== payload
      );
    },

    SET_STATUS_CLASSES(state, payload) {
      state.statusClasses = payload;
    },

    SET_UNITS_SELECTED(state, payload) {
      state.unitsSelected = payload;
    },

    SET_ROOMS_SELECTED(state, payload) {
      state.roomsSelected = payload;
    },

    SET_UNIT_SELECTED(state, payload) {
      state.unitSelected = payload;
    },

    SET_SCHEDULED_CLASSES_ITEM(state, payload) {
      state.scheduledClassesItem = payload;
    },

    SET_AVAILABLE_ROOMS(state, payload) {
      state.availableRooms = payload;
    },

    SET_HAS_ROOMS(state, payload) {
      state.hasRooms = payload;
    },
  },

  getters: {
    schedulingSimulationService() {
      const token = Cookies.get('token');
      return new SchedulingSimulationService(token);
    },
  },

  actions: {
    async getInitialData({ commit, getters }) {
      await getters.schedulingSimulationService
        .getInitialData()
        .then((response) => {
          commit('GET_INITIAL_DATA', response);
        });
    },

    async getCourseUpdated({ commit, getters, state }) {
      const { selectedYears, selectedPeriods, className } = state;

      const data = {
        year: selectedYears[0].year,
        period: selectedPeriods[0].period,
        className,
      };

      await getters.schedulingSimulationService
        .getCourseUpdated(data)
        .then((result) => {
          result.courseUpdated.percentage = 0;
          commit('SET_SELECTED_COURSES', result.courseUpdated);
          commit('SET_STATUS_CLASSES', [result.statusClass]);
          commit('SET_SELECTED_STATUS_CLASS', [result.statusClass]);
        });
    },

    cleanData({ state }) {
      state.selectedYears = [];
      state.selectedPeriods = [];
      state.selectedModalities = [];
      state.selectedStatusClass = [];
      state.selectedCourse = null;
      state.selectedClass = null;
      state.isRehearse = false;
      state.selectedCourses = [];
      state.statusClasses = [];
      state.unitsSelected = [];
      state.roomsSelected = [];
      state.unitSelected = [];
      state.scheduledClassesItem = null;
      state.isShowGrid = false;
      state.availableRooms = [];
      state.hasRooms = false;
    },
  },
};

export default SchedulingSimulation;
