<template>
  <div class="flex items-end gap-5">
    <h1 class="mt-5 mb-2 text-blue-500 text-lg font-medium">
      Selecione uma ou mais unidades
    </h1>
  </div>

  <div class="relative">
    <div
      class="w-[calc(100vw-210px-200px)] overflow-x-auto flex-nowrap whitespace-nowrap pb-2"
      ref="scrollDiv"
    >
      <SelectButton
        v-model="unitSelected"
        :options="units"
        optionLabel="name"
        multiple
        aria-labelledby="multiple"
        class="text-blue-500"
        unselectable="true"
        @change="handleChangeUnit"
      />
    </div>
    <button @click="scrollToLeft" class="absolute top-1.5 -left-4 text-4xl">
      <i class="ph ph-caret-left text-blue-500"></i>
    </button>
    <button @click="scrollToRight" class="absolute top-1.5 -right-4 text-4xl">
      <i class="ph ph-caret-right text-blue-500"></i>
    </button>
  </div>
  <table class="w-full items-center mt-5 border-b">
    <thead>
      <tr class="border-b border-b-gray-300">
        <th class="w-[460px] font-normal text-gray-500 text-left">Unidade</th>
        <th class="text-sm font-normal text-gray-500">
          <div class="w-[470px] text-left">Sala</div>
        </th>
        <th></th>
      </tr>
    </thead>
    <tbody>
      <tr
        v-for="(item, index) in unitsSelected"
        :key="item.id"
        v-show="item.id !== 0"
      >
        <td class="flex text-left rounded bg-blue-500 text-white m-1 p-2 mr-5">
          <div class="w-[400px] truncate">
            {{ item.name }}
          </div>
        </td>
        <td>
          <MultiSelect
            v-model="rooms[index]"
            :options="item.rooms"
            optionLabel="name"
            placeholder="Selecione a sala"
            class="max-w-md h-10"
            @change="handleChangeRoom"
          />
        </td>
        <td class="flex items-center justify-center">
          <button
            type="button"
            @click="handleRemoveUnit(item)"
            class="mt-1 w-8 h-8 bg-red-500 text-white rounded-full hover:brightness-90 transition-all text-lg disabled:cursor-not-allowed disabled:opacity-60"
          >
            <i class="ph ph-trash"></i>
          </button>
        </td>
      </tr>
    </tbody>
  </table>
</template>
<script>
import SelectButton from 'primevue/selectbutton';
import MultiSelect from 'primevue/multiselect';

export default {
  name: 'Units',
  components: {
    SelectButton,
    MultiSelect,
  },

  props: {
    units: Array,
    handleSelectRoom: Function,
  },

  data() {
    return {
      rooms: [],
    };
  },

  computed: {
    unitSelected: {
      get() {
        return this.$store.state.SchedulingSimulation.unitSelected;
      },

      set(value) {
        this.$store.commit('SchedulingSimulation/SET_UNIT_SELECTED', value);
      },
    },

    unitsSelected: {
      get() {
        return this.$store.state.SchedulingSimulation.unitsSelected;
      },

      set(value) {
        this.$store.commit('SchedulingSimulation/SET_UNITS_SELECTED', value);
      },
    },

    roomsSelected: {
      get() {
        return this.$store.state.SchedulingSimulation.roomsSelected;
      },

      set(value) {
        this.$store.commit('SchedulingSimulation/SET_ROOMS_SELECTED', value);
      },
    },
  },

  methods: {
    handleChangeUnit(event) {
      const value = event.value || [];
      const lastItem = value[value.length - 1];

      this.unitsSelected = [];
      this.rooms = [];
      this.unitSelected = [];
      this.roomsSelected = null;

      this.unitsSelected = [lastItem];
      this.rooms.push(lastItem.rooms);
      this.roomsSelected = this.rooms;
      this.unitSelected = [lastItem];
    },

    handleChangeRoom(event) {
      this.consoleLog(event, 'event');
    },

    handleRemoveUnit(unit) {
      this.unitsSelected = this.unitsSelected.filter(
        (item) => item.id !== unit.id
      );

      this.unitSelected = this.unitSelected.filter(
        (item) => item.id !== unit.id
      );

      if (unit.rooms) {
        this.handleSelectRoom(unit.rooms, true);
      }

      this.rooms = this.rooms.filter((item) => item.id !== unit.id);
    },

    scrollToRight() {
      const div = this.$refs.scrollDiv;
      div.scrollLeft = div.scrollWidth;
    },

    scrollToLeft() {
      const div = this.$refs.scrollDiv;
      div.scrollLeft = 0;
    },

    consoleLog(param, title) {
      console.log(JSON.parse(JSON.stringify(param)), title);
    },
  },
};
</script>
