import api from '../lib/axios';
import Cookies from 'vue-cookies';

class RoomsService {
  constructor() {
    this.token = Cookies.get('token');
  }

  async getRooms() {
    try {
      const response = await api.get('/rooms', {
        headers: {
          token: this.token,
        },
      });
      return response.data;
    } catch (error) {
      console.error('Error fetching rooms:', error);
    }
  }

  async updateRoom(id, data) {
    try {
      const response = await api.patch(`/rooms/${id}`, data, {
        headers: {
          token: this.token,
        },
      });
      return response.data;
    } catch (error) {
      console.error('Error fetching room:', error);
    }
  }

  async getRoomById(id) {
    try {
      const response = await api.get(`/rooms/${id}`, {
        headers: {
          token: this.token,
        },
      });
      return response.data;
    } catch (error) {
      console.error('Error fetching room:', error);
    }
  }

  async getRoomsByLocalId(localId) {
    try {
      const response = await api.get(`/rooms/local/${localId}`, {
        headers: {
          token: this.token,
        },
      });
      return response.data;
    } catch (error) {
      console.error('Error fetching room:', error);
    }
  }
}

export const useRoomsService = () => new RoomsService();
