<template>
  <Dialog
    v-model:visible="displayModal"
    :style="{ width: '30vw' }"
    :modal="true"
    :closable="false"
    :showHeader="false"
  >
    <button
      @click="closeModal(false)"
      class="float-right bg-blue-500 text-white rounded-full py-2 w-9 h-9 mt-3 justify-self-end"
    >
      <i class="ph ph-x"></i>
    </button>
    <h1 class="border-b-2 mt-5 mb-5 text-blue-500 pb-5 text-xl">
      Editar {{ displayName }}
    </h1>
    <div v-if="isSemesterPeriod">
      <label class="flex text-sm text-gray-500"> Período </label>
      <div class="flex items-center">
        <input
          v-model="this.$props.data.period"
          type="number"
          class="w-full border-2 border-black-500 h-11 rounded-lg mt-1 mb-2 p-2 pt-1 focus:border-blue-500 focus:border-blue-500 transition-colors outline-none"
        />
      </div>
      <label class="flex text-sm text-gray-500"> Semestre </label>
      <div class="flex items-center">
        <input
          v-model="this.$props.data.semester"
          type="text"
          class="w-full border-2 border-black-500 h-11 rounded-lg mt-1 mb-2 p-2 pt-1 focus:border-blue-500 focus:border-blue-500 transition-colors outline-none"
        />
      </div>
    </div>
    <div v-else>
      <label class="flex text-sm text-gray-500"> {{ displayName }} </label>
      <div class="flex items-center">
        <input
          v-model="this.$props.data.name"
          type="text"
          class="w-full border-2 border-black-500 h-11 rounded-lg mt-1 mb-2 p-2 pt-1 focus:border-blue-500 focus:border-blue-500 transition-colors outline-none"
        />
      </div>
    </div>
    <label class="flex text-sm text-gray-500">Ativo</label>
    <label class="flex mb-5">
      <input
        v-model="this.$props.data.active"
        type="checkbox"
        class="toggle-input"
      />
      <div class="slider">
        <button class="button"></button>
      </div>
    </label>
    <button
      @click="closeModal(true)"
      class="w-full bg-blue-500 text-white rounded-full pt-2 pb-3 text-xl"
    >
      Salvar
    </button>
  </Dialog>
</template>
<script>
export default {
  name: 'ModalEditItem',

  props: {
    display: Boolean,
    data: Object,
    callBack: Function,
    displayName: String,
    isSemesterPeriod: Boolean,
  },

  methods: {
    closeModal(isUpdate) {
      this.displayModal = false;
      const dataToUpdate = {
        ...this.data,
        isShowModal: false,
        isUpdate,
      };
      this.callBack(dataToUpdate);
    },
  },

  data() {
    return {
      displayModal: false,
      itemName: '',
      active: false,
    };
  },

  updated() {
    this.displayModal = this.display;
    this.itemName = this.data.name;
  },
};
</script>
<style scoped lang="scss">
.slider {
  height: 22px;
}
</style>
