import api from '../../lib/axios';
import Cookies from 'vue-cookies';

const RoomTypes = {
  namespaced: true,
  state: {
    roomtypesdata: [],
    isLoading: true,
  },
  mutations: {
    GET_DATA_ROOMTYPES(state, payload) {
      state.roomtypesdata = payload;
    },
  },
  actions: {
    getRoomTypesByData({ commit }, payload) {
      const token = Cookies.get('token');

      api
        .get('/roomtypes', {
          headers: {
            token: token,
          },
        })
        .then((res) => {
          commit('GET_DATA_ROOMTYPES', res.data);
          this.state.RoomTypes.isLoading = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};

export default RoomTypes;
