import api from '../lib/axios';

export class ClassScheduleCalendarService {
  constructor(token) {
    this.token = token;
  }

  async getPeriodsByYear(year) {
    try {
      const response = await api.get(`/class-schedule-calendar/${year}`, {
        headers: {
          token: this.token,
        },
      });
      return response.data;
    } catch (error) {
      console.error('Error fetching periods by year:', error);
    }
  }
}
