<template>
  <div class="flex items-end gap-5">
    <div class="flex-1 text-sm text-gray-500">
      <h1 class="mb-1 text-blue-500">Ano</h1>
      <MultiSelect
        v-model="selectedYears"
        :options="years"
        optionLabel="year"
        placeholder="Selecione o ano"
        :maxSelectedLabels="3"
        class="w-full h-12 mt-2 pt-1"
        @change="handleChangePeriodAndYear"
        :disabled="isRehearse"
        emptyMessage="Nenhum ano encontrado"
      />
    </div>
    <div class="flex-1 text-sm text-gray-500">
      <h1 class="mb-1 text-blue-500">Período</h1>
      <MultiSelect
        v-model="selectedPeriods"
        :options="periods"
        optionLabel="period"
        placeholder="Selecione o período"
        :maxSelectedLabels="3"
        class="w-full h-12 mt-2 pt-1"
        @change="handleChangePeriodAndYear"
        :disabled="!selectedYears.length || isRehearse"
        emptyMessage="Nenhum período encontrado"
      />
    </div>
    <div class="flex-1 text-sm text-gray-500">
      <h1 class="mb-1 text-blue-500">Modalidades</h1>
      <MultiSelect
        v-model="selectedModalities"
        :options="modalities"
        optionLabel="name"
        placeholder="Selecione a modalidade"
        :maxSelectedLabels="3"
        class="w-full h-12 mt-2 pt-1"
        :disabled="!selectedPeriods.length || isRehearse"
        @change="handleChangeModality"
        emptyMessage="Nenhuma modalidade encontrada"
      />
    </div>
  </div>
  <h1 class="mt-5 mb-2 text-blue-500 text-lg font-medium">
    Selecione um ou mais cursos
  </h1>
  <div class="flex items-end gap-5">
    <div class="flex-1 text-sm text-gray-500">
      <h1 class="mb-1 text-blue-500">Curso*</h1>
      <Dropdown
        v-model="selectedCourse"
        :options="courses"
        optionLabel="courseName"
        placeholder="Selecione o curso"
        class="w-full h-12 mt-2"
        @change="handleCourseChange"
        :disabled="!selectedModalities.length || isRehearse"
        emptyMessage="Nenhum curso encontrado"
      />
    </div>
    <div class="text-sm text-gray-500 flex-none w-150">
      <h1 class="mb-1 text-blue-500">Turma*</h1>
      <div class="flex items-center gap-4">
        <Dropdown
          v-model="selectedClass"
          :options="classes"
          optionLabel="name"
          placeholder="Selecione a turma"
          class="w-full h-12 mt-2"
          optionValue="name"
          :disabled="selectedCourse === null"
        />
        <button
          type="button"
          :disabled="selectedClass === null || selectedCourse === null"
          @click="handleAddClass"
          class="mt-2 p-1 bg-blue-500 text-white rounded-full hover:brightness-90 transition-all flex items-center justify-center text-lg disabled:cursor-not-allowed disabled:opacity-60"
        >
          <i class="ph ph-plus"></i>
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import MultiSelect from 'primevue/multiselect';
import Dropdown from 'primevue/dropdown';

export default {
  name: 'SchedulingSimulationForm',
  components: {
    MultiSelect,
    Dropdown,
  },

  props: {
    callBack: Function,
    schedulingSimulationService: Object,
    dynamicLyceumService: Object,
    years: Array,
    periods: Array,
  },

  data() {
    return {
      courses: [],
      coursesBackup: [],
      classes: [],
    };
  },

  computed: {
    selectedYears: {
      get() {
        return this.$store.state.SchedulingSimulation.selectedYears;
      },

      set(value) {
        this.$store.commit('SchedulingSimulation/SET_SELECTED_YEARS', value);
      },
    },

    selectedPeriods: {
      get() {
        return this.$store.state.SchedulingSimulation.selectedPeriods;
      },

      set(value) {
        this.$store.commit('SchedulingSimulation/SET_SELECTED_PERIODS', value);
      },
    },

    selectedModalities: {
      get() {
        return this.$store.state.SchedulingSimulation.selectedModalities;
      },

      set(value) {
        this.$store.commit(
          'SchedulingSimulation/SET_SELECTED_MODALITIES',
          value
        );
      },
    },

    selectedStatusClass: {
      get() {
        return this.$store.state.SchedulingSimulation.selectedStatusClass;
      },

      set(value) {
        this.$store.commit(
          'SchedulingSimulation/SET_SELECTED_STATUS_CLASS',
          value
        );
      },
    },

    selectedCourse: {
      get() {
        return this.$store.state.SchedulingSimulation.selectedCourse;
      },

      set(value) {
        this.$store.commit('SchedulingSimulation/SET_SELECTED_COURSE', value);
      },
    },

    selectedClass: {
      get() {
        return this.$store.state.SchedulingSimulation.selectedClass;
      },

      set(value) {
        this.$store.commit('SchedulingSimulation/SET_SELECTED_CLASS', value);
      },
    },

    isRehearse() {
      const isRehearse = this.$store.state.SchedulingSimulation.isRehearse;
      if (isRehearse) {
        this.handleChangePeriodAndYear();
      }
      return isRehearse;
    },

    statusClasses: {
      get() {
        return this.$store.state.SchedulingSimulation.statusClasses;
      },

      set(value) {
        this.$store.commit('SchedulingSimulation/SET_STATUS_CLASSES', value);
      },
    },

    modalities: {
      get() {
        return this.$store.state.SchedulingSimulation.modalities;
      },
    },
  },

  methods: {
    handleCourseChange(event) {
      const value = event.value;
      this.classes = [];
      this.classes.push({ name: value.class });
    },

    async handleChangePeriodAndYear() {
      try {
        const years = this.selectedYears.map((year) => year.year);
        const periods = this.selectedPeriods.map((period) => period.period);

        if (!years.length || !periods.length) {
          return;
        }

        if (this.isRehearse) {
          this.$store.dispatch('SchedulingSimulation/getCourseUpdated');
          return;
        }

        this.handleGetCoursesByYearsAndPeriods();
      } catch (error) {
        console.log(error);
      }
    },

    async handleGetStatusMatriculaTurma() {
      try {
        if (!this.selectedModalities.length) {
          this.statusClasses = [];
          return;
        }

        const result =
          await this.schedulingSimulationService.getStatusMatriculaTurma();
        this.statusClasses = result;
      } catch (error) {
        console.log(error);
      }
    },

    async handleGetCourses() {
      try {
        if (!this.selectedStatusClass.length) {
          this.courses = [];
          return;
        }

        const courseCodes = this.selectedStatusClass.map(
          (statusClass) => statusClass.COD_CURSO
        );

        const result =
          await this.schedulingSimulationService.getCoursesByCourseCodes(
            courseCodes
          );
        this.courses = result;
      } catch (error) {
        console.log(error);
      }
    },

    async handleGetCoursesByYearsAndPeriods() {
      const years = this.selectedYears.map((year) => year.year);
      const periods = this.selectedPeriods.map((period) => period.period);

      if (!years.length || !periods.length) {
        return;
      }

      const result =
        await this.schedulingSimulationService.getCoursesByYearsAndPeriods(
          years,
          periods
        );

      this.courses = result;
      this.coursesBackup = result;
    },

    async handleAddClass() {
      const year = this.selectedCourse.year;
      const period = this.selectedCourse.period;
      const className = this.selectedCourse.class;

      const result = await this.dynamicLyceumService.getStatusMatrTurma({
        year,
        period,
        className,
      });

      if (result.length === 0) {
        return;
      }

      const enrollment = result[0].MATRICULAS;

      const data = {
        ...this.selectedCourse,
        enrollment,
        percentage: 0,
        enrollmentBackup: enrollment,
      };

      this.selectedClass = null;
      this.selectedCourse = null;
      
      this.callBack(data);
    },

    handleChangeModality() {
      const modalityIds = this.selectedModalities.map(
        (modality) => modality.id
      );

      const filteredList = this.coursesBackup.filter((course) =>
        modalityIds.includes(course.classScheduleCalendar.modality.id)
      );

      this.courses = filteredList;
    },
  },
};
</script>
