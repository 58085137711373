import api from '../../lib/axios';
import Cookies from 'vue-cookies';

const Profile = {
  namespaced: true,
  state: {
    profiledata: [],
    isLoading: true,
  },
  mutations: {
    GET_DATA_PROFILE(state, payload) {
      state.profiledata = payload;
    },
  },
  actions: {
    getProfileByData({ commit }, payload) {
      const token = Cookies.get('token');

      api
        .get('/profiles', {
          headers: {
            token: token,
          },
        })
        .then((res) => {
          commit('GET_DATA_PROFILE', res.data);
          this.state.Profile.isLoading = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};

export default Profile;
