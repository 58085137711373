<template>
  <div class="relative mt-5">
    <div
      class="w-[calc(100vw-210px-200px)] overflow-x-auto flex-nowrap whitespace-nowrap pb-2"
      ref="scrollDiv"
    >
      <SelectButton
        v-model="this.$props.unitSelected"
        :options="selectableUnits"
        optionLabel="name"
        multiple
        aria-labelledby="multiple"
        class="text-blue-500"
        unselectable="true"
        @change="handleChangeUnit"
      />
    </div>
    <button
      @click="scrollToLeft"
      class="absolute top-1.5 -left-8 text-4xl"
      v-if="selectableUnits.length"
    >
      <i class="ph ph-caret-left text-blue-500"></i>
    </button>
    <button
      @click="scrollToRight"
      class="absolute top-1.5 -right-4 text-4xl"
      v-if="selectableUnits.length"
    >
      <i class="ph ph-caret-right text-blue-500"></i>
    </button>
  </div>
</template>
<script>
import SelectButton from 'primevue/selectbutton';

export default {
  name: 'SelectableUnits',
  components: {
    SelectButton,
  },

  props: {
    selectableUnits: Array,
    unitSelected: Array,
  },

  methods: {
    handleChangeUnit(event) {
      this.$emit('handleChangeUnit', event);
    },

    scrollToRight() {
      const div = this.$refs.scrollDiv;
      div.scrollLeft = div.scrollWidth;
    },

    scrollToLeft() {
      const div = this.$refs.scrollDiv;
      div.scrollLeft = 0;
    },
  },
};
</script>
