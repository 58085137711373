export default {
  units() {
    return this.$store.state.Units.unitsdata;
  },

  isLoading: {
    set(value) {
      this.$store.commit('Default/CHANGE_ISLOADING', value);
    },
  },

  roomType: {
    get() {
      return this.$store.state.SolicitationStore.roomType;
    },
  },

  unitId: {
    get() {
      return this.$store.state.SolicitationStore.unitId;
    },

    set(value) {
      this.$store.commit('SolicitationStore/SET_UNIT_ID', value);
    },
  },

  localId: {
    get() {
      return this.$store.state.SolicitationStore.localId;
    },

    set(value) {
      this.$store.commit('SolicitationStore/SET_LOCAL_ID', value);
    },
  },

  date: {
    get() {
      return this.$store.state.SolicitationStore.date;
    },

    set(value) {
      this.$store.commit('SolicitationStore/SET_DATE', value);
    },
  },

  startTime: {
    get() {
      return this.$store.state.SolicitationStore.startTime;
    },

    set(value) {
      this.$store.commit('SolicitationStore/SET_START_TIME', value);
    },
  },

  finalTime: {
    get() {
      return this.$store.state.SolicitationStore.finalTime;
    },

    set(value) {
      this.$store.commit('SolicitationStore/SET_FINAL_TIME', value);
    },
  },

  costCenter: {
    get() {
      return this.$store.state.SolicitationStore.costCenter;
    },

    set(value) {
      this.$store.commit('SolicitationStore/SET_COST_CENTER', value);
    },
  },

  manager: {
    get() {
      return this.$store.state.SolicitationStore.manager;
    },

    set(value) {
      this.$store.commit('SolicitationStore/SET_MANAGER', value);
    },
  },

  board: {
    get() {
      return this.$store.state.SolicitationStore.board;
    },

    set(value) {
      this.$store.commit('SolicitationStore/SET_BOARD', value);
    },
  },

  recurrent: {
    get() {
      return this.$store.state.SolicitationStore.recurrent;
    },

    set(value) {
      this.$store.commit('SolicitationStore/SET_RECURRENT', value);
    },
  },

  equipmentsData() {
    return this.$store.state.Equipments.equipmentsdata;
  },

  equipments: {
    get() {
      return this.$store.state.SolicitationStore.equipments;
    },
    set(value) {
      this.$store.commit('SolicitationStore/SET_EQUIPMENTS', value);
    },
  },

  localType: {
    get() {
      return this.$store.state.SolicitationStore.localType;
    },

    set(value) {
      this.$store.commit('SolicitationStore/SET_LOCAL_TYPE', value);
    },
  },

  layoutInfoImage: {
    get() {
      return this.$store.state.SolicitationStore.layoutInfoImage;
    },
    set(value) {
      this.$store.commit('SolicitationStore/SET_LAYOUT_INFO_IMAGE', value);
    },
  },

  locals: {
    get() {
      return this.$store.state.SolicitationStore.locals;
    },
    set(value) {
      this.$store.commit('SolicitationStore/SET_LOCALS', value);
    },
  },

  auditories: {
    get() {
      return this.$store.state.SolicitationStore.auditories;
    },
    set(value) {
      this.$store.commit('SolicitationStore/SET_AUDITORIES', value);
    },
  },

  chosenAuditoriums: {
    get() {
      return this.$store.state.SolicitationStore.chosenAuditoriums;
    },
    set(value) {
      this.$store.commit('SolicitationStore/SET_CHOSEN_AUDITORIUMS', value);
    },
  },

  isChooseAuditory: {
    get() {
      return this.$store.state.SolicitationStore.isChooseAuditory;
    },
    set(value) {
      this.$store.commit('SolicitationStore/SET_IS_CHOOSE_AUDITORY', value);
    },
  },
};
