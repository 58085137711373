import api from '../../../lib/axios';

export class ClassCalendarApiService {
  constructor(token) {
    this.token = token;
  }

  async getInitialData() {
    try {
      const response = await api.get('/class-schedule-calendar/initial-data', {
        headers: {
          token: this.token,
        },
      });
      return response.data;
    } catch (error) {
      console.error('Error fetching initial datas:', error);
    }
  }

  async getInitialDataCourseCalendar() {
    try {
      const response = await api.get('/course-calendar/initial-data', {
        headers: {
          token: this.token,
        },
      });
      return response.data;
    } catch (error) {
      console.error('Error fetching initial datas:', error);
    }
  }

  async updateDaysWeekSchedules(data) {
    try {
      const response = await api.patch(
        `/days-week-schedules/${data.id}`,
        data,
        {
          headers: {
            token: this.token,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error('Error updating days week schedules:', error);
    }
  }

  async deleteDaysWeekSchedules(itemId) {
    try {
      const response = await api.delete(`/days-week-schedules/${itemId}`, {
        headers: {
          token: this.token,
        },
      });
      return response.data;
    } catch (error) {
      console.error('Error deleting days week schedules:', error);
    }
  }

  async getCoursesLyceum(data) {
    try {
      const response = await api.post('/course-calendar/courses-lyceum', data, {
        headers: {
          token: this.token,
        },
      });
      return response.data;
    } catch (error) {
      console.error('Error fetching courses lyceum:', error);
    }
  }

  async getClassesLyceum(data) {
    try {
      const response = await api.post('/course-calendar/classes-lyceum', data, {
        headers: {
          token: this.token,
        },
      });
      return response.data;
    } catch (error) {
      console.error('Error fetching class lyceum:', error);
    }
  }

  async createCourseCalendar(data) {
    try {
      const response = await api.post('/course-calendar', data, {
        headers: {
          token: this.token,
        },
      });
      return response.data;
    } catch (error) {
      console.error('Error creating course calendar:', error);
    }
  }

  async updateCourseCalendar(data) {
    try {
      const response = await api.patch(`/course-calendar/${data.id}`, data, {
        headers: {
          token: this.token,
        },
      });
      return response.data;
    } catch (error) {
      console.error('Error updating course calendar:', error);
    }
  }

  async deactivateCourseCalendar(itemId) {
    try {
      const response = await api.patch(
        `/course-calendar/deactivate/${itemId}`,
        {
          headers: {
            token: this.token,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error('Error deactivating course calendar:', error);
    }
  }
}
