<template>
  <v-calendar
    is-expanded
    :disabled-dates="{ weekdays: workingTimesDisabled }"
    :attributes="attrs"
    @dayclick="onDayClick"
    ref="calendar"
    :max-date="new Date(maxDate)"
    :min-date="new Date(minDate)"
  />
</template>

<script>
import { messageWarning } from '../../lib/toast';
export default {
  name: 'CalendarSelect',

  props: {
    workingTimesDisabled: Array,
    year: String,
    month: String,
    maxDate: String,
    minDate: String,
    callback: Function,
    dateSelection: Array,
  },

  computed: {
    days: {
      get() {
        return this.$store.state.ClassScheduleCalendar.days;
      },

      set(value) {
        this.$store.commit('ClassScheduleCalendar/SET_DAYS', value);
      },
    },

    attrs: {
      get() {
        return this.$store.state.ClassScheduleCalendar.attrs;
      },

      set(value) {
        this.$store.commit('ClassScheduleCalendar/SET_ATTRS', value);
      },
    },
  },

  async mounted() {
    const calendar = this.$refs.calendar;
    await calendar.move({ month: Number(this.month), year: Number(this.year) });
    this.dateSelection.forEach((dateSelection) => {
      this.attrs.push({
        id: dateSelection.date,
        highlight: dateSelection.active,
        dates: new Date(
          dateSelection.year,
          dateSelection.month - 1,
          dateSelection.day
        ),
      });

      this.days.push({
        id: dateSelection.date,
        date: new Date(
          dateSelection.year,
          dateSelection.month - 1,
          dateSelection.day
        ),
      });
    });
  },

  unmounted() {
    this.days = [];
    this.attrs = [];
  },

  methods: {
    onDayClick(day) {
      if (!day.isDisabled) {
        const dayFound = this.days.find((d) => d.id === day.id);
        const dataToSave = {
          day: day.day,
          month: day.month,
          year: day.year,
          date: day.id,
        };

        if (dayFound) {
          // if the day was found, remove it from days and filter out attrs with that date
          this.days = this.days.filter((d) => d.id !== dayFound.id); // use filter to remove the item from the array instead of splice
          // this.dateSelection = this.days.filter((d) => d.id !== dayFound.id); // use filter to remove the item from the array instead of splice

          this.attrs = this.attrs.filter((d) => d.id !== day.id);
          dataToSave['active'] = false;
        } else {
          dataToSave['active'] = true;
          dataToSave['weekDayIndex'] = this.getDayOfWeedIndex(dataToSave);
          // if the day was not found, add it to days and push an attr with that date to attrs
          this.days = [...this.days, { id: day.id, date: day.date }]; // use spread operator to add item to array instead of push
          this.attrs = [
            ...this.attrs,
            { id: day.id, highlight: true, dates: day.date },
          ]; // use spread operator to add item to array instead of push
        }
        this.callback(dataToSave);
      } else {
        messageWarning('Este dia está desabilitado');
      }
    },

    getDayOfWeedIndex(date) {
      const day = new Date(date.year, date.month - 1, date.day).getDay();
      return day === 0 ? 6 : day - 1;
    },
  },
};
</script>
