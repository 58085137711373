export default {
  name: {
    get() {
      return this.$store.state.SolicitationStore.name;
    },

    set(value) {
      this.$store.commit('SolicitationStore/SET_NAME', value);
    },
  },

  email: {
    get() {
      return this.$store.state.SolicitationStore.email;
    },

    set(value) {
      this.$store.commit('SolicitationStore/SET_EMAIL', value);
    },
  },

  branch: {
    get() {
      return this.$store.state.SolicitationStore.branch;
    },

    set(value) {
      this.$store.commit('SolicitationStore/SET_BRANCH', value);
    },
  },

  roomType: {
    get() {
      return this.$store.state.SolicitationStore.roomType;
    },

    set(value) {
      this.$store.commit('SolicitationStore/SET_ROOM_TYPE', value);
    },
  },

  units() {
    return this.$store.state.Units.unitsdata;
  },

  unitId: {
    set(value) {
      this.$store.commit('SolicitationStore/SET_UNIT_ID', value);
    },
  },

  localId: {
    set(value) {
      this.$store.commit('SolicitationStore/SET_LOCAL_ID', value);
    },
  },

  locals: {
    set(value) {
      this.$store.commit('SolicitationStore/SET_LOCALS', value);
    },
  },

  auditories: {
    set(value) {
      this.$store.commit('SolicitationStore/SET_AUDITORIES', value);
    },
  },

  chosenAuditoriums: {
    set(value) {
      this.$store.commit('SolicitationStore/SET_CHOSEN_AUDITORIUMS', value);
    },
  },

  isChooseAuditory: {
    set(value) {
      this.$store.commit('SolicitationStore/SET_IS_CHOOSE_AUDITORY', value);
    },
  },

  localType: {
    set(value) {
      this.$store.commit('SolicitationStore/SET_LOCAL_TYPE', value);
    },
  },
};
