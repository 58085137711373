const AdminScreen = {
  namespaced: true,
  state: {
    filterRoomName: '',
    filterEventName: '',
    filterLocalName: '',
    filterDateFormatted: '',
    filterTime: '',
    filterSolicitationId: '',
    unitsSelected: [],
  },

  mutations: {
    SET_FILTER_ROOM_NAME(state, payload) {
      state.filterRoomName = payload;
    },
    
    SET_FILTER_EVENT_NAME(state, payload) {
      state.filterEventName = payload;
    },

    SET_FILTER_LOCAL_NAME(state, payload) {
      state.filterLocalName = payload;
    },
    
    SET_FILTER_DATE_FORMATTED(state, payload) {
      state.filterDateFormatted = payload;
    },

    SET_FILTER_TIME(state, payload) {
      state.filterTime = payload;
    },

    SET_FILTER_SOLICITATION_ID(state, payload) {
      state.filterSolicitationId = payload;
    },

    SET_UNITS_SELECTED(state, payload) {
      state.unitsSelected = payload;
    },
  },
};

export default AdminScreen;
