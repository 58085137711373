import api from '../lib/axios';
import Cookies from 'vue-cookies';

export class UnitService {
  constructor() {
    this.token = Cookies.get('token');
  }

  async getUnits() {
    try {
      const response = await api.get('/units', {
        headers: {
          token: this.token,
        },
      });
      return response.data;
    } catch (error) {
      console.error('Error fetching units:', error);
    }
  }

  async getLocalsWithUnit(unitId) {
    try {
      const response = await api.get(`/units/${unitId}`, {
        headers: {
          token: this.token,
        },
      });
      return response.data;
    } catch (error) {
      console.error('Error fetching locals:', error);
    }
  }
}

export const useUnitService = () => new UnitService();
