<template>
  <div
    class="w-full h-screen absolute py-12 left-0 top-0 bg-modal-600 z-50 flex items-center justify-center backdrop-blur-sm"
  >
    <div
      class="bg-white w-[600px] h-auto max-h-full overflow-y-auto p-6 rounded-xl shadow-lg relative"
    >
      <button
        type="button"
        @click="onCloseModal"
        class="absolute right-2 top-2 text-white bg-blue-500 w-8 h-8 rounded-full flex items-center justify-center"
      >
        <i class="ph ph-x"></i>
      </button>

      <div class="text-2xl pb-5 text-blue-500 border-b border-gray-300">
        Selecionar equipamentos
      </div>

      <div class="flex flex-col gap-4 h-[400px] overflow-y-auto mt-7">
        <div
          class="border-2 border-gray-300 rounded-lg p-4 flex items-center justify-between"
          v-for="item in data"
          :key="item.id"
        >
          <div>
            <h3 class="text-blue-500 text-lg">{{ item.name }}</h3>
            <span class="text-sm text-gray-400">{{
              item.type === 0 ? 'Equipamento fixo' : 'Equipamento móvel'
            }}</span>
            <p class="text-blue-500 mt-3 text-sm">
              Patrimônio: {{ item.patrimony }}
            </p>
          </div>
          <button
            v-show="profile"
            type="button"
            @click="catchName(item.id, item.name, item.type, item.patrimony)"
            class="bg-green-500 text-white w-10 h-10 rounded-lg flex items-center justify-center hover:brightness-90 transition-all"
          >
            <i class="ph ph-plus"></i>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ModalSelectEquipmentItem',
  props: {
    onCloseModal: Function,
    data: Array,
    selectedEquipament: String,
  },

  computed: {
    profile() {
      return this.$store.state.Default.profile;
    },
  },
  methods: {
    catchName(id, name, type, patrimony) {
      const data = {
        id: id,
        name: name,
        type: type,
        patrimony: patrimony,
      };
      this.$emit('catchInfoEquipment', data);
      this.onCloseModal();
    },
  },
};
</script>
