<template>
  <DataTable
    :value="scheduledClasses"
    paginatorTemplate="FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink RowsPerPageDropdown"
    paginator
    :rows="15"
    :rowsPerPageOptions="[15, 20, 30, 50]"
    currentPageReportTemplate="{first} de {last} de {totalRecords}"
    :globalFilterFields="[
      'roomName',
      'courseName',
      'unitName',
      'class',
      'layoutName',
    ]"
    v-model:filters="filters"
    @rowSelect="onRowSelect"
    selectionMode="single"
  >
    <template #header>
      <span class="p-input-icon-left">
        <i class="pi pi-search pt-1" />
        <input
          v-model="filters['global'].value"
          placeholder="Pesquisa global"
          class="border-2 border-gray-300 p-3 pl-10 rounded-lg w-full text-sm mt-2"
        />
      </span>
    </template>
    <Column field="roomName" header="Sala"></Column>
    <Column header="Curso" style="width: 5rem">
      <template #body="{ data }">
        <span class="block truncate w-52" :title="data.courseName">{{
          data.courseName
        }}</span>
      </template>
    </Column>
    <Column field="unitName" header="Unidade"></Column>
    <Column field="class" header="Turma"></Column>
    <Column header="Calendário">
      <template #body="{ data }">
        <span
          class="block w-52 truncate"
          :title="data.classScheduleCalendar.version"
          >{{ data.classScheduleCalendar.version }}</span
        >
      </template>
    </Column>
    <Column field="layoutName" header="Layout"> </Column>
    <Column field="roomCapacity" header="Cap. Sala"> </Column>
    <Column field="enrollment" header="Matricula"> </Column>
    <Column header="Realizado">
      <template #body="slotProps">
        <span>{{ formatDate(slotProps.data.createdAt) }}</span>
      </template>
    </Column>
    <Column header="Ações">
      <template #body="slotProps">
        <Menu ref="menu" :model="items" :popup="true" />
        <button
          label="Toggle"
          @click="toggleMenu($event, slotProps.data)"
          class="bg-white text-xl mr-2"
        >
          <i class="ph-bold ph-dots-three-vertical"></i>
        </button>
      </template>
    </Column>
  </DataTable>
  <Dialog
    v-model:visible="displayModal"
    :style="{ width: '60vw' }"
    :modal="true"
    :closable="false"
    :showHeader="false"
  >
    <button
      @click="handleCloseModal()"
      class="float-right bg-blue-500 text-white rounded-full py-2 w-9 h-9 mt-3 justify-self-end"
    >
      <i class="ph ph-x"></i>
    </button>
    <h1 class="border-b-2 mt-5 mb-3 text-blue-500 pb-5 text-xl">
      {{ modalTitle }}
    </h1>
    <h1 class="mt-5 text-gray-500 pb-5 text-lg" v-if="isDelete">
      Excluir a simulação de ensalamento para a sala:
      <b>{{ scheduledClassesItem.roomName }}</b>
    </h1>
    <h1 class="mt-5 text-gray-500 pb-5 text-lg" v-if="isRehearse">
      Você tem certeza que deseja reensalar a turma abaixo?
    </h1>
    <div class="flex justify-between">
      <h1 class="text-gray-500 mb-5">
        <b>Curso: </b>{{ scheduledClassesItem.courseName }}
      </h1>
      <h1 class="text-gray-500 mb-5">
        <b>Calendário: </b
        >{{ scheduledClassesItem.classScheduleCalendar.version }}
      </h1>
    </div>
    <div class="flex justify-between border-b-2 mb-5">
      <h1 class="text-gray-500 mb-5">
        <b>Turma: </b>{{ scheduledClassesItem.class }}
      </h1>
      <h1 class="text-gray-500 mb-5">
        <b>Sala: </b>{{ scheduledClassesItem.roomName }}
      </h1>
      <h1 class="text-gray-500 mb-5">
        <b>Unidade: </b>{{ scheduledClassesItem.unitName }}
      </h1>
      <h1 class="text-gray-500 mb-5">
        <b>Layout: </b>{{ scheduledClassesItem.layoutName }}
      </h1>
    </div>
    <DataTable
      :value="scheduledClassesItem.Solicitations"
      v-model:expandedRows="expandedRows"
      dataKey="id"
      v-if="!isDelete && !isRehearse"
    >
      <Column expander></Column>
      <Column field="id" header="Id"></Column>
      <Column field="name" header="Nome"></Column>
      <Column field="email" header="E-mail"></Column>
      <Column field="roomLayout" header="Layout"></Column>
      <Column field="status" header="Status"></Column>
      <template #expansion="slotProps">
        <h5 class="font-bold mb-5">
          Compromissos: {{ slotProps.data.Appointments.length }}
        </h5>
        <DataTable
          paginatorTemplate="FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink RowsPerPageDropdown"
          paginator
          :rows="15"
          :rowsPerPageOptions="[15, 20, 30, 50]"
          currentPageReportTemplate="{first} de {last} de {totalRecords}"
          :value="slotProps.data.Appointments"
        >
          <Column field="id" header="Id"></Column>
          <Column header="Data">
            <template #body="slotProps">
              <span>{{ formatDate(slotProps.data.date) }}</span>
            </template>
          </Column>
          <Column field="startTime" header="Horário inicial"></Column>
          <Column field="finalTime" header="Horário final"></Column>
          <Column header="Ações">
            <template #body="{ data }">
              <ConfirmPopup />
              <button
                class="bg-red-500 w-[25px] h-[25px] flex items-center justify-center text-white rounded-full hover:brightness-90 transition-all"
                title="Clique para excluir o calendário"
                type="button"
                v-if="showButtonDelete(data.date)"
                @click="handleDeleteAppointment($event, data.id)"
              >
                <i class="ph ph-trash"></i>
              </button>
            </template>
          </Column>
        </DataTable>
      </template>
    </DataTable>
    <div class="flex justify-between mb-1" v-if="isDelete || isRehearse">
      <h1 class="text-gray-500">
        <b>Resumo dos encontros: </b>
      </h1>
    </div>
    <div class="flex justify-start mb-5" v-if="isDelete || isRehearse">
      <div class="mr-10">
        <h6>Total de encontros:</h6>
        <h5 class="bg01">{{ totalMeetings }}</h5>
      </div>
      <div class="mr-10">
        <h6>Encontros realizados:</h6>
        <h5 class="bg02">{{ meetingsHeld }}</h5>
      </div>
      <div class="mr-10">
        <h6>Faltam realizar:</h6>
        <h5 class="bg03">{{ meetingsToBeHeld }}</h5>
      </div>
    </div>
    <div
      class="border-t-2 flex items-end justify-end mt-5"
      v-if="isDelete || isRehearse"
    >
      <button
        type="button"
        @click="handleCloseModal()"
        v-if="!loading"
        class="mr-5 w-[150px] h-12 text-blue-500 border border-blue-500 rounded-full hover:brightness-90 transition-all text-lg disabled:cursor-not-allowed"
      >
        Cancelar
      </button>
      <button
        @click="handleConfirm()"
        v-if="!loading"
        class="w-[150px] bg-blue-500 text-white rounded-full pt-2 pb-3 text-xl mt-5 float-right"
      >
        Confirmar
      </button>
      <div v-if="loading" class="flex items-center justify-center mt-[35px]">
        <div
          class="w-11 h-11 rounded-full flex border-2 border-t-blue-500 animate-spin"
        ></div>
      </div>
    </div>
  </Dialog>
</template>
<script>
import ConfirmPopup from 'primevue/confirmpopup';
import { useConfirm } from 'primevue/useconfirm';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Menu from 'primevue/menu';
import { FilterMatchMode } from 'primevue/api';
import { format, parseISO } from 'date-fns';
import { ref } from 'vue';
import {
  useAppointmentService,
  ScheduledClassesService,
} from '../../../services';
import Cookies from 'vue-cookies';
import { messageSuccess } from '../../../lib/toast';

export default {
  name: 'ScheduledClasses',

  components: {
    DataTable,
    Column,
    Menu,
    ConfirmPopup,
  },

  props: {
    scheduledClasses: Array,
  },

  mounted() {
    const token = Cookies.get('token');
    this.scheduledClassesService = new ScheduledClassesService(token);
  },

  created() {
    this.initFilters();
  },

  data() {
    return {
      filters: null,
      scheduledClassesItem: null,
      displayModal: false,
      expandedRows: ref([]),
      loading: false,
      confirm: useConfirm(),
      appointmentService: useAppointmentService(),
      scheduledClassesService: null,
      modalTitle: '',
      isDelete: false,
      totalMeetings: 0,
      meetingsHeld: 0,
      meetingsToBeHeld: 0,
      items: [
        {
          label: 'Detalhes',
          command: () => {
            this.displayModal = true;
            this.modalTitle = 'Detalhes do agendamento';
            this.isDelete = false;
            this.isRehearse = false;
            setTimeout(() => {
              this.expandedRows =
                this.scheduledClassesItem.Solicitations.filter((s) => s.id);
            }, 100);
          },
        },
        {
          label: 'Reensalar',
          command: () => {
            this.displayModal = true;
            this.modalTitle = 'Reensalar agendamento';
            this.isDelete = false;
            this.isRehearse = true;
          },
        },
        {
          label: 'Excluir',
          command: () => {
            this.displayModal = true;
            this.modalTitle = 'Excluir agendamento';
            this.isDelete = true;
            this.isRehearse = false;
          },
        },
      ],
    };
  },

  computed: {
    isRehearse: {
      get() {
        return this.$store.state.SchedulingSimulation.isRehearse;
      },
      set(value) {
        this.$store.commit('SchedulingSimulation/SET_IS_REHEARSE', value);
      },
    },
  },

  methods: {
    initFilters() {
      this.filters = {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      };
    },

    toggleMenu(event, item) {
      this.scheduledClassesItem = item;
      this.$refs.menu.toggle(event);
      this.getMeetings();
    },

    formatDate(date) {
      return format(parseISO(date), 'dd/MM/yyyy');
    },

    handleCloseModal() {
      this.displayModal = false;
    },

    onRowSelect(event) {
      this.scheduledClassesItem = event.data;
      this.displayModal = true;
      this.modalTitle = 'Detalhes do agendamento';
      this.isDelete = false;
      this.isRehearse = false;
      setTimeout(() => {
        this.expandedRows = this.scheduledClassesItem.Solicitations.filter(
          (s) => s.id
        );
      }, 50);
    },

    showButtonDelete(date) {
      const currentDate = new Date();
      const dateSelected = new Date(date);

      if (dateSelected > currentDate) {
        return true;
      }

      return false;
    },

    handleDeleteAppointment(event, id) {
      this.confirm.require({
        target: event.currentTarget,
        message: 'Deseja remover esse compromisso?',
        icon: 'pi pi-exclamation-triangle',
        acceptLabel: 'Sim',
        rejectLabel: 'Não',
        accept: () => {
          this.appointmentService
            .deleteAppointment(id)
            .then(() => {
              this.$store.dispatch('ScheduledClasses/getScheduledClasses');
              this.handleCloseModal();
            })
            .catch((error) => {
              console.log(error);
            });
        },
        reject: () => {},
      });
    },

    handleConfirm() {
      if (this.isDelete) {
        this.loading = true;
        this.scheduledClassesService
          .deleteScheduledClasses(this.scheduledClassesItem.id)
          .then(() => {
            this.$store.dispatch('ScheduledClasses/getScheduledClasses');
            this.handleCloseModal();
            messageSuccess('Agendamento excluído com sucesso!');
            this.loading = false;
          });
      }

      if (this.isRehearse) {
        const { classScheduleCalendar } = this.scheduledClassesItem;

        const payload = {
          year: classScheduleCalendar.year,
          period: classScheduleCalendar.period,
          modality: classScheduleCalendar.modality,
          className: this.scheduledClassesItem.class,
        };

        this.$store.commit(
          'SchedulingSimulation/SET_DATA_TO_REHEARSE',
          payload
        );
        this.$store.commit('SchedulingSimulation/SET_TAB_ACTIVE', 0);
        this.$store.commit(
          'SchedulingSimulation/SET_SCHEDULED_CLASSES_ITEM',
          this.scheduledClassesItem
        );
        this.handleCloseModal();
      }
    },

    getMeetings() {
      this.meetingsHeld = 0;
      this.meetingsToBeHeld = 0;

      const { Appointments } = this.scheduledClassesItem.Solicitations[0];
      this.totalMeetings = Appointments.length;

      for (const appointment of Appointments) {
        const currentDate = new Date();
        const pastDate = new Date(appointment.date);
        if (pastDate < currentDate) {
          this.meetingsHeld++;
        } else {
          this.meetingsToBeHeld++;
        }
      }
    },
  },
};
</script>
<style scoped lang="scss">
.bg01 {
  background-color: #dfecfb;
  border-radius: 5px;
  font-weight: bold;
  color: #1e56a0;
  padding: 5px;
}

.bg02 {
  background-color: #e1f3e1;
  border-radius: 5px;
  font-weight: bold;
  color: #19801d;
  padding: 5px;
}

.bg03 {
  background-color: #f9e5df;
  border-radius: 5px;
  font-weight: bold;
  color: #d64214;
  padding: 5px;
}
</style>
