<template>
  <div class="flex items-center gap-[20px]">
    <label class="flex-1 text-sm text-gray-500">
      Unidade*
      <Dropdown
        v-model="this.$props.formObjects.unitId"
        :options="units"
        optionLabel="name"
        placeholder="Selecione as unidades"
        class="w-full h-12 mt-2"
        optionValue="id"
        @change="handleMountCalendarVersion"
      />
    </label>
    <label class="flex-1 text-sm text-gray-500">
      <div class="flex items-center gap-2">
        Modalidade*

        <div
          class="group cursor-help text-blue-500 relative flex items-center justify-center text-lg"
        >
          <i class="ph ph-warning-circle"></i>

          <div
            class="group-hover:scale-100 scale-0 transition-all origin-bottom-left absolute -left-1 bottom-7 bg-gray-700 w-[250px] px-4 py-2 text-white rounded-md text-xs font-light"
          >
            Apenas administradores podem editar essa informação

            <div
              class="w-3 h-3 bg-gray-700 rotate-45 absolute left-2 -bottom-1"
            ></div>
          </div>
        </div>
      </div>
      <Dropdown
        v-model="this.$props.formObjects.modalityId"
        :options="modalities"
        optionLabel="name"
        placeholder="Selecione uma modalidade"
        class="w-full h-12 mt-2"
        optionValue="id"
        @change="handleMountCalendarVersion"
      />
    </label>
    <label class="text-sm text-gray-500">
      Ano*

      <input
        class="w-[165px] flex text-sm border-2 border-gray-300 h-12 pl-4 rounded mt-2 focus:ring-2 focus:ring-blue-500 outline-none transition-all"
        v-model="this.$props.formObjects.year"
        @change="handleMountCalendarVersion"
        oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
        type="number"
        placeholder="Digite um ano"
        maxlength="4"
      />
    </label>

    <label class="text-sm text-gray-500">
      <div class="flex items-center gap-2">
        Período*

        <div
          class="group cursor-help text-blue-500 relative flex items-center justify-center text-lg"
        >
          <i class="ph ph-warning-circle"></i>

          <div
            class="group-hover:scale-100 scale-0 transition-all origin-bottom-right absolute -right-1 bottom-7 bg-gray-700 w-[250px] px-4 py-2 text-white rounded-md text-xs font-light"
          >
            Apenas administradores podem editar essa informação

            <div
              class="w-3 h-3 bg-gray-700 rotate-45 absolute right-2 -bottom-1"
            ></div>
          </div>
        </div>
      </div>

      <input
        class="w-[165px] flex text-sm border-2 border-gray-300 h-12 pl-4 rounded mt-2 focus:ring-2 focus:ring-blue-500 outline-none transition-all"
        v-model="this.$props.formObjects.period"
        @change="handleMountCalendarVersion"
        type="text"
        placeholder="Digite um semestre"
      />
    </label>
  </div>
  <div class="flex items-center gap-[20px]">
    <label class="flex-1 text-sm text-gray-500">
      <div class="flex items-center gap-2">
        Tipo de Calendário*

        <div
          class="group cursor-help text-blue-500 relative flex items-center justify-center text-lg"
        >
          <i class="ph ph-warning-circle"></i>

          <div
            class="group-hover:scale-100 scale-0 transition-all origin-bottom-left absolute -left-1 bottom-7 bg-gray-700 w-[250px] px-4 py-2 text-white rounded-md text-xs font-light"
          >
            Apenas administradores podem editar essa informação

            <div
              class="w-3 h-3 bg-gray-700 rotate-45 absolute left-2 -bottom-1"
            ></div>
          </div>
        </div>
      </div>
      <Dropdown
        v-model="this.$props.formObjects.calendarTypeId"
        :options="calendarTypes"
        optionLabel="name"
        placeholder="Selecione um tipo de calendário"
        class="w-full h-12 mt-2"
        optionValue="id"
        @change="handleMountCalendarVersion"
      />
    </label>

    <label class="flex-1 text-sm text-gray-500">
      <div class="flex items-center gap-2">
        Frequência*

        <div
          class="group cursor-help text-blue-500 relative flex items-center justify-center text-lg"
        >
          <i class="ph ph-warning-circle"></i>

          <div
            class="group-hover:scale-100 scale-0 transition-all origin-bottom-left absolute -left-1 bottom-7 bg-gray-700 w-[250px] px-4 py-2 text-white rounded-md text-xs font-light"
          >
            Apenas administradores podem editar essa informação

            <div
              class="w-3 h-3 bg-gray-700 rotate-45 absolute left-2 -bottom-1"
            ></div>
          </div>
        </div>
      </div>

      <input
        class="w-full text-sm border-2 border-gray-300 h-12 pl-4 rounded mt-2 focus:ring-2 focus:ring-blue-500 outline-none transition-all"
        v-model="this.$props.formObjects.frequency"
        type="text"
        placeholder="Digite uma frequência"
      />
    </label>

    <label class="text-sm text-gray-500">
      Versão
      <input
        v-model="this.$props.formObjects.version"
        :title="this.$props.formObjects.version"
        disabled
        class="w-[350px] flex text-sm border-2 border-gray-300 h-12 pl-4 rounded mt-2 focus:ring-2 focus:ring-blue-500 outline-none transition-all disabled:opacity-70 disabled:cursor-not-allowed"
      />
    </label>
  </div>
  <div class="flex items-start gap-[20px]">
    <label class="text-sm text-gray-500">
      <div class="flex items-center justify-between">
        <span>Mês de Início*</span>
        <span class="text-xs">Janeiro/2023</span>
      </div>

      <input
        v-model="this.$props.formObjects.monthStart"
        class="w-[255px] flex text-sm border-2 border-gray-300 h-12 pl-4 rounded mt-2 focus:ring-2 focus:ring-blue-500 outline-none transition-all"
        type="month"
        placeholder="00/0000"
        :disabled="this.$props.formObjects.dateSelection.length > 0"
      />
    </label>

    <label class="text-sm text-gray-500">
      <div class="flex items-center justify-between">
        <span> Mês de Término*</span>
        <span class="text-xs">Dezembro/2023</span>
      </div>

      <input
        v-if="!isDuplicate"
        v-model="this.$props.formObjects.monthEnd"
        :disabled="
          formObjects.monthStart === '' ||
          this.$props.formObjects.dateSelection.length > 0
        "
        class="w-[255px] flex text-sm border-2 border-gray-300 h-12 pl-4 rounded mt-2 focus:ring-2 focus:ring-blue-500 outline-none transition-all disabled:opacity-80 disabled:cursor-not-allowed"
        type="month"
        placeholder="00/0000"
      />

      <input
        v-else
        v-model="this.$props.formObjects.monthEnd"
        :disabled="
          formObjects.monthStart === '' ||
          this.$props.formObjects.dateSelection.length > 0
        "
        class="w-[255px] flex text-sm border-2 border-gray-300 h-12 pl-4 rounded mt-2 focus:ring-2 focus:ring-blue-500 outline-none transition-all disabled:opacity-80 disabled:cursor-not-allowed"
        type="month"
        placeholder="00/0000"
        @blur="handleCalcBetweenMonths"
      />
    </label>

    <label class="flex-1 text-sm text-gray-500">
      <div class="flex items-center justify-between">
        <span>Observação</span>
        <span class="italic">Opcional</span>
      </div>

      <textarea
        v-model="this.$props.formObjects.observation"
        @change="handleMountCalendarVersion"
        class="w-full resize-y min-h-20 max-h-20 flex text-sm border-2 border-gray-300 h-12 px-4 py-2 rounded mt-2 focus:ring-2 focus:ring-blue-500 outline-none transition-all"
      ></textarea>
    </label>
  </div>
</template>
<script>
import Dropdown from 'primevue/dropdown';

export default {
  name: 'CalendarRegistrationForm',
  components: {
    Dropdown,
  },
  props: {
    units: Array,
    modalities: Array,
    calendarTypes: Array,
    formObjects: Object,
    handleCalcBetweenMonths: Function,
    isDuplicate: Boolean,
  },
  data() {
    return {};
  },

  methods: {
    handleMountCalendarVersion() {
      this.$props.formObjects.version = '';
      let version = '';

      const unit = this.units.find(
        (unit) => unit.id === this.$props.formObjects.unitId
      );

      const modality = this.modalities.find(
        (modality) => modality.id === this.$props.formObjects.modalityId
      );

      if (unit && unit.name) {
        version += unit.name;
      }

      if (modality && modality.name) {
        version += ' ' + modality.name;
      }

      if (this.formObjects.year) {
        version += ' ' + this.$props.formObjects.year;
      }

      if (this.formObjects.period) {
        version += ' ' + this.$props.formObjects.period;
      }

      const calendarType = this.calendarTypes.find(
        (calendarType) =>
          calendarType.id === this.$props.formObjects.calendarTypeId
      );

      if (calendarType && calendarType.name) {
        version += ' ' + calendarType.name;
      }

      if (this.formObjects.observation) {
        version += ' ' + this.$props.formObjects.observation;
      }

      if (this.isDuplicate) {
        version += ' ' + 'CUSTOMIZADO';
      }

      this.$props.formObjects.version = version;
    },
  },
};
</script>
