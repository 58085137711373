export default {
  SET_STEP(state, payload) {
    state.step = payload;
  },

  SET_NAME(state, payload) {
    state.name = payload;
  },

  SET_BRANCH(state, payload) {
    state.branch = payload;
  },

  SET_EMAIL(state, payload) {
    state.email = payload;
  },

  SET_ROOM_TYPE(state, payload) {
    state.roomType = payload;
  },

  SET_LOCAL_TYPE(state, payload) {
    state.localType = payload;
  },

  SET_UNIT_ID(state, payload) {
    state.unitId = payload;
  },

  SET_LOCAL_ID(state, payload) {
    state.localId = payload;
  },

  SET_DATE(state, payload) {
    state.date = payload;
  },

  SET_START_TIME(state, payload) {
    state.startTime = payload;
  },

  SET_FINAL_TIME(state, payload) {
    state.finalTime = payload;
  },

  SET_RECURRENT(state, payload) {
    state.recurrent = payload;
  },

  SET_COST_CENTER(state, payload) {
    state.costCenter = payload;
  },

  SET_MANAGER(state, payload) {
    state.manager = payload;
  },

  SET_BOARD(state, payload) {
    state.board = payload;
  },

  SET_LAYOUT_ID(state, payload) {
    state.layoutId = payload;
  },

  SET_ROOM_LAYOUT(state, payload) {
    state.roomLayout = payload;
  },

  SET_ROOM_ID(state, payload) {
    state.roomId = payload;
  },

  SET_EVENT_NAME(state, payload) {
    state.eventName = payload;
  },

  SET_EVENT_DESCRIPTION(state, payload) {
    state.eventDescription = payload;
  },

  SET_NUMBER_OF_PARTICIPANTS(state, payload) {
    state.numberOfParticipants = payload;
  },

  SET_EQUIPMENTS_DESCRIPTION(state, payload) {
    state.equipmentsDescription = payload;
  },

  SET_PREVIOUS_PREPARATION(state, payload) {
    state.previousPreparation = payload;
  },

  SET_PREPARATION_START_DAY(state, payload) {
    state.preparationStartDay = payload;
  },

  SET_PREPARATION_START_INITIAL(state, payload) {
    state.preparationStartInitial = payload;
  },

  SET_PREPARATION_START_FINAL(state, payload) {
    state.preparationStartFinal = payload;
  },

  SET_PREPARATION_END_DAY(state, payload) {
    state.preparationEndDay = payload;
  },

  SET_PREPARATION_END_INITIAL(state, payload) {
    state.preparationEndInitial = payload;
  },

  SET_PREPARATION_END_FINAL(state, payload) {
    state.preparationEndFinal = payload;
  },

  SET_LAYOUT_INFO_IMAGE(state, payload) {
    state.layoutInfoImage = payload;
  },

  SET_RECURRENT_SOLICITATION(state, payload) {
    state.recurrentSolicitation = payload;
  },

  SET_MOBILE_EQUIPMENTS(state, payload) {
    state.mobileEquipments.connect = payload;
  },

  SET_EQUIPMENTS(state, payload) {
    state.equipments = payload;
  },

  SET_TYPE_OF_TRANSMISSION(state, payload) {
    state.typeOfTransmission = payload;
  },

  SET_SOCIAL_NETWORK_TEXT(state, payload) {
    state.socialNetworkText = payload;
  },

  SET_TYPE_OF_LINK(state, payload) {
    state.typeOfLink = payload;
  },

  SET_ZOOM_RESOURCES(state, payload) {
    state.zoomResources = payload;
  },

  SET_ACCESSES_PREVISION(state, payload) {
    state.accessesPrevision = payload;
  },

  SET_TRANSMISSION_AUDIO_FILE(state, payload) {
    state.transmissionAudioFile = payload;
  },

  SET_TRANSMISSION_DESCRIPTION(state, payload) {
    state.transmissionDescription = payload;
  },

  SET_THUMBNAIL(state, payload) {
    state.thumbNail = payload;
  },

  SET_PROGRAMMING_FILE(state, payload) {
    state.programmingFile = payload;
  },

  SET_LOCALS(state, payload) {
    state.locals = payload;
  },

  SET_AUDITORIES(state, payload) {
    state.auditories = payload;
  },

  SET_IS_CHOOSE_AUDITORY(state, payload) {
    state.isChooseAuditory = payload;
  },

  SET_CHOSEN_AUDITORIUMS(state, payload) {
    state.chosenAuditoriums = payload;
  },

  SET_ROUTER(state, payload) {
    state.router = payload;
  },
};
