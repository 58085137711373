import api from '../lib/axios';

export class DynamicLyceumService {
  constructor(token) {
    this.token = token;
  }

  async getStatusMatrTurma(data){
    try {
      const response = await api.post('/dynamic-lyceum/status-matr-turma', data, {
        headers: {
          token: this.token,
        },
      });
      return response.data;
    } catch (error) {
      console.error('Error fetching dynamic lyceum status matr turma data:', error);
    }
  }
}
