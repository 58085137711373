<template>
  <Schema>
    <div
      class="w-full overflow-y-auto h-[calc(100vh-96px-160px)] pr-5"
      ref="scrollReserves"
    >
      <!-- Breadcrumb -->
      <div class="flex w-full mb-4 text-sm">
        <div class="mb-4 text-gray-400">
          <router-link to="/app" class="hover:text-blue-500 transition-colors">
            Home
          </router-link>
          <span class="text-blue-500"> • Reservas</span>
        </div>
      </div>

      <div class="flex items-center justify-between">
        <div class="flex flex-col">
          <h1 class="text-3xl text-gray-600">Gerenciamento de reservas</h1>
          <p class="text-gray-400 max-w-xs mt-1">
            Gerencie as reservas do Albert Einstein
          </p>
        </div>
        <router-link
          v-show="userInfo.role === 'Administrador'"
          to="/gerenciamento/reservas/reserva-direta"
          class="bg-blue-500 text-white px-5 text-sm flex items-center gap-2 py-2 rounded-full hover:brightness-90 transition-all"
        >
          <i class="ph ph-arrow-up-right"></i>
          Reserva direta
        </router-link>
      </div>

      <div class="mt-10 flex items-center justify-center">
        <Calendar inline v-on:date-select="handleFilterDateCalendar" />
      </div>

      <div v-show="!isLoading" class="flex items-center gap-4 mt-20">
        <button
          type="button"
          class="bg-red-500 text-white px-3 py-2 rounded-full hover:brightness-90 transition-all text-sm flex items-center gap-3"
          @click="handleResetFilter"
        >
          <i class="ph-light ph-x"></i>

          Limpar filtros
        </button>
        <div class="bg-gray-100 py-2 px-4 rounded-full border border-gray-200">
          <label class="text-sm text-gray-500 flex flex-col">
            <div class="toggle-wrapper">
              <div class="switch">
                <label class="flex items-center gap-2 select-none">
                  <input
                    v-model="isFilterMostRecent"
                    type="checkbox"
                    class="toggle-input"
                    @change="handleGetAllReservation(1)"
                  />
                  <div class="slider">
                    <button class="button mt-[3px]"></button>
                  </div>
                  Filtrar por mais recentes
                </label>
              </div>
            </div>
          </label>
        </div>
      </div>

      <div class="mt-4 w-[calc(100vw-22rem)] overflow-auto pb-10">
        <table class="w-full mt-4" v-if="reservation.length > 0">
          <thead>
            <tr class="border-b border-gray-300 bg-blue-500 text-sm">
              <td class="text-white rounded-tl-md py-4 px-4">
                Solicitação
                <i
                  class="pi pi-filter cursor-pointer text-white"
                  @click="toggle($event, 'id')"
                  v-if="filterId === ''"
                ></i>

                <i
                  class="pi pi-filter-fill cursor-pointer text-white"
                  @click="toggle($event, 'id')"
                  v-else
                ></i>

                <OverlayPanel ref="overlayPanelId">
                  <input
                    type="number"
                    class="h-10 border-2 border-gray-300 px-3 rounded-lg"
                    v-model="filterId"
                    placeholder="Nº da solicitação"
                    @keyup.enter="handleGetAllReservation(1)"
                  />
                  <div class="flex justify-between">
                    <button
                      v-on:click="
                        {
                          filterId = '';
                          handleGetAllReservation(1);
                        }
                      "
                      class="text-blue-500 px-5 text-sm flex items-center gap-2 py-2 mt-5 hover:brightness-90 transition-all"
                    >
                      Limpar
                    </button>
                    <button
                      @click="handleGetAllReservation(1)"
                      class="bg-blue-500 text-white px-5 text-sm flex items-center mt-5 gap-2 py-2 rounded-md hover:brightness-90 transition-all"
                    >
                      Aplicar
                    </button>
                  </div>
                </OverlayPanel>
              </td>
              <td class="text-white py-2 px-4">
                Solicitante
                <i
                  class="pi pi-filter cursor-pointer text-white"
                  @click="toggle($event, 'requester')"
                  v-if="filterRequester === ''"
                ></i>

                <i
                  class="pi pi-filter-fill cursor-pointer text-white"
                  @click="toggle($event, 'requester')"
                  v-else
                ></i>
                <OverlayPanel ref="overlayPanelRequester">
                  <input
                    type="text"
                    class="h-10 border-2 border-gray-300 px-3 rounded-lg"
                    v-model="filterRequester"
                    placeholder="Solicitante"
                    @keyup.enter="handleGetAllReservation(1)"
                  />
                  <div class="flex justify-between">
                    <button
                      v-on:click="
                        {
                          filterRequester = '';
                          handleGetAllReservation(1);
                        }
                      "
                      class="text-blue-500 px-5 text-sm flex items-center gap-2 py-2 mt-5 hover:brightness-90 transition-all"
                    >
                      Limpar
                    </button>
                    <button
                      @click="handleGetAllReservation(1)"
                      class="bg-blue-500 text-white px-5 text-sm flex items-center mt-5 gap-2 py-2 rounded-md hover:brightness-90 transition-all"
                    >
                      Aplicar
                    </button>
                  </div>
                </OverlayPanel>
              </td>
              <td class="text-white py-2 px-4">
                Data

                <i
                  class="pi pi-filter cursor-pointer"
                  @click="toggle($event, 'date')"
                  v-if="filterDate === ''"
                ></i>
                <i
                  class="pi pi-filter-fill cursor-pointer"
                  @click="toggle($event, 'date')"
                  v-else
                ></i>
                <OverlayPanel ref="overlayPanelDate">
                  <input
                    type="date"
                    class="h-10 border-2 border-gray-300 px-3 rounded-lg"
                    v-model="filterDate"
                    @keyup.enter="handleGetAllReservation(1)"
                  />
                  <div class="flex justify-between">
                    <button
                      v-on:click="
                        {
                          filterDate = '';
                          handleGetAllReservation(1);
                        }
                      "
                      class="text-blue-500 px-5 text-sm flex items-center gap-2 py-2 mt-5 hover:brightness-90 transition-all"
                    >
                      Limpar
                    </button>
                    <button
                      @click="handleGetAllReservation(1)"
                      class="bg-blue-500 text-white px-5 text-sm flex items-center mt-5 gap-2 py-2 rounded-md hover:brightness-90 transition-all"
                    >
                      Aplicar
                    </button>
                  </div>
                </OverlayPanel>
              </td>
              <td class="text-white py-2 px-4">
                Local
                <i
                  class="pi pi-filter cursor-pointer"
                  @click="toggle($event, 'local')"
                  v-if="filterLocal === '' && filterUnit === ''"
                ></i>
                <i
                  class="pi pi-filter-fill cursor-pointer"
                  @click="toggle($event, 'local')"
                  v-else
                ></i>
                <OverlayPanel ref="overlayPanelLocal">
                  <input
                    type="text"
                    class="w-[180px] h-10 border-2 border-gray-300 px-3 rounded-lg"
                    placeholder="Buscar por local"
                    v-model="filterLocal"
                    @keyup.enter="handleGetAllReservation(1)"
                  />
                  <p>Ou</p>
                  <p>
                    <input
                      type="text"
                      class="w-[180px] h-10 border-2 border-gray-300 px-3 rounded-lg"
                      placeholder="Buscar por unidade"
                      v-model="filterUnit"
                      @keyup.enter="handleGetAllReservation(1)"
                    />
                  </p>
                  <div class="flex justify-between">
                    <button
                      v-on:click="
                        {
                          filterLocal = '';
                          filterUnit = '';
                          handleGetAllReservation(1);
                        }
                      "
                      class="text-blue-500 px-5 text-sm flex items-center gap-2 py-2 mt-5 hover:brightness-90 transition-all"
                    >
                      Limpar
                    </button>
                    <button
                      @click="handleGetAllReservation(1)"
                      class="bg-blue-500 text-white px-5 text-sm flex items-center mt-5 gap-2 py-2 rounded-md hover:brightness-90 transition-all"
                    >
                      Aplicar
                    </button>
                  </div>
                </OverlayPanel>
              </td>
              <td class="text-white py-2 px-4">
                Sala
                <i
                  class="pi pi-filter cursor-pointer"
                  @click="toggle($event, 'room')"
                  v-if="filterRoom === ''"
                ></i>
                <i
                  class="pi pi-filter-fill cursor-pointer"
                  @click="toggle($event, 'room')"
                  v-else
                ></i>
                <OverlayPanel ref="overlayPanelRoom">
                  <input
                    type="text"
                    class="h-10 border-2 border-gray-300 px-3 rounded-lg"
                    placeholder="Buscar por sala"
                    v-model="filterRoom"
                    @keyup.enter="handleGetAllReservation(1)"
                  />
                  <div class="flex justify-between">
                    <button
                      v-on:click="
                        {
                          filterRoom = '';
                          handleGetAllReservation(1);
                        }
                      "
                      class="text-blue-500 px-5 text-sm flex items-center gap-2 py-2 mt-5 hover:brightness-90 transition-all"
                    >
                      Limpar
                    </button>
                    <button
                      @click="handleGetAllReservation(1)"
                      class="bg-blue-500 text-white px-5 text-sm flex items-center mt-5 gap-2 py-2 rounded-md hover:brightness-90 transition-all"
                    >
                      Aplicar
                    </button>
                  </div>
                </OverlayPanel>
              </td>
              <td class="text-white py-2 px-4">
                Nome do evento
                <i
                  class="pi pi-filter cursor-pointer"
                  @click="toggle($event, 'eventName')"
                  v-if="filterEventName === ''"
                ></i>
                <i
                  class="pi pi-filter-fill cursor-pointer"
                  @click="toggle($event, 'eventName')"
                  v-else
                ></i>
                <OverlayPanel ref="overlayPanelEventName">
                  <input
                    type="text"
                    class="w-[170px] h-10 border-2 border-gray-300 px-3 rounded-lg"
                    placeholder="Buscar por nome"
                    v-model="filterEventName"
                    @keyup.enter="handleGetAllReservation(1)"
                  />
                  <div class="flex justify-between">
                    <button
                      v-on:click="
                        {
                          filterEventName = '';
                          handleGetAllReservation(1);
                        }
                      "
                      class="text-blue-500 px-5 text-sm flex items-center gap-2 py-2 mt-5 hover:brightness-90 transition-all"
                    >
                      Limpar
                    </button>
                    <button
                      @click="handleGetAllReservation(1)"
                      class="bg-blue-500 text-white px-5 text-sm flex items-center mt-5 gap-2 py-2 rounded-md hover:brightness-90 transition-all"
                    >
                      Aplicar
                    </button>
                  </div>
                </OverlayPanel>
              </td>
              <td class="text-white py-2 px-4 whitespace-nowrap">
                Horário
                <i
                  class="pi pi-filter cursor-pointer"
                  @click="toggle($event, 'time')"
                  v-if="filterStartTime === '' && filterEndTime === ''"
                ></i>
                <i
                  class="pi pi-filter-fill cursor-pointer"
                  @click="toggle($event, 'time')"
                  v-else
                ></i>
                <OverlayPanel ref="overlayPanelTime">
                  <p>Ínicio</p>
                  <input
                    type="time"
                    class="h-10 border-2 border-gray-300 px-3 rounded-lg"
                    v-model="filterStartTime"
                    @keyup.enter="handleGetAllReservation(1)"
                  />
                  <p>Término</p>
                  <p>
                    <input
                      type="time"
                      class="h-10 border-2 border-gray-300 px-3 rounded-lg"
                      v-model="filterEndTime"
                      @keyup.enter="handleGetAllReservation(1)"
                    />
                  </p>

                  <div class="flex justify-between">
                    <button
                      v-on:click="
                        {
                          filterStartTime = '';
                          filterEndTime = '';
                          handleGetAllReservation(1);
                        }
                      "
                      class="text-blue-500 px-5 text-sm flex items-center gap-2 py-2 mt-5 hover:brightness-90 transition-all"
                    >
                      Limpar
                    </button>
                    <button
                      @click="handleGetAllReservation(1)"
                      class="bg-blue-500 text-white px-5 text-sm flex items-center mt-5 gap-2 py-2 rounded-md hover:brightness-90 transition-all"
                    >
                      Aplicar
                    </button>
                  </div>
                </OverlayPanel>
              </td>
              <td class="text-white py-2 px-4 w-10">
                Status
                <i
                  class="pi pi-filter cursor-pointer"
                  @click="toggle($event, 'status')"
                  v-if="filterStatus === ''"
                ></i>
                <i
                  class="pi pi-filter-fill cursor-pointer"
                  @click="toggle($event, 'status')"
                  v-else
                ></i>
                <OverlayPanel ref="overlayPanelStatus">
                  <input
                    type="text"
                    class="h-10 border-2 border-gray-300 px-3 rounded-lg"
                    placeholder="Buscar por status"
                    v-model="filterStatus"
                    @keyup.enter="handleGetAllReservation(1)"
                  />
                  <div class="flex justify-between">
                    <button
                      v-on:click="
                        {
                          filterStatus = '';
                          handleGetAllReservation(1);
                        }
                      "
                      class="text-blue-500 px-5 text-sm flex items-center gap-2 py-2 mt-5 hover:brightness-90 transition-all"
                    >
                      Limpar
                    </button>
                    <button
                      @click="handleGetAllReservation(1)"
                      class="bg-blue-500 text-white px-5 text-sm flex items-center mt-5 gap-2 py-2 rounded-md hover:brightness-90 transition-all"
                    >
                      Aplicar
                    </button>
                  </div>
                </OverlayPanel>
              </td>
              <td
                class="text-white rounded-tr-md py-2 px-4 whitespace-nowrap w-10"
              >
                Ações
              </td>
            </tr>
          </thead>

          <tbody>
            <tr
              v-for="(item, index) in reservation"
              :key="item.id"
              class="border-b border-gray-200 text-sm text-gray-500 cursor"
            >
              <td :title="item.id" :class="`py-2 px-4 cursor-pointer`">
                <div
                  class="group relative"
                  @click="handleOpenModalDetailSolicitation(item)"
                >
                  <span class="underline"> #{{ item.id }} </span>
                  <i
                    v-if="item.recurrent"
                    :style="{ backgroundColor: '#37CBCF' }"
                    class="ph ph-repeat p-1.5 ml-5 rounded-full text-white"
                    v-tooltip.bottom="'Evento recorrente'"
                  ></i>
                </div>
              </td>
              <td :class="`py-1 px-4`">
                {{ item.requesterEmail }}
              </td>
              <td :class="`py-1 px-4`">
                {{ formattedDate(item.date) }}
              </td>
              <td :class="`py-1 px-4`">
                {{ item.getLocalId?.name }}
                <p>{{ item.getUnitId.name }}</p>
              </td>
              <td :class="`py-1 px-4`">
                {{
                  item.getRoomId && item.getRoomId.name
                    ? item.getRoomId.name
                    : item.roomType === 'On-line'
                    ? 'On-line'
                    : '-'
                }}
                <span v-tooltip.bottom="getRoomsNames(item)">
                  {{ countOfRooms(item) }}
                </span>
              </td>
              <td :class="`py-1 px-4`">
                <span :title="item.eventName" v-if="item.eventName">
                  {{ item.eventName.slice(0, 33) }}
                  <span v-if="item.eventName.length > 33">...</span>
                </span>
              </td>
              <td :class="`py-1 px-4`">
                {{ item.startTime }} - {{ item.finalTime }}
              </td>
              <td :class="`py-1 px-4`">
                <div
                  @click="toggle($event, 'changeStatus', index, item)"
                  :style="
                    item.status === 'Pendente'
                      ? { color: '#cdb207' }
                      : item.status === 'Aprovada'
                      ? { color: '#43A047' }
                      : item.status === 'Cancelada'
                      ? { color: '#F44336' }
                      : item.status === 'Reprovada'
                      ? { color: '#F38A33' }
                      : item.status === 'Parcialmente aprovada'
                      ? { color: '#42A5F5' }
                      : ''
                  "
                  :class="`border-2 border-gray-300 h-10 rounded-lg w-[200px] text-md text-sm bold pl-2 pt-2 cursor-pointer font-bold`"
                >
                  {{ item.status }}
                  <OverlayPanel ref="overlayPanelChangeStatus">
                    <select
                      v-model="item.status"
                      class="border-2 border-gray-300 h-10 rounded-lg w-[200px] text-md text-sm bold pl-2"
                      @change="handleOpenDialog($event)"
                    >
                      <option
                        value="Pendente"
                        :disabled="userInfo.role === 'Básico'"
                      >
                        Pendente
                      </option>
                      <option
                        value="Aprovada"
                        :disabled="
                          userInfo.role === 'Básico' ||
                          (!item.roomId && item.roomType !== 'On-line')
                        "
                      >
                        Aprovada
                      </option>
                      <option value="Cancelada">Cancelada</option>
                      <option
                        value="Reprovada"
                        :disabled="
                          userInfo.role === 'Básico' ||
                          (!item.roomId && item.roomType !== 'On-line')
                        "
                      >
                        Reprovada
                      </option>
                      <option value="Parcialmente aprovada" disabled>
                        Parcialmente aprovada
                      </option>
                    </select>
                  </OverlayPanel>
                </div>
              </td>
              <td :class="`py-1 px-4 flex items-center justify-center`">
                <button
                  class="bg-blue-500 w-[30px] h-[30px] flex items-center justify-center text-white rounded-full hover:brightness-90 transition-all"
                  v-tooltip.bottom="'Editar solicitação'"
                  type="button"
                  @click="handleEditSolicitation(item.id)"
                >
                  <i class="ph ph-pencil-simple"></i>
                </button>
                <button
                  class="bg-blue-500 w-[30px] h-[30px] ml-2 flex items-center justify-center text-white rounded-full disabled:opacity-70 disabled:brightness-100 disabled:cursor-not-allowed hover:brightness-90 transition-all"
                  type="button"
                  v-tooltip.bottom="'Adicionar sala(s)'"
                  @click="handleOpenModalAddRooms(item)"
                  :disabled="handleBottomDisabled(item)"
                  v-show="
                    userInfo.role === 'Administrador' ||
                    userInfo.role === 'Técnico'
                  "
                >
                  <i class="ph ph-swap"></i>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
        <div
          v-else
          class="py-5 flex w-full px-4 border-b border-gray-200 text-sm text-gray-500"
        >
          <span class="">Não foi possível encontrar os dados...</span>
        </div>
      </div>

      <div
        class="flex justify-center items-center gap-3 pr-4 border-b border-t border-gray-300 py-3"
      >
        <Pagination
          :onPageChange="handleGetAllReservation"
          :currentPage="page"
          :registersPerPage="perPage"
          :totalCountOnRegisters="
            filterDate !== '' ||
            filterLocal !== '' ||
            filterSolicitante !== '' ||
            filterUnit !== '' ||
            filterEventType !== '' ||
            filterBranch !== '' ||
            filterEventName !== '' ||
            filterStartTime !== '' ||
            filterEndTime !== '' ||
            filterStatus !== ''
              ? totalFilter
              : totalRegisters
          "
        />

        <select
          v-model="perPage"
          @change="handleGetAllReservation(1)"
          class="bg-white border border-gray-300 px-2 py-2 text-sm text-gray-500 rounded-sm"
        >
          <option :value="2">2 por página</option>
          <option :value="4">4 por página</option>
          <option :value="10">10 por página</option>
          <option :value="20">20 por página</option>
          <option :value="30">30 por página</option>
          <option :value="40">40 por página</option>
          <option :value="50">50 por página</option>
        </select>
      </div>
    </div>

    <DialogDetailSolicitation
      :appointmentsData="appointmentsData"
      :isOpenModalDetailSolicitation="isOpenModalDetailSolicitation"
      :solicitationData="solicitationData"
      @handleCloseModalDetailSolicitation="handleCloseModalDetailSolicitation"
      @handleOpenModalRoomChange="handleOpenModalRoomChange"
      @handleChangeRoomConfirmation="handleChangeRoomConfirmation"
      :userInfo="userInfo"
      :roomType="roomType"
    />

    <DialogRoomChange
      :isOpenModalRoomChange="isOpenModalRoomChange"
      @handleCloseModalRoomChange="handleCloseModalRoomChange"
      :roomToChange="roomToChange"
      :units="units"
      @handleOpenModalRoomChangeConfirmation="
        handleOpenModalRoomChangeConfirmation
      "
      :formObjects="formObjects"
      :solicitationData="solicitationData"
    />

    <DialogRoomChangeConfirmation
      :isOpenModalRoomChangeConfirmation="isOpenModalRoomChangeConfirmation"
      @handleCloseModalRoomChangeConfirmation="
        handleCloseModalRoomChangeConfirmation
      "
      @handleChangeRoomConfirmation="handleChangeRoomConfirmation"
      :objRoomToChange="objRoomToChange"
      :userInfo="userInfo"
    />

    <ComposableRoomDialog
      :displayDialog="displayDialog"
      :composableRooms="composableRooms"
      @handleCloseDialog="handleCloseDialog"
      @handleConfirm="handleConfirm"
    />

    <DialogAddMultipleRooms
      :isOpenModalAddMultipleRooms="isOpenModalAddMultipleRooms"
      @handleCloseModalAddRooms="handleCloseModalAddRooms"
      :roomsAvailables="roomsAvailables"
      :solicitationData="solicitationData"
      :userInfo="userInfo"
      @updateRoomsAvailables="updateRoomsAvailables"
    />
  </Schema>
</template>

<script>
import { format } from 'date-fns';
import Calendar from 'primevue/calendar';
import OverlayPanel from 'primevue/overlaypanel';
import Cookies from 'vue-cookies';
import Pagination from '../../components/Pagination/index.vue';
import Schema from '../../components/Schema/index.vue';
import {
  useReservationService,
  useUnitService,
  useSolicitationService,
  useRoomsService,
  useAppointmentService,
  useHelperService,
} from '../../services';
import DialogDetailSolicitation from './components/DialogDetailSolicitation.vue';
import DialogRoomChange from './components/DialogRoomChange.vue';
import DialogRoomChangeConfirmation from './components/DialogRoomChangeConfirmation.vue';
import { messageSuccess } from '../../lib/toast';
import ComposableRoomDialog from '../../components/ComposableRoomDialog.vue';
import DialogAddMultipleRooms from './components/DialogAddMultipleRooms.vue';
import computed from './reserve-computed';

export default {
  name: 'ManagementReserveView',
  components: {
    Schema,
    Pagination,
    DialogDetailSolicitation,
    DialogRoomChangeConfirmation,
    DialogRoomChange,
    OverlayPanel,
    Calendar,
    ComposableRoomDialog,
    DialogAddMultipleRooms,
  },

  mounted() {
    setTimeout(() => {
      this.isLoading = true;
      this.handleGetAllReservation(1);
    }, 1000);
  },

  data() {
    return {
      reservation: [],
      reservationBackup: [],
      units: [],
      userInfo: Cookies.get('user_info'),
      reservationService: useReservationService(),
      unitService: useUnitService(),
      solicitationService: useSolicitationService(),
      roomsService: useRoomsService(),
      appointmentService: useAppointmentService(),
      helperService: useHelperService(),
      perPage: 10,
      page: 1,
      totalRegisters: 0,
      reservationCount: 0,
      totalFilter: 0,
      isFilterMostRecent: true,
      isOpenModalDetailSolicitation: false,
      isOpenModalRoomChange: false,
      isOpenModalRoomChangeConfirmation: false,
      isOpenModalAddMultipleRooms: false,
      solicitationData: null,
      appointmentsData: [],
      roomToChange: null,
      objRoomToChange: null,
      formObjects: {
        unitId: '',
        localId: '',
        roomId: '',
        roomLayoutId: '',
      },
      displayDialog: false,
      composableRooms: null,
      roomNames: [],
      roomType: null,
      roomsAvailables: [],
      roomsAvailablesBackup: [],
      isComposable: false,
      composablePairRoomId: null,
      optionComposableSelected: false,
      statusSelected: null,
    };
  },

  computed,

  methods: {
    updateRoomsAvailables(room, chosenRoomsLength = 0) {
      if (chosenRoomsLength === 1) {
        this.roomsAvailables = this.roomsAvailablesBackup;
      }

      if (room && room.composable && chosenRoomsLength > 1) {
        this.roomsAvailables = this.roomsAvailablesBackup.filter(
          (roomAvailable) => roomAvailable.id === room.composablePair
        );

        this.roomsAvailables.push(room);
      } else {
        if (room && !room.composable) {
          this.roomsAvailables = this.roomsAvailablesBackup.filter(
            (roomAvailable) => !roomAvailable.composable
          );
        } else {
          this.roomsAvailables = this.roomsAvailablesBackup;
        }
      }
    },

    handleBottomDisabled(item) {
      const roomOfSolicitation = item.getRoomId || {};
      const composablePairRoomId = roomOfSolicitation.composablePair || 0;

      const appointmentsOfRoomSolicitation = item.Appointments.filter(
        (appointment) => appointment.roomId === roomOfSolicitation.id
      );

      const appointmentsOfComposablePair = item.Appointments.filter(
        (appointment) => appointment.roomId === composablePairRoomId
      );

      if (
        roomOfSolicitation.composable &&
        appointmentsOfRoomSolicitation.length ===
          appointmentsOfComposablePair.length
      ) {
        return true;
      }

      if (item.status === 'Aprovada') {
        return true;
      }

      if (item.roomType === 'On-line') {
        return true;
      }

      return false;
    },

    handleOpenModalAddRooms(item) {
      this.roomsAvailables = [];
      this.solicitationData = item;

      const date = item.date;

      this.isLoading = true;

      this.appointmentService
        .getAppointmentsDateForward({ date: date })
        .then(async (appointments) => {
          this.isOpenModalAddMultipleRooms = true;

          item.getLocalId.Rooms.filter(item => item.active === true).forEach((room) => {
            const payload = {
              roomId: room.id,
              date: date,
              startTime: item.startTime,
              finalTime: item.finalTime,
            };

            const isBlocked = this.helperService.checkAvailableRooms(
              payload,
              appointments
            );

            if (!isBlocked) {
              this.roomsAvailables.push(room);
            }
          });

          this.isLoading = false;

          this.roomsAvailables.sort((a, b) =>
            a.name.localeCompare(b.name, undefined, { sensitivity: 'base' })
          );

          const roomOfSolicitation = item.getRoomId || {};

          if (roomOfSolicitation.composable) {
            this.roomsAvailables = this.roomsAvailables.filter(
              (room) => room.id === roomOfSolicitation.composablePair
            );
          }

          this.roomsAvailablesBackup = this.roomsAvailables;
        });
    },

    handleCloseModalAddRooms(isReload) {
      this.isOpenModalAddMultipleRooms = false;
      if (isReload) {
        this.handleGetAllReservation(1);
      }
    },

    handleCloseDialog() {
      this.displayDialog = false;
      this.isComposable = true;
      this.optionComposableSelected = false;
      this.updateStatusSolicitation();
    },

    async handleOpenDialog(event) {
      this.isComposable = false;
      this.composablePairRoomId = null;
      this.optionComposableSelected = false;

      const statusSelected = event.target.value.toString();

      this.statusSelected = statusSelected;

      if (statusSelected === 'Aprovada') {
        if (this.solicitationData.roomId) {
          const result = await this.roomsService.getRoomById(
            this.solicitationData.roomId
          );

          if (result && result.composable) {
            this.composableRooms = {
              room1: result.name,
              room2: result.roomComposable.name,
            };

            this.composablePairRoomId = result.composablePair;

            this.displayDialog = true;
          } else {
            await this.updateStatusSolicitation();
          }
        } else {
          await this.updateStatusSolicitation();
        }
      } else {
        this.updateStatusSolicitation();
      }
    },

    handleConfirm() {
      this.displayDialog = false;
      this.isComposable = true;
      this.optionComposableSelected = true;
      this.updateStatusSolicitation();
    },

    async updateStatusSolicitation() {
      try {
        this.isLoading = true;

        const payload = {
          solicitation: this.solicitationData.id,
          status: this.statusSelected,
          updatedBy: this.userInfo.id,
          unitId: this.solicitationData.unitId,
          recurrent: this.solicitationData.recurrent,
          roomId: this.solicitationData.roomId,
        };

        const dataUpdateAppointment = {
          solicitationId: this.solicitationData.id,
          roomType: this.solicitationData.roomType,
          status: this.solicitationData.status,
          localId: this.solicitationData.localId,
          approvedBy: Number(this.userInfo.id),
          isComposable: this.isComposable,
          composablePairRoomId: this.composablePairRoomId,
          appointments: this.solicitationData.Appointments,
          optionComposableSelected: this.optionComposableSelected,
        };

        await this.appointmentService.updateStatus(dataUpdateAppointment);

        await this.solicitationService.updateStatusSolicitation(payload);
        this.handleGetAllReservation(1);
        messageSuccess('Status atualizado com sucesso!');
      } catch (error) {
        console.log(error);
      } finally {
        this.isLoading = false;
      }
    },

    handleOpenModalRoomChange(item) {
      this.units = [];
      this.formObjects = {
        unitId: '',
        localId: '',
        roomId: '',
        roomLayoutId: '',
      };
      this.roomToChange = item.getRoom;
      this.roomToChange.appointmentId = item.id;
      this.roomToChange.solicitationId = this.solicitationData.id;
      this.roomToChange.isRecurrent = this.solicitationData.recurrent;
      this.roomToChange.updatedBy = this.userInfo.id;
      this.roomToChange.startTime = item.startTime;
      this.roomToChange.finalTime = item.finalTime;
      this.roomToChange.date = item.date;
      this.getUnits();
      this.isOpenModalRoomChange = true;
    },

    handleOpenModalRoomChangeConfirmation(objRoomToChange) {
      this.isOpenModalRoomChangeConfirmation = true;
      this.objRoomToChange = objRoomToChange;
    },

    handleCloseModalDetailSolicitation() {
      this.isOpenModalDetailSolicitation = false;
    },

    handleCloseModalRoomChange() {
      this.isOpenModalRoomChange = false;
    },

    handleCloseModalRoomChangeConfirmation() {
      this.isOpenModalRoomChangeConfirmation = false;
    },

    handleChangeRoomConfirmation() {
      this.isOpenModalRoomChangeConfirmation = false;
      this.isOpenModalRoomChange = false;
      this.isOpenModalDetailSolicitation = false;
      this.handleGetAllReservation(1);
    },

    toggle(event, target, index, item) {
      this.solicitationData = item;

      if (target === 'id') {
        this.$refs.overlayPanelId.toggle(event);
      } else if (target === 'date') {
        this.$refs.overlayPanelDate.toggle(event);
      } else if (target === 'local') {
        this.$refs.overlayPanelLocal.toggle(event);
      } else if (target === 'status') {
        this.$refs.overlayPanelStatus.toggle(event);
      } else if (target === 'room') {
        this.$refs.overlayPanelRoom.toggle(event);
      } else if (target === 'eventName') {
        this.$refs.overlayPanelEventName.toggle(event);
      } else if (target === 'time') {
        this.$refs.overlayPanelTime.toggle(event);
      } else if (target === 'changeStatus') {
        this.$refs.overlayPanelChangeStatus[index].toggle(event);
      } else if (target === 'requester') {
        this.$refs.overlayPanelRequester.toggle(event);
      }
    },

    handleEditSolicitation(id) {
      this.$router.push(`/gerenciamento/reservas/${id}`);
    },

    handleOpenModalDetailSolicitation(item) {
      this.appointmentsData = item.Appointments.sort(
        (a, b) => new Date(a.date) - new Date(b.date)
      );

      this.appointmentsData = this.appointmentsData.map((appointment) => {
        return {
          ...appointment,
          dateFormatted: this.formattedDate(appointment.date),
        };
      });

      this.solicitationData = item;
      this.roomType = item.roomType;
      this.isOpenModalDetailSolicitation = true;
    },

    handleResetFilter() {
      this.filterId = '';
      this.filterDate = '';
      this.filterLocal = '';
      this.filterRequester = '';
      this.filterUnit = '';
      this.filterStatus = '';
      this.filterEventType = '';
      this.filterBranch = '';
      this.filterEventName = '';
      this.filterStartTime = '';
      this.filterEndTime = '';
      this.filterRoom = '';

      this.handleGetAllReservation(1);
    },

    formattedDate(date) {
      return format(new Date(date + ' 00:00:00'), 'dd/MM/yyyy');
    },

    handleFilterDateCalendar(date) {
      this.filterDate = format(new Date(date), 'yyyy-MM-dd');
      this.handleGetAllReservation(1);
    },

    async getUnits() {
      this.isLoading = true;
      this.units = await this.unitService.getUnits().finally(() => {
        this.isLoading = false;
      });
    },

    sortFunction(a, b) {
      a = a.id;
      b = b.id;

      return b - a;
    },

    async handleGetAllReservation(page) {
      try {
        this.page = page;
        this.isLoading = true;

        const result = await this.reservationService.getAllByUserId(
          this.userInfo.id,
          this.page,
          this.perPage,
          this.filterId,
          this.filterDate,
          this.filterLocal.trim(),
          this.filterRequester.trim(),
          this.filterUnit.trim(),
          this.filterStatus.trim(),
          this.filterEventType.trim(),
          this.filterBranch.trim(),
          this.filterEventName.trim(),
          this.filterStartTime.trim(),
          this.filterEndTime.trim(),
          this.filterRoom.trim(),
          this.isFilterMostRecent
        );

        this.reservation = result.solicitations.sort(this.sortFunction);
        this.reservationBackup = result.solicitations.sort(this.sortFunction);
        this.totalRegisters = result.total;
        this.totalFilter = result.totalFilter;
        this.solicitationData = {};

        this.$refs.scrollReserves.scrollIntoView({
          block: 'start',
        });
      } catch (error) {
        console.log(error);
      } finally {
        this.isLoading = false;
      }
    },

    countOfRooms(item) {
      // this.roomNames = [];
      const roomList = [];

      if (item.Appointments) {
        item.Appointments.forEach((appointment) => {
          if (appointment.getRoom && item.roomId !== appointment.getRoom.id) {
            roomList.push(appointment.getRoom);
          }
        });
      }
      // this.roomNames.push({ [item.id]: { rooms: roomList } });
      return `${roomList.length > 0 ? '+' + roomList.length : ''}`;
    },

    getRoomsNames(item) {
      const newList = this.roomNames.find((room) => room[item.id]);

      return `${
        newList && newList[item.id].rooms
          ? newList[item.id].rooms.map((room) => room.name).join(', ')
          : ''
      }`;
    },
  },
};
</script>
