<template>
  <div
    title="Clique para ver o histórico"
    class="group cursor-help relative bg-blue-500 w-[25px] h-[25px] flex items-center justify-center text-white rounded-full hover:brightness-90 transition-all mr-2"
  >
    <i class="ph ph-clock-counter-clockwise"></i>

    <div
      class="group-hover:scale-100 origin-top-right transition-all scale-0 absolute right-10 top-0 bg-gray-700 z-40 truncate text-left rounded-lg p-4"
    >
      <h1 class="text-white text-lg">Histórico</h1>

      <div class="flex flex-col gap-4 mt-2">
        <div
          v-for="(item, index) in history"
          :key="item"
          class="flex items-start"
        >
          <div class="mr-5 flex items-center gap-2">
            <i class="ph ph-arrow-up-right"></i> {{ index + 1 }}
          </div>

          <div class="">
            <a
              href=""
              class="underline underline-of-set-4 hover:opacity-80 transition-all"
              >{{ item.calendarName }}</a
            >
            <p>{{ item.description }} {{ item.user.name }}</p>
          </div>
        </div>
      </div>

      <div class="w-3 h-3 bg-gray-500 rotate-45 absolute -right-1 top-2"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HistoryButtonComponent',
  props: {
    history: Array,
  },
};
</script>
