export const schedulesMock = [
  {
    weekDayIndex: 0,
    dayOfWeek: 'Segunda-Feira',
    scheduleStart: '00:00',
    scheduleEnd: '00:00',
    active: true,
    disabledButton: false,
    intervalStart1: '',
    intervalEnd1: '',
    intervalStart2: '',
    intervalEnd2: '',
  },
  {
    weekDayIndex: 1,
    dayOfWeek: 'Terça-Feira',
    scheduleStart: '00:00',
    scheduleEnd: '00:00',
    active: true,
    disabledButton: false,
    intervalStart1: '',
    intervalEnd1: '',
    intervalStart2: '',
    intervalEnd2: '',
  },
  {
    weekDayIndex: 2,
    dayOfWeek: 'Quarta-Feira',
    scheduleStart: '00:00',
    scheduleEnd: '00:00',
    active: true,
    disabledButton: false,
    intervalStart1: '',
    intervalEnd1: '',
    intervalStart2: '',
    intervalEnd2: '',
  },
  {
    weekDayIndex: 3,
    dayOfWeek: 'Quinta-Feira',
    scheduleStart: '00:00',
    scheduleEnd: '00:00',
    active: true,
    disabledButton: false,
    intervalStart1: '',
    intervalEnd1: '',
    intervalStart2: '',
    intervalEnd2: '',
  },
  {
    weekDayIndex: 4,
    dayOfWeek: 'Sexta-Feira',
    scheduleStart: '00:00',
    scheduleEnd: '00:00',
    active: true,
    disabledButton: false,
    intervalStart1: '',
    intervalEnd1: '',
    intervalStart2: '',
    intervalEnd2: '',
  },
  {
    weekDayIndex: 5,
    dayOfWeek: 'Sábado',
    scheduleStart: '00:00',
    scheduleEnd: '00:00',
    active: true,
    disabledButton: false,
    intervalStart1: '',
    intervalEnd1: '',
    intervalStart2: '',
    intervalEnd2: '',
  },
  {
    weekDayIndex: 6,
    dayOfWeek: 'Domingo',
    scheduleStart: '00:00',
    scheduleEnd: '00:00',
    active: true,
    disabledButton: false,
    intervalStart1: '',
    intervalEnd1: '',
    intervalStart2: '',
    intervalEnd2: '',
  },
];
