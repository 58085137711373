<template>
  <div class="relative group">
    <router-link :to="url">
      <i
        :class="`${icon} text-2xl w-10 h-10 rounded-lg bg-gray-100 text-gray-400 flex items-center justify-center hover:bg-blue-500 hover:text-white transition-all`"
      ></i>
    </router-link>

    <div
      class="absolute z-50 w-auto min-w-max shadow-md group-hover:scale-100 left-14 top-1/2 -translate-y-1/2 text-white bg-gray-500 rounded-lg p-2 text-sm transition-all duration-200 scale-0 origin-left"
    >
      {{ text }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'TooltipItem',

  props: {
    text: String,
    icon: String,
    url: String,
  },
};
</script>
