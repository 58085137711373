<template>
  <div class="mt-6 bg-gray-100 p-4 rounded-lg">
    <h1 class="text-gray-500 text-lg">Solicitação recorrente</h1>

    <div class="mt-5">
      <h1 class="text-blue-500">Padrão de recorrência</h1>

      <div class="flex items-center gap-5 mt-3">
        <RadioButton
          v-model="this.$props.recurrentSolicitationDataForm.defaultRecurrent"
          name="diario"
          value="Diário"
          @click="handleClick"
        />
        <label for="ingredient1" class="text-sm opacity-75">Diário</label>

        <RadioButton
          v-model="this.$props.recurrentSolicitationDataForm.defaultRecurrent"
          name="semanal"
          value="Semanal"
          @click="handleClick"
        />
        <label for="ingredient1" class="text-sm opacity-75">Semanal</label>

        <!-- <RadioButton
          v-model="this.$props.recurrentSolicitationDataForm.defaultRecurrent"
          inputId="ingredient1"
          name="mensal"
          value="Mensal"
        />
        <label for="ingredient1" class="text-sm opacity-75">Mensal</label>

        <RadioButton
          v-model="this.$props.recurrentSolicitationDataForm.defaultRecurrent"
          inputId="ingredient1"
          name="anual"
          value="Anual"
        />
        <label for="ingredient1" class="text-sm opacity-75">Anual</label> -->
      </div>
    </div>

    <div class="mt-5 border-t border-gray-300 pt-5">
      <h1 class="text-blue-500 text-sm">
        A cada
        <input
          type="number"
          v-model="this.$props.recurrentSolicitationDataForm.repeatWeek"
          class="w-20 h-10 rounded-lg px-2 mx-2 outline-none"
        />
      </h1>

      <div class="flex flex-col gap-2 mt-3">
        <label class="flex items-center gap-3 text-sm">
          <input
            class="w-5 h-5"
            type="checkbox"
            v-model="this.$props.recurrentSolicitationDataForm.toggleSunday"
            :disabled="this.$props.recurrentSolicitationDataForm.activeSunday"
            :value="this.$props.recurrentSolicitationDataForm.valueSunday"
          />
          Domingo
        </label>

        <label class="flex items-center gap-3 text-sm">
          <input
            class="w-5 h-5"
            type="checkbox"
            v-model="this.$props.recurrentSolicitationDataForm.toggleMonday"
            :disabled="this.$props.recurrentSolicitationDataForm.activeMonday"
            :value="this.$props.recurrentSolicitationDataForm.valueMonday"
          />
          Segunda-feira
        </label>

        <label class="flex items-center gap-3 text-sm">
          <input
            class="w-5 h-5"
            type="checkbox"
            :disabled="this.$props.recurrentSolicitationDataForm.activeTuesday"
            v-model="this.$props.recurrentSolicitationDataForm.toggleTuesday"
            :value="this.$props.recurrentSolicitationDataForm.valueTuesday"
          />
          Terça-feira
        </label>

        <label class="flex items-center gap-3 text-sm">
          <input
            class="w-5 h-5"
            type="checkbox"
            v-model="this.$props.recurrentSolicitationDataForm.toggleWednesday"
            :disabled="
              this.$props.recurrentSolicitationDataForm.activeWednesday
            "
            :value="this.$props.recurrentSolicitationDataForm.valueWednesday"
          />
          Quarta-feira
        </label>

        <label class="flex items-center gap-3 text-sm">
          <input
            class="w-5 h-5"
            type="checkbox"
            v-model="this.$props.recurrentSolicitationDataForm.toggleThursday"
            :disabled="this.$props.recurrentSolicitationDataForm.activeThursday"
            :value="this.$props.recurrentSolicitationDataForm.valueThursday"
          />
          Quinta-feira
        </label>

        <label class="flex items-center gap-3 text-sm">
          <input
            class="w-5 h-5"
            type="checkbox"
            v-model="this.$props.recurrentSolicitationDataForm.toggleFriday"
            :disabled="this.$props.recurrentSolicitationDataForm.activeFriday"
            :value="this.$props.recurrentSolicitationDataForm.valueFriday"
          />
          Sexta-feira
        </label>

        <label class="flex items-center gap-3 text-sm">
          <input
            class="w-5 h-5"
            type="checkbox"
            v-model="this.$props.recurrentSolicitationDataForm.toggleSaturday"
            :disabled="this.$props.recurrentSolicitationDataForm.activeSaturday"
            :value="this.$props.recurrentSolicitationDataForm.valueSaturday"
          />
          Sábado
        </label>
      </div>
    </div>

    <div class="bg-blue-500 mt-5 p-4 rounded-lg">
      <div class="flex items-center gap-6">
        <div class="flex-1 flex flex-col gap-2">
          <label class="flex flex-col gap-3 text-gray-200">
            Termina em:
            <Calendar
              v-model="this.$props.recurrentSolicitationDataForm.endDate"
              showIcon
              class="border-2 border-gray-300 h-12 rounded-lg w-full text-md mt-2 pl-4 bg-white"
              dateFormat="dd/mm/yy"
              :manualInput="false"
            />
          </label>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import RadioButton from 'primevue/radiobutton';
import Calendar from 'primevue/calendar';

export default {
  name: 'RecurrentSolicitation',
  components: {
    RadioButton,
    Calendar,
  },

  props: {
    recurrentSolicitationDataForm: Object,
  },

  methods: {
    handleClick() {
      setTimeout(() => {
        this.$emit('getNameDayOfWeekByDate');
      }, 500);
    },
  },
};
</script>
