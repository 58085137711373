<template>
  <div className="flex items-center gap-4 text-gray-600/80">
    <button @click="onPageChange(startPage)" v-show="previosPages.length > 0">
      <i class="ph-light ph-caret-double-left"></i>
    </button>

    <div class="flex items-center gap-4">
      <div v-for="page in previosPages" :key="page">
        <button @click="onPageChange(page)">
          <i class="ph-light ph-caret-left"></i>
        </button>
      </div>
    </div>

    <pagination-item
      :isCurrent="true"
      :number="currentPage"
      :onPageChange="onPageChange"
      >{{ currentPage }} de {{ lastPage === 0 ? 1 : lastPage }}</pagination-item
    >

    <div class="flex items-center gap-4">
      <div v-for="page in nextPages" :key="page">
        <button @click="onPageChange(page)">
          <i class="ph-light ph-caret-right"></i>
        </button>
      </div>
    </div>

    <button @click="onPageChange(lastPage)" v-show="nextPages.length > 0">
      <i class="ph-light ph-caret-double-right"></i>
    </button>
  </div>
</template>

<script>
import PaginationItem from './PaginationItem.vue';

export default {
  name: 'PaginationComponent',

  components: {
    PaginationItem,
  },

  props: {
    totalCountOnRegisters: Number,
    registersPerPage: Number,
    currentPage: Number,
    onPageChange: Function,
  },

  data() {
    return {
      startPage: 1,
      siblingsCount: 1,
      lastPage: 0,
      previosPages: [],
      nextPages: [],
    };
  },

  watch: {
    currentPage(currentPage) {
      const lastPageResult = Math.ceil(
        this.totalCountOnRegisters / this.registersPerPage
      );
      this.lastPage = lastPageResult;

      if (this.currentPage > 1) {
        const result = this.generatePagesArray(
          this.currentPage - 1 - this.siblingsCount,
          this.currentPage - 1
        );

        this.previosPages = result;
      } else {
        this.previosPages = [];
      }

      if (this.currentPage < this.lastPage) {
        const result = this.generatePagesArray(
          this.currentPage,
          Math.min(this.currentPage + this.siblingsCount, this.lastPage)
        );

        this.nextPages = result;
      } else {
        this.nextPages = [];
      }
    },

    totalCountOnRegisters(totalCountOnRegisters) {
      const lastPageResult = Math.ceil(
        this.totalCountOnRegisters / this.registersPerPage
      );
      this.lastPage = lastPageResult;

      if (this.currentPage > 1) {
        const result = this.generatePagesArray(
          this.currentPage - 1 - this.siblingsCount,
          this.currentPage - 1
        );

        this.previosPages = result;
      } else {
        this.previosPages = [];
      }

      if (this.currentPage < this.lastPage) {
        const result = this.generatePagesArray(
          this.currentPage,
          Math.min(this.currentPage + this.siblingsCount, this.lastPage)
        );

        this.nextPages = result;
      } else {
        this.nextPages = [];
      }
    },

    registersPerPage(registersPerPage) {
      const lastPageResult = Math.ceil(
        this.totalCountOnRegisters / this.registersPerPage
      );

      this.lastPage = lastPageResult;

      if (this.currentPage > 1) {
        const result = this.generatePagesArray(
          this.currentPage - 1 - this.siblingsCount,
          this.currentPage - 1
        );

        this.previosPages = result;
      } else {
        this.previosPages = [];
      }

      if (this.currentPage < this.lastPage) {
        const result = this.generatePagesArray(
          this.currentPage,
          Math.min(this.currentPage + this.siblingsCount, this.lastPage)
        );

        this.nextPages = result;
      } else {
        this.nextPages = [];
      }
    },
  },

  mounted() {
    const lastPageResult = Math.ceil(
      this.totalCountOnRegisters / this.registersPerPage
    );
    this.lastPage = Number(lastPageResult);

    if (this.currentPage > 1) {
      const result = this.generatePagesArray(
        this.currentPage - 1 - this.siblingsCount,
        this.currentPage - 1
      );

      this.previosPages = result;
    } else {
      this.previosPages = [];
    }

    if (this.currentPage < this.lastPage) {
      const result = this.generatePagesArray(
        this.currentPage,
        Math.min(this.currentPage + this.siblingsCount, this.lastPage)
      );

      this.nextPages = result;
    } else {
      this.nextPages = [];
    }
  },

  methods: {
    // FUNC: Função para gerar as páginas de previos e next baseadas em from e to
    generatePagesArray(from, to) {
      return [...new Array(to - from)].map((_, index) => {
        return from + index + 1;
      });
    },
  },
};
</script>
