<template>
  <Schema>
    <form
      encytpe="multipart/formdata"
      method="post"
      class="w-full max-w-3xl"
      @submit.prevent="handleCreateNewLayoutRoom()"
    >
      <!-- Breadcrumb -->
      <div class="flex w-full mb-4 text-sm">
        <div class="mb-4 text-gray-400">
          <router-link to="/app" class="hover:text-blue-500 transition-colors">
            Home
          </router-link>
          <span> • Cadastrar</span>
          <span class="text-blue-500"> • Layouts de salas</span>
        </div>
      </div>

      <div class="flex flex-col">
        <h1 class="text-3xl text-gray-600">Cadastro de layouts de salas</h1>
        <p class="text-gray-400 max-w-xs mt-1">
          Cadastre os layouts de salas do Albert Einstein
        </p>
      </div>

      <!-- Layout name and active -->
      <section class="flex items-center gap-6 mt-14">
        <div class="flex-1">
          <label class="text-sm text-gray-500">
            Nome do layout

            <input
              required
              type="text"
              v-model="name"
              class="border-2 border-gray-300 h-12 rounded-lg w-full text-md mt-2 pl-4"
            />
          </label>
        </div>
      </section>

      <!-- Description -->
      <section class="mt-4">
        <label class="text-sm text-gray-500">
          Descrição

          <input
            type="text"
            v-model="description"
            class="border-2 border-gray-300 h-12 rounded-lg w-full text-md mt-2 pl-4"
          />
        </label>
      </section>

      <!-- File selected -->
      <section class="mt-4 flex">
        <label
          class="text-sm text-gray-500 flex w-full flex-col cursor-pointer"
        >
          Imagem do formato da sala

          <div
            class="w-full border-dashed border-2 border-gray-300 min-h-[120px] flex items-center justify-center rounded-lg mt-2 hover:border-blue-500 hover:text-blue-500 transition-colors"
          >
            Click para fazer o upload da imagem
          </div>

          <input hidden type="file" @change="newFileSelected" />
          <span v-show="selectedFileName !== ''" class="imagePicker">
            Imagem selecionada: {{ selectedFileName }}
          </span>
        </label>
      </section>

      <!-- Button save -->
      <section class="mt-6" v-show="profile">
        <button
          type="submit"
          :disabled="name === '' || isLoadingButton === true"
          class="bg-blue-500 py-4 text-white px-10 rounded-full flex items-center justify-center disabled:opacity-70 disabled:brightness-100 disabled:cursor-not-allowed disabled"
        >
          <SmallLoader v-if="isLoadingButton === true" />

          <span v-else>Salvar layout da sala</span>
        </button>
      </section>
    </form>

    <div
      v-if="isLoading === true"
      class="w-[50px] h-[50px] border-[6px] border-gray-200 border-t-blue-500 animate-spin rounded-full"
    ></div>

    <!-- Table -->
    <div v-else class="w-full mt-20" v-show="roomlayoutsdata.length > 0">
      <DataTable
        :value="roomlayoutsdata"
        :rowClass="rowClass"
        paginatorTemplate="FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink RowsPerPageDropdown"
        paginator
        :rows="15"
        :rowsPerPageOptions="[15, 20, 30, 50]"
        currentPageReportTemplate="{first} de {last} de {totalRecords}"
        :globalFilterFields="['name', 'description']"
        v-model:filters="filters"
      >
        <template #header>
          <span class="p-input-icon-left">
            <i class="pi pi-search pt-1" />
            <input
              v-model="filters['global'].value"
              placeholder="Pesquisa global"
              class="border-2 border-gray-300 p-3 pl-10 rounded-lg w-full text-sm mt-2"
            />
          </span>
        </template>
        <Column field="name" header="Layout"></Column>
        <Column field="description" header="Descrição">
          <template #body="{ data }">
            <span>{{
              data.description === '' ? 'Sem descrição' : data.description
            }}</span>
          </template></Column
        >

        <Column field="id" header="Editar" class="w-20">
          <template #body="{ data }">
            <button
              type="button"
              class="bg-blue-500 text-white px-4 py-1 rounded-full hover:brightness-90 transition-all"
              @click="handleOpenModal(data.id)"
            >
              Editar
            </button>
          </template>
        </Column>
      </DataTable>

      <ModalEditRoomLayouts
        :onCloseModal="handleCloseModal"
        :id="currentIdModal"
        :data="roomlayoutsDataByID"
        v-show="isOpenModal"
      />
    </div>
  </Schema>
</template>

<script>
import Schema from '../../components/Schema/index.vue';
import SmallLoader from '../../components/Loaders/SmallLoader.vue';
import TableRoomLayouts from '../../components/Tables/TableRoomLayouts.vue';

import ModalEditRoomLayouts from '../../components/Modal/ModalEditRoomLayouts.vue';

import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import { FilterMatchMode } from 'primevue/api';

import api from '../../lib/axios';
import { useToast } from 'vue-toastification';
import Cookies from 'vue-cookies';

export default {
  name: 'LayoutView',
  components: {
    TableRoomLayouts,
    Schema,
    SmallLoader,
    DataTable,
    Column,
    ModalEditRoomLayouts,
  },
  setup() {
    const toast = useToast();
    return { toast };
  },
  data() {
    return {
      name: '',
      description: '',
      selectedFile: null,
      selectedFileName: '',
      isLoadingButton: false,
      active: true,

      isOpenModal: false,
      currentIdModal: 0,
      roomlayoutsDataByID: {},

      filters: null,
    };
  },
  computed: {
    // Api call room layout
    roomlayoutsdata() {
      return this.$store.state.RoomLayouts.roomlayoutsdata;
    },

    // Api call loading
    isLoading() {
      return this.$store.state.RoomLayouts.isLoading;
    },

    profile() {
      return this.$store.state.Default.profile;
    },
  },
  mounted() {
    // Api call room layout
    this.$store.dispatch('RoomLayouts/getRoomLayoutByData', '/roomlayouts');
  },

  created() {
    this.initFilters();
  },
  methods: {
    initFilters() {
      this.filters = {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      };
    },

    rowClass(data) {
      return [{ 'disabled-row': data.active === false }];
    },

    handleOpenModal(id) {
      this.isOpenModal = true;
      this.currentIdModal = id;
      this.getRoomLayouts(id);
    },
    handleCloseModal() {
      this.isOpenModal = false;
    },
    getRoomLayouts(id) {
      const token = this.$cookies.get('token');

      api
        .get(`/roomlayouts/${id}`, {
          headers: {
            token: token,
          },
        })
        .then((res) => {
          this.roomlayoutsDataByID = res.data;
        });
    },

    newFileSelected(event) {
      this.selectedFile = event.target.files[0];
      this.selectedFileName = event.target.files[0].name;
    },

    // Function to create new Layout Room
    handleCreateNewLayoutRoom() {
      const formData = new FormData();

      formData.append('active', this.active);
      formData.append('deleted', false);
      formData.append('createdBy', 1);
      formData.append('updatedBy', 1);
      formData.append('name', this.name);
      formData.append('description', this.description);
      formData.append('file', this.selectedFile);

      this.isLoadingButton = true;
      const token = this.$cookies.get('token');
      api
        .post('/roomlayouts', formData, {
          headers: {
            token: token,
          },
        })
        .then((res) => {
          // Reset form
          this.name = '';
          this.description = '';
          this.active = true;

          this.isLoadingButton = false;
          this.$store.dispatch(
            'RoomLayouts/getRoomLayoutByData',
            '/roomlayouts'
          );
          this.toast.success(
            'Cadastro de layout de sala realizado com sucesso'
          );
        })
        .catch((err) => {
          if (err.response.status === 500) {
            this.toast.error(
              'Não é permitido criar dois layouts de sala com o mesmo nome'
            );
          }
          this.isLoadingButton = false;
          console.log(err);
        });
    },

    // Function to create new image
    handleUploadImage(id) {
      const token = Cookies.get('token');

      api
        .patch(
          `/roomlayouts/imageurl/${id}`,
          {
            image: this.selectedFile,
          },
          {
            headers: {
              'Content-Type': 'multipart/form-data',
              token: token,
            },
          }
        )
        .then(() => {
          this.selectedFileName = '';
          this.selectedFile = null;

          this.isLoadingButton = false;
          this.$store.dispatch(
            'RoomLayouts/getRoomLayoutByData',
            '/roomlayouts'
          );
          this.toast.success(
            'Cadastro de layout de sala realizado com sucesso'
          );
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style scoped lang="scss">
.controlShapeRoomFile {
  border: 2px dashed;
  border-color: var(--gray-200);
  height: 100px;
  border-radius: 0.25rem;
  display: flex;
  align-items: center;
  justify-content: space-between;

  position: relative !important;
  top: 0 !important;

  padding: 0 1.5rem;

  color: var(--gray-300);
  font-weight: 300;
  cursor: pointer;
  transition: border-color 0.2s;

  &:hover {
    border-color: var(--blue-500);
  }
}

.imagePicker {
  display: block;
  margin-top: 1rem;
  opacity: 0.7;
  font-size: 0.9rem;
}
</style>
