<template>
  <Dialog
    v-model:visible="this.$props.isOpenModalRoomChange"
    :style="{ width: '60vw' }"
    :modal="true"
    :showHeader="false"
  >
    <button
      @click="handleCloseModalRoomChange()"
      class="float-right bg-blue-500 text-white rounded-full py-2 w-9 h-9 mt-3 justify-self-end"
    >
      <i class="ph ph-x"></i>
    </button>
    <h1 class="border-b-2 mt-5 mb-3 text-blue-500 pb-5 text-xl">
      Trocar de sala
    </h1>
    <div class="bg-gray-100 rounded-md mt-5 mb-5 p-5">
      <div class="flex justify-between">
        <h1 class="text-2xl">Sala antiga: {{ roomToChange.name }}</h1>
      </div>
      <div class="flex justify-between">
        <h1><b>Unidade: </b> {{ roomToChange.getLocalId.name }}</h1>
      </div>
    </div>
    <div class="flex">
      <h1 class="text-gray-400 text-xl mb-2">
        Escolha a sala desejada para realizar a troca:
      </h1>
    </div>
    <div class="flex justify-between gap-2">
      <label class="flex-1 text-sm text-gray-500">
        <div class="flex items-center gap-2">Unidades</div>
        <Dropdown
          v-model="this.$props.formObjects.unitId"
          :options="units"
          optionLabel="name"
          placeholder="Selecione uma unidade"
          class="w-full mt-2 h-12"
          optionValue="id"
          @change="handleUnitChange"
          :disabled="!units.length"
        />
      </label>
      <label class="flex-1 text-sm text-gray-500">
        <div class="flex items-center gap-2">Locais</div>
        <Dropdown
          v-model="this.$props.formObjects.localId"
          :options="locals"
          optionLabel="name"
          placeholder="Selecione um local"
          class="w-full mt-2 h-12"
          optionValue="id"
          :disabled="!formObjects.unitId || !locals.length"
          @change="handleLocalChange"
        />
      </label>
    </div>
    <div class="flex justify-between gap-2">
      <label class="flex-1 text-sm text-gray-500">
        <div class="flex items-center gap-2">Salas</div>
        <Dropdown
          v-model="this.$props.formObjects.roomId"
          :options="rooms"
          optionLabel="name"
          placeholder="Selecione uma sala"
          class="w-full mt-2 h-12"
          optionValue="id"
          @change="handleRoomChange"
          :disabled="!formObjects.localId || !rooms.length"
        />
      </label>
      <label class="flex-1 text-sm text-gray-500">
        <div class="flex items-center gap-2">Layouts</div>
        <Dropdown
          v-model="this.$props.formObjects.roomLayoutId"
          :options="roomLayouts"
          optionLabel="name"
          placeholder="Selecione um layout"
          class="w-full mt-2 h-12"
          optionValue="id"
          :disabled="!formObjects.roomId || !roomLayouts.length"
        />
      </label>
    </div>
    <div class="flex items-center justify-end">
      <button
        @click="handleCloseModalRoomChange()"
        class="text-blue-500 px-10 text-xl flex items-center gap-2 py-3 mt-5 hover:brightness-90 transition-all border-2 border-blue-500 rounded-full"
      >
        Cancelar
      </button>
      <button
        @click="handleOpenModalRoomChangeConfirmation"
        :disabled="!formObjects.roomId || !formObjects.roomLayoutId"
        class="bg-blue-500 text-white px-10 text-xl flex items-center mt-5 gap-2 py-3 ml-2 rounded-full hover:brightness-90 transition-all border-2 border-blue-500 disabled:opacity-70 disabled:brightness-100 disabled:cursor-not-allowed disabled"
      >
        Trocar de sala
      </button>
    </div>
  </Dialog>
</template>
<script>
import Dialog from 'primevue/dialog';
import Dropdown from 'primevue/dropdown';
import {
  useLocalsService,
  useHelperService,
  useAppointmentService,
} from '../../../services';
import { messageWarning } from '../../../lib/toast';

export default {
  name: 'DialogRoomChange',

  components: {
    Dialog,
    Dropdown,
  },

  data() {
    return {
      locals: [],
      rooms: [],
      roomLayouts: [],
      localsService: useLocalsService(),
      helperService: useHelperService(),
      appointmentService: useAppointmentService(),
      appointments: [],
    };
  },

  props: {
    isOpenModalRoomChange: {
      type: Boolean,
      required: true,
    },

    roomToChange: {
      type: Object,
      required: true,
    },

    units: {
      type: Array,
      required: true,
    },

    formObjects: {
      type: Object,
      required: true,
    },

    solicitationData: {
      type: Object,
      required: true,
    },
  },

  computed: {
    isLoading: {
      set(value) {
        this.$store.commit('Default/CHANGE_ISLOADING', value);
      },
    },
  },

  methods: {
    handleCloseModalRoomChange() {
      this.$emit('handleCloseModalRoomChange');
      this.$props.formObjects = {
        unitId: '',
        localId: '',
        roomId: '',
        roomLayoutId: '',
      };
    },

    async handleUnitChange() {
      this.isLoading = true;

      if (this.formObjects.unitId) {
        this.appointmentService
          .getAppointmentsDateForward({ date: this.roomToChange.date })
          .then(async (appointments) => {
            this.appointments = appointments;

            const locals = await this.localsService.getLocals().finally(() => {
              this.isLoading = false;
            });

            this.locals = locals.filter(
              (local) => local.unitId === this.formObjects.unitId
            );
          });
      }
    },

    handleLocalChange() {
      const newRoomList = [];

      if (this.formObjects.localId) {
        const local = this.locals.find(
          (local) => local.id === this.formObjects.localId
        );

        this.rooms = local.Rooms.sort((a, b) =>
          a.name.localeCompare(b.name, undefined, { sensitivity: 'base' })
        );
      }

      if (!this.appointments.length) {
        messageWarning('Algo deu errado, tente novamente mais tarde.');
        return;
      }

      const getRoom = this.solicitationData.getRoomId;

      this.rooms.forEach((room) => {
        const payload = {
          roomId: room.id,
          date: this.roomToChange.date,
          startTime: this.roomToChange.startTime,
          finalTime: this.roomToChange.finalTime,
        };

        const isBlocked = this.helperService.checkAvailableRooms(
          payload,
          this.appointments
        );

        if (!isBlocked && !getRoom.composable && !room.composable) {
          newRoomList.push(room);
        }
      });

      this.rooms = newRoomList;
    },

    handleRoomChange() {
      if (this.formObjects.roomId) {
        const room = this.rooms.find(
          (room) => room.id === this.formObjects.roomId
        );

        this.roomLayouts = room.getLayouts;
      }
    },

    handleOpenModalRoomChangeConfirmation() {
      const objToChange = {
        appointmentId: this.roomToChange.appointmentId,
        oldRoomName: this.roomToChange.name,
        newRoomName: this.rooms.find(
          (room) => room.id === this.formObjects.roomId
        ).name,
        formObjects: this.formObjects,
        unitName: this.units.find((unit) => unit.id === this.formObjects.unitId)
          .name,
        layoutName: this.roomLayouts.find(
          (layout) => layout.id === this.formObjects.roomLayoutId
        ).name,
        solicitationId: this.roomToChange.solicitationId,
        isRecurrent: this.roomToChange.isRecurrent,
        roomLayoutName: this.roomLayouts.find(
          (layout) => layout.id === this.formObjects.roomLayoutId
        ).name,
        updatedBy: this.roomToChange.updatedBy,
        date: this.roomToChange.date,
        startTime: this.roomToChange.startTime,
        finalTime: this.roomToChange.finalTime,
      };
      this.$emit('handleOpenModalRoomChangeConfirmation', objToChange);
    },
  },
};
</script>
