<template>
  <div
    class="w-full h-screen absolute py-12 left-0 top-0 bg-modal-600 z-50 flex items-center justify-center backdrop-blur-sm"
  >
    <div
      class="bg-white w-[600px] h-auto max-h-full overflow-y-auto p-6 rounded-xl shadow-lg relative"
    >
      <button
        type="button"
        @click="onCloseModal"
        class="absolute right-2 top-2 text-white bg-blue-500 w-8 h-8 rounded-full flex items-center justify-center"
      >
        <i class="ph ph-x"></i>
      </button>

      <div class="text-2xl pb-5 text-blue-500 border-b border-gray-300">
        Editar Unidade
      </div>

      <form
        method="post"
        @submit.prevent="handleUpdatedUnity"
        class="w-full mt-10"
      >
        <label class="text-sm text-gray-500">
          Unidade

          <input
            type="text"
            v-model="data.name"
            class="border-2 border-gray-300 h-12 rounded-lg w-full text-md mt-2 pl-4"
          />
        </label>

        <label class="text-sm text-gray-500 flex flex-col mt-4">
          Quantidade de salas

          <input
            type="number"
            v-model="data.capacity"
            class="border-2 border-gray-300 h-12 rounded-lg w-full text-md mt-2 pl-4"
          />
        </label>

        <label class="text-sm text-gray-500 flex flex-col mt-4">
          Descrição

          <input
            type="text"
            v-model="data.description"
            class="border-2 border-gray-300 h-12 rounded-lg w-full text-md mt-2 pl-4"
          />
        </label>

        <label class="text-sm text-gray-500 flex flex-col mt-4">
          Admins responsáveis

          <div
            class="bg-gray-100 p-4 h-[200px] overflow-y-auto rounded-lg mt-4 flex flex-col gap-2"
          >
            <div
              v-for="item in data.administratorsId"
              :key="item.id"
              class="bg-blue-500 px-4 py-2 rounded-lg text-white flex items-center justify-between"
            >
              <span>{{ item.name }}</span>
            </div>
          </div>
        </label>

        <label class="text-sm text-gray-500 flex flex-col mt-4">
          Observação

          <textarea
            v-model="data.note"
            class="border-2 border-gray-300 min-h-[140px] resize-y rounded-lg w-full text-md mt-2 p-4"
          ></textarea>
        </label>

        <div class="mt-4">
          <label class="text-sm text-gray-500 flex flex-col mt-4">
            Ativo

            <div class="toggle-wrapper">
              <div class="switch">
                <label>
                  <input
                    v-model="data.active"
                    type="checkbox"
                    class="toggle-input"
                  />
                  <div class="slider">
                    <button class="button mt-[3px]"></button>
                  </div>
                </label>
              </div>
            </div>
          </label>
        </div>

        <button
          v-show="profile"
          type="submit"
          class="bg-blue-500 w-full text-white py-3 rounded-lg mt-10"
        >
          Salvar
        </button>
      </form>
    </div>
  </div>
</template>

<script>
import { useToast } from 'vue-toastification';
import api from '../../lib/axios';
import Cookies from 'vue-cookies';

export default {
  name: 'ModalUnitsItem',
  props: {
    onCloseModal: Function,
    id: Number,
    data: Object,
  },

  setup() {
    const toast = useToast();
    return { toast };
  },

  computed: {
    profile() {
      return this.$store.state.Default.profile;
    },
  },

  methods: {
    handleUpdatedUnity() {
      const token = this.$cookies.get('token');
      api
        .patch(
          `/units/${this.id}`,
          {
            name: this.data.name,
            description: this.data.description,
            capacity: this.data.capacity,
            active: this.data.active,
            note: this.data.note,
          },
          {
            headers: {
              token: token,
            },
          }
        )
        .then(() => {
          this.onCloseModal();
          this.$store.dispatch('Units/getUnitsByData', '/units');
        })
        .catch((err) => {
          this.toast.error(err.response.data.err);
          console.log(err);
          this.data.active = true;
        });
    },
  },
};
</script>
