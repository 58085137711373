<template lang="">
  <Dialog
    v-model:visible="this.$props.displayDialog"
    :style="{ width: '30vw' }"
    :modal="true"
    :closable="false"
    :showHeader="false"
  >
    <h1 class="border-b-2 mt-5 mb-3 text-blue-500 pb-5 text-xl">
      Salas componíveis
    </h1>
    <h1 class="mt-5 text-gray-500 pb-5 text-lg">
      Você está reservando a <b>{{ composableRooms.room1 }}</b
      >, que pode ser usada com a <b>{{ composableRooms.room2 }}</b
      >. Gostaria de reservar as duas salas?
    </h1>

    <div class="border-t-2 flex items-end justify-end mt-5">
      <button
        type="button"
        @click="handleCloseDialog()"
        class="mr-5 w-[150px] h-12 text-blue-500 border border-blue-500 rounded-full hover:brightness-90 transition-all text-lg disabled:cursor-not-allowed"
      >
        Não
      </button>
      <button
        @click="handleConfirm()"
        class="w-[150px] bg-blue-500 text-white rounded-full pt-2 pb-3 text-xl mt-5 float-right"
      >
        Sim
      </button>
    </div>
  </Dialog>
</template>
<script>
export default {
  name: 'ComposableRoomDialog',

  props: {
    displayDialog: {
      type: Boolean,
      required: true,
    },

    composableRooms: {
      type: Object,
      required: true,
    },
  },

  methods: {
    handleCloseDialog() {
      this.$emit('handleCloseDialog');
    },

    handleConfirm() {
      this.$emit('handleConfirm');
    },
  },
};
</script>
