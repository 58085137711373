import api from '../lib/axios';
import Cookies from 'vue-cookies';

export class AppointmentService {
  constructor() {
    this.token = Cookies.get('token');
  }

  async createAppointment(payload) {
    try {
      const response = await api.post('/appointments', payload, {
        headers: {
          token: this.token,
        },
      });
      return response.data;
    } catch (error) {
      console.error('Error creating appointment:', error);
    }
  }

  async getAppointments() {
    try {
      const response = await api.get('/appointments', {
        headers: {
          token: this.token,
        },
      });
      return response.data;
    } catch (error) {
      console.error('Error fetching appointments:', error);
    }
  }

  async getAppointmentsDateForward(date) {
    try {
      const response = await api.post('/appointments/date-forward', date, {
        headers: {
          token: this.token,
        },
      });
      return response.data;
    } catch (error) {
      console.error('Error fetching appointments:', error);
    }
  }

  async deleteAppointment(id) {
    try {
      const response = await api.delete(`/appointments/${id}`, {
        headers: {
          token: this.token,
        },
      });
      return response.data;
    } catch (error) {
      console.error('Error deleting appointment:', error);
    }
  }

  async getAllAppointmentsWithApproved(data) {
    try {
      const response = await api.post(`/appointments/approved`, data, {
        headers: {
          token: this.token,
        },
      });
      return response.data;
    } catch (error) {
      console.error('Error fetching appointments:', error);
    }
  }

  async updateAppointment(appointmentId, data) {
    try {
      const response = await api.put(`/appointments/${appointmentId}`, data, {
        headers: {
          token: this.token,
        },
      });
      return response.data;
    } catch (error) {
      console.error('Error updating appointment:', error);
    }
  }

  async checkIsBlocked(payload) {
    try {
      const response = await api.post(
        '/appointments/check-is-blocked',
        payload,
        {
          headers: {
            token: this.token,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error('Error checking is blocked:', error);
    }
  }

  async getByRoomIdDateStartTimeFinalTime(payload) {
    try {
      const response = await api.post(
        '/appointments/get-by-room-id-date-start-time-final-time',
        payload,
        {
          headers: {
            token: this.token,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error('Error fetching appointments:', error);
    }
  }

  async getDisponibility(payload) {
    try {
      const response = await api.post('/appointments/room/date', payload, {
        headers: {
          token: this.token,
        },
      });
      return response.data;
    } catch (error) {
      console.error('Error fetching disponibility:', error);
    }
  }

  async updateStatus(payload) {
    try {
      const response = await api.post(`/appointments/status`, payload, {
        headers: {
          token: this.token,
        },
      });
      return response.data;
    } catch (error) {
      console.error('Error updating status:', error);
    }
  }

  async getAppointmentsAdminScreen(payload) {
    try {
      const response = await api.post('/appointments/admin-screen', payload, {
        headers: {
          token: this.token,
        },
      });
      return response.data;
    } catch (error) {
      console.error('Error fetching appointments:', error);
    }
  }
}

export const useAppointmentService = () => new AppointmentService();
