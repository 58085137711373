export const workingTimesMock = [
  {
    weekDayIndex: 0,
    dayOfWeek: '',
    scheduleStart: '',
    scheduleEnd: '',
    disabled: true,
    intervals: '',
    intervalStart1: '',
    intervalEnd1: '',
    intervalStart2: '',
    intervalEnd2: '',
  },
  {
    weekDayIndex: 1,
    dayOfWeek: '',
    scheduleStart: '',
    scheduleEnd: '',
    disabled: true,
    intervals: '',
    intervalStart1: '',
    intervalEnd1: '',
    intervalStart2: '',
    intervalEnd2: '',
  },
  {
    weekDayIndex: 2,
    dayOfWeek: '',
    scheduleStart: '',
    scheduleEnd: '',
    disabled: true,
    intervals: '',
    intervalStart1: '',
    intervalEnd1: '',
    intervalStart2: '',
    intervalEnd2: '',
  },
  {
    weekDayIndex: 3,
    dayOfWeek: '',
    scheduleStart: '',
    scheduleEnd: '',
    disabled: true,
    intervals: '',
    intervalStart1: '',
    intervalEnd1: '',
    intervalStart2: '',
    intervalEnd2: '',
  },
  {
    weekDayIndex: 4,
    dayOfWeek: '',
    scheduleStart: '',
    scheduleEnd: '',
    disabled: true,
    intervals: '',
    intervalStart1: '',
    intervalEnd1: '',
    intervalStart2: '',
    intervalEnd2: '',
  },
  {
    weekDayIndex: 5,
    dayOfWeek: '',
    scheduleStart: '',
    scheduleEnd: '',
    disabled: true,
    intervals: '',
    intervalStart1: '',
    intervalEnd1: '',
    intervalStart2: '',
    intervalEnd2: '',
  },
  {
    weekDayIndex: 6,
    dayOfWeek: '',
    scheduleStart: '',
    scheduleEnd: '',
    disabled: true,
    intervals: '',
    intervalStart1: '',
    intervalEnd1: '',
    intervalStart2: '',
    intervalEnd2: '',
  },
];
