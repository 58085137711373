export default {
  isLoading: {
    set(value) {
      this.$store.commit('Default/CHANGE_ISLOADING', value);
    },
  },

  roomType: {
    get() {
      return this.$store.state.SolicitationStore.roomType;
    },
  },

  eventName: {
    get() {
      return this.$store.state.SolicitationStore.eventName;
    },
    set(value) {
      this.$store.commit('SolicitationStore/SET_EVENT_NAME', value);
    },
  },

  eventDescription: {
    get() {
      return this.$store.state.SolicitationStore.eventDescription;
    },
    set(value) {
      this.$store.commit('SolicitationStore/SET_EVENT_DESCRIPTION', value);
    },
  },

  numberOfParticipants: {
    get() {
      return this.$store.state.SolicitationStore.numberOfParticipants;
    },
    set(value) {
      this.$store.commit('SolicitationStore/SET_NUMBER_OF_PARTICIPANTS', value);
    },
  },

  layoutInfoImage: {
    get() {
      return this.$store.state.SolicitationStore.layoutInfoImage;
    },
    set(value) {
      this.$store.commit('SolicitationStore/SET_LAYOUT_INFO_IMAGE', value);
    },
  },

  localId: {
    get() {
      return this.$store.state.SolicitationStore.localId;
    },
  },

  previousPreparation: {
    get() {
      return this.$store.state.SolicitationStore.previousPreparation;
    },
    set(value) {
      this.$store.commit('SolicitationStore/SET_PREVIOUS_PREPARATION', value);
    },
  },

  equipments: {
    get() {
      return this.$store.state.SolicitationStore.equipments;
    },
    set(value) {
      this.$store.commit('SolicitationStore/SET_EQUIPMENTS', value);
    },
  },

  mobileEquipments: {
    get() {
      return this.$store.state.SolicitationStore.mobileEquipments;
    },
    set(value) {
      this.$store.commit('SolicitationStore/SET_MOBILE_EQUIPMENTS', value);
    },
  },

  router: {
    set(value) {
      this.$store.commit('SolicitationStore/SET_ROUTER', value);
    },
  },

  localType: {
    get() {
      return this.$store.state.SolicitationStore.localType;
    },
  },

  typeOfLink: {
    get() {
      return this.$store.state.SolicitationStore.typeOfLink;
    },
  },

  zoomResources: {
    get() {
      return this.$store.state.SolicitationStore.zoomResources;
    },
  },

  accessesPrevision: {
    get() {
      return this.$store.state.SolicitationStore.accessesPrevision;
    },
  },

  preparationStartDay: {
    get() {
      return this.$store.state.SolicitationStore.preparationStartDay;
    },
    set(value) {
      this.$store.commit('SolicitationStore/SET_PREPARATION_START_DAY', value);
    },
  },

  preparationStartInitial: {
    get() {
      return this.$store.state.SolicitationStore.preparationStartInitial;
    },
    set(value) {
      this.$store.commit(
        'SolicitationStore/SET_PREPARATION_START_INITIAL',
        value
      );
    },
  },

  preparationStartFinal: {
    get() {
      return this.$store.state.SolicitationStore.preparationStartFinal;
    },
    set(value) {
      this.$store.commit(
        'SolicitationStore/SET_PREPARATION_START_FINAL',
        value
      );
    },
  },

  preparationEndDay: {
    get() {
      return this.$store.state.SolicitationStore.preparationEndDay;
    },
    set(value) {
      this.$store.commit('SolicitationStore/SET_PREPARATION_END_DAY', value);
    },
  },

  preparationEndInitial: {
    get() {
      return this.$store.state.SolicitationStore.preparationEndInitial;
    },
    set(value) {
      this.$store.commit(
        'SolicitationStore/SET_PREPARATION_END_INITIAL',
        value
      );
    },
  },

  preparationEndFinal: {
    get() {
      return this.$store.state.SolicitationStore.preparationEndFinal;
    },
    set(value) {
      this.$store.commit('SolicitationStore/SET_PREPARATION_END_FINAL', value);
    },
  },
};
