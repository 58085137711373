import { createStore } from 'vuex';

import Units from './parts/Units';
import api from '../lib/axios';
import Rooms from './parts/Rooms';
import Locations from './parts/Locations';
import Profile from './parts/Profile';
import Calendar from './parts/Calendar';
import RoomTypes from './parts/RoomTypes';
import Equipments from './parts/Equipments';
import RoomLayouts from './parts/RoomLayouts';
import Auth from './parts/Auth';
import SchedulingSimulation from './scheduling-simulation';
import ScheduledClasses from './scheduled-classes';
import ClassScheduleCalendar from './class-schedule-calendar';
import ReserveFilter from './reserve-filter';
import AdminScreenFilterStore from './admin-screen-filter';
import SolicitationStore from './solicitation';
import AdminScreen from './admin-screen';

import Cookies from 'vue-cookies';

const Default = {
  namespaced: true,
  state: {
    isActiveSubMenuRegister: false,
    isActiveSubMenuRequests: false,
    isActiveSubMenuRehearsal: false,
    isActiveOcurrence: false,
    isActiveRela: false,
    datastore: [],
    isLoadingButton: false,

    isActiveModalReload: false,

    isAuthenticated: false,

    profile: true,
    isLoading: false,
  },
  mutations: {
    CHANGE_DATA(state, payload) {
      state.datastore = payload;
    },
    
    CHANGE_LOADING(state, payload) {
      state.isLoadingButton = payload;
    },

    CHANGE_AUTHENTICATION(state) {
      state.isAuthenticated = !state.isAuthenticated;
    },

    CHANGE_PROFILE(state, payload) {
      state.profile = payload;
    },

    CHANGE_MODAL_RELOAD(state, payload) {
      state.isActiveModalReload = payload;
    },

    CHANGE_ISLOADING(state, payload) {
      state.isLoading = payload;
    },
  },
  actions: {
    handleAddNew({ commit }, payload) {
      const token = Cookies.get('token');

      api
        .post(payload.url, payload.data, {
          headers: {
            token: token,
          },
        })
        .then(() => {
          commit('CHANGE_LOADING', false);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getDataToPages({ commit }, payload) {
      const token = Cookies.get('token');

      api
        .get(payload, {
          headers: {
            token: token,
          },
        })
        .then((res) => {
          commit('CHANGE_DATA', res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};

export default createStore({
  modules: {
    Auth,
    Default,
    Units,
    Locations,
    Equipments,
    RoomLayouts,
    RoomTypes,
    Rooms,
    Profile,
    Calendar,
    SchedulingSimulation,
    ScheduledClasses,
    ClassScheduleCalendar,
    ReserveFilter,
    AdminScreenFilterStore,
    SolicitationStore,
    AdminScreen
  },
  // plugins: [createLogger()]
});
