import api from '../lib/axios';
import Cookies from 'vue-cookies';

class RoomLayoutsService {
  constructor() {
    this.token = Cookies.get('token');
  }

  async getLayoutById(layoutId) {
    try {
      const response = await api.get(`/roomlayouts/${layoutId}`, {
        headers: {
          token: this.token,
        },
      });
      return response.data;
    } catch (error) {
      console.error('Error fetching layout:', error);
    }
  }
}

export const useRoomLayoutsService = () => new RoomLayoutsService();
