<template>
  <div class="flex items-end gap-5">
    <h1 class="mt-5 mb-2 text-blue-500 text-lg font-medium">
      Configurar faixa de porcentagem de matrículas efetivadas
    </h1>
  </div>
  <table class="w-full items-center mt-5 border-b">
    <thead>
      <tr class="border-b border-b-gray-300">
        <th class="w-20 font-normal text-gray-500 text-left"></th>
        <th class="flex text-sm font-normal text-gray-500 max-w-4xl">
          <div class="w-[41%] text-left">Curso</div>
          <div class="w-[20%] text-left">Turma</div>
          <div class="w-[38%] text-left">Calendário</div>
        </th>
        <th></th>
        <th class="text-sm font-normal text-gray-500 text-left">Matrículas</th>
        <th class="text-sm font-normal text-gray-500 text-left">
          Faixa de porcentagem
        </th>
        <th></th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="(item, index) in selectedCourses" :key="item.id">
        <td class="text-left w-20">{{ index + 1 }}</td>
        <td class="flex text-left rounded bg-blue-500 text-white m-1 p-2 max-w-4xl">
          <div class="w-[41%] truncate" :title="item.courseName">
            {{ item.courseName }}
          </div>
          <div class="w-[20%] text-left truncate" :title="item.class">
            {{ item.class }}
          </div>
          <div
            class="w-[38%] text-left truncate"
            :title="item.classScheduleCalendar.version"
            v-if="item.classScheduleCalendar"
          >
            {{ item.classScheduleCalendar.version }}
          </div>
        </td>
        <td></td>
        <td class="text-left mt-5">
          <InputText v-model="item.enrollment" class="w-20 h-10" />
        </td>
        <td class="text-left w-36">
          <div class="p-inputgroup">
            <InputText
              class="h-10"
              v-model="item.percentage"
              v-on:update:modelValue="handleChangePercentage(item)"
            />
            <span class="p-inputgroup-addon h-10">%</span>
          </div>
        </td>
        <td class="text-left w-10">
          <button
            type="button"
            @click="handleRemoveCourseAndClass(item)"
            v-if="!isRehearse"
            class="mt-1 w-8 h-8 bg-red-500 text-white rounded-full hover:brightness-90 transition-all flex items-center justify-center text-lg disabled:cursor-not-allowed disabled:opacity-60"
          >
            <i class="ph ph-trash"></i>
          </button>
        </td>
      </tr>
    </tbody>
  </table>
</template>
<script>
import InputText from 'primevue/inputtext';

export default {
  name: 'ConfigureEnrollmentPercentage',
  components: {
    InputText,
  },

  data() {
    return {
      percentage: 0,
      enrollment: 0,
      enrollmentBackup: 0,
    };
  },

  computed: {
    selectedCourses: {
      get() {
        return this.$store.state.SchedulingSimulation.selectedCourses;
      },
      set(value) {
        this.$store.commit('SchedulingSimulation/SET_SELECTED_COURSES', value);
      },
    },

    isRehearse() {
      return this.$store.state.SchedulingSimulation.isRehearse;
    },
  },

  methods: {
    handleRemoveCourseAndClass(data) {
      this.$store.commit(
        'SchedulingSimulation/REMOVE_SELECTED_COURSES',
        data.class
      );
    },

    handleChangePercentage(item) {
      if (!item.enrollmentBackup) {
        item.enrollmentBackup = item.enrollment;
      }

      const enrollment = item.enrollmentBackup;
      const percentage = item.percentage;

      const resultPorcentage = (enrollment * percentage) / 100;

      let result = enrollment - resultPorcentage;

      result = Math.round(result);
      item.enrollment = result;
    },
  },
};
</script>
