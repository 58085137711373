import api from '../lib/axios';

export class SchedulingSimulationService {
  constructor(token) {
    this.token = token;
  }

  async getInitialData() {
    try {
      const response = await api.get('/scheduling-simulation/initial-data', {
        headers: {
          token: this.token,
        },
      });
      return response.data;
    } catch (error) {
      console.error('Error fetching scheduling simulation data:', error);
    }
  }

  async getModalitiesByYearsAndPeriods(years, periods) {
    try {
      const response = await api.post(
        '/scheduling-simulation/modalities',
        { years, periods },
        {
          headers: {
            token: this.token,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error(
        'Error fetching scheduling simulation modalities data:',
        error
      );
    }
  }

  async getStatusMatriculaTurma() {
    try {
      const response = await api.get(
        '/scheduling-simulation/status-matricula-turma',
        {
          headers: {
            token: this.token,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error(
        'Error fetching scheduling simulation status matricula turma data:',
        error
      );
    }
  }

  async getCoursesByCourseCodes(courseCodes) {
    try {
      const response = await api.post(
        '/scheduling-simulation/courses',
        { courseCodes },
        {
          headers: {
            token: this.token,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error(
        'Error fetching scheduling simulation courses data:',
        error
      );
    }
  }

  async getCoursesByYearsAndPeriods(years, periods) {
    try {
      const response = await api.post(
        '/scheduling-simulation/courses-by-years-and-periods',
        { years, periods },
        {
          headers: {
            token: this.token,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error(
        'Error fetching scheduling simulation courses data:',
        error
      );
    }
  }

  async getAvailableRooms(data) {
    try {
      const response = await api.post(
        '/scheduling-simulation/available-rooms',
        data,
        {
          headers: {
            token: this.token,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error(
        'Error fetching scheduling simulation available rooms data:',
        error
      );
    }
  }

  async getCourseUpdated(data) {
    try {
      const response = await api.post(
        '/scheduling-simulation/course-updated',
        data,
        {
          headers: {
            token: this.token,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error(
        'Error fetching scheduling simulation enrollment status class data:',
        error
      );
    }
  }
}
