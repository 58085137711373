<template>
  <Schema>
    <form
      method="post"
      class="w-full max-w-3xl"
      @submit.prevent="handleCreateNewEquipment"
    >
      <!-- Breadcrumb -->
      <div class="flex w-full mb-4 text-sm">
        <div class="mb-4 text-gray-400">
          <router-link to="/app" class="hover:text-blue-500 transition-colors">
            Home
          </router-link>
          <span> • Cadastrar</span>
          <span class="text-blue-500"> • Equipamentos</span>
        </div>
      </div>

      <div class="flex flex-col">
        <h1 class="text-3xl text-gray-600">Cadastro de equipamentos</h1>
        <p class="text-gray-400 max-w-xs mt-1">
          Cadastre os equipamentos do Albert Einstein
        </p>
      </div>

      <!-- Equipment type -->
      <section class="flex items-center gap-6 mt-14">
        <div class="flex-1">
          <label class="text-sm text-gray-500">
            Tipo de equipamento

            <select
              required
              v-model="type"
              @change="handleChangeEquipmentFixedOrMoved"
              class="border-2 border-gray-300 h-12 rounded-lg w-full text-md mt-2 pl-4"
            >
              <option value="" selected disabled>
                Selecione o tipo do equipamento
              </option>
              <option value="0">Equipamento fixo</option>
              <option value="1">Equipamento móvel</option>
            </select>
          </label>
        </div>
      </section>

      <!-- Local -->
      <section class="mt-4">
        <div class="flex-1">
          <label class="text-sm text-gray-500">
            Local

            <select
              required
              v-model="localId"
              class="border-2 border-gray-300 h-12 rounded-lg w-full text-md mt-2 pl-4"
            >
              <option value="" selected disabled>Selecione um local</option>
              <option
                v-for="item in locationsdata"
                :key="item.id"
                :value="item.id"
              >
                {{ item.name }}
              </option>
            </select>
          </label>
        </div>
      </section>

      <!-- Equiment name and patrimony and active -->
      <section
        class="flex items-center mt-4 gap-8"
        v-show="isFixedOrMoved !== null"
      >
        <div class="flex-1">
          <div v-if="isFixedOrMoved === false">
            <label class="text-sm text-gray-500">
              Equipamento fixo

              <input
                required
                type="text"
                v-model="name"
                class="border-2 border-gray-300 h-12 rounded-lg w-full text-md mt-2 pl-4"
              />
            </label>
          </div>

          <div v-else>
            <label class="text-sm text-gray-500">
              Equipamento móvel

              <input
                required
                type="text"
                v-model="name"
                class="border-2 border-gray-300 h-12 rounded-lg w-full text-md mt-2 pl-4"
              />
            </label>
          </div>
        </div>
        <div class="flex items-center gap-8">
          <div>
            <label class="text-sm text-gray-500">
              Número de patrimônio

              <input
                type="text"
                v-model="patrimony"
                class="border-2 border-gray-300 h-12 rounded-lg w-full text-md mt-2 pl-4"
              />
            </label>
          </div>
        </div>
      </section>

      <!-- Description -->
      <section class="mt-4">
        <label class="text-sm text-gray-500">
          Descrição

          <input
            type="text"
            v-model="description"
            class="border-2 border-gray-300 h-12 rounded-lg w-full text-md mt-2 pl-4"
          />
        </label>
      </section>

      <!-- Button save -->
      <section class="mt-6" v-show="profile">
        <button
          type="submit"
          :disabled="name === '' || localId === '' || isLoadingButton === true"
          class="bg-blue-500 py-4 text-white px-10 rounded-full flex items-center justify-center disabled:opacity-70 disabled:brightness-100 disabled:cursor-not-allowed disabled"
        >
          <SmallLoader v-if="isLoadingButton === true" />

          <span v-else>Salvar equipamento</span>
        </button>
      </section>
    </form>

    <div
      v-if="isLoading === true"
      class="w-[50px] h-[50px] border-[6px] border-gray-200 border-t-blue-500 animate-spin rounded-full"
    ></div>

    <!-- Table -->
    <div v-else class="w-full mt-20" v-show="equipmentsdata.length > 0">
      <DataTable
        :value="equipmentsdata"
        :rowClass="rowClass"
        paginatorTemplate="FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink RowsPerPageDropdown"
        paginator
        :rows="15"
        :rowsPerPageOptions="[15, 20, 30, 50]"
        currentPageReportTemplate="{first} de {last} de {totalRecords}"
        :globalFilterFields="[
          'type',
          'patrimony',
          'name',
          'description',
          'getLocalId.name',
        ]"
        v-model:filters="filters"
      >
        <template #header>
          <span class="p-input-icon-left">
            <i class="pi pi-search pt-1" />
            <input
              v-model="filters['global'].value"
              placeholder="Pesquisa global"
              class="border-2 border-gray-300 p-3 pl-10 rounded-lg w-full text-sm mt-2"
            />
          </span>
        </template>
        <Column field="type" header="Equipamento">
          <template #body="{ data }">
            <span>{{
              data.type === 0 ? 'Equipamento fixo' : 'Equipamento móvel'
            }}</span>
          </template>
        </Column>
        <Column field="patrimony" header="Patrimônio"></Column>
        <Column field="name" header="Nome"></Column>
        <Column field="description" header="Descrição">
          <template #body="{ data }">
            <span>{{
              data.description === '' ? 'Sem descrição' : data.description
            }}</span>
          </template>
        </Column>
        <Column field="getLocalId.name" header="Local"></Column>

        <Column field="id" header="Editar" class="w-20">
          <template #body="{ data }">
            <button
              type="button"
              class="bg-blue-500 text-white px-4 py-1 rounded-full hover:brightness-90 transition-all"
              @click="handleOpenModal(data.id)"
            >
              Editar
            </button>
          </template>
        </Column>
      </DataTable>

      <ModalEditEquipments
        v-show="isOpenModal"
        :onCloseModal="handleCloseModal"
        :id="currentIdModal"
        :data="equipmentsDataByID"
      />
    </div>
  </Schema>
</template>

<script>
import Schema from '../../components/Schema/index.vue';
import SmallLoader from '../../components/Loaders/SmallLoader.vue';
import TableEquipments from '../../components/Tables/TableEquipments.vue';

import ModalEditEquipments from '../../components/Modal/ModalEditEquipments.vue';

import api from '../../lib/axios';
import { useToast } from 'vue-toastification';

import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import { FilterMatchMode } from 'primevue/api';

export default {
  name: 'EquipamentsView',
  components: {
    Schema,
    TableEquipments,
    SmallLoader,
    DataTable,
    Column,
    ModalEditEquipments,
  },
  setup() {
    const toast = useToast();
    return { toast };
  },
  data() {
    return {
      active: true,
      name: '',
      description: '',
      type: 0,
      patrimony: '',
      localId: '',
      localName: '',
      isFixedOrMoved: false,
      isLoadingButton: false,

      filters: null,

      isOpenModal: false,
      currentIdModal: 0,
      equipmentsDataByID: {},
      localId: 0,
    };
  },
  computed: {
    // Api call equipments
    equipmentsdata() {
      return this.$store.state.Equipments.equipmentsdata;
    },

    // Api call locals
    locationsdata() {
      return this.$store.state.Locations.locationsdata;
    },

    // Api call loading
    isLoading() {
      return this.$store.state.Equipments.isLoading;
    },

    profile() {
      return this.$store.state.Default.profile;
    },
  },
  mounted() {
    // Api call equipments
    this.$store.dispatch('Equipments/getEquipmentsByData');

    // Api call locals
    this.$store.dispatch('Locations/getLocationsByData');
  },

  created() {
    this.initFilters();
  },

  methods: {
    initFilters() {
      this.filters = {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      };
    },

    rowClass(data) {
      return [{ 'disabled-row': data.active === false }];
    },

    handleOpenModal(id) {
      this.isOpenModal = true;
      this.currentIdModal = id;
      this.getEquipmentsById(id);
    },
    handleCloseModal() {
      this.isOpenModal = false;
    },
    getEquipmentsById(id) {
      const token = this.$cookies.get('token');

      api
        .get(`/equipments/${id}`, {
          headers: {
            token: token,
          },
        })
        .then((res) => {
          this.equipmentsDataByID = res.data;
        });
    },
    handleFormatData() {
      const test = this.data.map((item) => {
        return item;
      });
    },

    handleCreateNewEquipment() {
      const data = {
        active: this.active,
        createdBy: 1,
        updatedBy: 1,
        deleted: false,
        name: this.name,
        type: Number(this.type),
        description: this.description,
        patrimony: this.patrimony,
        localId: Number(this.localId),
      };

      this.isLoadingButton = true;
      const token = this.$cookies.get('token');

      api
        .post('/equipments', data, {
          headers: {
            token: String(token),
          },
        })
        .then(() => {
          // Reset form
          this.active = true;
          this.name = '';
          this.type = '';
          this.description = '';
          this.patrimony = '';
          this.localId = '';

          this.isLoadingButton = false;

          // Api call equipments
          this.$store.dispatch('Equipments/getEquipmentsByData');

          // Notification success
          this.toast.success('Cadastro de equipamentos realizado com sucesso');
        })
        .catch((err) => {
          if (err.response.status === 500) {
            this.toast.error(
              'Não é permitido criar dois equipamentos com o mesmo nome'
            );
          }

          this.isLoadingButton = false;
        });
    },
    handleChangeEquipmentFixedOrMoved() {
      this.isFixedOrMoved = !this.isFixedOrMoved;
    },
  },
};
</script>
