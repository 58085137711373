import { AdminFilterService } from '../../views/home/service/admin-filter.service';
import Cookies from 'vue-cookies';
import { format } from 'date-fns';

const AdminScreenFilterStore = {
  namespaced: true,
  state: {
    filterUnit: '',
    filterLocal: '',
    filterRoom: '',
    filterDates: [],
    units: [],
    locals: [],
    rooms: [],
    resultFilter: {
      units: {
        name: '',
        Locals: [],
      },
    },
    loading: false,
    activeAppointments: true,
  },

  mutations: {
    SET_FILTER_UNIT(state, payload) {
      state.filterUnit = payload;
    },

    SET_FILTER_LOCAL(state, payload) {
      state.filterLocal = payload;
    },

    SET_FILTER_ROOM(state, payload) {
      state.filterRoom = payload;
    },

    SET_FILTER_DATES(state, payload) {
      state.filterDates = payload;
    },

    SET_UNITS(state, payload) {
      state.units = payload;
    },

    SET_LOCALS(state, payload) {
      state.locals = payload;
    },

    SET_ROOMS(state, payload) {
      state.rooms = payload;
    },

    SET_RESULT_FILTER(state, payload) {
      state.resultFilter = payload;
    },

    SET_LOADING(state, payload) {
      state.loading = payload;
    },

    SET_ACTIVE_APPOINTMENTS(state, payload) {
      state.activeAppointments = payload;
    },
  },

  getters: {
    adminFilterService() {
      const token = Cookies.get('token');
      return new AdminFilterService(token);
    },
  },

  actions: {
    async getAllUnits({ commit, getters }) {
      await getters.adminFilterService.getAllUnits().then((response) => {
        commit('SET_UNITS', response);
      });
    },

    async getLocalsByUnit({ commit, getters, state }) {
      await getters.adminFilterService
        .getLocalsByUnitId(state.filterUnit)
        .then((response) => {
          commit('SET_LOCALS', response);
        });
    },

    async getRoomsByLocal({ commit, getters, state }) {
      await getters.adminFilterService
        .getRoomsByLocalId(state.filterLocal)
        .then((response) => {
          commit('SET_ROOMS', response);
        });
    },

    async filter({ commit, getters, state }) {
      state.loading = true;
      const startDate = state.filterDates[0]
        ? format(state.filterDates[0], 'yyyy-MM-dd')
        : 'null';
      let endDate = state.filterDates[1]
        ? format(state.filterDates[1], 'yyyy-MM-dd')
        : 'null';

      if (state.filterDates[0] && !state.filterDates[1]) {
        endDate = startDate;
      }

      const userInfo = Cookies.get('user_info');

      const params = {
        unit: state.filterUnit === '' ? 'null' : Number(state.filterUnit),
        local: state.filterLocal === '' ? 'null' : Number(state.filterLocal),
        room: state.filterRoom === '' ? 'null' : Number(state.filterRoom),
        startDate: startDate,
        endDate: endDate,
        active: state.activeAppointments ? 'enabled' : 'disabled',
        userId: userInfo.id
      };

      await getters.adminFilterService
        .filter(params)
        .then((response) => {
          commit('SET_RESULT_FILTER', response);
        })
        .finally(() => {
          state.loading = false;
        });
    },

    cleanData({ state }) {
      state.filterUnit = '';
      state.filterLocal = '';
      state.filterRoom = '';
      state.locals = [];
      state.rooms = [];
      state.filterDates = [];
      state.resultFilter = {
        units: {
          name: '',
          Locals: [],
        },
      };
    },
  },
};

export default AdminScreenFilterStore;
