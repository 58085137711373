import axios from 'axios';
import Cookies from 'vue-cookies';

// Configuração do axios para chamadas na api interna
const api = axios.create({
  baseURL: process.env.VUE_APP_API_CONNECT,
  headers: { token: Cookies.get('token') },
});

export default api;
