<template>
  <Schema>
    <div class="flex w-full h-full flex-col">
      <!-- Breadcrumb -->
      <div class="flex w-full text-sm">
        <div class="mb-4 text-gray-400">
          <router-link to="/app" class="hover:text-blue-500 transition-colors">
            Home
          </router-link>
          <span> • Ensalamento</span>
          <span class="text-blue-500"> • Cadastro de calendários</span>
        </div>
      </div>

      <!-- Title da página -->
      <div class="mt-[11px] border-b-2 border-gray-400 flex items-center gap-5">
        <button
          :class="`${
            pageCurrentSelect === 1
              ? 'text-blue-500 border-blue-500'
              : 'text-gray-500 border-gray-400'
          } py-4 px-5  font-medium text-sm uppercase border-b-2  relative top-[2px]`"
          type="button"
          @click="handleChangePageCurrentSelect(1)"
        >
          Calendários cadastrados
        </button>

        <button
          :class="`${
            pageCurrentSelect === 2
              ? 'text-blue-500 border-blue-500'
              : 'text-gray-500 border-gray-400'
          } py-4 px-5  font-medium text-sm uppercase border-b-2  relative top-[2px]`"
          type="button"
          @click="handleChangePageCurrentSelect(2)"
        >
          Cadastro de calendário
        </button>
      </div>

      <section class="mt-[30px]" v-show="pageCurrentSelect === 1">
        <div class="flex flex-col mt-[24px]">
          <h1 class="text-2xl text-gray-600">
            Lista de Calendários cadastrados
          </h1>
        </div>

        <div
          v-if="isLoadingCalendarsData"
          class="flex items-center justify-center mt-[35px]"
        >
          <div
            class="w-16 h-16 rounded-full flex border-2 border-t-blue-500 animate-spin"
          ></div>
        </div>

        <div v-else class="mt-10">
          <DataTable
            :value="calendarsData"
            paginatorTemplate="FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink RowsPerPageDropdown"
            paginator
            :rows="15"
            :rowsPerPageOptions="[15, 20, 30, 50]"
            currentPageReportTemplate="{first} de {last} de {totalRecords}"
            :globalFilterFields="[
              'version',
              'modality.name',
              'calendarType.name',
              'year',
            ]"
            v-model:filters="filters"
            class="table-fixed"
          >
            <template #header>
              <span class="p-input-icon-left">
                <i class="pi pi-search pt-1" />
                <input
                  v-model="filters['global'].value"
                  placeholder="Pesquisa global"
                  class="border-2 border-gray-300 p-3 pl-10 rounded-lg w-full text-sm mt-2"
                />
              </span>
            </template>
            <Column field="version" header="Nome"> </Column>
            <Column field="modality.name" header="Modalidade"> </Column>
            <Column
              field="calendarType.name"
              header="Tipo de Calendário"
            ></Column>
            <Column field="year" header="Ano"> </Column>
            <Column header="Dia da Semana">
              <template #body="{ data }">
                <div
                  class="w-40 truncate"
                  :title="
                    data.daysWeekSchedules
                      .map((schedule) => schedule.dayOfWeek)
                      .join(', ')
                  "
                >
                  {{
                    data.daysWeekSchedules
                      .map((schedule) => schedule.dayOfWeek)
                      .join(', ')
                  }}
                </div>
              </template>
            </Column>
            <Column header="Ações">
              <template #body="{ data }">
                <ConfirmPopup />
                <div class="flex w-full justify-end">
                  <HistoryButton :history="data.classScheduleCalendarHistory" />
                  <button
                    class="bg-red-500 w-[25px] h-[25px] flex items-center justify-center text-white rounded-full hover:brightness-90 transition-all mr-2"
                    title="Clique para excluir o calendário"
                    type="button"
                    @click="handleToggleModalErrorDelete($event, data.id)"
                  >
                    <i class="ph ph-trash"></i>
                  </button>
                  <Menu ref="menu" :model="items" :popup="true" />
                  <button
                    label="Toggle"
                    @click="toggleMenu($event, data)"
                    class="bg-white text-xl mr-2"
                  >
                    <i class="ph-bold ph-dots-three-vertical"></i>
                  </button>
                </div>
              </template>
            </Column>
          </DataTable>
        </div>

        <ModalError
          v-show="isToggleModalErrorDelete"
          :isActive="true"
          type="excluir"
          content="Calendário está atrelado em alguma sala, por isso não pode ser excluído"
          :onToggleModal="handleToggleModalErrorDelete"
        />
      </section>

      <section class="mt-[30px]" v-show="pageCurrentSelect === 2">
        <div class="flex flex-col mt-[24px]">
          <h1 class="text-3xl text-gray-600">Cadastro de calendários</h1>
          <p class="text-gray-400 mt-1">
            Configure as informações abaixo para cadastrar um novo calendário
          </p>
        </div>

        <form method="post" class="flex flex-col gap-[18px] mt-[30px]">
          <CalendarRegistrationForm
            :units="units"
            :modalities="modalities"
            :calendarTypes="calendarTypes"
            :formObjects="formObjects"
            :handleCalcBetweenMonths="handleCalcBetweenMonths"
            :isDuplicate="isDuplicate"
          />

          <span class="text-gray-500 text-sm">* Campos obrigatórios</span>

          <div class="bg-gray-200 rounded p-5">
            <div class="flex items-center justify-between">
              <h1 class="text-blue-500 text-xl font-normal">
                Dia da Semana e horário
              </h1>
              <button
                class="px-4 py-2 rounded-full border border-blue-500 text-blue-500 font-medium hover:bg-blue-500 hover:text-white transition-all"
                type="button"
                @click="handleToggleModalEditInterval"
                v-show="isUpdate"
              >
                Editar
              </button>
            </div>

            <div class="flex items-start mt-[12px] gap-[20px]">
              <div class="flex-1">
                <h2 class="text-gray-500">
                  Escolha o horário de funcionamento
                </h2>

                <table class="w-full mt-[18px]">
                  <thead class="border-b border-gray-400 text-sm text-gray-500">
                    <tr>
                      <td class="py-2 pr-0">Dia da semana</td>
                      <td class="py-2">Início</td>
                      <td class="py-2 px-7"></td>
                      <td class="py-2">Término</td>
                      <td></td>
                    </tr>
                  </thead>
                  <tbody class="text-[15px] text-gray-600">
                    <tr v-for="schedule in schedules" :key="schedule.id">
                      <td
                        :class="`py-3 ${
                          schedule.disabledButton && 'opacity-60'
                        }`"
                      >
                        {{ schedule.dayOfWeek }}
                      </td>
                      <td class="py-3">
                        <input
                          :class="`${
                            schedule.disabledButton &&
                            'opacity-60 border border-gray-400'
                          } px-3 w-full rounded h-10 focus:ring-2 focus:ring-blue-500 outline-none transition-all`"
                          type="time"
                          :disabled="schedule.disabledButton"
                          v-model="schedule.scheduleStart"
                        />
                      </td>
                      <td class="text-center">às</td>
                      <td class="py-3">
                        <input
                          :class="`${
                            schedule.disabledButton &&
                            'opacity-60 border border-gray-400'
                          } px-3 w-full rounded h-10 focus:ring-2 focus:ring-blue-500 outline-none transition-all`"
                          type="time"
                          :disabled="schedule.disabledButton"
                          v-model="schedule.scheduleEnd"
                        />
                      </td>
                      <td class="py-3 pl-4 w-5">
                        <button
                          type="button"
                          :disabled="schedule.disabledButton"
                          @click="handleAddNewWorkingTimeInArray(schedule)"
                          title="Clique para adicionar o horário"
                          class="bg-blue-500 rounded-full leading-0 w-7 flex items-center justify-center h-7 text-white hover:brightness-90 transition-all disabled:cursor-not-allowed disabled:bg-gray-500 disabled:hover:brightness-100"
                        >
                          <i class="ph ph-plus"></i>
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="flex-1 w-[50px]">
                <h2 class="text-gray-500">
                  Dias da Semana e horários selecionados
                </h2>

                <div class="flex flex-col gap-2">
                  <div
                    class="mt-[18px] py-2 px-1 border-b border-gray-400 text-sm text-gray-500 flex items-center"
                  >
                    <span class="flex flex-1 justify-center"
                      >Dia da semana</span
                    >
                    <span class="flex flex-1 justify-center">Horário</span>
                    <span class="flex flex-1 justify-center">Intervalo</span>
                    <span class="flex flex-1 justify-center" v-show="!isUpdate"
                      >Excluir</span
                    >
                  </div>

                  <div class="flex flex-col gap-[20px] mt-[5px]">
                    <div
                      v-for="item in workingTimes"
                      :key="item.weekDayIndex"
                      :class="`${
                        item.disabled ? 'bg-gray-300' : 'bg-blue-500'
                      } h-10 mb-1 rounded-lg flex items-center px-1 text-white text-sm`"
                    >
                      <span
                        class="flex flex-1 justify-center"
                        v-show="!item.disabled"
                        >{{ item.dayOfWeek }}</span
                      >

                      <span
                        class="flex flex-1 justify-center"
                        v-show="!item.disabled"
                        >{{ item.scheduleStart }} às
                        {{ item.scheduleEnd }}</span
                      >

                      <span
                        class="flex flex-1 justify-center truncate"
                        :title="item.intervals"
                        v-show="!item.disabled"
                        >{{
                          item.intervalStart1 !== null
                            ? `${item.intervalStart1} às ${item.intervalEnd1};`
                            : ''
                        }}

                        {{
                          item.intervalStart2 !== null
                            ? `${item.intervalStart2} às ${item.intervalEnd2};`
                            : ''
                        }}
                      </span>
                      <div
                        v-show="!item.disabled && !isUpdate"
                        class="flex flex-1 justify-center"
                      >
                        <button
                          type="button"
                          class="bg-red-500 rounded-full leading-0 w-7 flex items-center justify-center h-7 text-white hover:brightness-90 transition-all disabled:cursor-not-allowed disabled:bg-gray-500 disabled:hover:brightness-100"
                          @click="handleRemoveWorkingTime(item)"
                        >
                          <i class="ph ph-trash-simple"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div v-show="calendarsCreateWithMonths.length > 0" class="mt-[32px]">
            <h1 class="text-blue-500 text-xl">Seleção de Datas</h1>

            <h2 class="text-gray-500">
              Escolha os dias de aula presencial no calendário
            </h2>

            <section>
              <div
                class="mt-[25px] text-blue-500 flex items-center justify-between gap-[24px]"
              >
                <div class="flex"></div>
                <select class="" v-model="yearsSelect">
                  <option
                    v-for="item in yearsDataMounted"
                    :key="item"
                    :value="item"
                  >
                    {{ item }}
                  </option>
                </select>

                <span class="text-blue-500 font-bold italic flex"
                  >{{ formObjects.dateSelection.length }} selecionados</span
                >
              </div>

              <div class="grid grid-cols-3 gap-[46px] mt-[24px]">
                <CalendarSelect
                  v-for="calendar in calendarsCreateWithMonths"
                  :key="calendar"
                  v-show="calendar.year === yearsSelect"
                  :year="calendar.year"
                  :month="calendar.month"
                  :maxDate="calendar.maxDate"
                  :minDate="calendar.minDate"
                  :workingTimesDisabled="workingTimesDisabled"
                  :dateSelection="formObjects.dateSelection"
                  @setDateSelection="setDateSelection"
                  :callback="handleCallbackCalendarSelect"
                />
              </div>
            </section>
          </div>

          <ModalEditInterval
            :onToggleModalEditInterval="handleToggleModalEditInterval"
            :handleRemoveWorkingTime="handleRemoveWorkingTime"
            v-show="isToggleModalEditInterval"
            :workingTimes="workingTimes"
            :classCalendarApiService="classCalendarApiService"
          />
        </form>

        <div class="flex items-center justify-between mt-10">
          <button
            title="Clique para cancelar a criação"
            class="border border-blue-500 px-4 py-2 rounded-full text-blue-500 hover:bg-blue-500 hover:text-white transition-all"
            @click="handleCleanData(1)"
          >
            Cancelar
          </button>
          <button
            v-if="!loading"
            title="Clique para salvar o calendário"
            class="bg-blue-500 text-white px-4 py-2 rounded-full hover:brightness-90 transition-all disabled:cursor-not-allowed disabled:opacity-80 disabled:hover:brightness-100"
            type="button"
            :disabled="verifyFieldEmpty()"
            @click="handleSaveCalendarSettingsInAPI"
          >
            Salvar
          </button>
          <div
            v-if="loading"
            class="flex items-center justify-center mt-[35px]"
          >
            <p class="mr-5">{{ loadingTitle }}</p>
            <div
              class="w-11 h-11 rounded-full flex border-2 border-t-blue-500 animate-spin"
            ></div>
          </div>
        </div>
      </section>

      <ModalAddIntervalComponent
        :display="isModalInterval"
        :schedule="schedule"
        :callBack="handleCallBackModalAddInterval"
      />
    </div>
  </Schema>
</template>

<script>
import Schema from '../../components/Schema/index.vue';

import HistoryButton from '../../components/rehearsal/HistoryButton.vue';
import ModalEditInterval from '../../components/Modal/ModalEditInterval.vue';
import CalendarSelect from '../../components/rehearsal/CalendarSelect.vue';

import CalendarRegistrationForm from './components/calendar-registration-form.vue';
import ModalAddIntervalComponent from './components/modal-add-interval.vue';

import ModalError from '../../components/rehearsal/ModalError.vue';

import Menu from 'primevue/menu';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import { FilterMatchMode } from 'primevue/api';

import {
  differenceInCalendarMonths,
  addMonths,
  getYear,
  getMonth,
} from 'date-fns';
import { messageSuccess, messageError } from '../../lib/toast';
import api from '../../lib/axios';
import Cookies from 'vue-cookies';
import {
  schedulesMock,
  workingTimesMock,
  weekdaysMock,
  formObjectEmptyMock,
} from './mocks/index';
import { formObjects } from './utils/index';
import { ClassCalendarApiService } from './service/class-calendar.service';
import ConfirmPopup from 'primevue/confirmpopup';
import { useConfirm } from 'primevue/useconfirm';

export default {
  name: 'CalendarViewRehearsal',

  components: {
    Schema,
    HistoryButton,
    ModalEditInterval,
    ModalError,
    CalendarSelect,
    CalendarRegistrationForm,
    Menu,
    DataTable,
    Column,
    ModalAddIntervalComponent,
    ConfirmPopup,
  },

  data() {
    return {
      classCalendarApiService: null,
      schedules: JSON.parse(JSON.stringify(schedulesMock)),
      workingTimes: JSON.parse(JSON.stringify(workingTimesMock)),

      workingTimesDisabled: [1, 2, 3, 4, 5, 6, 7],
      calendarsCreateWithMonths: [],

      isToggleModalErrorEdit: false,
      isToggleModalErrorDelete: false,
      isToggleModalEditInterval: false,
      isModalInterval: false,

      formObjects: JSON.parse(JSON.stringify(formObjects)),

      dateSelection: [],

      units: [],
      modalities: [],

      calendarTypes: [],
      frequency: [],

      calendarsData: [],
      isLoadingCalendarsData: false,
      pageCurrentSelect: 1,

      yearsDataMounted: [],
      yearsSelect: null,

      token: null,
      userInfo: Cookies.get('user_info'),
      calendarItem: null,
      isUpdate: false,
      isDuplicate: false,
      items: [
        {
          label: 'Editar',
          icon: 'pi pi-pencil',
          command: () => {
            this.handleToggleEdit();
          },
        },
        {
          label: 'Duplicar',
          icon: 'pi pi-copy',
          command: () => {
            this.handleToggleData();
          },
        },
      ],
      filters: null,
      schedule: null,
      confirm: useConfirm(),
      loading: false,
      loadingTitle: '',
    };
  },

  mounted() {
    this.token = Cookies.get('token');
    this.classCalendarApiService = new ClassCalendarApiService(this.token);
    this.getInitialData();
    this.formObjects = formObjectEmptyMock;
  },

  created() {
    this.initFilters();
  },

  computed: {
    attrs: {
      get() {
        return this.$store.state.ClassScheduleCalendar.attrs;
      },

      set(value) {
        this.$store.commit('ClassScheduleCalendar/SET_ATTRS', value);
      },
    },
  },

  methods: {
    setDateSelection(dates) {
      this.formObjects.dateSelection = dates;
    },

    initFilters() {
      this.filters = {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      };
    },

    verifyFieldEmpty() {
      const {
        monthStart,
        monthEnd,
        dateSelection,
        unitId,
        modalityId,
        year,
        period,
        calendarTypeId,
        frequency,
      } = this.formObjects;
      return (
        !monthStart ||
        !monthEnd ||
        !unitId ||
        !modalityId ||
        !year ||
        !period ||
        !calendarTypeId ||
        !frequency ||
        (dateSelection && dateSelection.length === 0)
      );
    },

    toggleMenu(event, calendarItem) {
      this.calendarItem = calendarItem;
      this.$refs.menu.toggle(event);
    },

    async getInitialData() {
      try {
        this.isLoadingCalendarsData = true;

        const { units, modalities, calendarTypes, classScheduleCalendars } =
          await this.classCalendarApiService.getInitialData();

        this.units = units;
        this.calendarTypes = calendarTypes;
        this.modalities = modalities;
        this.calendarsData = classScheduleCalendars;
      } catch (error) {
        console.error('Error fetching initial datas:', error);
      } finally {
        this.isLoadingCalendarsData = false;
      }
    },

    // FUNC: Função para selecionar a página de listagem ou de criar calendário
    handleChangePageCurrentSelect(page) {
      this.pageCurrentSelect = page;
      this.handleCleanData(page);
    },

    // FUNC: Função para abrir ou fechar o modal de error ao deletar calendário
    async handleToggleModalErrorDelete(event, itemId) {
      this.confirm.require({
        target: event.currentTarget,
        message: 'Deseja remover esse calendário?',
        icon: 'pi pi-exclamation-triangle',
        acceptLabel: 'Sim',
        rejectLabel: 'Não',
        accept: async () => {
          await api.patch(
            `/class-schedule-calendar/deactivate/${itemId}`,
            {},
            {
              headers: {
                token: this.token,
              },
            }
          );
          messageSuccess('Calendário excluido com sucesso!');
          this.getInitialData();
        },
        reject: () => {},
      });
    },

    // FUNC: Função para abrir ou fechar o modal de editar intervalos
    handleToggleModalEditInterval() {
      this.isToggleModalEditInterval = !this.isToggleModalEditInterval;
    },

    // FUNC: Função para adicionar novos dias e horários
    handleAddNewWorkingTimeInArray(item) {
      if (
        this.formObjects.monthStart === '' ||
        this.formObjects.monthEnd === ''
      ) {
        messageError('Selecione o mês inicial e final');
        return;
      }

      if (item.scheduleStart === item.scheduleEnd) {
        messageError('Horário inicial e final não podem ser iguais');
        return;
      }

      const findSchedule = this.schedules.find(
        (schedule) => schedule.weekDayIndex === item.weekDayIndex
      );

      const dataSchedule = {
        ...findSchedule,
        intervalEnd1: '',
        intervalEnd2: '',
        intervalStart1: '',
        intervalStart2: '',
        disabledButton: true,
      };

      const daysOfWeek = {
        'Segunda-Feira': 2,
        'Terça-Feira': 3,
        'Quarta-Feira': 4,
        'Quinta-Feira': 5,
        'Sexta-Feira': 6,
        Sábado: 7,
        Domingo: 1,
      };

      const disabledWorkingTime = this.workingTimesDisabled.filter(
        (work) => work !== daysOfWeek[item.dayOfWeek]
      );

      this.workingTimesDisabled = disabledWorkingTime;

      this.workingTimes.splice(item.weekDayIndex, 1, item);
      this.schedules.splice(item.weekDayIndex, 1, dataSchedule);

      if (!item.id) {
        this.formObjects.daysWeekSchedules.push({
          weekDayIndex: item.weekDayIndex,
          dayOfWeek: item.dayOfWeek,
          scheduleStart: item.scheduleStart,
          scheduleEnd: item.scheduleEnd,
          active: item.active,
        });
      }

      this.handleCalcBetweenMonths();
    },

    // FUNC: Função para remover novos dias e horários
    async handleRemoveWorkingTime(data) {
      const daysOfWeek = {
        'Segunda-Feira': 2,
        'Terça-Feira': 3,
        'Quarta-Feira': 4,
        'Quinta-Feira': 5,
        'Sexta-Feira': 6,
        Sábado: 7,
        Domingo: 1,
      };

      this.formObjects.daysWeekSchedules =
        this.formObjects.daysWeekSchedules.filter(
          (item) => item.weekDayIndex !== data.weekDayIndex
        );

      const daysToDelete = this.formObjects.dateSelection.filter(
        (item) => item.weekDayIndex === data.weekDayIndex
      );

      daysToDelete.forEach((item) => {
        this.attrs = this.attrs.filter((attr) => attr.id !== item.date);
      });

      this.formObjects.dateSelection = this.formObjects.dateSelection.filter(
        (item) => item.weekDayIndex !== data.weekDayIndex
      );

      this.workingTimesDisabled = [
        ...this.workingTimesDisabled,
        daysOfWeek[data.dayOfWeek],
      ];

      const findSchedule = this.schedules.find(
        (schedule) => schedule.weekDayIndex === data.weekDayIndex
      );

      const dataSchedule = {
        ...findSchedule,
        intervalEnd1: '',
        intervalEnd2: '',
        intervalStart1: '',
        intervalStart2: '',
        scheduleEnd: '00:00',
        scheduleStart: '00:00',
        disabledButton: false,
      };

      const workingTimeRemove = {
        weekDayIndex: data.weekDayIndex,
        dayOfWeek: '',
        scheduleStart: '',
        scheduleEnd: '',
        disabled: true,
        intervals: '',
        intervalStart1: '',
        intervalEnd1: '',
        intervalStart2: '',
        intervalEnd2: '',
      };

      this.workingTimes.splice(data.weekDayIndex, 1, workingTimeRemove);
      this.schedules.splice(data.weekDayIndex, 1, dataSchedule);

      if (this.isUpdate) {
        await this.classCalendarApiService.deleteDaysWeekSchedules(data.id);
      }

      messageSuccess('Removido com sucesso');
    },

    // FUNC: Função para salvar todos os intervalos
    handleSaveIntervalsInWeekDay() {},

    // FUNC: Função para ver quantos meses tem e gerar os calendários
    handleCalcBetweenMonths() {
      this.calendarsCreateWithMonths = [];

      const result = differenceInCalendarMonths(
        new Date(this.formObjects.monthEnd + '-01'),
        new Date(this.formObjects.monthStart + '-01')
      );

      for (var i = 1; i <= result + 1; i++) {
        const addMonthsInResult = addMonths(
          new Date(this.formObjects.monthStart),
          i
        );

        const primaryDay = new Date(
          addMonthsInResult.getFullYear(),
          addMonthsInResult.getMonth(),
          1
        );

        const lastDay = new Date(
          addMonthsInResult.getFullYear(),
          addMonthsInResult.getMonth() + 1,
          0
        );

        const data = {
          month: String(getMonth(addMonthsInResult) + 1),
          year: String(getYear(addMonthsInResult)),
          date: addMonthsInResult,
          maxDate: lastDay.toDateString(),
          minDate: primaryDay.toDateString(),
        };

        this.calendarsCreateWithMonths.push(data);
      }

      // this.formObjects.year = this.calendarsCreateWithMonths[0].year;
      const yearsArray = this.calendarsCreateWithMonths.map((item) => {
        return item.year;
      });

      const setUnico = new Set(yearsArray);

      this.yearsDataMounted = [...setUnico];

      this.yearsSelect = this.yearsDataMounted[0];
    },

    // Função de duplicar
    handleToggleData() {
      this.formObjects = this.calendarItem;
      this.pageCurrentSelect = 2;
      this.isUpdate = false;
      this.isDuplicate = true;

      this.formObjects.version = this.formObjects.version + ' CUSTOMIZADO';

      this.schedules = this.formObjects.daysWeekSchedules.map((item) => {
        return {
          ...item,
          disabledButton: true,
        };
      });

      this.workingTimes = this.formObjects.daysWeekSchedules.map((item) => {
        return {
          ...item,
          disabledButton: true,
        };
      });

      const missingDays = weekdaysMock.filter(
        (day) =>
          !this.schedules.some(
            (schedule) => schedule.dayOfWeek === day.dayOfWeek
          )
      );

      this.schedules = [
        ...this.schedules,
        ...missingDays.map((day) => ({
          weekDayIndex: day.weekDayIndex,
          dayOfWeek: day.dayOfWeek,
          scheduleStart: '00:00',
          scheduleEnd: '00:00',
          active: true,
          disabledButton: false,
        })),
      ];

      this.schedules = this.schedules.sort((a, b) => {
        return a.weekDayIndex - b.weekDayIndex;
      });

      this.workingTimes = [
        ...this.workingTimes,
        ...missingDays.map((day) => ({
          weekDayIndex: day.weekDayIndex,
          disabled: true,
        })),
      ];

      this.workingTimes = this.workingTimes.sort((a, b) => {
        return a.weekDayIndex - b.weekDayIndex;
      });

      this.formObjects.daysWeekSchedules.forEach((item) => {
        this.handleAddNewWorkingTimeInArray(item);
      });
    },

    handleToggleEdit() {
      this.formObjects = this.calendarItem;
      this.pageCurrentSelect = 2;
      this.isUpdate = true;

      this.schedules = this.formObjects.daysWeekSchedules.map((item) => {
        return {
          ...item,
          disabledButton: true,
        };
      });

      this.workingTimes = this.formObjects.daysWeekSchedules.map((item) => {
        return {
          ...item,
          disabledButton: true,
        };
      });

      // Filtrar os dias da semana que faltam na lista original
      const missingDays = weekdaysMock.filter(
        (day) =>
          !this.schedules.some(
            (schedule) => schedule.dayOfWeek === day.dayOfWeek
          )
      );

      // Mapear a lista de dias da semana que faltam para o formato do objeto esperado e adicioná-los a lista original
      this.schedules = [
        ...this.schedules,
        ...missingDays.map((day) => ({
          weekDayIndex: day.weekDayIndex,
          dayOfWeek: day.dayOfWeek,
          scheduleStart: '00:00',
          scheduleEnd: '00:00',
          active: true,
          disabledButton: false,
        })),
      ];

      this.schedules = this.schedules.sort((a, b) => {
        return a.weekDayIndex - b.weekDayIndex;
      });

      this.workingTimes = [
        ...this.workingTimes,
        ...missingDays.map((day) => ({
          weekDayIndex: day.weekDayIndex,
          disabled: true,
        })),
      ];

      this.workingTimes = this.workingTimes.sort((a, b) => {
        return a.weekDayIndex - b.weekDayIndex;
      });

      this.formObjects.daysWeekSchedules.forEach((item) => {
        this.handleAddNewWorkingTimeInArray(item);
      });
    },

    async handleSaveCalendarSettingsInAPI() {
      delete this.formObjects.modality;
      delete this.formObjects.calendarType;
      delete this.formObjects.unit;
      delete this.formObjects.classScheduleCalendarHistory;
      this.formObjects.year = this.formObjects.year.toString();
      this.loading = true;

      try {
        if (this.isUpdate) {
          this.loadingTitle = 'Atualizando calendário, por favor aguarde...';
          this.formObjects.updatedBy = this.userInfo.id;

          await api.patch(
            `/class-schedule-calendar/${this.formObjects.id}`,
            this.formObjects,
            {
              headers: {
                token: this.token,
              },
            }
          );
          messageSuccess(`Calendário atualizado com sucesso!`);
        } else {
          if (this.isDuplicate) {
            this.formObjects.createdBy = this.userInfo.id;
            this.formObjects.countScheduleSelect =
              this.formObjects.dateSelection.length;

            delete this.formObjects.id;
            delete this.formObjects.createdAt;
            delete this.formObjects.updatedAt;
            this.loadingTitle = 'Salvando calendário, por favor aguarde...';

            this.formObjects.dateSelection = this.formObjects.dateSelection.map(
              (item) => {
                return {
                  day: item.day,
                  month: item.month,
                  year: item.year,
                  date: item.date,
                  active: item.active,
                  weekDayIndex: item.weekDayIndex,
                };
              }
            );

            this.formObjects.daysWeekSchedules =
              this.formObjects.daysWeekSchedules.map((item) => {
                return {
                  weekDayIndex: item.weekDayIndex,
                  dayOfWeek: item.dayOfWeek,
                  scheduleStart: item.scheduleStart,
                  scheduleEnd: item.scheduleEnd,
                  active: item.active,
                };
              });

            await api.post('/class-schedule-calendar', this.formObjects, {
              headers: {
                token: this.token,
              },
            });

            messageSuccess(`Calendário cadastrado com sucesso!`);
          } else {
            this.formObjects.createdBy = this.userInfo.id;
            this.formObjects.countScheduleSelect =
              this.formObjects.dateSelection.length;
            this.loadingTitle = 'Salvando calendário, por favor aguarde...';

            await api.post('/class-schedule-calendar', this.formObjects, {
              headers: {
                token: this.token,
              },
            });

            messageSuccess(`Calendário cadastrado com sucesso!`);
          }
        }
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
        this.handleCleanData(1);
      }
    },

    handleCleanData(page) {
      this.formObjects = JSON.parse(JSON.stringify(formObjects));
      this.pageCurrentSelect = page;
      this.isUpdate = false;
      this.isDuplicate = false;
      (this.schedules = JSON.parse(JSON.stringify(schedulesMock))),
        (this.workingTimes = JSON.parse(JSON.stringify(workingTimesMock))),
        (this.calendarsCreateWithMonths = []);
      this.workingTimesDisabled = [1, 2, 3, 4, 5, 6, 7];
      this.schedule = null;
      this.getInitialData();
    },

    handleDeleteCalendar() {
      // TODO: Usar a api para deletar por id um calendário da listagem
      messageSuccess('Calendário deletado com sucesso!');
    },

    handleDuplicateCalendar() {
      // TODO: Usar api para buscar um calendário por id e redirecionar para a página assim colocando uma tag CUSTOMIZADO
      messageSuccess('Calendário duplicado com sucesso!');
    },

    reloadWhenChangePage(number) {
      this.currentPagePagination = number;
      const pageStart = Number(number - 1) * Number(15);
      const pageEnd = pageStart + Number(15);

      const total = this.countTotalRegisters.slice(pageStart, pageEnd);
      this.calendarsData = total;
    },

    handleCallbackCalendarSelect(data) {
      if (data) {
        // Remove do array o item que está vindo
        let newSelections = this.formObjects.dateSelection.filter(
          (item) => item.date !== data.date
        );

        if (data.active) {
          newSelections.push(data);
        }

        if (this.isUpdate && data.id) {
          newSelections = newSelections.filter((item) => item.id !== data.id);
        }

        this.formObjects.dateSelection = newSelections;
      }
    },

    handleOpenModalAddInterval(schedule) {
      this.schedule = schedule;
      this.isModalInterval = true;
    },

    handleCallBackModalAddInterval(schedule) {
      const findIndexSchedule = this.schedules.find(
        (item) => item.weekDayIndex === schedule.weekDayIndex
      );

      const resultFindSchedule = {
        ...findIndexSchedule,
        disabledButton: true,
      };

      this.schedules.splice(
        findIndexSchedule.weekDayIndex,
        1,
        resultFindSchedule
      );

      const index = this.formObjects.daysWeekSchedules.findIndex(
        (item) => item.weekDayIndex === schedule.weekDayIndex
      );

      const findWorkingTime = this.workingTimes.find(
        (item) => item.weekDayIndex === schedule.weekDayIndex
      );

      const resultFormFindWorkingTime = {
        ...findWorkingTime,
        intervalStart1: schedule.intervalStart1,
        intervalEnd1: schedule.intervalEnd1,

        intervalStart2: schedule.intervalStart2,
        intervalEnd2: schedule.intervalEnd2,
      };

      this.schedules.splice(
        findIndexSchedule.weekDayIndex,
        1,
        resultFindSchedule
      );

      this.workingTimes.splice(
        findWorkingTime.weekDayIndex,
        1,
        resultFormFindWorkingTime
      );

      this.formObjects.daysWeekSchedules[index] = schedule;
      delete this.formObjects.daysWeekSchedules[index].disabledButton;
      this.isModalInterval = false;
    },
  },
};
</script>
