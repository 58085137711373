<template lang="">
  <h1 class="mt-10 bg-blue-500 text-white px-5 py-2 rounded-lg text-sm">
    • Tipo de sala: {{ roomType }}
  </h1>

  <section class="mt-10 flex gap-4">
    <div class="flex-1 gap-4">
      <label class="text-sm text-gray-500">
        Unidade

        <select
          v-model="unitId"
          @change="getLocalsWithUnit"
          :disabled="roomType === 'Híbrido'"
          class="border-2 border-gray-300 h-12 rounded-lg w-full text-md mt-2 pl-4"
        >
          <option :value="null" selected disabled>Selecione uma unidade</option>
          <option v-for="item in units" :key="item.id" :value="item.id">
            {{ item.name }}
          </option>
        </select>
      </label>
    </div>

    <div class="flex-1">
      <label class="text-sm text-gray-500">
        Local

        <select
          :disabled="unitId === '' || roomType === 'Híbrido' || !locals.length"
          v-model="localId"
          class="border-2 border-gray-300 h-12 rounded-lg w-full text-md mt-2 pl-4"
          @change="handleChangeLocal"
        >
          <option value="" selected disabled>Selecione um local</option>
          <option v-for="item in locals" :key="item.id" :value="item.id">
            {{ item.name }}
          </option>
        </select>
      </label>
    </div>
  </section>

  <section
    class="mt-4 border-dashed border p-2 rounded-lg"
    v-show="isChooseAuditory || roomType === 'Híbrido'"
  >
    <div
      v-for="(item, index) in chosenAuditoriums"
      :key="item.id"
      class="flex gap-4"
    >
      <div class="flex-1">
        <label class="text-sm text-gray-500">
          Auditórios

          <select
            :disabled="!auditories.length"
            @change="handleAuditoryChange($event, index)"
            class="border-2 border-gray-300 h-12 rounded-lg w-full text-md mt-2 pl-4"
          >
            <option value="" selected disabled>Selecione uma sala</option>
            <option
              v-for="room in auditories"
              :key="room.id"
              :value="room.id"
              v-show="room.maintenance === false && room.occult === false"
            >
              {{ room.name }}
            </option>
          </select>
        </label>
      </div>
      <div class="flex-1">
        <label class="text-sm text-gray-500">
          Layout da sala

          <div class="flex items-end gap-4">
            <select
              @change="handleLayoutChange($event, index)"
              v-model="chosenAuditoriums[index].layoutId"
              class="border-2 border-gray-300 h-12 rounded-lg w-full text-md mt-2 pl-4"
            >
              <option value="" selected disabled>
                Selecione um layout de sala
              </option>
              <option
                v-for="item in layouts[index]"
                :key="item.id"
                :value="item.id"
              >
                {{ item.name }}
              </option>
            </select>

            <a
              v-show="layoutInfoImage.name !== ''"
              :href="layoutInfoImage.imageUrl"
              target="_blank"
            >
              <figure>
                <img
                  class="w-14 h-12 object-cover rounded-lg border-2 border-blue-500"
                  :src="layoutInfoImage.imageUrl"
                  :alt="layoutInfoImage.name"
                />
              </figure>
            </a>
          </div>
        </label>
      </div>
      <div class="flex gap-1 mt-8">
        <button
          type="button"
          :disabled="chosenAuditoriums.length === 1"
          @click="removeAuditory(index)"
          class="bg-red-500 py-2 w-[46px] h-[45px] text-white text-sm rounded-full flex items-center justify-center disabled:opacity-70 disabled:brightness-100 disabled:cursor-not-allowed hover:brightness-90 transition-all"
        >
          <i class="ph ph-trash text-xl"></i>
        </button>
      </div>
    </div>
    <div class="flex justify-end mt-4">
      <button
        @click="addAuditory"
        type="button"
        class="bg-blue-500 py-2 text-white text-sm px-10 rounded-full flex items-center justify-center disabled:opacity-70 disabled:brightness-100 disabled:cursor-not-allowed hover:brightness-90 transition-all"
      >
        <span>Adicionar</span>
      </button>
    </div>
  </section>

  <section class="mt-4 flex gap-4">
    <div class="flex-1">
      <label class="text-sm text-gray-500">
        <div class="flex items-center justify-between">
          Data

          <div class="group relative cursor-help">
            <i class="ph ph-info text-xl"></i>

            <div
              class="absolute right-2 bottom-6 bg-gray-500 w-[200px] shadow-lg z-40 rounded-lg text-white p-2 text-center transition-all scale-0 origin-bottom-right duration-300 group-hover:scale-100"
            >
              Em caso de recorrência de data, informar as datas
            </div>
          </div>
        </div>

        <Calendar
          v-model="date"
          showIcon
          class="border-2 border-gray-300 h-12 rounded-lg w-full text-md mt-2 pl-4"
          dateFormat="dd/mm/yy"
          :manualInput="false"
          @date-select="getNameDayOfWeekByDate"
        />
      </label>
    </div>

    <div class="flex-1">
      <label class="text-sm text-gray-500">
        <div class="flex items-center justify-between">
          Horário inicial

          <div class="group relative cursor-help">
            <i class="ph ph-info text-xl"></i>

            <div
              class="absolute right-2 bottom-6 bg-gray-500 w-[200px] shadow-lg z-40 rounded-lg text-white p-2 text-center transition-all scale-0 origin-bottom-right duration-300 group-hover:scale-100"
            >
              Insira o horário de início do evento e/ou da transmissão. O espaço
              ou link ficará à disposição com 1 hora de antecedência.
            </div>
          </div>
        </div>

        <input
          type="time"
          v-model="startTime"
          :disabled="date === ''"
          class="border-2 border-gray-300 h-12 rounded-lg w-full text-md mt-2 pl-4"
        />
      </label>
    </div>

    <div class="flex-1 items-end relative">
      <div class="flex-1">
        <label class="text-sm text-gray-500">
          Horário final

          <input
            type="time"
            v-model="finalTime"
            :disabled="startTime === ''"
            class="border-2 border-gray-300 h-12 rounded-lg w-full text-md mt-2 pl-4"
          />
        </label>
      </div>
    </div>
  </section>

  <section class="mt-4">
    <div class="flex-1">
      <label class="text-sm text-gray-500">
        Tornar recorrente

        <select
          v-model="recurrent"
          class="border-2 border-gray-300 h-12 rounded-lg w-full text-md mt-2 pl-4"
        >
          <option value="" disabled selected>Selecione uma opção</option>
          <option :value="true">Sim</option>
          <option :value="false">Não</option>
        </select>
      </label>
    </div>
  </section>

  <RecurrentSolicitation
    v-show="recurrent"
    :recurrentSolicitationDataForm="recurrentSolicitationDataForm"
    @getNameDayOfWeekByDate="getNameDayOfWeekByDate"
  />

  <section class="mt-4">
    <div class="flex-1">
      <label class="text-sm text-gray-500">
        Centro de custo

        <input
          type="text"
          v-model="costCenter"
          class="border-2 border-gray-300 h-12 rounded-lg w-full text-md mt-2 pl-4"
        />
      </label>
    </div>
  </section>

  <section class="mt-4">
    <div class="flex-1">
      <label class="text-sm text-gray-500">
        Gerência

        <input
          type="text"
          v-model="manager"
          class="border-2 border-gray-300 h-12 rounded-lg w-full text-md mt-2 pl-4"
        />
      </label>
    </div>
  </section>

  <section class="mt-4">
    <div class="flex-1">
      <label class="text-sm text-gray-500">
        Diretoria

        <input
          type="text"
          v-model="board"
          class="border-2 border-gray-300 h-12 rounded-lg w-full text-md mt-2 pl-4"
        />
      </label>
    </div>
  </section>

  <section class="mt-6 flex justify-between items-center">
    <button
      @click="handleStep(1)"
      type="button"
      class="bg-blue-500 py-2 text-white text-sm px-10 rounded-full flex items-center justify-center disabled:opacity-70 disabled:brightness-100 disabled:cursor-not-allowed hover:brightness-90 transition-all"
    >
      <span>Voltar</span>
    </button>

    <button
      @click="handleStep(3)"
      :disabled="handleBottomDisabled()"
      type="button"
      class="bg-blue-500 py-2 text-white text-sm px-10 rounded-full flex items-center justify-center disabled:opacity-70 disabled:brightness-100 disabled:cursor-not-allowed hover:brightness-90 transition-all"
    >
      <span>Continuar</span>
    </button>
  </section>

  <Dialog
    v-model:visible="displayDialog"
    :style="{ width: '30vw' }"
    :modal="true"
    :closable="false"
    :showHeader="false"
  >
    <h1 class="mt-5 mb-3 text-blue-500 pb-5 text-xl">
      Deseja escolher um ou mais auditório?
    </h1>
    <div class="flex items-end justify-end">
      <button
        type="button"
        @click="handleCloseDialog()"
        class="mr-5 w-[150px] h-12 text-blue-500 border border-blue-500 rounded-full hover:brightness-90 transition-all text-lg disabled:cursor-not-allowed"
      >
        Não
      </button>
      <button
        @click="handleConfirm()"
        class="w-[150px] bg-blue-500 text-white rounded-full pt-2 pb-3 text-xl mt-5 float-right"
      >
        Sim
      </button>
    </div>
  </Dialog>
</template>
<script>
import { useRoomsService } from '../../../services';
import Calendar from 'primevue/calendar';
import RecurrentSolicitation from './recurrent-solicitation.vue';
import { format } from 'date-fns';
import ptBR from 'date-fns/locale/pt-BR';
import computed from './step-two-computed';
import { messageWarning } from '../../../lib/toast';

export default {
  name: 'StepTwo',

  components: {
    Calendar,
    RecurrentSolicitation,
  },

  data() {
    return {
      roomsService: useRoomsService(),
      layouts: [],
      displayDialog: false,
      recurrentSolicitationDataForm: {
        defaultRecurrent: 'Diário',
        toggleMonday: true,
        toggleTuesday: true,
        toggleWednesday: true,
        toggleThursday: true,
        toggleFriday: true,
        toggleSaturday: true,
        toggleSunday: true,
        valueMonday: 'segunda-feira',
        valueTuesday: 'terça-feira',
        valueWednesday: 'quarta-feira',
        valueThursday: 'quinta-feira',
        valueFriday: 'sexta-feira',
        valueSaturday: 'sábado',
        valueSunday: 'domingo',
        activeMonday: false,
        activeTuesday: false,
        activeWednesday: false,
        activeThursday: false,
        activeFriday: false,
        activeSaturday: false,
        activeSunday: false,
        endDate: '',
        repeatWeek: '1',
      },
    };
  },

  methods: {
    async handleAuditoryChange(event, index) {
      const roomId = Number(event.target.value);
      const room = this.auditories.find((item) => item.id === roomId);
      this.layouts[index] = null;
      this.chosenAuditoriums[index].layoutId = null;
      this.chosenAuditoriums[index].roomLayout = null;

      if (this.chosenAuditoriums.some((item) => item.roomId === roomId)) {
        await this.removeAuditory(index);
        await this.addAuditory();
        messageWarning('Você já escolheu esse auditório!');
        return;
      }

      this.layouts[index] = room.getLayouts;
      this.chosenAuditoriums[index].roomId = roomId;
    },

    handleLayoutChange(event, index) {
      const layoutId = Number(event.target.value);
      const layout = this.layouts[index].find((item) => item.id === layoutId);

      this.chosenAuditoriums[index].roomLayout = layout.name;
      this.chosenAuditoriums[index].layoutId = layout.id;
      this.layoutInfoImage = layout;
    },

    handleCloseDialog() {
      this.isChooseAuditory = false;
      this.displayDialog = false;
    },

    async handleConfirm() {
      this.$store.dispatch('SolicitationStore/handleGetRoomsByLocalId');
      this.displayDialog = false;
      this.chosenAuditoriums.push({
        id: 1,
        roomId: null,
        layoutId: null,
        roomLayout: null,
      });
      this.isChooseAuditory = true;
    },

    async addAuditory() {
      await this.chosenAuditoriums.push({
        id: this.chosenAuditoriums.length + 1,
        roomId: null,
        layoutId: null,
        roomLayout: null,
      });
    },

    async removeAuditory(index) {
      await this.chosenAuditoriums.splice(index, 1);
    },

    async handleChangeLocal(event) {
      const localId = Number(event.target.value);
      const local = this.locals.find((item) => item.id === localId);
      this.chosenAuditoriums = [];

      if (local.name === 'Auditórios' && this.roomType === 'Presencial') {
        this.localId = local.id;
        this.localType = 'Auditórios';
        this.displayDialog = true;
        this.auditories = [];
      } else {
        if (local.name === 'Auditórios' && this.roomType === 'On-line') {
          this.localType = 'Auditórios';
        } else {
          this.localType = 'Salas';
        }
        this.isChooseAuditory = false;
      }
    },

    getLocalsWithUnit() {
      this.$store.dispatch('SolicitationStore/handleGetLocalsWithUnit');
    },

    getNameDayOfWeekByDate() {
      const nameDayOfWeek = format(this.date, 'EEEE', {
        locale: ptBR,
      });

      this.recurrentSolicitationDataForm.activeMonday = false;
      this.recurrentSolicitationDataForm.activeTuesday = false;
      this.recurrentSolicitationDataForm.activeWednesday = false;
      this.recurrentSolicitationDataForm.activeThursday = false;
      this.recurrentSolicitationDataForm.activeFriday = false;
      this.recurrentSolicitationDataForm.activeSaturday = false;
      this.recurrentSolicitationDataForm.activeSunday = false;

      if (this.recurrentSolicitationDataForm.valueMonday === nameDayOfWeek) {
        this.recurrentSolicitationDataForm.activeMonday = true;
      }

      if (this.recurrentSolicitationDataForm.valueTuesday === nameDayOfWeek) {
        this.recurrentSolicitationDataForm.activeTuesday = true;
      }

      if (this.recurrentSolicitationDataForm.valueWednesday === nameDayOfWeek) {
        this.recurrentSolicitationDataForm.activeWednesday = true;
      }

      if (this.recurrentSolicitationDataForm.valueThursday === nameDayOfWeek) {
        this.recurrentSolicitationDataForm.activeThursday = true;
      }

      if (this.recurrentSolicitationDataForm.valueFriday === nameDayOfWeek) {
        this.recurrentSolicitationDataForm.activeFriday = true;
      }

      if (this.recurrentSolicitationDataForm.valueSaturday === nameDayOfWeek) {
        this.recurrentSolicitationDataForm.activeSaturday = true;
      }

      if (this.recurrentSolicitationDataForm.valueSunday === nameDayOfWeek) {
        this.recurrentSolicitationDataForm.activeSunday = true;
      }
    },

    handleBottomDisabled() {
      if (
        this.date === '' ||
        this.startTime === '' ||
        this.finalTime === '' ||
        this.costCenter === '' ||
        this.manager === '' ||
        this.board === '' ||
        this.localId === ''
      ) {
        return true;
      }

      if (
        this.recurrent &&
        (this.recurrentSolicitationDataForm.defaultRecurrent === '' ||
          this.recurrentSolicitationDataForm.endDate === '')
      ) {
        return true;
      }

      if (
        this.chosenAuditoriums.some(
          (item) =>
            item.roomId === null ||
            item.layoutId === null ||
            item.roomLayout === null
        )
      ) {
        return true;
      }

      return false;
    },

    handleStep(step) {
      if (this.recurrent) {
        this.$store.commit(
          'SolicitationStore/SET_RECURRENT_SOLICITATION',
          this.recurrentSolicitationDataForm
        );
      }

      const equipmentsFilter = this.equipmentsData.filter(
        (equip) => equip.getLocalId.id === this.localId && equip.type === 1
      );

      this.equipments = equipmentsFilter;

      this.$store.commit('SolicitationStore/SET_STEP', step);
    },
  },

  computed,
};
</script>
