import { useRoomsService } from '../../services';

const Rooms = {
  namespaced: true,
  state: {
    roomsdata: [],
  },

  mutations: {
    GET_DATA_ROOMS(state, payload) {
      state.roomsdata = payload;
    },
  },

  getters: {
    roomsService() {
      return useRoomsService();
    },
  },

  actions: {
    getRoomsByData({ commit, rootState, getters }) {
      const defaultStore = rootState.Default;
      defaultStore.isLoading = true;

      getters.roomsService
        .getRooms()
        .then((res) => {
          const roomsFiltered = res.filter((room) => room.active === true);
          roomsFiltered.sort((a, b) =>
            a.name.localeCompare(b.name, undefined, { sensitivity: 'base' })
          );

          commit('GET_DATA_ROOMS', roomsFiltered);
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          defaultStore.isLoading = false;
        });
    },
  },
};

export default Rooms;
