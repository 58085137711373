<template>
  <div
    class="w-full h-screen p-20 z-50 fixed left-0 top-0 backdrop-blur-sm bg-gray-600/40 flex items-center justify-center"
  >
    <div class="bg-white shadow-lg w-full h-full p-5 rounded-lg relative">
      <button
        type="button"
        @click="onToggleModalEditInterval"
        class="absolute right-2 top-2 text-white bg-blue-500 w-8 h-8 rounded-full flex items-center justify-center"
      >
        <i class="ph ph-x"></i>
      </button>

      <h1 class="pb-[29px] text-blue-500 text-2xl border-b border-gray-300">
        Editar dia da semana e horário
      </h1>

      <div class="mt-[16px] h-[calc(100%-50px)] overflow-y-auto pb-10">
        <div class="">
          <table class="w-full table-fixed">
            <thead>
              <tr class="text-xl text-gray-500">
                <td>Dia da Semana</td>
                <td>Horários</td>
                <td>Intervalo 1</td>
                <td>Intervalo 2</td>
              </tr>
            </thead>

            <tbody>
              <tr>
                <td></td>
              </tr>
              <tr>
                <td class="py-5 pr-5"></td>
                <td class="py-5 pr-5">
                  <div
                    class="flex items-center justify-between border-b border-gray-400 pb-3 text-gray-500 text-sm"
                  >
                    <span>Início</span>
                    <span>Término</span>
                  </div>
                </td>
                <td class="py-5 pr-5">
                  <div
                    class="flex items-center justify-between border-b border-gray-400 pb-3 text-gray-500 text-sm"
                  >
                    <span>Início</span>
                    <span>Término</span>
                  </div>
                </td>
                <td class="py-5 pr-5">
                  <div
                    class="flex items-center justify-between border-b border-gray-400 pb-3 text-gray-500 text-sm"
                  >
                    <span>Início</span>
                    <span>Término</span>
                  </div>
                </td>
              </tr>

              <ConfirmPopup />

              <identify-week-day
                v-for="work in workingTimes"
                :key="work.weekDayIndex"
                :onChangeValuesStartAndEnd="changeValuesStartAndEnd"
                :onRemoveValuesStartAndEnd="removeValuesStartAndEnd"
                :handleRemoveWorkingTime="handleRemoveItem"
                :work="work"
                v-show="work.dayOfWeek"
              />
            </tbody>
          </table>
        </div>
      </div>

      <div class="absolute right-8 bottom-4 flex justify-end mt-4">
        <div
          class="bg-yellow-500 text-white px-4 py-1 text-sm rounded-md flex items-center justify-center gap-2"
        >
          <i class="ph-light ph-warning"></i>

          As informações são atualizadas automaticamente.
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { messageSuccess, messageError } from '../../lib/toast';
import IdentifyWeekDay from '../rehearsal/intervals/IdentifyWeekDay.vue';
import Cookies from 'vue-cookies';

import ConfirmPopup from 'primevue/confirmpopup';
import { useConfirm } from 'primevue/useconfirm';

export default {
  name: 'ModalEditIntervalComponent',

  components: {
    IdentifyWeekDay,
    ConfirmPopup,
  },

  props: {
    onToggleModalEditInterval: Function,
    workingTimes: Array,
    handleRemoveWorkingTime: Function,
    classCalendarApiService: Object,
  },

  data() {
    return {
      userInfo: Cookies.get('user_info'),
      confirm: useConfirm(),
    };
  },

  methods: {
    async handleSave() {
      try {
        this.workingTimes.forEach(async (work) => {
          const dataToUpdate = {
            id: work.id,
            updatedBy: this.userInfo.id,
            intervalStart1: work.intervalStart1,
            intervalEnd1: work.intervalEnd1,
            intervalStart2: work.intervalStart2,
            intervalEnd2: work.intervalEnd2,
            scheduleStart: work.scheduleStart,
            scheduleEnd: work.scheduleEnd,
          };

          if (dataToUpdate.id) {
            await this.classCalendarApiService.updateDaysWeekSchedules(
              dataToUpdate
            );
          }
        });
        messageSuccess('Dias da semana atualizados com sucesso');
      } catch (error) {
        console.log(error);
        messageError('Erro ao atualizar dias da semana');
      } finally {
        this.onToggleModalEditInterval();
      }
    },

    changeValuesStartAndEnd(index, intervalOne, intervalTwo) {
      let value = this.workingTimes.find((work) => work.weekDayIndex === index);

      let newValue = '';
      if (value.intervals === 'Sem intervalo') {
        value.intervals = '';
        newValue = {
          ...value,
          intervals: value.intervals + `${intervalOne} às ${intervalTwo};`,
        };
      } else {
        newValue = {
          ...value,
          intervals: value.intervals + `${intervalOne} às ${intervalTwo};`,
        };
      }

      this.workingTimes.splice(value.weekDayIndex, 1, newValue);
      messageSuccess('Intervalo adicionado com sucesso');
    },

    removeValuesStartAndEnd(event, itemIndex, index, intervalOne, intervalTwo) {
      this.confirm.require({
        target: event.currentTarget,
        message: 'Deseja remover esse item?',
        icon: 'pi pi-exclamation-triangle',
        acceptLabel: 'Sim',
        rejectLabel: 'Não',
        accept: async () => {
          let value = this.workingTimes.find(
            (work) => work.weekDayIndex === index.weekDayIndex
          );

          let valueIndex = this.workingTimes.findIndex(
            (work) => work.weekDayIndex === index.weekDayIndex
          );

          if (itemIndex === 1) {
            value.intervalStart1 = null;
            value.intervalEnd1 = null;
          } else {
            value.intervalStart2 = null;
            value.intervalEnd2 = null;
          }

          this.workingTimes.splice(valueIndex, 1, value);
          messageSuccess('Intervalo removido com sucesso');
        },
        reject: () => {},
      });
    },

    handleRemoveItem(data) {
      this.confirm.require({
        target: event.currentTarget,
        message: 'Deseja remover esse item?',
        icon: 'pi pi-exclamation-triangle',
        acceptLabel: 'Sim',
        rejectLabel: 'Não',
        accept: async () => {
          this.handleRemoveWorkingTime(data);
        },
        reject: () => {},
      });
    },
  },
};
</script>
