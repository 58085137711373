<template>
  <div
    :class="`w-full h-screen absolute py-12 left-0 top-0 bg-modal-600 z-50 flex items-center justify-center backdrop-blur-sm transition-all duration-300 origin-center ${
      isActiveModal ? 'opacity-100 visible' : 'opacity-0 invisible'
    } ${
      userUnits.id === currentId ? 'opacity-100 visible' : 'opacity-0 invisible'
    }`"
  >
    <div
      class="bg-white max-w-[1000px] min-w-[900px] h-auto max-h-full overflow-y-auto p-6 rounded-xl shadow-lg relative"
    >
      <button
        type="button"
        @click="onToggleModal(0)"
        class="absolute right-2 top-2 text-white bg-blue-500 w-8 h-8 rounded-full flex items-center justify-center hover:bg-red-500 transition-all"
      >
        <i class="ph ph-x"></i>
      </button>

      <div class="text-2xl pb-5 text-blue-500 border-b border-gray-300">
        Selecionar unidades para o usuário
      </div>

      <div class="flex items-start gap-4">
        <div class="mt-10 flex-1">
          <label class="text-sm">Unidades selecionadas</label>

          <div class="flex flex-col gap-4 mt-2 bg-gray-100 p-2 rounded-lg">
            <div
              v-for="item in userUnits.Units"
              :key="item.id"
              class="border-2 rounded-lg border-dashed border-gray-400 px-6 py-3 flex items-center justify-between"
            >
              <h1>
                {{ item.name }}
              </h1>

              <button
                type="button"
                class="bg-red-500 px-5 py-1 text-xs text-white rounded-lg hover:brightness-90 transition-all"
                @click="handleRemoveUnit(item.id)"
              >
                Remover
              </button>
            </div>
          </div>
        </div>

        <div class="mt-10 flex-1">
          <label class="text-sm">Unidades não selecionadas</label>

          <div class="flex flex-col gap-4 mt-2 bg-gray-100 p-2 rounded-lg">
            <div
              v-for="item in unitsdata"
              :key="item.id"
              class="border-2 rounded-lg border-dashed border-gray-400 px-6 py-3 flex items-center justify-between"
            >
              <h1>
                {{ item.name }}
              </h1>

              <button
                type="button"
                v-show="profile"
                class="bg-green-500 px-5 py-1 text-xs text-white rounded-lg hover:brightness-90 transition-all"
                @click="handleAddUnit(item.id)"
              >
                Adicionar
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useToast } from 'vue-toastification';

export default {
  name: 'ModalSelectUnitsItem',
  props: {
    onToggleModal: Function,
    userUnits: Object,
    isActiveModal: Boolean,
    currentId: Number,
    userService: Object,
    profile: Boolean,
  },

  setup() {
    // Variável que armazena a notificação
    const toast = useToast();
    return { toast };
  },

  computed: {
    // Variável com todas as unidades
    unitsdata() {
      return this.$store.state.Units.unitsdata;
    },
  },

  mounted() {
    // Quando a página é carregada ele traz todas as unidades cadastradas
    this.$store.dispatch('Units/getUnitsByData');
  },

  methods: {
    // Função para adicionar uma unidade atrelada ao usuário
    async handleAddUnit(unitId) {
      try {
        await this.userService.addOrRemoveUnit(unitId, this.userUnits.id);
        this.$emit('handleGetUsers');
        this.toast.success('Unidade foi atrelada com usuário');
      } catch (err) {
        console.log(err);
      }
    },

    // Função para remover uma unidade atrelada ao usuário
    async handleRemoveUnit(unitId) {
      try {
        await this.userService.addOrRemoveUnit(unitId, this.userUnits.id, true);
        this.$emit('handleGetUsers');
        this.toast.success('Unidade foi removida do usuário');
      } catch (err) {
        console.log(err);
      }
    },
  },
};
</script>
