<template>
  <section class="mt-4 border-dashed border p-2 rounded-lg">
    <h1 class="mb-2">Recorrência personalizada</h1>
    <div v-for="item in recurrenceList" :key="item.id" class="flex gap-4">
      <div class="flex-1">
        <label class="text-sm text-gray-500">
          Data

          <Calendar
            v-model="item.date"
            showIcon
            class="border-2 border-gray-300 h-12 rounded-lg w-full text-md mt-2 pl-4"
            dateFormat="dd/mm/yy"
            :manualInput="false"
          />
        </label>
      </div>
      <div class="flex-1 flex items-center gap-4">
        <label class="text-sm text-gray-500 flex-1">
          Horário de início

          <input
            type="time"
            v-model="item.startTime"
            class="border-2 border-gray-300 h-12 rounded-lg w-full text-md mt-2 pl-4"
          />
        </label>

        <label class="text-sm text-gray-500 flex-1">
          Horário de término

          <input
            type="time"
            v-model="item.finalTime"
            class="border-2 border-gray-300 h-12 rounded-lg w-full text-md mt-2 pl-4"
          />
        </label>
      </div>
      <div class="flex gap-1 mt-8">
        <button
          type="button"
          :disabled="recurrenceList.length === 1"
          @click="handleRemoveRecurrenceItem(item.id)"
          class="bg-red-500 py-2 w-[46px] h-[45px] text-white text-sm rounded-full flex items-center justify-center disabled:opacity-70 disabled:brightness-100 disabled:cursor-not-allowed hover:brightness-90 transition-all"
        >
          <i class="ph ph-trash text-xl"></i>
        </button>
      </div>
    </div>
    <div class="flex justify-end mt-4">
      <button
        @click="handleAddRecurrenceItem()"
        type="button"
        class="bg-blue-500 py-2 text-white text-sm px-10 rounded-full flex items-center justify-center disabled:opacity-70 disabled:brightness-100 disabled:cursor-not-allowed hover:brightness-90 transition-all"
      >
        <span>Adicionar</span>
      </button>
    </div>
  </section>
</template>
<script>
import Calendar from 'primevue/calendar';
export default {
  name: 'CustomRecurrence',

  components: {
    Calendar,
  },

  props: {
    recurrenceList: {
      type: Array,
      required: true,
    },
  },

  methods: {
    handleAddRecurrenceItem() {
      this.$emit('handleAddRecurrenceItem');
    },

    handleRemoveRecurrenceItem(id) {
      this.$emit('handleRemoveRecurrenceItem', id);
    },
  },
};
</script>
