<template>
  <Dialog
    v-model:visible="displayModal"
    :style="{ width: '70vw' }"
    :modal="true"
    :closable="false"
    :showHeader="false"
  >
    <button
      @click="closeModal()"
      class="float-right bg-blue-500 text-white rounded-full py-2 w-9 h-9 mt-3 justify-self-end"
    >
      <i class="ph ph-x"></i>
    </button>
    <h1 class="border-b-2 mt-5 mb-5 text-blue-500 pb-5 text-xl">
      Editar horários
    </h1>
    <table class="w-full table-fixed">
      <thead>
        <tr class="text-xl text-gray-500">
          <td>Dia da Semana</td>
          <td>Horários</td>
          <td>Intervalo 1</td>
          <td>Intervalo 2</td>
        </tr>
      </thead>

      <tbody>
        <tr>
          <td class="py-5 pr-5"></td>
          <td class="py-5 pr-5">
            <div
              class="flex items-center justify-between border-b border-gray-400 pb-3 text-gray-500 text-sm"
            >
              <span>Início</span>
              <span>Término</span>
            </div>
          </td>
          <td class="py-5 pr-5">
            <div
              class="flex items-center justify-between border-b border-gray-400 pb-3 text-gray-500 text-sm"
            >
              <span>Início</span>
              <span>Término</span>
            </div>
          </td>
          <td class="py-5 pr-5">
            <div
              class="flex items-center justify-between border-b border-gray-400 pb-3 text-gray-500 text-sm"
            >
              <span>Início</span>
              <span>Término</span>
            </div>
          </td>
        </tr>
        <tr>
          <td class="py-2 pr-5">
            <div
              class="w-150 h-10 border-2 border-gray-500 text-gray-500 text-sm pl-4 rounded-md flex items-center"
            >
              {{
                schedule.dayOfWeek === ''
                  ? 'Você precisa adicionar esse dia'
                  : schedule.dayOfWeek
              }}
            </div>
          </td>
          <td class="pr-5">
            <div class="flex items-center justify-between">
              <input
                class="border-2 rounded-md px-3 text-sm py-2 flex-1"
                type="time"
                v-model="this.$props.schedule.scheduleStart"
              />
              <span class="text-sm px-2">às</span>
              <input
                class="border-2 rounded-md px-3 text-sm py-2 flex-1"
                type="time"
                v-model="this.$props.schedule.scheduleEnd"
              />
            </div>
          </td>
          <td class="pr-5">
            <div class="flex items-center justify-between">
              <input
                class="border-2 rounded-md px-3 text-sm py-2 flex-1"
                type="time"
                v-model="this.$props.schedule.intervalStart1"
              />
              <span class="text-sm px-2">às</span>
              <input
                class="border-2 rounded-md px-3 text-sm py-2 flex-1"
                type="time"
                v-model="this.$props.schedule.intervalEnd1"
              />
            </div>
          </td>
          <td class="pr-5">
            <div class="flex items-center justify-between">
              <input
                class="border-2 rounded-md px-3 text-sm py-2 flex-1"
                type="time"
                v-model="this.$props.schedule.intervalStart2"
              />
              <span class="text-sm px-2">às</span>
              <input
                class="border-2 rounded-md px-3 text-sm py-2 flex-1"
                type="time"
                v-model="this.$props.schedule.intervalEnd2"
              />
            </div>
          </td>
        </tr>
      </tbody>
    </table>
    <button
      @click="closeModal()"
      class="w-[150px] bg-blue-500 text-white rounded-full pt-2 pb-3 text-xl mt-5 float-right"
    >
      Salvar
    </button>
  </Dialog>
</template>
<script>
export default {
  name: 'ModalAddIntervalComponent',

  props: {
    display: Boolean,
    callBack: Function,
    schedule: Object,
  },

  methods: {
    closeModal() {
      this.displayModal = false;
      this.callBack(this.schedule);
    },
  },

  data() {
    return {
      displayModal: false,
    };
  },

  updated() {
    this.displayModal = this.display;
  },
};
</script>
