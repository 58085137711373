import api from '../lib/axios';

export class ConfigurationService {
  constructor(token) {
    this.token = token;
  }

  async getModalities() {
    try {
      const response = await api.get('/configuration/modalities', {
        headers: {
          token: this.token,
        },
      });
      return response.data;
    } catch (error) {
      console.error('Error fetching modalities', error);
    }
  }

  async getBusinessAreas() {
    try {
      const response = await api.get('/configuration/business-areas', {
        headers: {
          token: this.token,
        },
      });
      return response.data;
    } catch (error) {
      console.error('Error fetching business areas', error);
    }
  }

  async getCalendarTypes() {
    try {
      const response = await api.get('/configuration/calendar-types', {
        headers: {
          token: this.token,
        },
      });
      return response.data;
    } catch (error) {
      console.error('Error fetching calendar types', error);
    }
  }

  async getRelationshipPeriodSemester() {
    try {
      const response = await api.get(
        '/configuration/relationship-period-semester',
        {
          headers: {
            token: this.token,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error('Error fetching relationship period semester', error);
    }
  }
}
