<template>
  <div
    class="w-full h-screen absolute py-12 left-0 top-0 bg-modal-600 z-50 flex items-center justify-center backdrop-blur-sm"
  >
    <div
      class="bg-white w-[600px] h-auto max-h-full overflow-y-auto p-6 rounded-xl shadow-lg relative"
    >
      <button
        type="button"
        @click="onCloseModal"
        class="absolute right-2 top-2 text-white bg-blue-500 w-8 h-8 rounded-full flex items-center justify-center"
      >
        <i class="ph ph-x"></i>
      </button>

      <div class="text-2xl pb-5 text-blue-500 border-b border-gray-300">
        Editar perfil {{ data.id }}
      </div>

      <form
        method="post"
        @submit.prevent="handleUpdatedProfile"
        class="w-full mt-10"
      >
        <label class="text-sm text-gray-500">
          Nome do perfil

          <input
            type="text"
            v-model="data.name"
            class="border-2 border-gray-300 h-12 rounded-lg w-full text-md mt-2 pl-4"
          />
        </label>

        <label class="text-sm text-gray-500 flex flex-col mt-4">
          Descrição

          <input
            type="text"
            v-model="data.description"
            class="border-2 border-gray-300 h-12 rounded-lg w-full text-md mt-2 pl-4"
          />
        </label>

        <label class="text-sm text-gray-500 flex flex-col mt-4">
          Acessos selecionados

          <div
            class="bg-gray-100 h-[200px] overflow-y-auto p-4 rounded-lg mt-4 flex flex-col gap-2"
          >
            <div
              v-for="item in data.Access"
              :key="item.name"
              class="bg-blue-500 px-4 py-2 rounded-lg text-white flex items-center justify-between"
            >
              <span>{{ item.name }}</span>

              <button
                type="button"
                v-show="profile"
                @click="handleRemoveItem(item.id)"
                class="bg-red-500 text-white w-6 h-6 flex items-center justify-center rounded-full"
              >
                <i class="ph ph-trash text-white"></i>
              </button>
            </div>
          </div>
        </label>

        <label
          class="text-sm text-gray-500 flex flex-col mt-4"
          v-show="profile"
        >
          Acessos

          <div
            class="bg-gray-100 h-[200px] overflow-y-auto p-4 rounded-lg mt-4 flex flex-col gap-2"
          >
            <div
              v-for="item in screensData"
              :key="item.id"
              class="bg-blue-500 px-4 py-2 rounded-lg text-white flex items-center justify-between"
            >
              <span>{{ item.name }}</span>

              <button
                type="button"
                @click="handleAddItem(item.id)"
                class="bg-green-500 w-6 h-6 flex items-center justify-center rounded-full"
              >
                <i class="ph ph-plus"></i>
              </button>
            </div>
          </div>
        </label>

        <div class="mt-4">
          <label class="text-sm text-gray-500 flex flex-col mt-4">
            Ativo

            <div class="toggle-wrapper">
              <div class="switch">
                <label>
                  <input
                    v-model="data.active"
                    type="checkbox"
                    class="toggle-input"
                  />
                  <div class="slider">
                    <button class="button mt-[3px]"></button>
                  </div>
                </label>
              </div>
            </div>
          </label>
        </div>

        <button
          type="submit"
          v-show="profile"
          class="bg-blue-500 w-full text-white py-3 rounded-lg mt-10"
        >
          Salvar
        </button>
      </form>
    </div>
  </div>
</template>

<script>
import { useToast } from 'vue-toastification';
import api from '../../lib/axios';
import Cookies from 'vue-cookies';

export default {
  name: 'ModalEditProfileItem',
  props: {
    onCloseModal: Function,
    id: Number,
    data: Object,
  },
  setup() {
    const toast = useToast();
    return { toast };
  },
  data() {
    return {
      adms: [],
      screensData: [],
    };
  },
  mounted() {
    this.getAccessScreensModal();
  },
  computed: {
    unitsData() {
      return this.$store.state.Units.unitsdata;
    },

    profile() {
      return this.$store.state.Default.profile;
    },
  },
  methods: {
    handleUpdatedProfile() {
      const token = Cookies.get('token');

      if (this.data.active === true) {
        api
          .patch(
            `/profiles/${this.id}`,
            {
              name: this.data.name,
              description: this.data.description,
              active: this.data.active,
            },
            {
              headers: {
                token: token,
              },
            }
          )
          .then(() => {
            this.$store.dispatch('Profile/getProfileByData', '/profiles');
            this.onCloseModal();
          })
          .catch((err) => {
            if (err.response.status === 500) {
              this.toast.error(
                'Não é permitido criar dois perfis com o mesmo nome'
              );
            }
            console.log(err);
          });
      } else {
        if (this.data.Access[0].length > 0) {
          api
            .patch(
              `/profiles/${this.id}`,
              {
                name: this.data.name,
                description: this.data.description,
                active: this.data.active,
              },
              {
                headers: {
                  token: token,
                },
              }
            )
            .then(() => {
              this.$store.dispatch('Profile/getProfileByData', '/profiles');
              this.onCloseModal();
            })
            .catch((err) => {
              if (err.response.status === 500) {
                this.toast.error(
                  'Não é permitido criar dois perfis com o mesmo nome'
                );
              }
              console.log(err);
            });
        } else {
          this.toast.error(
            'Esse perfil não pode ser desativado, pois está em uso!'
          );
        }
      }
    },
    getAccessScreensModal() {
      const token = Cookies.get('token');
      // Api call to search screens
      api
        .get('/screens', {
          headers: {
            token: token,
          },
        })
        .then((res) => {
          this.screensData = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    handleRemoveItem(id) {
      const token = Cookies.get('token');
      api
        .patch(
          `/profiles/${this.data.id}/access`,
          {
            Access: {
              disconnect: [{ id: id }],
            },
          },
          {
            headers: {
              token: token,
            },
          }
        )
        .then(() => {
          this.$emit('getProfileById', this.data.id);
        })
        .catch((err) => {
          console.log(err);
        });
    },

    handleAddItem(id) {
      const token = Cookies.get('token');
      api
        .patch(
          `/profiles/${this.data.id}/access`,
          {
            Access: {
              connect: [{ id: id }],
            },
          },
          {
            headers: {
              token: token,
            },
          }
        )
        .then(() => {
          this.$emit('getProfileById', this.data.id);
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>
