<template>
  <DataTable
    :value="units"
    v-model:expandedRows="expandedRows"
    paginatorTemplate="FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink RowsPerPageDropdown"
    paginator
    :rows="15"
    dataKey="id"
    :rowsPerPageOptions="[15, 20, 30, 50]"
    currentPageReportTemplate="{first} de {last} de {totalRecords}"
    v-model:filters="filters"
    :globalFilterFields="['name', 'localNames']"
    filterDisplay="menu"
  >
    <template #header>
      <span class="p-input-icon-left">
        <i class="pi pi-search pt-1" />
        <input
          v-model="filters['global'].value"
          placeholder="Pesquisa global"
          class="border-2 border-gray-300 p-3 pl-10 rounded-lg w-full text-sm mt-2"
        />
      </span>
    </template>
    <Column expander style="width: 5rem" />
    <Column field="name" header="Unidade" sortable>
      <template #filter="{}">
        <MultiSelect
          v-model="this.unitsSelected"
          :options="unitsBackup"
          optionLabel="name"
          placeholder="Selecione a unidade"
          :maxSelectedLabels="3"
          class="w-full h-12 mt-2 pt-1"
          emptyMessage="Nenhuma unidade encontrada"
        />
        <div class="flex justify-between">
          <button
            @click="handleFilterClear('unitsSelected')"
            class="text-blue-500 px-5 text-sm flex items-center gap-2 py-2 mt-5 hover:brightness-90 transition-all"
          >
            Limpar
          </button>
          <button
            @click="handleFilter"
            class="bg-blue-500 text-white px-5 text-sm flex items-center mt-5 gap-2 py-2 rounded-md hover:brightness-90 transition-all"
          >
            Aplicar
          </button>
        </div>
      </template>
    </Column>
    <Column field="localNames" header="Local" sortable>
      <template #filter="{}">
        <input
          type="text"
          class="h-10 border-2 border-gray-300 px-3 rounded-lg"
          v-model="filterLocalNames"
          placeholder="Pesquisar por local"
        />
        <div class="flex justify-between">
          <button
            @click="handleFilterClear('filterLocalNames')"
            class="text-blue-500 px-5 text-sm flex items-center gap-2 py-2 mt-5 hover:brightness-90 transition-all"
          >
            Limpar
          </button>
          <button
            @click="handleFilter"
            :disabled="!filterLocalNames"
            class="bg-blue-500 text-white px-5 text-sm flex items-center mt-5 gap-2 py-2 rounded-md hover:brightness-90 transition-all"
          >
            Aplicar
          </button>
        </div>
      </template>
    </Column>
    <Column field="qtRooms" header="Qtd de Salas" sortable />
    <Column header="Ações" class="w-16">
      <template #body="{ data }">
        <button
          class="bg-blue-500 w-[30px] h-[30px] flex items-center justify-center text-white rounded-full hover:brightness-90 transition-all"
          v-tooltip.bottom="'Exportar'"
          type="button"
          @click="handleExport(data)"
        >
          <i class="ph ph-export"></i>
        </button>
      </template>
    </Column>
    <template #expansion="slotProps">
      <DataTable
        :value="handleMountAppointments(slotProps.data.appointments)"
        ref="dataTableRef"
        v-model:expandedRowGroups="expandedRowGroups"
        expandableRowGroups
        dataKey="id"
        rowGroupMode="subheader"
        groupRowsBy="roomName"
        sortField="roomName"
        sortMode="multiple"
        :multiSortMeta="[{ field: 'roomName', order: 1 }]"
      >
      <template #groupheader="slotProps">
          <span class="align-middle ml-2 font-bold leading-normal">{{ slotProps.data.roomName }}</span>
    </template>

        <Column field="eventName" header="Sala" sortable sortField="roomName">
          <template #body="{ data }">
        <span>{{ data.roomName }}</span>
      </template>
        </Column>
        <Column field="eventName" header="Nome do evento" />
        <Column field="localName" header="Local" />
        <Column field="dateFormatted" header="Data" />
        <Column field="time" header="Hora" />
        <Column field="solicitationId" header="Solicitação">
          <template #body="{ data }">
            <span
              class="cursor-pointer underline"
              @click="handleShowSolicitation(data.solicitationId)"
              >#{{ data.solicitationId }}</span
            >
            <i
              v-if="data.recurrent"
              :style="{ backgroundColor: '#37CBCF' }"
              class="ph ph-repeat p-1.5 ml-5 rounded-full text-white"
              v-tooltip.bottom="'Evento recorrente'"
            ></i>
          </template>
        </Column>
      </DataTable>
    </template>
    <template #empty> Nenhum registro encontrado </template>
  </DataTable>
</template>

<script>
import DataTable from 'primevue/datatable';
import MultiSelect from 'primevue/multiselect';
import Column from 'primevue/column';
import { FilterMatchMode } from 'primevue/api';
import { ref } from 'vue';

export default {
  name: 'DataTableUnits',

  components: {
    DataTable,
    Column,
    MultiSelect,
  },

  props: {
    units: Array,
    unitsBackup: Array,
  },

  created() {
    this.initFilters();
  },


  updated() {
    this.handleChangeElement();
  },

  data() {
    return {
      filters: null,
      expandedRows: ref([]),
      expandedRowGroups: null,
      filterLocalNames: '',
    };
  },

  methods: {
    initFilters() {
      this.filters = {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        name: { value: null, matchMode: null },
        localNames: { value: null, matchMode: null },
      };
    },

    handleExport(data) {
      this.expandedRows = [];
      this.expandedRows.push(data);
      setTimeout(() => {
        this.$refs.dataTableRef.exportCSV();
      }, 500);
    },

    handleObjectFilter() {
      return {
        unitsSelected: this.unitsSelected,
        filterLocalName: this.filterLocalNames,
      };
    },

    handleFilter() {
      this.$emit('handleUnitsFilter', this.handleObjectFilter());
    },

    handleFilterClear(field) {
      if (field === 'filterLocalNames') {
        this.filterLocalNames = '';
      } else if (field === 'unitsSelected') {
        this.unitsSelected = [];
      }
      this.$emit('handleUnitsFilter', this.handleObjectFilter());
    },

    handleChangeElement() {
      const getElement1 = document.getElementsByClassName(
        'pi pi-filter-icon pi-filter'
      );

      const getElement2 = document.getElementsByClassName('pi pi-filter-fill');

      if (this.unitsSelected.length && getElement1.length) {
        getElement1[0].className = 'pi pi-filter-fill';
        return;
      }

      if (!this.unitsSelected.length && getElement2.length) {
        getElement2[0].className = 'pi pi-filter-icon pi-filter';
      }

      if (this.filterLocalNames.length && getElement1.length) {
        getElement1[1].className = 'pi pi-filter-fill';
        return;
      }

      if (!this.filterLocalNames.length && getElement2.length) {
        getElement2[1].className = 'pi pi-filter-icon pi-filter';
      }
    },

    handleShowSolicitation(solicitationId) {
      this.$router.push(`/gerenciamento/reservas/${solicitationId}`);
    },

    handleMountAppointments(appointments) {
      return appointments.map((appointment) => {
        const roomName = appointment.getRoom
          ? appointment.getRoom.name
          : appointment.getSolicitationId.roomType === 'On-line'
          ? 'On-line'
          : '-';
        return {
          roomName: roomName,
          eventName: appointment.getSolicitationId.eventName,
          localName: appointment.getSolicitationId.getLocalId
            ? appointment.getSolicitationId.getLocalId.name
            : '-',
          dateFormatted: appointment.dateFormatted,
          time: `${appointment.startTime} - ${appointment.finalTime}`,
          solicitationId: appointment.getSolicitationId.id,
          recurrent: appointment.getSolicitationId.recurrent,
        };
      });
    },
  },

  computed: {
    unitsSelected: {
      get() {
        return this.$store.state.AdminScreen.unitsSelected;
      },
      set(value) {
        this.$store.commit('AdminScreen/SET_UNITS_SELECTED', value);
      },
    },
  },
};
</script>

<style>
.p-column-filter-menu {
  margin: 0 !important;
}
</style>
