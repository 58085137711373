<template>
  <div
    :class="`hidden md:block h-full border-r-2 bg-white border-gray-200 transition-all origin-left duration-300 ${
      isActiveSidebar ? 'w-[210px]' : 'w-20'
    }`"
  >
    <div
      class="h-24 border-b border-gray-200 flex justify-center items-center relative"
    >
      <router-link to="/app" title="Clique para voltar para a Home">
        <figure>
          <img
            src="/assets/favicon.svg"
            alt="Albert Einstein Logo"
            :class="`transition-all duration-300 ${
              isActiveSidebar ? 'w-14' : 'w-12'
            }`"
          />
        </figure>
      </router-link>

      <div class="absolute -right-4 top-3">
        <button
          type="button"
          @click="isActiveSidebar = !isActiveSidebar"
          class="bg-gray-200 w-6 h-6 text-gray-500 rounded-lg flex items-center justify-center hover:brightness-90 transition-all"
        >
          <i
            :class="
              isActiveSidebar
                ? 'ph ph-caret-double-left'
                : 'ph ph-caret-double-right'
            "
          ></i>
        </button>
      </div>
    </div>

    <!-- Menu aberto -->
    <div
      v-show="isActiveSidebar"
      class="px-10 pt-10 h-[calc(100vh-7.5rem)] overflow-x-hidden overflow-y-auto flex justify-center"
    >
      <div class="flex flex-col items-start">
        <nav-link name="Administração" url="/app"
          ><i class="ph ph-house"></i> Administração</nav-link
        >

        <div class="py-4 px-2 text-gray-400">
          <button
            class="hover:text-blue-500 transition-colors flex items-center gap-2"
            @click="handleActiveSubmenu('register')"
          >
            <i class="ph ph-list-plus"></i> Cadastrar
          </button>

          <div
            v-show="isActiveSubMenuRegister === true"
            class="mt-4 ml-2 flex flex-col items-start"
          >
            <nav-link name="Perfil" url="/add/perfil"
              ><i class="ph ph-plus"></i> Perfil</nav-link
            >
            <nav-link name="Unidades" url="/add/unidades"
              ><i class="ph ph-plus"></i> Unidades</nav-link
            >
            <nav-link name="Locais" url="/add/locais"
              ><i class="ph ph-plus"></i> Locais</nav-link
            >
            <nav-link name="Salas" url="/add/salas"
              ><i class="ph ph-plus"></i> Salas</nav-link
            >
            <nav-link name="Equipamentos" url="/add/equipamentos"
              ><i class="ph ph-plus"></i> Equipamentos</nav-link
            >
            <nav-link name="Layouts" url="/add/layout-da-sala"
              ><i class="ph ph-plus"></i> Layouts</nav-link
            >
            <nav-link name="Tipos de salas" url="/add/tipos-de-sala"
              ><i class="ph ph-plus"></i> Tipos de salas</nav-link
            >
            <nav-link name="Calendários" url="/add/calendario"
              ><i class="ph ph-plus"></i> Calendários</nav-link
            >
          </div>
        </div>

        <nav-link name="Configuração" url="/configuration"
          ><i class="ph ph-gear-six"></i> Configuração</nav-link
        >

        <div class="py-4 px-2 text-gray-400">
          <button
            class="hover:text-blue-500 transition-colors flex items-center gap-2"
            @click="handleActiveSubmenu('rehearsal')"
          >
            <i class="ph ph-chalkboard-teacher"></i> Ensalamento
          </button>

          <div
            v-show="isActiveSubMenuRehearsal === true"
            class="mt-4 ml-2 flex flex-col items-start"
          >
            <nav-link name="Calendários" url="/ensalamento/add/calendario"
              ><i class="ph ph-calendar-blank"></i> Calendários</nav-link
            >
            <nav-link
              class="truncate"
              name="Vincular curso"
              url="/ensalamento/vincular-curso"
              ><i class="ph ph-book-bookmark"></i> Vincular curso</nav-link
            >
            <nav-link
              name="Ensalamento simulação"
              url="/ensalamento/agendamento"
            >
              <i class="ph ph-clock"></i>Simulação Ensalamento</nav-link
            >
          </div>
        </div>

        <div class="py-4 px-2 text-gray-400">
          <button
            class="hover:text-blue-500 transition-colors flex items-center gap-2"
            @click="handleActiveSubmenu('occurrence')"
          >
            <i class="ph ph-warning"></i> Ocorrências
          </button>

          <div
            v-show="isActiveOcurrence === true"
            class="mt-4 ml-2 flex flex-col items-start"
          >
            <nav-link name="Criar ocorrência" url="/registro-ocorrencia"
              ><i class="ph ph-plus"></i> Criar nova</nav-link
            >
            <nav-link
              name="Gerenciar ocorrências"
              url="/gerenciamento/ocorrencias"
              ><i class="ph ph-list-dashes"></i> Gerenciar</nav-link
            >
          </div>
        </div>

        <div class="py-4 px-2 text-gray-400">
          <button
            class="hover:text-blue-500 transition-colors flex items-center gap-2"
            @click="handleActiveSubmenu('rela')"
          >
            <i class="ph ph-newspaper"></i> Relatórios
          </button>

          <div
            v-show="isActiveRela === true"
            class="mt-4 ml-2 flex flex-col items-start"
          >
            <nav-link name="Criar ocorrência" url="/relatorios"
              ><i class="ph ph-plus"></i> Relatório</nav-link
            >
            <nav-link name="Gerenciar ocorrências" url="/dashboards"
              ><i class="ph ph-list-dashes"></i> Dashboards</nav-link
            >
          </div>
        </div>

        <nav-link name="Reservas" url="/gerenciamento/reservas"
          ><i class="ph ph-swap"></i> Reservas</nav-link
        >
        <nav-link name="Solicitação" url="/solicitacao"
          ><i class="ph ph-vibrate"></i> Solicitação</nav-link
        >
        <nav-link name="Usuários" url="/gerenciamento/usuarios"
          ><i class="ph ph-users"></i> Usuários</nav-link
        >
        <nav-link name="Configuração de e-mail" url="/configuracao/email"
          ><i class="ph ph-envelope-open"></i> E-mail</nav-link
        >
      </div>
    </div>

    <!-- Menu fechado -->
    <div
      v-show="!isActiveSidebar"
      class="h-[calc(100vh-6rem)] py-10 flex flex-col items-center gap-4"
    >
      <Tooltip icon="ph ph-house" text="Home" url="/app" />

      <Tooltip icon="ph ph-list-plus" text="Cadastrar" url="" />

      <Tooltip icon="ph ph-film-strip" text="Ensalamento" url="" />

      <Tooltip icon="ph ph-warning" text="Ocorrências" url="" />

      <Tooltip icon="ph ph-newspaper" text="Relatórios" url="/relatorios" />

      <Tooltip
        icon="ph ph-swap"
        text="Reservas"
        url="/gerenciamento/reservas"
      />

      <Tooltip icon="ph ph-vibrate" text="Solicitação" url="/solicitacao" />

      <Tooltip
        icon="ph ph-users"
        text="Usuários"
        url="/gerenciamento/usuarios"
      />

      <Tooltip
        icon="ph ph-envelope-open"
        text="Configuração de e-mail"
        url="/configuracao/email"
      />
    </div>
  </div>
</template>

<script>
import NavLink from './NavLink.vue';
import Tooltip from './Tooltip.vue';

export default {
  name: 'SidebarItem',

  components: {
    NavLink,
    Tooltip,
  },

  data() {
    return {
      isActiveSidebar: true,
    };
  },

  computed: {
    isActiveSubMenuRegister() {
      return this.$store.state.Default.isActiveSubMenuRegister;
    },

    isActiveSubMenuRequests() {
      return this.$store.state.Default.isActiveSubMenuRequests;
    },

    isActiveSubMenuRehearsal() {
      return this.$store.state.Default.isActiveSubMenuRehearsal;
    },

    isActiveOcurrence() {
      return this.$store.state.Default.isActiveOcurrence;
    },

    isActiveRela() {
      return this.$store.state.Default.isActiveRela;
    },
  },

  methods: {
    handleActiveSubmenu(type) {
      if (type === 'register') {
        this.$store.state.Default.isActiveSubMenuRegister =
          !this.$store.state.Default.isActiveSubMenuRegister;
      } else if (type === 'request') {
        this.$store.state.Default.isActiveSubMenuRequests =
          !this.$store.state.Default.isActiveSubMenuRequests;
      } else if (type === 'rehearsal') {
        this.$store.state.Default.isActiveSubMenuRehearsal =
          !this.$store.state.Default.isActiveSubMenuRehearsal;
      } else if (type === 'occurrence') {
        this.$store.state.Default.isActiveOcurrence =
          !this.$store.state.Default.isActiveOcurrence;
      } else if (type === 'rela') {
        this.$store.state.Default.isActiveRela =
          !this.$store.state.Default.isActiveRela;
      }
    },
  },
};
</script>

<style scoped>
::-webkit-scrollbar {
  width: 3px;
}

::-webkit-scrollbar-thumb {
  background: #ccc;
}
</style>
