<template>
  <div class="border-r-2 border-black-500 w-[300px]">
    <section class="mb-5" v-show="!isSemesterPeriod">
      <label class="flex text-sm text-gray-500">
        {{ description }}
      </label>
      <div class="flex items-center">
        <input
          v-model="name"
          type="text"
          class="w-full border-2 border-black-500 h-11 rounded-lg mt-1 mb-2 p-2 pt-1 focus:border-blue-500 focus:border-blue-500 transition-colors outline-none"
        />
        <button type="button" :disabled="name === ''" @click="addNewItem">
          <i
            class="ph ph-plus bg-blue-500 text-white rounded-full mb-1 p-1 ml-1 mr-5 cursor-pointer"
          ></i>
        </button>
      </div>
    </section>
    <section class="mb-5" v-show="isSemesterPeriod">
      <label class="flex text-sm text-gray-500"> Período </label>
      <div class="flex items-center">
        <input
          v-model="relationshipPeriodSemester.period"
          type="number"
          class="w-full border-2 border-black-500 h-11 rounded-lg mt-1 mb-2 mr-5 p-2 pt-1 focus:border-blue-500 focus:border-blue-500 transition-colors outline-none"
        />
      </div>
      <label class="flex text-sm text-gray-500"> Semestre </label>
      <div class="flex items-center">
        <input
          v-model="relationshipPeriodSemester.semester"
          type="text"
          class="w-full border-2 border-black-500 h-11 rounded-lg mt-1 mb-2 p-2 pt-1 focus:border-blue-500 focus:border-blue-500 transition-colors outline-none"
        />
        <button
          type="button"
          :disabled="
            relationshipPeriodSemester.period === '' &&
            relationshipPeriodSemester.semester === ''
          "
          @click="addNewItem"
        >
          <i
            class="ph ph-plus bg-blue-500 text-white rounded-full p-1 ml-1 mr-5 cursor-pointer"
          ></i>
        </button>
      </div>
    </section>
    <h1 class="text-blue-500 mb-2 border-b-2 border-black-500 mr-3">
      {{ description }}
    </h1>
    <div class="flex items-center justify-center mt-6" v-show="isShowLoading">
      <div
        class="w-[50px] h-[50px] border-[6px] border-gray-200 border-t-blue-500 animate-spin rounded-full"
      ></div>
    </div>
    <ul v-show="!isShowLoading">
      <div v-show="isSemesterPeriod" class="flex border-b-2 mr-3 mt-5">
        <h1 class="text-blue-500 font-semibold w-6/12 text-sm">Período</h1>
        <h1 class="text-blue-500 font-semibold w-6/12 text-sm">Semestre</h1>
        <div class="text-white">em</div>
      </div>
      <li
        v-for="item in listData"
        :key="item.code"
        class="text-gray-400 cursor-pointer text-sm border-b-2 border-black-500 mr-3 p-3 pr-0 pl-0 flex"
        @click="editItem(item)"
      >
        <span
          class="w-6/12"
          :class="{ 'text-[red]': !item.active }"
          v-show="isSemesterPeriod"
          >{{ item.period }}</span
        ><span
          class="w-6/12"
          :class="{ 'text-[red]': !item.active }"
          v-show="isSemesterPeriod"
          >{{ item.semester }}</span
        >
        <span
          v-show="!isSemesterPeriod"
          class="w-11/12"
          :class="{ 'text-[red]': !item.active }"
          >{{ item.name }}</span
        >
        <i
          class="ph ph-pencil-simple bg-blue-500 text-white rounded-full p-1 float-right"
        ></i>
      </li>
    </ul>
    <ModalEditItem
      :display="isShowModal"
      :data="dataToEdit"
      :callBack="callBack"
      :displayName="description"
      :isSemesterPeriod="isSemesterPeriod"
    />
  </div>
</template>
<script>
import ModalEditItem from './modal-edit-item.vue';

export default {
  name: 'ConfigurationItem',
  components: {
    ModalEditItem,
  },
  props: {
    description: String,
    isSemesterPeriod: Boolean,
    createOrUpdate: Function,
    listData: Array,
    isShowLoading: Boolean,
  },

  data() {
    return {
      selectedCity: null,
      isShowModal: false,
      dataToEdit: null,
      name: '',
      relationshipPeriodSemester: {
        period: '',
        semester: '',
      },
    };
  },

  created() {},

  methods: {
    addNewItem() {
      if (this.isSemesterPeriod) {
        this.createOrUpdate(this.relationshipPeriodSemester);
        this.relationshipPeriodSemester = {};
      } else {
        this.createOrUpdate({ name: this.name });
        this.name = '';
      }
    },

    editItem(item) {
      this.dataToEdit = item;
      this.isShowModal = true;
    },

    callBack(data) {
      this.isShowModal = data.isShowModal;
      if (data.isUpdate) {
        this.createOrUpdate(data);
      }
    },
  },
};
</script>
