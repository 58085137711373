<template>
  <section class="mt-14">
    <div class="flex-1">
      <label class="text-sm text-gray-500">
        Nome

        <input
          type="text"
          v-model="name"
          disabled
          class="border-2 border-gray-300 h-12 rounded-lg w-full text-md mt-2 pl-4"
        />
      </label>
    </div>
  </section>
  <section class="mt-4">
    <div class="flex-1">
      <label class="text-sm text-gray-500">
        Ramal/Telefone

        <input
          type="text"
          v-model="branch"
          class="border-2 border-gray-300 h-12 rounded-lg w-full text-md mt-2 pl-4"
        />
      </label>
    </div>
  </section>

  <section class="mt-4">
    <div class="flex-1">
      <label class="text-sm text-gray-500">
        E-mail

        <input
          type="text"
          v-model="email"
          disabled
          class="border-2 border-gray-300 h-12 rounded-lg w-full text-md mt-2 pl-4"
        />
      </label>
    </div>
  </section>
  <section class="mt-4">
    <div class="flex-1">
      <label class="text-sm text-gray-500">
        Tipo de sala

        <select
          v-model="roomType"
          class="border-2 border-gray-300 h-12 rounded-lg w-full text-md mt-2 pl-4"
        >
          <option value="" disabled selected>Selecione o tipo de sala</option>
          <option value="On-line">On-line</option>
          <option value="Presencial">Presencial</option>
          <option value="Híbrido">Híbrido</option>
        </select>
      </label>
    </div>
  </section>
  <section class="mt-6 flex justify-between items-center">
    <button
      @click="handleStep"
      :disabled="
        branch === '' || email === '' || name === '' || roomType === ''
      "
      type="button"
      class="bg-blue-500 py-2 text-white text-sm px-10 rounded-full flex items-center justify-center disabled:opacity-70 disabled:brightness-100 disabled:cursor-not-allowed hover:brightness-90 transition-all"
    >
      <span>Continuar</span>
    </button>
  </section>
</template>
<script>
import computed from './step-one-computed';
export default {
  name: 'StepOne',

  computed,

  methods: {
    handleStep() {
      this.chosenAuditoriums = [];
      this.isChooseAuditory = false;

      if (this.roomType === 'Híbrido') {
        const unit = this.units.find((item) => item.name === 'Morumbi');
        this.unitId = unit.id;

        const local = unit.Locals.find((item) => item.name === 'Auditórios');
        this.localId = local.id;

        this.locals = unit.Locals;

        this.$store.dispatch('SolicitationStore/handleGetLocalsWithUnit');
        this.$store.dispatch('SolicitationStore/handleGetRoomsByLocalId');

        this.chosenAuditoriums = [
          {
            id: 1,
            roomId: null,
            layoutId: null,
            roomLayout: null,
          },
        ];

        this.isChooseAuditory = true;

        this.localType = 'Auditórios';
      } else {
        this.localId = '';
      }

      this.$store.commit('SolicitationStore/SET_STEP', 2);
    },
  },
};
</script>
