<template lang="">
  <Dialog
    v-model:visible="this.$props.displayChoiceComposableDialog"
    :style="{ width: '30vw' }"
    :modal="true"
    :closable="false"
    :showHeader="false"
  >
    <h1 class="mt-5 mb-3 text-blue-500 pb-5 text-xl text-center">
      Escolha um modo de recorrência
    </h1>
    <div class="flex items-end justify-between">
      <button
        type="button"
        @click="handleRecurrenceDefault()"
        class="w-[260px] bg-blue-500 text-white rounded-full p-3 text-xl mt-5 float-right"
      >
        Recorrência padrão
      </button>
      <button
        @click="handleCustomRecurrence()"
        class="w-[260px] bg-blue-500 text-white rounded-full p-3 text-xl mt-5 float-right"
      >
        Recorrência personalizada
      </button>
    </div>
  </Dialog>
</template>
<script>
export default {
  name: 'ChoiceComposableDialog',

  props: {
    displayChoiceComposableDialog: {
      type: Boolean,
      required: true,
    },
  },

  methods: {
    handleRecurrenceDefault() {
      this.$emit('handleRecurrenceDefault', true);
    },
    
    handleCustomRecurrence() {
      this.$emit('handleCustomRecurrence', false);
    },
  },
};
</script>
