<template>
  <div
    class="w-full h-screen absolute py-12 left-0 top-0 bg-modal-600 z-50 flex items-center justify-center backdrop-blur-sm"
  >
    <div
      class="bg-white w-[600px] h-auto max-h-full overflow-y-auto p-6 rounded-xl shadow-lg relative"
    >
      <button
        type="button"
        @click="onCloseModal"
        class="absolute right-2 top-2 text-white bg-blue-500 w-8 h-8 rounded-full flex items-center justify-center"
      >
        <i class="ph ph-x"></i>
      </button>

      <div class="text-2xl pb-5 text-blue-500 border-b border-gray-300">
        Editar layout da sala
      </div>

      <form
        method="post"
        @submit.prevent="handleUpdatedRoomLayout"
        class="w-full mt-10"
      >
        <label class="text-sm text-gray-500">
          Nome do layout da sala

          <input
            type="text"
            v-model="data.name"
            class="border-2 border-gray-300 h-12 rounded-lg w-full text-md mt-2 pl-4"
          />
        </label>

        <label class="text-sm text-gray-500 flex flex-col mt-4">
          Descrição

          <input
            type="text"
            v-model="data.description"
            class="border-2 border-gray-300 h-12 rounded-lg w-full text-md mt-2 pl-4"
          />
        </label>

        <label class="text-sm text-gray-500 flex flex-col mt-4">
          Subir formato da sala

          <div
            class="border-dashed border-2 border-gray-300 w-full mt-2 h-[60px] rounded-lg flex items-center justify-center hover:border-blue-500 hover:text-blue-500 cursor-pointer"
          >
            Clique para fazer o upload da imagem
          </div>

          <input hidden type="file" @change="newFileSelected" />
        </label>
        <div class="mt-4">
          <label class="text-sm text-gray-500 flex flex-col mt-4">
            Imagem do layout da sala atual

            <div
              class="border-2 border-gray-200 p-4 rounded-lg flex items-center justify-center mt-2"
            >
              <img
                v-show="data.imageUrl !== ''"
                class="w-40"
                :src="data.imageUrl"
                alt="Layout da sala"
              />
            </div>
          </label>
        </div>

        <div class="mt-4">
          <label class="text-sm text-gray-500 flex flex-col mt-4">
            Ativo

            <div class="toggle-wrapper">
              <div class="switch">
                <label>
                  <input
                    v-model="data.active"
                    type="checkbox"
                    class="toggle-input"
                  />
                  <div class="slider">
                    <button class="button mt-[3px]"></button>
                  </div>
                </label>
              </div>
            </div>
          </label>
        </div>

        <button
          v-show="profile"
          type="submit"
          class="bg-blue-500 w-full text-white py-3 rounded-lg mt-10"
        >
          Salvar
        </button>
      </form>
    </div>
  </div>
</template>

<script>
import { useToast } from 'vue-toastification';
import api from '../../lib/axios';
import Cookies from 'vue-cookies';

export default {
  name: 'ModalRoomLayoutsItem',
  props: {
    onCloseModal: Function,
    id: Number,
    data: Object,
  },
  setup() {
    const toast = useToast();
    return { toast };
  },
  computed: {
    profile() {
      return this.$store.state.Default.profile;
    },
  },
  data() {
    return {
      selectedFile: null,
      selectedFileName: '',
    };
  },
  methods: {
    handleUpdatedRoomLayout() {
      const token = Cookies.get('token');
      const data = {
        name: this.data.name,
        description: this.data.description,
        active: this.data.active,
      };
      api
        .patch(`/roomlayouts/${this.id}`, data, {
          headers: {
            token: token,
          },
        })
        .then((res) => {
          if (this.selectedFile !== null) {
            this.uploadImage();
          }
          this.onCloseModal();
          this.$store.dispatch('RoomLayouts/getRoomLayoutByData', '/locals');
        })
        .catch((err) => {
          if (err.response.status === 500) {
            this.toast.error(
              'Não é permitido criar dois layouts de sala com o mesmo nome'
            );
          }
        });
    },
    newFileSelected(event) {
      this.selectedFile = event.target.files[0];
    },
    uploadImage() {
      const token = Cookies.get('token');
      api
        .patch(
          `/roomlayouts/imageurl/${this.id}`,
          {
            image: this.selectedFile,
          },
          {
            headers: {
              'Content-Type': 'multipart/form-data',
              token: token,
            },
          }
        )
        .then(() => {
          this.selectedFile = null;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>
