import Cookies from 'vue-cookies';
import { ScheduledClassesService } from '../../services';

const ScheduledClasses = {
  namespaced: true,
  state: {
    list: [],
  },

  mutations: {
    GET_SCHEDULED_CLASSES(state, payload) {
      state.list = payload;
    },
  },

  getters: {
    scheduledClassesService() {
      const token = Cookies.get('token');
      return new ScheduledClassesService(token);
    },
  },

  actions: {
    getScheduledClasses({ commit, getters }) {
      getters.scheduledClassesService.getScheduledClasses().then((response) => {
        commit('GET_SCHEDULED_CLASSES', response);
      });
    },
  },
};

export default ScheduledClasses;
