const ClassScheduleCalendar = {
  namespaced: true,
  state: {
    days: [],
    attrs: [],
  },

  mutations: {
    SET_DAYS(state, payload) {
      state.days = payload;
    },

    SET_ATTRS(state, payload) {
      state.attrs = payload;
    },
  }
};

export default ClassScheduleCalendar;
