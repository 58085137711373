<template>
  <Schema>
    <div class="flex w-full h-full flex-col">
      <!-- Breadcrumb -->
      <div class="flex w-full mb-4 text-sm">
        <div class="mb-4 text-gray-400">
          <router-link to="/app" class="hover:text-blue-500 transition-colors">
            Home
          </router-link>
          <span class="text-blue-500"> • Simulação Ensalamento</span>
        </div>
      </div>
      <TabView v-model:activeIndex="tabActive" @tab-change="handleTabChange">
        <TabPanel header="DISPONIBILIDADE DE SALAS">
          <div class="bg-gray-100 rounded p-4">
            <div class="flex gap-5">
              <div class="flex-1">
                <h1 class="text-gray-600 mb-2 mr-3 text-2xl">
                  Pesquisar disponibilidade de salas
                </h1>
                <p class="text-gray-400 mb-5">
                  Faça uma pesquisa e encontre as melhores opções de salas
                </p>
              </div>
              <div class="flex-none w-250">
                <h1
                  class="flex text-blue-500 text-1xl font-medium items-center cursor-pointer"
                >
                  <i class="ph ph-eye mt-1 mr-2 font-bold"></i> Esconder
                </h1>
              </div>
            </div>

            <SchedulingSimulationForm
              :callBack="handleAddCourseAndClass"
              :years="years"
              :periods="periods"
              :schedulingSimulationService="schedulingSimulationService"
              :dynamicLyceumService="dynamicLyceumService"
            />

            <ConfigureEnrollmentPercentage />

            <Units
              :units="units"
              v-if="units.length && selectedCourses.length"
              :handleSelectRoom="handleSelectRoom"
            />

            <div class="flex items-end justify-end mt-10 mb-5">
              <button
                type="button"
                class="mr-5 w-60 h-12 text-blue-500 border border-blue-500 rounded-full hover:brightness-90 transition-all text-lg disabled:cursor-not-allowed"
                @click="handleClearSearch"
                v-if="!isRehearse"
              >
                Limpar pesquisar
              </button>
              <button
                type="button"
                class="w-60 h-12 bg-blue-500 text-white rounded-full hover:brightness-90 transition-all text-lg disabled:cursor-not-allowed disabled:opacity-60"
                @click="handleSearch"
                :disabled="!selectedCourses.length || !roomsSelected.length"
              >
                Pesquisar
              </button>
            </div>
          </div>
          <div
            v-if="isLoading"
            class="flex items-center justify-center mt-[35px]"
          >
            <div
              class="w-16 h-16 rounded-full flex border-2 border-t-blue-500 animate-spin"
            ></div>
          </div>
          <SchedulingSimulationResults
            :availableRooms="availableRooms"
            :scheduledClassesService="scheduledClassesService"
            :handleSearchAgain="handleSearchAgain"
          />
        </TabPanel>
        <TabPanel header="ENSALAMENTOS REALIZADOS">
          <ScheduledClasses :scheduledClasses="scheduledClasses" />
        </TabPanel>
      </TabView>
    </div>
  </Schema>
</template>

<script>
import Schema from '../../components/Schema/index.vue';
import SchedulingSimulationForm from './components/scheduling-simulation-form.vue';
import ConfigureEnrollmentPercentage from './components/configure-enrollment-percentage.vue';
import Units from './components/units.vue';
import SchedulingSimulationResults from './components/scheduling-simulation-results.vue';
import ScheduledClasses from './components/scheduled-classes.vue';
import {
  SchedulingSimulationService,
  ScheduledClassesService,
  DynamicLyceumService,
} from '../../services';
import Cookies from 'vue-cookies';
import { messageWarning } from '../../lib/toast';

export default {
  name: 'SchedulingSimulation',
  components: {
    Schema,
    SchedulingSimulationForm,
    ConfigureEnrollmentPercentage,
    Units,
    SchedulingSimulationResults,
    ScheduledClasses,
  },

  data() {
    return {
      schedulingSimulationService: null,
      scheduledClassesService: null,
      dynamicLyceumService: null,
      isLoading: false,
    };
  },

  computed: {
    scheduledClasses() {
      return this.$store.state.ScheduledClasses.list;
    },

    years() {
      return this.$store.state.SchedulingSimulation.years;
    },

    periods() {
      return this.$store.state.SchedulingSimulation.periods;
    },

    units() {
      return this.$store.state.SchedulingSimulation.units;
    },

    tabActive: {
      get() {
        return this.$store.state.SchedulingSimulation.tabActive;
      },

      set(value) {
        this.$store.commit('SchedulingSimulation/SET_TAB_ACTIVE', value);
      },
    },

    isRehearse() {
      return this.$store.state.SchedulingSimulation.isRehearse;
    },

    selectedCourses: {
      get() {
        return this.$store.state.SchedulingSimulation.selectedCourses;
      },

      set(value) {
        this.$store.commit('SchedulingSimulation/SET_SELECTED_COURSES', value);
      },
    },

    roomsSelected: {
      get() {
        return this.$store.state.SchedulingSimulation.roomsSelected;
      },

      set(value) {
        this.$store.commit('SchedulingSimulation/SET_ROOMS_SELECTED', value);
      },
    },

    availableRooms: {
      get() {
        return this.$store.state.SchedulingSimulation.availableRooms;
      },

      set(value) {
        this.$store.commit('SchedulingSimulation/SET_AVAILABLE_ROOMS', value);
      },
    },

    hasRooms: {
      set(value) {
        this.$store.commit('SchedulingSimulation/SET_HAS_ROOMS', value);
      },
    },
  },

  mounted() {
    this.$store.dispatch('SchedulingSimulation/getInitialData');
    this.$store.dispatch('SchedulingSimulation/cleanData');
    this.$store.dispatch('ScheduledClasses/getScheduledClasses');
    this.schedulingSimulationService = new SchedulingSimulationService(
      Cookies.get('token')
    );
    this.scheduledClassesService = new ScheduledClassesService(
      Cookies.get('token')
    );
    this.dynamicLyceumService = new DynamicLyceumService(Cookies.get('token'));
  },

  methods: {
    handleTabChange() {
      if (this.tabActive === 1) {
        this.$store.dispatch('ScheduledClasses/getScheduledClasses');
      }

      this.$store.dispatch('SchedulingSimulation/cleanData');
      this.handleClearSearch();
    },

    handleAddCourseAndClass(data) {
      if (this.selectedCourses.some((course) => course.class === data.class)) {
        messageWarning(
          'Você já adicionou este curso e essa turma na lista abaixo'
        );
        return;
      }

      this.$store.commit('SchedulingSimulation/SET_SELECTED_COURSES', data);
    },

    handleClearSearch() {
      this.roomsSelected = [];
      this.$store.dispatch('ScheduledClasses/getScheduledClasses');
      this.$store.dispatch('SchedulingSimulation/cleanData');
    },
    
    // Essa função é responsável por remover um determinado curso que foi selecionado anteriormente e executar uma nova pesquisa.
    handleSearchAgain(classCode) {
      this.$store.commit('SchedulingSimulation/REMOVE_SELECTED_COURSES_BY_CLASSCODE', classCode);
      this.handleSearch();
    },

    async handleSearch() {
      this.isLoading = true;
      this.availableRooms = [];

      this.hasRooms = false;

      if (!this.selectedCourses.length) {
        messageWarning('Você precisa selecionar pelo menos um curso');
        this.isLoading = false;
        return;
      }

      this.selectedCourses.forEach((course) => {
        course.rooms = this.roomsSelected.flat();
      });

      try {
        const result = await this.schedulingSimulationService.getAvailableRooms(
          this.selectedCourses
        );

        this.availableRooms = result.availableRooms;
        this.hasRooms = result.hasRooms;
      } catch (error) {
        console.log(error);
      } finally {
        this.isLoading = false;
      }
    },

    handleSelectRoom(rooms, deleteRoom = false) {
      if (deleteRoom) {
        this.roomsSelected = [];
      } else {
        rooms.forEach((room) => {
          if (!this.roomsSelected.includes(room)) {
            this.roomsSelected.push(room);
          }
        });
      }
    },
  },
};
</script>
