import { createApp } from 'vue';

import App from './App.vue';

// Rotas da aplicação
import router from './router';

// Local onde fica salvo as variáveis globais
import store from './store';

// Biblioteca para criar o calendário
import VCalendar from 'v-calendar';
import 'v-calendar/dist/style.css';

// Biblioteca para criar notificações personalizadas
import Toast from 'vue-toastification';
import 'vue-toastification/dist/index.css';

// Biblioteca para salvar em cookies as informações
import VueCookies from 'vue-cookies';

// Biblioteca de components
// Documentação: https://primevue.org/setup
import PrimeVue from 'primevue/config';
import 'primevue/resources/themes/saga-blue/theme.css';
import 'primevue/resources/primevue.min.css';
import 'primeicons/primeicons.css';
import TabView from 'primevue/tabview';
import TabPanel from 'primevue/tabpanel';
import Dialog from 'primevue/dialog';
import Tooltip from 'primevue/tooltip';
import ConfirmationService from 'primevue/confirmationservice';

createApp(App)
  .use(store)
  .use(router)
  .use(VCalendar)
  .use(VueCookies)
  .use(Toast)
  .use(PrimeVue, {
    locale: {
      startsWith: 'Starts with',
      contains: 'Contains',
      notContains: 'Not contains',
      endsWith: 'Ends with',
      equals: 'Equals',
      notEquals: 'Not equals',
      noFilter: 'No Filter',
      lt: 'Less than',
      lte: 'Less than or equal to',
      gt: 'Greater than',
      gte: 'Greater than or equal to',
      dateIs: 'Date is',
      dateIsNot: 'Date is not',
      dateBefore: 'Date is before',
      dateAfter: 'Date is after',
      clear: 'Clear',
      apply: 'Apply',
      matchAll: 'Match All',
      matchAny: 'Match Any',
      addRule: 'Add Rule',
      removeRule: 'Remove Rule',
      accept: 'Yes',
      reject: 'No',
      choose: 'Choose',
      upload: 'Upload',
      cancel: 'Cancel',
      completed: 'Completed',
      pending: 'Pending',
      monthNames: [
        'Janeiro',
        'Fevereiro',
        'Março',
        'Abril',
        'Maio',
        'Junho',
        'Julho',
        'Agosto',
        'Setembro',
        'Outubro',
        'Novembro',
        'Dezembro',
      ],
      dayNamesShort: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
      dayNamesMin: ['D', 'S', 'T', 'Q', 'Q', 'S', 'S'],
      monthNamesShort: [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Oct',
        'Nov',
        'Dec',
      ],
      chooseYear: 'Choose Year',
      chooseMonth: 'Choose Month',
      chooseDate: 'Choose Date',
      prevDecade: 'Previous Decade',
      nextDecade: 'Next Decade',
      prevYear: 'Previous Year',
      nextYear: 'Next Year',
      prevMonth: 'Previous Month',
      nextMonth: 'Next Month',
      prevHour: 'Previous Hour',
      nextHour: 'Next Hour',
      prevMinute: 'Previous Minute',
      nextMinute: 'Next Minute',
      prevSecond: 'Previous Second',
      nextSecond: 'Next Second',
      am: 'am',
      pm: 'pm',
      today: 'Today',
      weekHeader: 'Wk',
      firstDayOfWeek: 0,
      dateFormat: 'mm/dd/yy',
      weak: 'Weak',
      medium: 'Medium',
      strong: 'Strong',
      emptyFilterMessage: 'No results found', // @deprecated Use 'emptySearchMessage' option instead.
      searchMessage: '{0} results are available',
      selectionMessage: '{0} items selected',
      emptySelectionMessage: 'No selected item',
      emptySearchMessage: 'No results found',
      emptyMessage: 'No available options',
    },
  })
  .use(ConfirmationService)
  .directive('tooltip', Tooltip)
  .component('TabView', TabView)
  .component('TabPanel', TabPanel)
  .component('Dialog', Dialog)
  .mount('#app');
