export const formObjects = {
  unitId: '',
  modalityId: '',
  year: '',
  period: '',
  calendarTypeId: '',
  frequency: '',
  version: '',
  monthStart: '',
  monthEnd: '',
  observation: '',
  createdBy: 0,
  updatedBy: 0,
  deleted: false,
  daysWeekSchedules: [],
  dateSelection: [],
};
