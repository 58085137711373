<template>
  <div id="nav">
    <router-view />
    <Loading />
  </div>
</template>

<script>
import './styles/index.css';
import Loading from './components/Loading/Loading';

export default {
  components: {
    Loading,
  },
  name: 'App',
};
</script>
