<template>
  <Dialog
    v-model:visible="this.$props.isOpenModalRoomChangeConfirmation"
    :style="{ width: '60vw' }"
    :modal="true"
    :showHeader="false"
  >
    <button
      @click="handleCloseModalRoomChangeConfirmation()"
      class="float-right bg-blue-500 text-white rounded-full py-2 w-9 h-9 mt-3 justify-self-end"
    >
      <i class="ph ph-x"></i>
    </button>
    <h1 class="border-b-2 mt-5 mb-3 text-blue-500 pb-5 text-xl">
      Detalhes do agendamento
    </h1>
    <div class="flex">
      <h1 class="text-gray-500 text-xl mb-2">
        Você está mudando o agendamento da
        {{ objRoomToChange.oldRoomName }} para a
        <b>{{ objRoomToChange.newRoomName }}</b
        >. Por favor, confirme as informações abaixo:
      </h1>
    </div>
    <div class="border-b-2">
      <div class="flex justify-between">
        <h1><b>Nova Sala: </b> {{ objRoomToChange.newRoomName }}</h1>
        <h1><b>Unidade: </b> {{ objRoomToChange.unitName }}</h1>
        <h1></h1>
      </div>
      <div class="flex justify-between mb-5">
        <h1><b>Unidade: </b> {{ objRoomToChange.layoutName }}</h1>
      </div>
    </div>
    <div class="flex items-center justify-end">
      <button
        @click="handleCloseModalRoomChangeConfirmation()"
        class="text-blue-500 px-10 text-xl flex items-center gap-2 py-3 mt-5 hover:brightness-90 transition-all border-2 border-blue-500 rounded-full"
      >
        Cancelar
      </button>
      <button
        @click="handleChangeRoomConfirmation()"
        class="bg-blue-500 text-white px-10 text-xl flex items-center mt-5 gap-2 py-3 ml-2 rounded-full hover:brightness-90 transition-all border-2 border-blue-500 disabled:opacity-70 disabled:brightness-100 disabled:cursor-not-allowed disabled"
      >
        Confirmar
      </button>
    </div>
  </Dialog>
</template>
<script>
import Dialog from 'primevue/dialog';
import {
  messageError,
  messageSuccess,
  messageWarning,
} from '../../../lib/toast';
import {
  useAppointmentService,
  useSolicitationService,
  useLocalsService,
} from '../../../services';
export default {
  name: 'DialogRoomChangeConfirmation',

  components: {
    Dialog,
  },

  props: {
    isOpenModalRoomChangeConfirmation: {
      type: Boolean,
      required: true,
    },

    objRoomToChange: {
      type: Object,
      required: true,
    },

    userInfo: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      appointmentService: useAppointmentService(),
      solicitationService: useSolicitationService(),
      localsService: useLocalsService(),
    };
  },

  computed: {
    isLoading: {
      set(value) {
        this.$store.commit('Default/CHANGE_ISLOADING', value);
      },
    },
  },

  methods: {
    handleCloseModalRoomChangeConfirmation() {
      this.$emit('handleCloseModalRoomChangeConfirmation');
    },

    async handleChangeRoomConfirmation() {
      const roomId = Number(this.objRoomToChange.formObjects.roomId);
      const solicitationId = Number(this.objRoomToChange.solicitationId);
      const appointmentId = Number(this.objRoomToChange.appointmentId);
      const date = this.objRoomToChange.date;
      const startTime = this.objRoomToChange.startTime;
      const finalTime = this.objRoomToChange.finalTime;

      const payload = {
        date,
        startTime,
        finalTime,
        roomId,
      };

      const checkIsBlocked = await this.appointmentService.checkIsBlocked(
        payload
      );

      if (checkIsBlocked) {
        messageWarning(
          `A Sala: ${this.objRoomToChange.newRoomName} já reservada para esse horário!`
        );
        return;
      }

      const payloadToCheckLocal = {
        localId: Number(this.objRoomToChange.formObjects.localId),
        initialTime: startTime,
        finalTime: finalTime,
        date,
      };

      const result = await this.localsService.verifyLocalWorkingTime(
        payloadToCheckLocal
      );

      if (result && result.blocked) {
        messageWarning(
          `Horário escolhido incompatível com funcionamento do local`
        );
        return;
      }

      const resultSolicitation =
        await this.solicitationService.getSolicitationId(solicitationId);

      if (!resultSolicitation) {
        messageWarning('Não foi possível encontrar a solicitação!');
        return;
      }

      const statusSolicitationToUpdate =
        this.solicitationService.verifyStatusSolicitation(
          resultSolicitation.Appointments
        );

      const dataToUpdateAppointment = {
        roomId: roomId,
        status: 'Aprovada',
        approvedBy: this.userInfo.id,
      };

      const dataToUpdateSolicitation = {
        localId: Number(this.objRoomToChange.formObjects.localId),
        unitId: Number(this.objRoomToChange.formObjects.unitId),
        roomId: roomId,
        roomLayout: this.objRoomToChange.roomLayoutName,
        updatedBy: this.objRoomToChange.updatedBy,
        status:
          resultSolicitation.Appointments.length > 1
            ? statusSolicitationToUpdate
            : 'Aprovada',
      };

      try {
        this.isLoading = true;

        await this.appointmentService.updateAppointment(
          appointmentId,
          dataToUpdateAppointment
        );

        await this.solicitationService.updateSolicitation(
          solicitationId,
          dataToUpdateSolicitation
        );

        messageSuccess('Solicitação aprovada com sucesso!');
        this.$emit('handleChangeRoomConfirmation');
      } catch (error) {
        console.log(error);
        messageError('Erro ao aprovar solicitação!');
        this.isLoading = false;
      }
    },
  },
};
</script>
