<template>
  <div
    class="w-full h-screen absolute py-12 left-0 top-0 bg-modal-600 z-50 flex items-center justify-center backdrop-blur-sm"
  >
    <div
      class="bg-white w-[600px] h-auto max-h-full overflow-y-auto p-6 rounded-xl shadow-lg relative"
    >
      <button
        type="button"
        @click="onCloseModal"
        class="absolute right-2 top-2 text-white bg-blue-500 w-8 h-8 rounded-full flex items-center justify-center"
      >
        <i class="ph ph-x"></i>
      </button>

      <div class="text-2xl pb-5 text-blue-500 border-b border-gray-300">
        Editar Local
      </div>

      <form
        method="post"
        @submit.prevent="handleUpdatedLocal"
        class="w-full mt-10"
      >
        <label class="text-sm text-gray-500">
          Nome do local

          <input
            type="text"
            v-model="data.name"
            class="border-2 border-gray-300 h-12 rounded-lg w-full text-md mt-2 pl-4"
          />
        </label>

        <label class="text-sm text-gray-500 flex flex-col mt-4">
          Unidade

          <select
            v-model="data.unitId"
            class="border-2 border-gray-300 h-12 rounded-lg w-full text-md mt-2 pl-4"
          >
            <option value="" selected disabled>Selecione uma unidade</option>
            <option v-for="item in unitsdata" :key="item.id" :value="item.id">
              {{ item.name }}
            </option>
          </select>
        </label>

        <div class="bg-gray-100 mt-5 p-4 rounded-lg flex flex-col gap-3">
          <div
            class="flex items-center gap-3"
            v-for="item in hours"
            :key="item.workingTimeDay"
          >
            <span class="w-36 text-gray-500 text-sm">{{
              item.workingTimeDay
            }}</span>
            <div class="flex items-center gap-2">
              <input
                class="h-10 px-4 rounded-md"
                type="time"
                v-model="item.initialTime"
              />
              -
              <input
                class="h-10 px-3 rounded-md"
                type="time"
                v-model="item.endTime"
              />
            </div>
            <div class="switch">
              <label>
                <input
                  type="checkbox"
                  class="toggle-input"
                  v-model="item.active"
                />
                <div class="slider">
                  <button class="button mt-[3px]"></button>
                </div>
              </label>
            </div>
          </div>
        </div>

        <div class="mt-4">
          <label class="text-sm text-gray-500 flex flex-col mt-4">
            Ativo

            <div class="toggle-wrapper">
              <div class="switch">
                <label>
                  <input
                    v-model="data.active"
                    type="checkbox"
                    class="toggle-input"
                  />
                  <div class="slider">
                    <button class="button mt-[3px]"></button>
                  </div>
                </label>
              </div>
            </div>
          </label>
        </div>

        <button
          v-show="profile"
          type="submit"
          class="bg-blue-500 w-full text-white py-3 rounded-lg mt-10"
        >
          Salvar
        </button>
      </form>
    </div>
  </div>
</template>

<script>
import { useToast } from 'vue-toastification';
import api from '../../lib/axios';
import Cookies from 'vue-cookies';

export default {
  name: 'ModalLocalsItem',
  props: {
    onCloseModal: Function,
    id: Number,
    data: Object,
    hours: Array,
  },
  setup() {
    const toast = useToast();
    return { toast };
  },
  computed: {
    // Api call units
    unitsdata() {
      return this.$store.state.Units.unitsdata;
    },

    profile() {
      return this.$store.state.Default.profile;
    },
  },
  methods: {
    handleUpdatedLocal() {
      const token = Cookies.get('token');
      const data = {
        name: this.data.name,
        active: this.data.active,
        getUnitId: {
          connect: {
            id: Number(this.data.unitId),
          },
        },
      };

      this.hours.map((item) => {
        const data = {
          workingTimeDay: item.workingTimeDay,
          initialTime: item.initialTime,
          endTime: item.endTime,
          active: item.active,
        };

        api
          .patch(`/workingTime/${item.id}`, data, {
            headers: {
              token: token,
            },
          })
          .then((res) => {
            // console.log(res);
          })
          .catch((err) => {
            console.log(err);
          });
      });

      api
        .patch(`/locals/${this.id}`, data, {
          headers: {
            token: token,
          },
        })
        .then(() => {
          this.$store.dispatch('Default/getDataToPages', '/locals');
          this.onCloseModal();
        })
        .catch((err) => {
          if (err.response.status === 500) {
            this.toast.error(
              'Não é permitido criar dois perfis com o mesmo nome'
            );
          }
          console.log(err);
        });
    },
  },
};
</script>
