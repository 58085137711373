import api from '../../lib/axios';
import Cookies from 'vue-cookies';

const Equipments = {
  namespaced: true,
  state: {
    equipmentsdata: [],
    isLoading: true,
    localData: {},
  },
  mutations: {
    GET_DATA_EQUIPMENTS(state, payload) {
      state.equipmentsdata = payload;
    },
  },
  actions: {
    getEquipmentsByData({ commit }, payload) {
      const token = Cookies.get('token');

      api
        .get('/equipments', {
          headers: {
            token: token,
          },
        })
        .then((res) => {
          commit('GET_DATA_EQUIPMENTS', res.data);
          this.state.Equipments.isLoading = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};

export default Equipments;
