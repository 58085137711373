<template>
  <Schema>
    <form class="w-full max-w-3xl" @submit.prevent="handleAddNewUnit">
      <div class="flex w-full mb-4 text-sm">
        <div class="mb-4 text-gray-400">
          <router-link to="/app" class="hover:text-blue-500 transition-colors">
            Home
          </router-link>
          <span> • Cadastrar</span>
          <span class="text-blue-500"> • Unidade</span>
        </div>
      </div>

      <div class="flex flex-col">
        <h1 class="text-3xl text-gray-600">Cadastro de unidades</h1>
        <p class="text-gray-400 max-w-xs mt-1">
          Cadastre as unidade do Albert Einstein
        </p>
      </div>

      <section class="flex items-center gap-6 mt-14">
        <div class="flex-1">
          <label class="text-sm text-gray-500">
            Unidade

            <input
              required
              type="text"
              v-model="name"
              class="border-2 border-gray-300 h-12 rounded-lg w-full text-md mt-2 pl-4 outline-none focus:ring-2 focus:ring-blue-500 transition-all"
            />
          </label>
        </div>
        <div class="flex items-center gap-6">
          <label class="text-sm text-gray-500">
            Quantidade de salas

            <input
              required
              type="number"
              v-model="capacity"
              class="border-2 border-gray-300 h-12 rounded-lg w-full text-md mt-2 pl-4 outline-none focus:ring-2 focus:ring-blue-500 transition-all"
            />
          </label>
        </div>
      </section>

      <section class="flex items-center mt-4">
        <div class="flex-1">
          <label class="text-sm text-gray-500">
            Descrição

            <input
              type="text"
              v-model="description"
              class="border-2 border-gray-300 h-12 rounded-lg w-full text-md mt-2 pl-4 outline-none focus:ring-2 focus:ring-blue-500 transition-all"
            />
          </label>
        </div>
        <div class="inputRight"></div>
      </section>

      <section class="flex items-center mt-4">
        <div class="flex-1">
          <label class="text-sm text-gray-500">
            Observação

            <textarea
              v-model="note"
              class="border-2 border-gray-300 min-h-[120px] rounded-lg w-full text-md mt-2 p-4 outline-none focus:ring-2 focus:ring-blue-500 transition-all"
            />
          </label>
        </div>
        <div class="inputRight"></div>
      </section>

      <!-- Button save -->
      <section class="mt-6" v-show="profile">
        <button
          type="submit"
          :disabled="name === '' || capacity === 0 || isLoadingButton === true"
          class="bg-blue-500 py-4 text-white px-10 rounded-full flex items-center justify-center disabled:opacity-70 disabled:brightness-100 disabled:cursor-not-allowed disabled"
        >
          <SmallLoader v-if="isLoadingButton === true" />

          <span v-else>Salvar unidade</span>
        </button>
      </section>
    </form>

    <div
      v-if="isLoading === true"
      class="w-[50px] h-[50px] border-[6px] border-gray-200 border-t-blue-500 animate-spin rounded-full"
    ></div>

    <!-- Table -->
    <div v-else class="w-full mt-20" v-show="unitsData.length > 0">
      <!-- <TableUnity :data="unitsData" v-show="unitsData.length != 0" /> -->

      <DataTable
        :value="unitsData"
        :rowClass="rowClass"
        paginatorTemplate="FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink RowsPerPageDropdown"
        paginator
        :rows="15"
        :rowsPerPageOptions="[15, 20, 30, 50]"
        currentPageReportTemplate="{first} de {last} de {totalRecords}"
        :globalFilterFields="['name', 'description', 'capacity']"
        v-model:filters="filters"
      >
        <template #header>
          <span class="p-input-icon-left">
            <i class="pi pi-search pt-1" />
            <input
              v-model="filters['global'].value"
              placeholder="Pesquisa global"
              class="border-2 border-gray-300 p-3 pl-10 rounded-lg w-full text-sm mt-2"
            />
          </span>
        </template>
        <Column field="name" header="Nome"></Column>
        <Column field="description" header="Descrição"></Column>
        <Column field="capacity" header="Quantidade de salas"></Column>
        <Column field="id" header="Editar" class="w-20">
          <template #body="{ data }">
            <button
              type="button"
              class="bg-blue-500 text-white px-4 py-1 rounded-full hover:brightness-90 transition-all"
              @click="handleOpenModal(data.id)"
            >
              Editar
            </button>
          </template>
        </Column>
      </DataTable>

      <ModalEditUnits
        :onCloseModal="handleCloseModal"
        :id="currentIdModal"
        :data="unityDataByID"
        v-show="isOpenModal"
        @getUnitByData="getUnitByData"
      />
    </div>
  </Schema>
</template>

<script>
import Schema from '../../components/Schema/index.vue';
import SmallLoader from '../../components/Loaders/SmallLoader.vue';
import TableUnity from '../../components/Tables/TableUnits.vue';

import api from '../../lib/axios';
import { useToast } from 'vue-toastification';

import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import { FilterMatchMode } from 'primevue/api';

import { messageSuccess, messageError } from '../../lib/toast';

import ModalEditUnits from '../../components/Modal/ModalEditUnits.vue';

export default {
  name: 'UnitsView',
  components: {
    TableUnity,
    Schema,
    SmallLoader,
    DataTable,
    Column,
    ModalEditUnits,
  },

  setup() {
    const toast = useToast();
    return { toast };
  },

  data() {
    return {
      name: '',
      capacity: 0,
      description: '',
      note: '',

      isLoadingButton: false,
      filters: null,

      isOpenModal: false,
      currentIdModal: 0,
      unityDataByID: {},
    };
  },
  computed: {
    // Api call to units
    unitsData() {
      return this.$store.state.Units.unitsdata;
    },

    // Api call to loading
    isLoading() {
      return this.$store.state.Units.isLoading;
    },

    profile() {
      return this.$store.state.Default.profile;
    },
  },
  mounted() {
    // Api call Units
    this.$store.dispatch('Units/getUnitsByData', '/units');
  },

  created() {
    this.initFilters();
  },

  methods: {
    initFilters() {
      this.filters = {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      };
    },

    rowClass(data) {
      return [{ 'disabled-row': data.active === false }];
    },

    handleOpenModal(id) {
      this.isOpenModal = true;
      this.currentIdModal = id;
      this.getUnitByData(id);
    },
    handleCloseModal() {
      this.isOpenModal = false;
    },
    getUnitByData(id) {
      const token = this.$cookies.get('token');

      api
        .get(`/units/${id}`, {
          headers: {
            token: token,
          },
        })
        .then((res) => {
          this.unityDataByID = res.data;
        });
    },

    // Function to create a new Unit
    handleAddNewUnit() {
      const data = {
        active: true,
        createdBy: 1,
        updatedBy: 1,
        deleted: false,

        name: this.name,
        capacity: this.capacity,
        description: this.description,
        note: this.note,
      };

      const token = this.$cookies.get('token');
      this.isLoadingButton = true;

      api
        .post('/units', data, {
          headers: {
            token: token,
          },
        })
        .then(() => {
          messageSuccess(
            `Cadastro da unidade: ${this.name}, foi realizado com sucesso!`
          );

          this.name = '';
          this.description = '';
          this.capacity = 0;
          this.note = '';
          this.$store.dispatch('Units/getUnitsByData', '/units');
          this.isLoadingButton = false;
        })
        .catch((err) => {
          messageError(err.response.data.err);
          this.isLoadingButton = false;
        });
    },
  },
};
</script>
