<template>
  <div
    class="w-full h-screen backdrop-blur-sm z-50 absolute left-0 top-0 flex items-center justify-center"
  >
    <div class="bg-white p-10 rounded-lg shadow-xl border w-full max-w-[700px]">
      <h1 class="text-blue-500 text-xl pb-3 border-b border-gray-300">
        Não é possível {{ type }} o calendário
      </h1>
      <p class="mt-4 text-lg text-gray-500">{{ content }}</p>

      <div class="flex justify-end mt-12">
        <button
          type="button"
          title="Clique para fechar o modal"
          @click="onToggleModal"
          class="bg-blue-500 text-white w-[180px] px-7 py-2 rounded-full hover:brightness-90 transition-all"
        >
          OK
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ModalError',

  props: {
    isActive: Boolean,
    onToggleModal: Function,
    type: String,
    content: String,
    isToggleModalErrorDelete: Boolean,
  },
};
</script>
