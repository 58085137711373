<template lang="">
  <Dialog
    v-model:visible="this.$props.isOpenModalAddMultipleRooms"
    :style="{ width: '60vw' }"
    :modal="true"
    :showHeader="false"
  >
    <button
      @click="handleCloseModalRoomChange()"
      class="float-right bg-blue-500 text-white rounded-full py-2 w-9 h-9 mt-3 justify-self-end"
    >
      <i class="ph ph-x"></i>
    </button>
    <h1 class="border-b-2 mt-5 mb-3 text-blue-500 pb-5 text-xl">
      Adicionar sala(s)
    </h1>
    <section class="mt-4 border-dashed border p-2 rounded-lg">
      <div v-for="(item, index) in chosenRooms" :key="item.id">
        <div class="flex justify-between gap-2 mt-5">
          <label class="flex-1 text-sm text-gray-500">
            <div class="flex items-center gap-2">Salas</div>
            <Dropdown
              v-model="item.roomId"
              :options="roomsAvailables"
              optionLabel="name"
              placeholder="Selecione uma sala"
              class="w-full mt-2 h-12"
              optionValue="id"
              @change="handleRoomChange($event, index)"
            />
          </label>
          <label class="flex-1 text-sm text-gray-500">
            <div class="flex items-center gap-2">Layouts</div>
            <Dropdown
              v-model="item.layoutId"
              :options="roomLayouts[index]"
              optionLabel="name"
              placeholder="Selecione um layout"
              class="w-full mt-2 h-12"
              optionValue="id"
              @change="handleLayoutChange($event, index)"
            />
          </label>
          <div class="flex gap-1 mt-7">
            <button
              type="button"
              :disabled="chosenRooms.length === 1"
              @click="handleRemoveRoom(index)"
              class="bg-red-500 py-2 w-[46px] h-[45px] text-white text-sm rounded-full flex items-center justify-center disabled:opacity-70 disabled:brightness-100 disabled:cursor-not-allowed hover:brightness-90 transition-all"
            >
              <i class="ph ph-trash text-xl"></i>
            </button>
          </div>
        </div>
      </div>
      <div class="flex justify-end mt-4">
        <button
          @click="handleAddRoom"
          :disabled="
            !chosenRooms[0].roomId ||
            !chosenRooms[0].layoutId ||
            !chosenRooms[0].roomLayout
          "
          type="button"
          class="bg-blue-500 py-2 text-white text-sm px-10 rounded-full flex items-center justify-center disabled:opacity-70 disabled:brightness-100 disabled:cursor-not-allowed hover:brightness-90 transition-all"
        >
          <span>Adicionar</span>
        </button>
      </div>
    </section>
    <div class="flex items-center justify-end mt-10">
      <button
        @click="handleCloseModalRoomChange()"
        class="text-blue-500 px-10 text-xl flex items-center gap-2 py-3 mt-5 hover:brightness-90 transition-all border-2 border-blue-500 rounded-full"
      >
        Cancelar
      </button>
      <button
        @click="handleAddMultipleRooms"
        :disabled="handleBottomDisabled()"
        class="bg-blue-500 text-white px-10 text-xl flex items-center mt-5 gap-2 py-3 ml-2 rounded-full hover:brightness-90 transition-all border-2 border-blue-500 disabled:opacity-70 disabled:brightness-100 disabled:cursor-not-allowed disabled"
      >
        Adicionar sala(s)
      </button>
    </div>
  </Dialog>
</template>
<script>
import Dropdown from 'primevue/dropdown';
import { messageWarning } from '../../../lib/toast';
import {
  useAppointmentService,
  useSolicitationService,
} from '../../../services';
export default {
  name: 'DialogAddMultipleRooms',
  components: {
    Dropdown,
  },

  props: {
    isOpenModalAddMultipleRooms: {
      type: Boolean,
      required: true,
    },

    roomsAvailables: {
      type: Array,
      required: true,
    },

    solicitationData: {
      type: Object,
      required: true,
    },

    userInfo: {
      type: Object,
      required: true,
    },
  },

  computed: {
    isLoading: {
      set(value) {
        this.$store.commit('Default/CHANGE_ISLOADING', value);
      },
    },
  },

  data() {
    return {
      chosenRooms: [
        {
          id: 1,
          roomId: null,
          layoutId: null,
          roomLayout: null,
        },
      ],
      roomLayouts: [],
      appointmentService: useAppointmentService(),
      solicitationService: useSolicitationService(),
      rooms: [],
    };
  },

  methods: {
    async handleAddMultipleRooms() {
      this.isLoading = true;

      const appointments = this.solicitationData.Appointments;

      for (const chosenRoom of this.chosenRooms) {
        const appointment = appointments.find(
          (appointment) => appointment.roomId === chosenRoom.roomId
        );

        if (appointment && appointment.roomId === chosenRoom.roomId) {
          messageWarning('Você já escolheu essa sala! Escolha outra sala!');
          this.isLoading = false;
          return;
        }
      }

      const payload = {
        recurrent: this.solicitationData.recurrent,
        chosenRooms: this.chosenRooms,
        solicitationId: this.solicitationData.id,
        updatedBy: this.userInfo.id,
      };

      await this.solicitationService
        .updateSolicitationAndAppointments(payload)
        .finally(() => {
          this.handleCloseModalRoomChange(true);
          this.isLoading = false;
        });
    },

    async handleAddRoom() {
      await this.chosenRooms.push({
        id: this.chosenRooms.length + 1,
        roomId: null,
        layoutId: null,
        roomLayout: null,
      });

      const room = this.roomsAvailables.find(
        (room) => room.id === this.chosenRooms[0].roomId
      );

      this.$emit('updateRoomsAvailables', room, this.chosenRooms.length);
    },

    async handleRemoveRoom(index) {
      await this.chosenRooms.splice(index, 1);
      this.$emit('updateRoomsAvailables', null, this.chosenRooms.length);
    },

    handleBottomDisabled() {
      return this.chosenRooms.some(
        (room) => !room.roomId || !room.layoutId || !room.roomLayout
      );
    },

    async handleRoomChange(event, index) {
      const roomId = Number(event.value);
      const room = this.roomsAvailables.find((room) => room.id === roomId);

      this.roomLayouts[index] = null;
      this.chosenRooms[index].layoutId = null;
      this.chosenRooms[index].roomLayout = null;
      this.chosenRooms[index].roomId = null;

      if (
        room.composable &&
        this.chosenRooms.some((room) => room.roomId === room.composablePair)
      ) {
        messageWarning('Sala componivel indisponível! Escolha outra sala!');
        return;
      }

      if (this.chosenRooms.some((item) => item.roomId === roomId)) {
        await this.handleRemoveRoom(index);
        await this.handleAddRoom();
        messageWarning('Você já escolheu essa sala!');
        return;
      }

      this.roomLayouts[index] = room.getLayouts;
      this.chosenRooms[index].roomId = roomId;
    },

    handleLayoutChange(event, index) {
      const layoutId = Number(event.value);
      const layout = this.roomLayouts[index].find(
        (layout) => layout.id === layoutId
      );

      this.chosenRooms[index].layoutId = layoutId;
      this.chosenRooms[index].roomLayout = layout.name;
    },

    handleCloseModalRoomChange(isReload = false) {
      this.$emit('handleCloseModalAddRooms', isReload);
      this.chosenRooms = [
        {
          id: 1,
          roomId: null,
          layoutId: null,
          roomLayout: null,
        },
      ];
      this.roomLayouts = [];
    },
  },
};
</script>
