import api from '../lib/axios';

export class ScheduledClassesService {
  constructor(token) {
    this.token = token;
  }

  async getScheduledClasses() {
    try {
      const response = await api.get('/scheduled-classes', {
        headers: {
          token: this.token,
        },
      });
      return response.data;
    } catch (error) {
      console.error(
        'Error fetching scheduling simulation scheduled classes data:',
        error
      );
    }
  }

  async createScheduledClasses(data) {
    try {
      const response = await api.post('/scheduled-classes', data, {
        headers: {
          token: this.token,
        },
      });
      return response.data;
    } catch (error) {
      console.error(
        'Error creating scheduling simulation scheduled classes data:',
        error
      );
    }
  }

  async deleteScheduledClasses(id) {
    try {
      const response = await api.delete(`/scheduled-classes/${id}`, {
        headers: {
          token: this.token,
        },
      });
      return response.data;
    } catch (error) {
      console.error(
        'Error deleting scheduling simulation scheduled classes data:',
        error
      );
    }
  }
}
