<template>
  <div
    class="w-full h-screen absolute py-12 left-0 top-0 bg-modal-600 z-50 flex items-center justify-center backdrop-blur-sm"
  >
    <div
      class="bg-white w-[600px] h-auto max-h-full overflow-y-auto p-6 rounded-xl shadow-lg relative"
    >
      <button
        type="button"
        @click="onCloseModal"
        class="absolute right-2 top-2 text-white bg-blue-500 w-8 h-8 rounded-full flex items-center justify-center"
      >
        <i class="ph ph-x"></i>
      </button>

      <div class="text-2xl pb-5 text-blue-500 border-b border-gray-300">
        Editar equipamento
      </div>

      <form
        method="post"
        @submit.prevent="handleUpdatedEquipments"
        class="w-full mt-10"
      >
        <label class="text-sm text-gray-500">
          Tipo de equipamento

          <select
            v-model="data.type"
            class="border-2 border-gray-300 h-12 rounded-lg w-full text-md mt-2 pl-4"
          >
            <option value="" selected disabled>
              Selecione o tipo de equipamento
            </option>
            <option value="0">Equipamento fixo</option>
            <option value="1">Equipamento móvel</option>
          </select>
        </label>

        <label class="text-sm text-gray-500 flex flex-col mt-4">
          Número de patrimônio

          <input
            type="text"
            v-model="data.patrimony"
            class="border-2 border-gray-300 h-12 rounded-lg w-full text-md mt-2 pl-4"
          />
        </label>

        <label class="text-sm text-gray-500 flex flex-col mt-4">
          Nome do equipamento

          <input
            type="text"
            v-model="data.name"
            class="border-2 border-gray-300 h-12 rounded-lg w-full text-md mt-2 pl-4"
          />
        </label>

        <label class="text-sm text-gray-500 flex flex-col mt-4">
          Descrição

          <input
            type="text"
            v-model="data.description"
            class="border-2 border-gray-300 h-12 rounded-lg w-full text-md mt-2 pl-4"
          />
        </label>

        <label class="text-sm text-gray-500 flex flex-col mt-4">
          Local

          <select
            v-model="data.localId"
            class="border-2 border-gray-300 h-12 rounded-lg w-full text-md mt-2 pl-4"
          >
            <option value="" disabled selected>Selecione um local</option>
            <option
              v-for="item in locationsdata"
              :key="item.id"
              :value="item.id"
            >
              {{ item.name }}
            </option>
          </select>
        </label>

        <div class="mt-4">
          <label class="text-sm text-gray-500 flex flex-col mt-4">
            Ativo

            <div class="toggle-wrapper">
              <div class="switch">
                <label>
                  <input
                    v-model="data.active"
                    type="checkbox"
                    class="toggle-input"
                  />
                  <div class="slider">
                    <button class="button mt-[3px]"></button>
                  </div>
                </label>
              </div>
            </div>
          </label>
        </div>

        <button
          v-show="profile"
          type="submit"
          class="bg-blue-500 w-full text-white py-3 rounded-lg mt-10"
        >
          Salvar
        </button>
      </form>
    </div>
  </div>
</template>

<script>
import { useToast } from 'vue-toastification';
import api from '../../lib/axios';
import Cookies from 'vue-cookies';

export default {
  name: 'ModalEquipmentsItem',
  props: {
    onCloseModal: Function,
    id: Number,
    data: Object,
  },
  setup() {
    const toast = useToast();
    return { toast };
  },
  computed: {
    locationsdata() {
      return this.$store.state.Locations.locationsdata;
    },
    profile() {
      return this.$store.state.Default.profile;
    },
  },
  methods: {
    handleUpdatedEquipments() {
      const token = Cookies.get('token');

      const datalocal = {
        name: this.data.name,
        description: this.data.description,
        localId: Number(this.data.localId),
        type: Number(this.data.type),
        patrimony: this.data.patrimony,
        active: this.data.active,
      };
      api
        .patch(`/equipments/${this.id}`, datalocal, {
          headers: {
            token: token,
          },
        })
        .then(() => {
          this.onCloseModal();
          this.$store.dispatch('Equipments/getEquipmentsByData', '/equipments');
        })
        .catch((err) => {
          if (err.response.status === 500) {
            this.toast.error(
              'Não é permitido criar dois equipamentos com o mesmo patrimônio'
            );
          }
        });
    },
  },
};
</script>
