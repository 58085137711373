<template>
  <Schema>
    <form
      method="post"
      class="w-full max-w-3xl"
      @submit.prevent="handleCreateNewRoomType"
    >
      <div class="flex w-full mb-4 text-sm">
        <div class="mb-4 text-gray-400">
          <router-link to="/app" class="hover:text-blue-500 transition-colors">
            Home
          </router-link>
          <span> • Cadastrar</span>
          <span class="text-blue-500"> • Tipos de salas</span>
        </div>
      </div>

      <div class="flex flex-col">
        <h1 class="text-3xl text-gray-600">Cadastro de tipos de salas</h1>
        <p class="text-gray-400 max-w-xs mt-1">
          Cadastre os tipos de salas do Albert Einstein
        </p>
      </div>

      <section class="flex items-center gap-6 mt-14">
        <div class="flex-1">
          <label class="text-sm text-gray-500">
            Tipo de sala

            <input
              required
              type="text"
              v-model="name"
              class="border-2 border-gray-300 h-12 rounded-lg w-full text-md mt-2 pl-4"
            />
          </label>
        </div>
      </section>

      <section class="mt-4">
        <label class="text-sm text-gray-500">
          Descrição

          <input
            type="text"
            placeholder="Descrição"
            v-model="description"
            class="border-2 border-gray-300 h-12 rounded-lg w-full text-md mt-2 pl-4"
          />
        </label>
      </section>

      <section class="mt-6" v-show="profile">
        <button
          type="submit"
          :disabled="name === '' || isLoadingButton === true"
          class="bg-blue-500 py-4 text-white px-10 rounded-full flex items-center justify-center disabled:opacity-70 disabled:brightness-100 disabled:cursor-not-allowed disabled"
        >
          <SmallLoader v-if="isLoadingButton === true" />

          <span v-else>Salvar tipo de sala</span>
        </button>
      </section>
    </form>
    <div
      v-if="isLoading === true"
      class="w-[50px] h-[50px] border-[6px] border-gray-200 border-t-blue-500 animate-spin rounded-full"
    ></div>

    <!-- Table -->
    <div v-else class="w-full mt-20" v-show="roomtypesdata.length > 0">
      <!-- <TableRoomTypes :data="roomtypesdata" /> -->

      <DataTable
        :value="roomtypesdata"
        :rowClass="rowClass"
        paginatorTemplate="FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink RowsPerPageDropdown"
        paginator
        :rows="15"
        :rowsPerPageOptions="[15, 20, 30, 50]"
        currentPageReportTemplate="{first} de {last} de {totalRecords}"
        :globalFilterFields="['name', 'description']"
        v-model:filters="filters"
      >
        <template #header>
          <span class="p-input-icon-left">
            <i class="pi pi-search pt-1" />
            <input
              v-model="filters['global'].value"
              placeholder="Pesquisa global"
              class="border-2 border-gray-300 p-3 pl-10 rounded-lg w-full text-sm mt-2"
            />
          </span>
        </template>
        <Column field="name" header="Layout"></Column>
        <Column field="description" header="Descrição">
          <template #body="{ data }">
            <span :title="data.description" class="w-28 truncate block">{{
              data.description === '' ? 'Sem descrição' : data.description
            }}</span>
          </template></Column
        >

        <Column field="id" header="Editar" class="w-20">
          <template #body="{ data }">
            <button
              type="button"
              class="bg-blue-500 text-white px-4 py-1 rounded-full hover:brightness-90 transition-all"
              @click="handleOpenModal(data.id)"
            >
              Editar
            </button>
          </template>
        </Column>
      </DataTable>

      <ModalEditRoomTypes
        :onCloseModal="handleCloseModal"
        :id="currentIdModal"
        :data="roomtypesDataByID"
        v-show="isOpenModal"
      />
    </div>
  </Schema>
</template>

<script>
import Schema from '../../components/Schema/index.vue';
import SmallLoader from '../../components/Loaders/SmallLoader.vue';
import TableRoomTypes from '../../components/Tables/TableRoomTypes.vue';

import ModalEditRoomTypes from '../../components/Modal/ModalEditRoomTypes.vue';

import api from '../../lib/axios';
import { useToast } from 'vue-toastification';

import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import { FilterMatchMode } from 'primevue/api';

import { messageSuccess, messageError } from '../../lib/toast';

export default {
  name: 'RoomTypesView',
  components: {
    Schema,
    TableRoomTypes,
    SmallLoader,
    DataTable,
    Column,
    ModalEditRoomTypes,
  },
  setup() {
    const toast = useToast();
    return { toast };
  },
  data() {
    return {
      name: '',
      description: '',
      isLoadingButton: false,

      isOpenModal: false,
      currentIdModal: 0,
      roomtypesDataByID: {},

      filters: null,
    };
  },

  computed: {
    roomtypesdata() {
      return this.$store.state.RoomTypes.roomtypesdata;
    },
    isLoading() {
      return this.$store.state.RoomTypes.isLoading;
    },
    profile() {
      return this.$store.state.Default.profile;
    },
  },

  mounted() {
    this.$store.dispatch('RoomTypes/getRoomTypesByData');
  },

  created() {
    this.initFilters();
  },

  methods: {
    initFilters() {
      this.filters = {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      };
    },

    rowClass(data) {
      return [{ 'disabled-row': data.active === false }];
    },

    handleOpenModal(id) {
      this.isOpenModal = true;
      this.currentIdModal = id;
      this.getRoomTypesById(id);
    },
    handleCloseModal() {
      this.isOpenModal = false;
    },
    getRoomTypesById(id) {
      const token = this.$cookies.get('token');

      api
        .get(`/roomtypes/${id}`, {
          headers: {
            token: token,
          },
        })
        .then((res) => {
          this.roomtypesDataByID = res.data;
        });
    },

    handleCreateNewRoomType() {
      const data = {
        name: this.name,
        description: this.description,
        createdBy: 1,
        updatedBy: 1,
        active: true,
        deleted: false,
      };

      const token = this.$cookies.get('token');

      this.isLoadingButton = true;
      api
        .post('/roomtypes', data, {
          headers: {
            token: token,
          },
        })
        .then(() => {
          messageSuccess(
            `Cadastro do tipo de sala: ${this.name}, foi realizado com sucesso!`
          );

          this.name = '';
          this.description = '';

          this.$store.dispatch('RoomTypes/getRoomTypesByData');
          this.isLoadingButton = false;
        })
        .catch((err) => {
          messageError(err.response.data.err);
          this.isLoadingButton = false;
        });
    },
  },
};
</script>
