export const formObjectMock = {
  unitId: 10,
  modalityId: 1,
  calendarTypeId: 1,
  year: '2023',
  period: '55',
  frequency: 'Testando',
  version: 'Paulista - Center 3 2023 55 Tipo de calendario 01',
  observation: 'Testando',
  monthStart: '2023-03',
  monthEnd: '2023-04',
  createdAt: new Date(),
  createdBy: 11,
  updatedAt: new Date(),
  updatedBy: 0,
  deleted: false,
  daysWeekSchedules: [],
  dateSelection: [],
};

export const formObjectEmptyMock = {
  unitId: 0,
  modalityId: 0,
  calendarTypeId: 0,
  year: '',
  period: '',
  frequency: '',
  version: '',
  observation: '',
  monthStart: '',
  monthEnd: '',
  createdAt: new Date(),
  createdBy: 0,
  updatedAt: new Date(),
  updatedBy: 0,
  deleted: false,
  daysWeekSchedules: [],
  dateSelection: [],
};
