<template>
  <table class="w-full">
    <thead>
      <tr class="border-b border-gray-300">
        <td class="text-blue-500 py-5">Perfil</td>
        <td class="text-blue-500 py-5">Descrição</td>
      </tr>
    </thead>
    <tbody>
      <tr v-for="item in data" :key="item.id" class="border-b border-gray-300">
        <ModalEditProfile
          :onCloseModal="handleCloseModal"
          :id="currentIdModal"
          :data="profileDataByID"
          v-show="isOpenModal === true && item.id === currentIdModal"
          @getProfileById="getProfileById"
        />
        <td
          :class="
            item.active === false ? 'text-red-500 py-5' : 'text-gray-400 py-5'
          "
        >
          {{ item.name }}
        </td>
        <td
          :title="item.description"
          :class="
            item.active === false
              ? 'text-red-500 py-5 pr-10 block w-32 white-space text-ellipsis truncate'
              : 'text-gray-400 py-5 pr-10 block w-36 white-space text-ellipsis truncate'
          "
        >
          {{ item.description === '' ? 'Sem descrição' : item.description }}
        </td>
        <td>
          <button
            type="button"
            @click="handleOpenModal(item.id)"
            class="bg-blue-500 text-white px-4 py-1 rounded-full hover:brightness-90 transition-all"
          >
            Editar
          </button>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import ModalEditProfile from '../Modal/ModalEditProfile.vue';
import api from '../../lib/axios';
import Cookies from 'vue-cookies';

export default {
  name: 'TableProfileItem',
  props: {
    data: Array,
  },
  components: {
    ModalEditProfile,
  },
  data() {
    return {
      isOpenModal: false,
      currentIdModal: 0,
      profileDataByID: {},
    };
  },
  methods: {
    handleOpenModal(id) {
      this.isOpenModal = true;
      this.currentIdModal = id;
      this.getProfileById(id);
    },
    handleCloseModal() {
      this.isOpenModal = false;
    },
    getProfileById(id) {
      const token = Cookies.get('token');

      api
        .get(`/profiles/${id}`, {
          headers: {
            token: token,
          },
        })
        .then((res) => {
          this.profileDataByID = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>
