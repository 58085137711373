export const weekdaysMock = [
  {
    weekDayIndex: 0,
    dayOfWeek: 'Segunda-Feira',
  },
  {
    weekDayIndex: 1,
    dayOfWeek: 'Terça-Feira',
  },
  {
    weekDayIndex: 2,
    dayOfWeek: 'Quarta-Feira',
  },
  {
    weekDayIndex: 3,
    dayOfWeek: 'Quinta-Feira',
  },
  {
    weekDayIndex: 4,
    dayOfWeek: 'Sexta-Feira',
  },
  {
    weekDayIndex: 5,
    dayOfWeek: 'Sábado',
  },
  {
    weekDayIndex: 6,
    dayOfWeek: 'Domingo',
  },
];
