<template>
  <section class="mt-4 flex items-start gap-8">
    <div class="flex-1">
      <label class="text-sm text-gray-500">
        Equipamentos

        <div
          class="bg-gray-200 p-4 rounded-lg flex flex-col gap-2 h-[300px] overflow-y-auto"
        >
          <div
            v-for="item in equipments"
            :key="item.id"
            class="bg-blue-500 flex items-center justify-between text-white px-4 py-2 rounded-lg hover:brightness-90 transition-all cursor-pointer"
            @click="handleAddEquipment(item.id, item.name)"
          >
            <span>{{ item.name }}</span>
            <div
              class="w-6 ml-auto h-6 bg-green-500 flex items-center justify-center text-white rounded-lg"
            >
              <i class="ph ph-plus"></i>
            </div>
          </div>
        </div>
      </label>
    </div>
    <div class="flex-1">
      <label class="text-sm text-gray-500">
        Equipamentos selecionados

        <div
          class="bg-gray-200 p-4 rounded-lg flex flex-col gap-2 h-[300px] overflow-y-auto"
        >
          <div
            v-for="item in equipmentsSelect"
            :key="item.id"
            class="bg-blue-500 flex items-center justify-between text-white px-4 py-2 rounded-lg hover:brightness-90 transition-all cursor-pointer"
            @click="handleRemoveEquipment(item.id, item.name)"
          >
            <span>{{ item.name }}</span>
            <div
              class="w-6 ml-auto h-6 bg-red-500 flex items-center justify-center text-white rounded-lg"
            >
              <i class="ph ph-trash"></i>
            </div>
          </div>
        </div>
      </label>
    </div>
  </section>
</template>
<script>
export default {
  name: 'EquipmentSelection',
  props: {
    equipments: Array,
    equipmentsSelect: Array,
    handleAddEquipment: Function,
    handleRemoveEquipment: Function,
  },
};
</script>
